import {
    TABS_PROCESSING,
    TABS_ERROR,
    SET_TABS_DATA,
    SET_CURRENT_TAB,
    SET_TABS_STORED_DATA,
    SET_CURRENT_TAB_DATA_ERROR,
    SET_CURRENT_TAB_DATA_PROCESSING,
    SET_CURRENT_TAB_DATA,
    SET_CURRENT_REVISIONS,
    SET_PAGE_SIZE,
    SET_TAB_DATA_COUNT,
    SET_SELECTED_REVISION,
    SET_ATTACHMENT_REVISIONS,
    SET_ATTACHMENT_REVISIONS_PROCESSING
} from '../action-types/item-form-action-types';
import { MIN_PAGE_SIZE } from '../config';
const initState = () => {
    return {
        tabsData: null,
        currentTab: null,
        tabsProcessing: false,
        tabsError: null,
        tabsStoredData: {},

        currentTabData: null,
        currentTabProcessing: null,
        currentTabError: null,

        currentRevisions: null,
        selectedRevision: '',

        totalTabData: 0,
        pageSizeTab: MIN_PAGE_SIZE,

        attachmentRevisions:[],
        attachmentRevisionsProcessing:false,
    };
};

const itemForm_reducer = (state = initState(), action: any) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_TABS_DATA:
            setData(newState, action.payload, 'tabsData');
            break;

        case SET_CURRENT_TAB:
            setData(newState, action.payload, 'currentTab');
            break;

        case TABS_PROCESSING:
            setData(newState, action.payload, 'tabsProcessing');
            break;

        case TABS_ERROR:
            setData(newState, action.payload, 'tabsError');
            break;

        case SET_TABS_STORED_DATA:
            setData(newState, action.payload, 'tabsStoredData');
            break;

        // cases for current tab data

        case SET_CURRENT_TAB_DATA:
            setData(newState, action.payload, 'currentTabData');
            break;

        case SET_CURRENT_TAB_DATA_PROCESSING:
            setData(newState, action.payload, 'currentTabProcessing');
            break;

        case SET_CURRENT_TAB_DATA_ERROR:
            setData(newState, action.payload, 'currentTabError');
            break;

        case SET_CURRENT_REVISIONS:
            setData(newState, action.payload, 'currentRevisions');
            break;

        case SET_SELECTED_REVISION:
            setData(newState, action.payload, 'selectedRevision');
            break;

        case SET_TAB_DATA_COUNT:
            setData(newState, action.payload, 'totalTabData');
            break;

        case SET_PAGE_SIZE:
            setData(newState, action.payload, 'pageSizeTab');
            break;

        case SET_ATTACHMENT_REVISIONS:
            setData(newState,action.payload,'attachmentRevisions')
            break;

        case SET_ATTACHMENT_REVISIONS_PROCESSING:
            setData(newState,action.payload,'attachmentRevisionsProcessing')
            break;

        default:
            break;
    }

    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default itemForm_reducer;
