import {
    SET_CATEGORIES_PROCESSING,
    SET_CATEGORIES_ERROR,
    GET_ALL_CATEGORIES,
    SET_TOTAL,
    SET_EDITION_CATEGORY,
    SET_ADD_CATEGORY_COURSE,
    SET_DETAILS_CATEGORY,
    SET_CAT_COURSES_TOTAL,
    SET_PAGE_SIZE_CATEGORIES,
} from '../action-types/categories-action-types';

const initState = () => {
    return {
        processing: false,
        message: 'Unable to find user data',
        error: null,
        total: null,
        totalCategoryCourses: null,
        allCategories: [],
        editingCategory: null,
        detailCategory: null,
        categoryCourseVal: null,
        pageSizeCategories: 8,
    };
};

const categories_reducer = (state = initState(), action: any) => {
    let newState = { ...state };

    switch (action.type) {
        case GET_ALL_CATEGORIES:
            setData(newState, action.payload, 'allCategories');
            break;
        case SET_CATEGORIES_ERROR:
            setData(newState, action.payload, 'error');
            break;
        case SET_CATEGORIES_PROCESSING:
            setData(newState, action.payload, 'processing');
            break;
        case SET_TOTAL:
            setData(newState, action.payload, 'total');
            break;
        case SET_CAT_COURSES_TOTAL:
            setData(newState, action.payload, 'totalCategoryCourses');
            break;
        case SET_EDITION_CATEGORY:
            setData(newState, action.payload, 'editingCategory');
            break;
        case SET_DETAILS_CATEGORY:
            setData(newState, action.payload, 'detailCategory');
            break;
        case SET_ADD_CATEGORY_COURSE:
            setData(newState, action.payload, 'categoryCourseVal');
            break;
        case SET_PAGE_SIZE_CATEGORIES:
            setData(newState, action.payload, 'pageSizeCategories');
            break;
        default:
            break;
    }

    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default categories_reducer;
