import {
    getClassPermissionData,
    getError,
    getTabsPermissionData,
    getUsergroupClassData,
    setSelectedUsergroup,
    setClassEditData,
    getClasses,
    getTabs,
    setTabsEditData,
    setPageSizeClass,
    setPageSizeTabs,
    setTotal,
    setClassProcessing,
    setTabsProcessing,
} from '../actions/permission';
import axios from '../utils/axios';

export const fetchClassPermission = (page?: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(getClassPermissionData(null));
        const { pageSizeClass }: any = getState().permission_reducer;
        let url = `/api/usergroups?Expand=[classes]`;
        if (page) {
            url = `/api/usergroups?Expand=[classes]&Page=${page - 1}&PageSize=${pageSizeClass}`;
        }
        dispatch(setClassProcessing(true));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.results) {
                    dispatch(getClassPermissionData(response.data.result.results));
                    dispatch(setTotal(response.data.result.total));
                    dispatch(setClassProcessing(false));
                } else {
                    dispatch(getClassPermissionData(response.data.result));
                    dispatch(setClassProcessing(false));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const fetchClasses = () => {
    return (dispatch: any) => {
        dispatch(getClasses(null));
        let url = `/api/classes`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(getClasses(response.data.result));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const fetchTabs = () => {
    return (dispatch: any) => {
        dispatch(getTabs(null));
        let url = `/api/tabs?IsActive=true`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(getTabs(response.data.result));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const fetchTabsPermission = (page?: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(getTabsPermissionData(null));
        const { pageSizeTabs }: any = getState().permission_reducer;
        let url = `/api/usergroups?Expand=[tabs]`;
        if (page) {
            url = `/api/usergroups?Expand=[tabs]&Page=${page - 1}&PageSize=${pageSizeTabs}`;
        }
        dispatch(setTabsProcessing(true));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.results) {
                    dispatch(getTabsPermissionData(response.data.result.results));
                    dispatch(setTotal(response.data.result.total));
                    dispatch(setTabsProcessing(false));
                } else {
                    dispatch(getTabsPermissionData(response.data.result));
                    dispatch(setTabsProcessing(false));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const fetchusergroupClass = (id: any) => {
    return (dispatch: any) => {
        dispatch(getUsergroupClassData(null));
        let url = `/api/usergroups/${id}?Expand=[classes]`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(getUsergroupClassData(response.data.result));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const setUsergroupSelected = (data: any) => {
    return (dispatch: any) => {
        dispatch(setSelectedUsergroup(data));
    };
};

export const setClassEdit = (data: any) => {
    return (dispatch: any) => {
        dispatch(setClassEditData(data));
    };
};

export const setTabsEdit = (data: any) => {
    return (dispatch: any) => {
        dispatch(setTabsEditData(data));
    };
};

export const setNewPageSizeClass = (value: any) => {
    return (dispatch: any) => {
        dispatch(setPageSizeClass(value));
    };
};

export const setNewPageSizeTabs = (value: any) => {
    return (dispatch: any) => {
        dispatch(setPageSizeTabs(value));
    };
};
