import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ClassPermission from './ClassPermission';
import TabsPermission from './TabsPermission';
import FeatureComingSoon from '../../components/FeatureComingSoon';
import RulePermissions from './RulePermissions';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function a11yProps(index: any) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
}

export default function SimpleTabs() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    return (
        <div>
            <AppBar position="static" style={{ marginTop: 20, backgroundColor: '#ffffff' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="off"
                    aria-label="scrollable prevent tabs example"
                    textColor="primary"
                >
                    <Tab label={'Class'} aria-label="phone" {...a11yProps(0)} />
                    <Tab label={'Tabs'} aria-label="favorite" {...a11yProps(1)} />
                    <Tab label={'Attributes'} aria-label="favorite" {...a11yProps(2)} />
                    <Tab label={'Permission Rules'} aria-label="favorite" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <div style={{ backgroundColor: '#f4f6f8' }}>
                <TabPanel value={value} index={0}>
                    <ClassPermission />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TabsPermission />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <FeatureComingSoon />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <RulePermissions/>
                </TabPanel>
            </div>
        </div>
    );
}
