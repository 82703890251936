import React, { Component } from 'react';
import theme from '../../../theme';
import {fetchUserAttachments} from '../../../Services/suppliers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Button, Grid, IconButton, TextField, Tooltip} from "@material-ui/core";
import Loader from "../../../components/Loader";
import Treefile from "../../../components/KendoUICmpt/TreeList";
import NoDataFound from "../../../components/Messages/NoDataFound";
import ConfirmationPopover from "../../Favorites/Components/ConfirmationPopover";
import Download from '@material-ui/icons/GetApp';
import {BasePath, BUCKET_BASE_URL, SUPPLIER_BUCKET} from "../../../config";
import moment from "moment";
import {Autocomplete} from "@material-ui/lab";
import {fetchUsergroups} from "../../../Services/usergroup";
import SnackbarCmpt from "../../../components/Snackbar";


const styles = {
    root: {

    },

};

interface props {
    fetchUserAttachments:any;
    userAttachmentProcessing:any;
    userAttachments:any;
    fetchUsergroups:any;
    usergroup:any;
    selectedUsergroup:any;
    deleteAttachment:any;
    history:any;
}

class Index extends Component<props> {
    state = {
        show: false,
        success: false,
        value:""
    };

    columns = [
        {title:"Title",
            field:"Title"
        },
        {
            title:"Description",
            field:"Description"
        },
        {
            title:"Created By",
            field:"CreatedBy"
        },
        {title: 'Created Date',
            cell: (rowData: any) => (
                <td>
                    <Tooltip title="Download Attachment">
                        <div>{moment(rowData.dataItem['CreatedDate']).format("MMMM Do YYYY")}</div>
                    </Tooltip>
                </td>
            )
        },
        {
            title: 'Download',
            cell: (rowData: any) => (
                <td>
                    <Tooltip title="Download Attachment">
                        <IconButton aria-label="Download" onClick={(e: any) => this.handleDownload(e, rowData)}>
                            <Download />
                        </IconButton>
                    </Tooltip>
                </td>
            ),
        }
    ];

    componentDidMount() {
            this.props.fetchUserAttachments();
    }




    handleDownload = (e: any, rowData: any) => {
        if (rowData.dataItem['Path']) {
            let path = rowData.dataItem['Path'];
            window.open(`${SUPPLIER_BUCKET}${path}`);
        }
    }






    render() {
        return (
            <div style={styles.root}>

                {this.props.userAttachmentProcessing && <Loader />}

                {this.props.userAttachments && this.props.userAttachments.length > 0 ? (
                    <div style={{marginTop:theme.spacing(2)}}>
                        <Treefile columns={this.columns} tableData={this.props.userAttachments} />
                    </div>
                ) : (
                    !this.props.userAttachmentProcessing && (
                        <div style={{ width: '100%' }}>
                            <NoDataFound title={'No Attachments found'} />
                        </div>
                    )
                )}

                {this.state.show && (
                    <SnackbarCmpt
                        message={this.state.success ? 'Deleted successfully' : 'Could not delete'}
                        severity={this.state.success ? 'success' : 'error'}
                    />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        userAttachments: state.suppliers_reducer.userAttachments,
        userAttachmentProcessing:state.suppliers_reducer.userAttachmentProcessing,
        usergroup: state.usergroup_reducer.usergroup,
        selectedUsergroup: state.suppliers_reducer.selectedUsergroup
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchUserAttachments: () => dispatch(fetchUserAttachments()),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
