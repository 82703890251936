import React, { Component } from 'react';
import TreeViewClassType from './Components/SideTree';

class ClassTypeNameList extends Component {
    render() {
        return (
            <div>
                <TreeViewClassType />
            </div>
        );
    }
}

export default ClassTypeNameList;
