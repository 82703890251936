import {
    RULES_PROCESSING,
    GET_RULES,
    GET_RULES_USERGROUP
} from '../action-types/rules-action-types';

const initState = () => {
    return {
        rules: null,
        processing:false,
        rulesUsergroups:null
    };
};

const rules_reducer = (state = initState(), action: any) => {
    let newState = { ...state };
    switch (action.type) {
        case GET_RULES:
            setData(newState, action.payload, 'rules');
            break;
        case RULES_PROCESSING:
            setData(newState, action.payload, 'processing');
            break;
        case GET_RULES_USERGROUP:
            setData(newState, action.payload, 'rulesUsergroups');
            break;
        default:
            break;
    }
    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default rules_reducer;
