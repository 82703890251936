import React, { Component } from 'react';
import { Grid, Paper } from '@material-ui/core';
import theme from '../../../theme';

import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import QueryBuilder from './Componets/QueryBuilder';
import {
    fetchClassData,
    fetchDocumentClassTypeData,
    fetchPartClassTypeData,
    fetchChangeClassTypeData,
    fetchManufacturerClassTypeData,
    fetchManufacturerPartsClassTypeData,
    fetchSuppliersClassTypeData,
    fetchSuppliersItemsClassTypeData,
    setPageSize,
    fetchPSRClassTypeData,
    searchAdvanceClassData,
    setDataSize
} from '../../../Services/browse';
import Pageing from './Componets/Pageing';
import { setSearchTabsProperty } from '../../../Services/favorites';
import {ClassTypes} from '../../../config'
const styles = {
    root: {
        marginTop: theme.spacing(1),
        minHeight: '100vh',
    },
    paperSearch: {
        padding: theme.spacing(2),
        overflow: 'auto',
    },

    paperSide: {
        maxHeight: '65vh',
        minHeight: '65vh',
        overflow: 'auto',
    },
};

interface props {
    searchAdvanceClassData: any;
    pageSizeClassContent: any;
    setSearchTabsProperty: any;
    setDataSize: any;
    advanceSearchData: any;


    fetchPartClassTypeData: any;
    fetchDocumentClassTypeData: any;
    PartsClassType: any;
    DocumentClassType: any;
    processingPart: any;
    errorPart: any;
    processingDocument: any;
    errorDocument: any;
    fetchClassData: any;
    SelectedClassData: any;
    ContentProcessing: any;
    fetchChangeClassTypeData: any;
    ChangesClassType: any;
    processingChange: any;
    errorChange: any;
    ManufacturersClassType: any;
    fetchManufacturerClassTypeData: any;
    ManufacturerPartsClassType: any;
    fetchManufacturerPartsClassTypeData: any;

    fetchSuppliersClassTypeData: any;
    SuppliersClassType: any;

    fetchSuppliersItemsClassTypeData: any;
    SuppliersItemsClassType: any;

    fetchPSRClassTypeData: any;
    PSRClassType: any;
}

class Index extends Component<props> {
    state = {
        filter: '',
    };

    componentDidMount() {
        if (this.props.advanceSearchData && this.props.advanceSearchData.COUNT) {
            this.props.setDataSize(this.props.advanceSearchData.COUNT);
        }else {
            this.props.setDataSize(0);
        }
        if (
            (this.props.PartsClassType == null || this.props.PartsClassType.length == 0) &&
            ClassTypes.includes('Parts')
        ) {
            this.props.fetchPartClassTypeData();
        }
        if (
            (this.props.DocumentClassType == null || this.props.DocumentClassType.length == 0) &&
            ClassTypes.includes('Documents')
        ) {
            this.props.fetchDocumentClassTypeData();
        }
        if (
            (this.props.ChangesClassType == null || this.props.ChangesClassType.length == 0) &&
            ClassTypes.includes('Changes')
        ) {
            this.props.fetchChangeClassTypeData();
        }
        if (
            (this.props.ManufacturersClassType == null || this.props.ManufacturersClassType.length == 0) &&
            ClassTypes.includes('Manufacturers')
        ) {
            this.props.fetchManufacturerClassTypeData();
        }

        if (
            (this.props.ManufacturerPartsClassType == null || this.props.ManufacturerPartsClassType.length == 0) &&
            ClassTypes.includes('Manufacturer_parts')
        ) {
            this.props.fetchManufacturerPartsClassTypeData();
        }
    }



    componentWillUnmount() {
        this.props.setSearchTabsProperty(null, false);
            this.props.setDataSize(0);
    }

    
    callWithFilters = (filter: any) => {

        this.setState({
            filter: filter,
        });


        this.props.searchAdvanceClassData(filter, 0, this.props.pageSizeClassContent);
    };
    handlePageResults = (offset: any,pageSize:any) => {
        this.props.searchAdvanceClassData(this.state.filter, offset, pageSize);
    };

    render() {
        return (
            <div style={styles.root}>
                <Grid container spacing={2}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Paper elevation={3} style={styles.paperSearch}>
                            <QueryBuilder
                                //@ts-ignore
                                callWithFilters={this.callWithFilters}
                            />
                        </Paper>
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Paper
                            elevation={3}
                            style={{ height: '10vh', marginTop: theme.spacing(1), padding: theme.spacing(1) }}
                        >
                            <Pageing
                                //@ts-ignore
                                handlePageResults={this.handlePageResults}
                                filter={this.state.filter}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        pageSizeClassContent: state.browse_reducer.pageSizeClassContent,
        searchProcessing: state.browse_reducer.searchProcessing,
        advanceSearchData: state.browse_reducer.advanceSearchData,

        PartsClassType: state.browse_reducer.PartsClassType,
        DocumentClassType: state.browse_reducer.DocumentClassType,
        ChangesClassType: state.browse_reducer.ChangesClassType,
        ManufacturersClassType: state.browse_reducer.ManufacturersClassType,
        ManufacturerPartsClassType: state.browse_reducer.ManufacturerPartsClassType,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        searchAdvanceClassData: (filter: any, offset: any, records: any) =>
            dispatch(searchAdvanceClassData(filter, offset, records)),
        setSearchTabsProperty: (searchQuery: any, advanceSearchFlag: any) =>
            dispatch(setSearchTabsProperty(searchQuery, advanceSearchFlag)),
        setDataSize: (size: any) => dispatch(setDataSize(size)),
        fetchPartClassTypeData: () => dispatch(fetchPartClassTypeData()),
        fetchDocumentClassTypeData: () => dispatch(fetchDocumentClassTypeData()),
        fetchChangeClassTypeData: () => dispatch(fetchChangeClassTypeData()),
        fetchManufacturerClassTypeData: () => dispatch(fetchManufacturerClassTypeData()),
        fetchManufacturerPartsClassTypeData: () => dispatch(fetchManufacturerPartsClassTypeData())
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
