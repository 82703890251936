import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { Component } from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function a11yProps(index: any) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
}

export default function ItemTabs(props: any) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        props.handleChangeTab(props.tabs[newValue]);
    };
    return (
        <div>
            <AppBar position="static" style={{ marginTop: 10, backgroundColor: '#ffffff' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable prevent tabs example"
                    textColor="primary"
                >
                    {props.tabs.map((tab: any, index: any) => {
                        return <Tab label={tab} aria-label="phone" {...a11yProps(index)} />;
                    })}
                </Tabs>
            </AppBar>
            <div style={{ backgroundColor: '#f4f6f8', maxHeight: '65vh', minHeight: '65vh', overflow: 'auto' }}>
                {props.tabs.map((tab: any, index: any) => {
                    return (
                        <TabPanel value={value} index={index}>
                            {props.children}
                        </TabPanel>
                    );
                })}
            </div>
        </div>
    );
}
