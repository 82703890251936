import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { Component, useEffect } from 'react';
import UserAttachments from './Attachments';
import SuppierAttachments from  './SupplierAttachments';
import { useSelector } from 'react-redux';
import favorites_reducer from '../../reducers/favorites_reducer';

import {returnRole} from "../../utils/ItemFormHelpers";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function a11yProps(index: any) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
}

export default function Suppliers() {
    let { searchAdvanceTab, advanceSearchQuery } = useSelector((state: any) => ({
        ...state.favorites_reducer,
    }));

    const [value, setValue] = React.useState(searchAdvanceTab ? 1 : 0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const user: any = useSelector((state: any) => ({
        ...state.user_reducer.user,
    }));

    useEffect(() => {
        if (searchAdvanceTab) {
            setValue(1);
        }
    }, [advanceSearchQuery, searchAdvanceTab]);

    let userRole:any = returnRole(user.roles);

    return (
        <div>
            <AppBar position="static" style={{ marginTop: 20, backgroundColor: '#ffffff' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="off"
                    aria-label="scrollable prevent tabs example"
                    textColor="primary"
                >
                    <Tab label={'Supplier Attachments'} aria-label="phone" {...a11yProps(0)} />
                    {userRole.Name == "Suppliers"? null:<Tab label={'Audit Trails'} aria-label="favorite" {...a11yProps(1)} />}
                </Tabs>
            </AppBar>
            <div style={{ backgroundColor: '#f4f6f8' }}>
                <TabPanel value={value} index={0}>
                    {userRole.Name == "Suppliers"? <UserAttachments/> : <SuppierAttachments/>}
                </TabPanel>
                 <TabPanel value={value} index={1}>

                </TabPanel>
            </div>
        </div>
    );
}
