import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
    root: {
        margin: theme.spacing(4, 1),
    },
    primary: {
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        margin: theme.spacing(1),
    },
    secondary: {
        textAlign: 'center',
        margin: theme.spacing(1),
    },
    tertiary: {
        textAlign: 'center',
        margin: theme.spacing(1),
    },
}));

const CustomCardBody = (props: any) => {
    const classes = useStyles();
    let { primaryText, secondaryText, tertiaryText } = props;
    return (
        <div className={classes.root}>
            <div className={classes.primary}>
                <Typography variant={'h5'}>{primaryText}</Typography>
            </div>
            <div className={classes.secondary}>
                <Typography variant={'h6'}>{secondaryText}</Typography>
            </div>
            <div className={classes.tertiary}>
                <Typography variant={'body2'}>{tertiaryText}</Typography>
            </div>
        </div>
    );
};

export default CustomCardBody;
