import { Button, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TransferList from '../../components/TransferList/TransferList';
import { containers } from '../../utils/style';
import { addUsergroup } from '../../Services/usergroup';
import validate from 'validate.js';
import moment from 'moment';
import { setStausForActiveUser } from '../../utils/userGroups';
import Loader from '../../components/Loader';
import { BasePath } from '../../config';
interface props {
    history: any;
    editData: any;
    userData: any;
    selectedUsers: any;
    addUsergroup: any;
    processing: any;
    user: any;
}

const schema = {
    groupDesc: {
        presence: { allowEmpty: true },
        length: {
            maximum: 250,
        },
    },
};

class EditUsergroup extends Component<props> {
    state = {
        disableEditBtn: false,
        id: null,
        groupName: '',
        groupDesc: '',
        flag: false,
        isValid: true,
        showErr: false,
        touched: {
            groupDesc: null,
        },
        errors: {
            groupDesc: [],
        },
        selectFromArr: new Array(),
        selectToArr: new Array(),
    };

    componentDidMount() {
        if(this.props.editData && this.props.editData.dataItem){
        this.setState(
            {
                id: this.props.editData.dataItem.id,
                groupName: this.props.editData.dataItem.Name,
                groupDesc: this.props.editData.dataItem.description,
                selectToArr: [...this.props.editData.dataItem.users],
                selectFromArr: [...this.props.userData],
            },
            () => {
                this.removeDuplicates(this.state.selectToArr, this.state.selectFromArr);
            },
        );}
        else{
            this.props.history.push(BasePath + '/userGroup');
        }
    }

    removeDuplicates = (a: any, b: any) => {
        b = b.filter(function(item: any) {
            for (var i = 0, len = a.length; i < len; i++) {
                if (a[i].id == item.id) {
                    return false;
                }
            }
            return true;
        });
        this.setState({
            selectFromArr: [...b],
            selectToArr: [...a],
            flag: true,
        });
    };

    handleChange = (event: any) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
                touched: { [event.target.name]: true },
            },
            () => {
                const errors = validate(this.state, schema);
                this.setState({
                    isValid: errors ? false : true,
                    errors: errors || {},
                });
            },
        );
    };

    handleCancel = () => {
        this.props.history.push(BasePath + '/userGroup');
    };

    checkingClassesEmpty = (flag: any) => {
        this.setState({
            disableEditBtn: flag,
        });
    };

    handleEditUsergroup = () => {
        if (this.state.disableEditBtn) {
            return;
        }
        this.setState({
            disableEditBtn: true,
        });
        let oldData = { ...this.props.editData.dataItem };
        let resultantData = setStausForActiveUser(oldData.users, this.props.selectedUsers, this.props.user);
        let formData = {
            id: this.state.id,
            Name: this.state.groupName.trim(),
            description: this.state.groupDesc.trim(),
            create_date: this.props.editData.dataItem.create_date,
            created_by: this.props.editData.dataItem.created_by,
            IsActive: 1,
            ModifiedBy: this.props.user.first_name+' '+this.props.user.last_name,
            ModifiedDate: moment.utc().format(),
            users: resultantData,
        };
        this.props.addUsergroup(formData, (res: any) => {
            if (res) {
                this.props.history.push(BasePath + '/userGroup');
            } else {
                this.setState({
                    showErr: true,
                    disableEditBtn: false,
                });
            }
        });
    };

    hasError = (field: any) =>
        //@ts-ignore
        this.state.touched[field] && this.state.errors[field] ? true : false;
    render() {
        if(this.props.editData && this.props.editData.dataItem){
        const titleContainer = {
            marginTop: 10,
            marginBottom: 'auto',
            marginLeft: 10,
        };
        return (
            <Grid container>
                {this.props.processing ? (
                    <Loader />
                ) : (
                    <Grid container xs={12} style={containers}>
                        <Grid container style={{ margin: 10 }}>
                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        disabled
                                        id={'GroupName'}
                                        label="Usergroup Name"
                                        variant={'outlined'}
                                        required
                                        fullWidth
                                        name="groupName"
                                        onChange={this.handleChange}
                                        type="text"
                                        value={this.state.groupName || ''}
                                        style={{ margin: 10 }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        id={'GroupDescription'}
                                        label="Description"
                                        variant={'outlined'}
                                        multiline
                                        fullWidth
                                        rows={3}
                                        name="groupDesc"
                                        onChange={this.handleChange}
                                        type="text"
                                        value={this.state.groupDesc || ''}
                                        style={{ margin: 10 }}
                                        error={this.hasError('groupDesc')}
                                        helperText={this.hasError('groupDesc') ? this.state.errors.groupDesc[0] : null}
                                    />
                                </Grid>
                            </Grid>

                            {this.state.flag ? (
                                <Grid container style={{ marginLeft: 20 }}>
                                    <Grid xs={5}>
                                        <TransferList
                                            leftData={this.state.selectFromArr.filter((user: any)=>{return user.IsActive})}
                                            rightData={this.state.selectToArr}
                                            checkingClassesEmpty={this.checkingClassesEmpty}
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}

                            <Grid container xs={5} justify="flex-end" style={titleContainer}>
                                <Grid>
                                    <Button
                                        onClick={this.handleCancel}
                                        color="inherit"
                                        variant="contained"
                                        style={{ marginRight: 10 }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button
                                        disabled={!this.state.isValid || this.state.disableEditBtn}
                                        onClick={this.handleEditUsergroup}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Edit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
    else{
        this.props.history.push(BasePath + '/userGroup');
        return(<a></a>)
    }
}
}

const mapStateToProps = (state: any) => {
    return {
        processing: state.usergroup_reducer.processing,
        editData: state.usergroup_reducer.editData,
        userData: state.userManagement_reducer.userData,
        user: state.user_reducer.user,
        selectedUsers: state.usergroup_reducer.selectedUsers,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        addUsergroup: (data: any, cb: any) => dispatch(addUsergroup(data, cb)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(EditUsergroup);

export default withRouter(connected);
