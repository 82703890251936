import {
    SET_ATTACHMENTS_PROCESSING,
    GET_ALL_ATTACHMENTS,
    SET_CURRENT_USERGROUP,
    SET_USER_ATTACHMENT_PROCESSING,
    GET_USER_ATTACHMENTS
} from '../action-types/suppliers-action-types';

const initState = () => {
    return {
        supplierAttachment: null,
        processingAttachments:false,
        selectedUsergroup:null,
        userAttachments:null,
        userAttachmentProcessing:false
    };
};

const suppliers_reducer = (state = initState(), action: any) => {
    let newState = { ...state };
    switch (action.type) {
        case GET_ALL_ATTACHMENTS:
            setData(newState, action.payload, 'supplierAttachment');
            break;
        case SET_ATTACHMENTS_PROCESSING:
            setData(newState, action.payload, 'processingAttachments');
            break;
        case SET_CURRENT_USERGROUP:
            setData(newState, action.payload, 'selectedUsergroup');
            break;
        case GET_USER_ATTACHMENTS:
            setData(newState, action.payload, 'userAttachments');
            break;
        case SET_USER_ATTACHMENT_PROCESSING:
            setData(newState, action.payload, 'userAttachmentProcessing');
            break;
        default:
            break;
    }
    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default suppliers_reducer;
