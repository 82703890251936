export const GET_BOOKMARKS_DATA = 'GET_BOOKMARKS_DATA';
export const SET_BOOKMARKS_DATA = 'SET_BOOKMARKS_DATA';
export const SET_PROCESSING = 'SET_PROCESSING';
export const GET_SAVED_SEARCH_DATA = 'GET_SAVED_SEARCH_DATA';
export const SET_SAVED_SEARCH_DATA = 'SET_SAVED_SEARCH_DATA';
export const SET_SAVED_SEARCH_PROCESSING = 'SET_SAVED_SEARCH_PROCESSING';
export const SET_TOTAL = 'SET_TOTAL';

export const SET_FAVORITES_ERROR = 'SET_FAVORITES_ERROR';
export const SET_FVAOURITES_MODAL_STATE = 'SET_FVAOURITES_MODAL_STATE';

export const SET_ADVANCE_SEARCH_STRING = 'SET_ADVANCE_SEARCH_STRING';
export const SET_CURRENT_TAB_SEARCH = 'SET_CURRENT_TAB_SEARCH';

export const SET_SEARCH_RECORD_TOTAL = 'SET_SEARCH_RECORD_TOTAL';
export const SET_BOOKMARKS_RECORD_TOTAL = 'SET_BOOKMARKS_RECORD_TOTAL';

export const SET_SEARCH_RECORD_PAGE_SIZE = 'SET_SEARCH_RECORD_PAGE_SIZE';
export const SET_BOOKMARKS_RECORD_PAGE_SIZE = 'SET_BOOKMARKS_RECORD_PAGE_SIZE';

export const SET_SEARCH_PAGE = 'SET_SEARCH_PAGE';
export const SET_BOOKMARKS_PAGE = 'SET_BOOKMARKS_PAGE';
