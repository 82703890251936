import React from 'react';
import {Avatar, Chip, Grid, Modal, Tooltip, Typography, Dialog, Button} from '@material-ui/core';
import theme from '../../../theme';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeHiraricalData from '../../../components/KendoUICmpt/TreeHiraricalData';
import { TreeListTextFilter } from '@progress/kendo-react-treelist';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {fetchRedlines, setRedlinedItemNumber} from '../../../Services/user';
import { getAttachmentRevisions } from '../../../Services/itemForm';
import {BasePath, BUCKET_BASE_URL, ORACLE_CLOUD_BASE_URL, TIME_ZONE,TIME_ZONE_LABEL} from '../../../config';
import {useDispatch, useSelector} from 'react-redux';
import { withRouter } from 'react-router-dom';
import RedlinesUI from '../../RedlinesUI';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import SubjectIcon from '@material-ui/icons/Subject';
import {downloadFileFromVault} from "../../../Services/browse";
import { replaceNames } from '../../../utils/ItemFormHelpers';
import moment from 'moment-timezone';
import {returnUTCFormatedDate} from "../../../utils";
import {provideIconForTab} from "../../../utils/IconProvider";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Loader from "../../../components/Loader"
const returnFormatedText = (text: any) => {
    return (
        <Typography variant={'h5'} style={{ marginBottom: '8px' }}>
            {text}
        </Typography>
    );
};

function TreeDataHandler(props: any) {
    let layout = props.layout;
    let data = props.treeData;

    const {redlinedItem,}: any = useSelector((state: any) => ({
        ...state.user_reducer,
    }));
    const {attachmentRevisions,attachmentRevisionsProcessing}: any = useSelector((state: any) => ({
        ...state.itemForm_reducer,
    }));
    function rand() {
        return Math.round(Math.random() * 20) - 10;
    }

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            paper: {
                position: 'absolute',
                width: 500,
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[5],
                padding: theme.spacing(2, 4, 3),
            },
        }),
    );

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const [open, setOpen] = React.useState(false);
    const [openAttachRev, setOpenAttachRev] = React.useState(false);
    const [popupData, setPopupData] = React.useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = () => {
        let data: any = popupData.split(',');

        return (
            <div style={modalStyle} className={classes.paper}>
                <Typography variant={'h5'}>Reference Designators</Typography>
                <p id="simple-modal-description" style={{ marginTop: '8px' }}>
                    {data.map((val: any, index: any) => {
                        return (
                            <Chip
                                key={index}
                                style={{ margin: '2px' }}
                                variant="outlined"
                                label={`${val}`}
                                color="primary"
                            />
                        );
                    })}
                </p>
            </div>
        );
    };

    const dispatch = useDispatch();

    const showRedlinesUI = () => {
        return (
            <div style={{ marginTop: '16px' }}>
                <RedlinesUI />
            </div>
        );
    };

    const showRedlines = (event: any, data: any) => {
        // let classType = "Changes" // data.dataItem['CLASS_TYPE'];
        // let className = data.dataItem['CLASS_NAME'];
        let itemId = data.dataItem.ID_NUMBER;
        let rev = data.dataItem.REV;
        let itemClassName = data.dataItem.CLASS_NAME;
        let itemGuid = data.dataItem.GUID;
        let { productId, classType, className } = props.match.params;

        dispatch(fetchRedlines(classType, className, productId, itemId, rev, itemClassName, itemGuid));
        dispatch(setRedlinedItemNumber(itemId));
    };
    const openAttachmentRevisions =(data:any)=>{
        if(data.ATTACH_ID){
            dispatch(getAttachmentRevisions(data.GUID,data.ATTACH_ID))
            setOpenAttachRev(true)
        }
    }


   const downloadFile = (event: any, data: any) => {
        if (data.dataItem['FILE_PATH']) {
            let path = data.dataItem['FILE_PATH'];
            if(data.dataItem['DID']){
                let val =  `${ORACLE_CLOUD_BASE_URL}/fscmUI/content/conn/FusionAppsContentRepository/uuid/dDocID:${data.dataItem['DID']}?download`;
                window.open(`${val}`);
            }
           else if (path.includes('http')) {
                window.open(`${path}`);
            } else {
                downloadFileFromVault(path, (data:any)=>{
                    if(data){
                        window.open(`${data}`);
                    }
                })

            }
        }
    };

    const viewReferenceDesignators = (event: any, data: any) => {
        let refDesignator: any = ``;
        refDesignator = data.dataItem['REF_DESIG'];
        setPopupData(refDesignator);
        handleOpen();
    };

    const showDetails = (event: any, data: any) => {
        // ;
        let classType = data.dataItem['CLASS_TYPE'];
        let className = data.dataItem['CLASS_NAME'];

        if (!classType || !className) {
            if (data.dataItem.tableData && data.dataItem.tableData.length > 1) {
            }
        }

        let id = data.dataItem.ID_NUMBER;

        let url = `${BasePath}/item/${classType}/${className}/${id}`;
        let rev = data.dataItem.REV;
        let guid = data.dataItem.GUID;

        if (rev) {
            url = url + `/${rev}`;
        } else {
            url = url + `/1`;
        }

        if (guid) {
            url = url + `/${guid}`;
        }

        // dispatch(resetConfiguration() );
        //  dispatch(getConfigurations(classType, className, rev,id))
        props.history.push(url);
    };
    const checkForKey = (key:any) =>{
        var existence = false;
        attachmentRevisions.forEach((attach:any)=>{
            if(attach[key] ){
                existence=true
            }
        })
        if(key=="FILENAME" || key=="FILE_DESCRIPTION" || key=="VERSION_NUM" || key=="VERSION_CREATE_DATE" || key=="CHECKIN_USER" || key=="FILE_ID"){
            existence=true
        }
        return existence
    }
    const createColumns = (type?:any) =>{
    let columns = [];

    for (let each in layout.HEADERS)
        if (layout.HEADERS[each].LABEL_DISPLAY && layout.HEADERS[each].TYPE != 'Hidden') {
            if (layout.HEADERS[each].ATTRIBUTE_NAME == 'ID_NUMBER') {
                columns.push({
                    title: `${layout.HEADERS[each].LABEL_DISPLAY}`,
                    field: `${layout.HEADERS[each].ATTRIBUTE_NAME}`,
                    filter: TreeListTextFilter,
                    cell: (rowData: any) => (
                        <Tooltip title="Open details">
                            <td>
                                <span
                                    style={{ color: 'blue', cursor: 'pointer' }}
                                    onClick={(e: any) => {
                                        showDetails(e, rowData);
                                    }}
                                >
                                    {rowData.dataItem[rowData.field]}
                                </span>
                            </td>
                        </Tooltip>
                    ),
                });
            } else if (layout.TAB == 'Affected Items' && layout.HEADERS[each].ATTRIBUTE_NAME == 'HAS_BEEN_REDLINED') {
                columns.push({
                    title: `Redlined`,
                    field: `${layout.HEADERS[each].ATTRIBUTE_NAME}`,
                    cell: (rowData: any) => (
                        <Tooltip title="Open redlines">
                            <td
                                style={{
                                    width: '70px',
                                    height: '100%',
                                    backgroundColor:
                                        redlinedItem && redlinedItem == rowData.dataItem['ID_NUMBER']
                                            ? '#d7d7d7'
                                            : '#f1f1f1',
                                    cursor: 'pointer',
                                }}
                                onClick={(e: any) => {
                                    showRedlines(e, rowData);
                                }}
                            >
                                {rowData.dataItem[rowData.field] ? (
                                    <IconButton disabled={true} style={{ width: '100%' }}>
                                        <TimelineIcon style={{ color: 'red' }} />
                                    </IconButton>
                                ) : null}
                            </td>
                        </Tooltip>
                    ),
                });
            } else if (layout.TAB == 'Attachments' && layout.HEADERS[each].ATTRIBUTE_NAME == 'FILE_PATH') {
                if(!type){
                    columns.push({
                    title: `Folder Version`,
                    filter: TreeListTextFilter,
                    cell: (rowData: any) =>
                    <td>
                        <Tooltip title={rowData.dataItem.ATTACH_ID ? "Show Versions" : "No other Versions"}>
                            <InfoOutlinedIcon style={{cursor:rowData.dataItem.ATTACH_ID?'pointer':"",color:"grey"}} onClick={()=>openAttachmentRevisions(rowData.dataItem)}/>
                        </Tooltip>
                    </td>
                    
                });}
                columns.push({
                    title: `${layout.HEADERS[each].LABEL_DISPLAY}`,
                    field: `${layout.HEADERS[each].ATTRIBUTE_NAME}`,
                    filter: TreeListTextFilter,
                    cell: (rowData: any) =>
                        rowData.dataItem[rowData.field] || true ? (
                            <td>
                                <Tooltip title="Download">
                                  <span>  <Avatar
                                        src={provideIconForTab('Attachments')}
                                        onClick={(e: any) => {
                                            downloadFile(e, rowData);
                                        }}
                                        style={{ borderRadius: "10%", maxWidth: "25px", maxHeight: "25px" , cursor:'pointer'}} />
                                  </span>
                                </Tooltip>
                            </td>
                        ) : (
                            <td>
                                <span></span>
                            </td>
                        ),
                });
            } else if (layout.HEADERS[each].ATTRIBUTE_NAME == 'REF_DESIG') {
                columns.push({
                    title: `${layout.HEADERS[each].LABEL_DISPLAY}`,
                    field: `${layout.HEADERS[each].ATTRIBUTE_NAME}`,
                    filter: TreeListTextFilter,
                    cell: (rowData: any) =>
                        rowData.dataItem[rowData.field] ? (
                            <Tooltip title="View reference designators">
                                <td>
                                   <span> <IconButton
                                        style={{ width: '100%' }}
                                        onClick={(e: any) => {
                                            viewReferenceDesignators(e, rowData);
                                        }}
                                    >
                                        <DynamicFeedIcon />
                                    </IconButton>
                                   </span>
                                </td>
                            </Tooltip>
                        ) : (
                            <td>
                                <span></span>
                            </td>
                        ),
                });
            }
            else if(layout.HEADERS[each].TYPE == 'Date'){
                columns.push({
                    title: `${layout.HEADERS[each].LABEL_DISPLAY}`,
                    field: `${layout.HEADERS[each].ATTRIBUTE_NAME}`,
                    filter: TreeListTextFilter,
                    cell: (rowData: any) =>
                        rowData.dataItem[rowData.field] ? (
                                <td>
                                    <span> {moment(returnUTCFormatedDate(rowData.dataItem[rowData.field])).tz(TIME_ZONE).format('DD-MMMM-YYYY  h:mm:ss A')} {TIME_ZONE_LABEL} </span>
                                </td>
                        ) : (
                            <td>
                                <span></span>
                            </td>
                        ),
                });

            }
            else {
                columns.push({
                    title: layout.HEADERS[each].LABEL_DISPLAY,
                    field: layout.HEADERS[each].ATTRIBUTE_NAME,
                    filter: TreeListTextFilter,
                    cell: (rowData: any) =>
                        rowData.dataItem[rowData.field] ? (
                            <td>
                              <span>
                                  <div style={{minHeight:"50px", maxHeight:"130px", overflow:"auto"}}>
                                    {typeof (rowData.dataItem[rowData.field]) !== "string" ? rowData.dataItem[rowData.field].toString() : rowData.dataItem[rowData.field]}
                                  </div>
                                </span>
                            </td>
                        ) : (
                            <td>
                                <span></span>
                            </td>
                        ),
                });
            }
        }
        if(type){
            columns.unshift({
                title: `File Name`,
                field: `FILENAME`,
                filter: TreeListTextFilter,
                cell: (rowData: any) =>
                            rowData.dataItem[rowData.field] ? (
                                <td>
                                    <div style={{minHeight:"50px", maxHeight:"130px", overflow:"auto"}}>
                                        {typeof (rowData.dataItem[rowData.field]) !== "string" ? rowData.dataItem[rowData.field].toString() : rowData.dataItem[rowData.field]}
                                    </div>
                                </td>
                            ) : (
                                <td>
                                </td>
                            ),
            });
            columns.unshift({
                title: `File Description`,
                field: `FILE_DESCRIPTION`,
                filter: TreeListTextFilter,
                cell: (rowData: any) =>
                            rowData.dataItem[rowData.field] ? (
                                <td>
                                    <div style={{minHeight:"50px", maxHeight:"130px", overflow:"auto"}}>
                                        {typeof (rowData.dataItem[rowData.field]) !== "string" ? rowData.dataItem[rowData.field].toString() : rowData.dataItem[rowData.field]}
                                    </div>
                                </td>
                            ) : (
                                <td>
                                </td>
                            ),
            });
            columns.unshift({
                title: `Folder Version`,
                field: `VERSION_NUM`,
                filter: TreeListTextFilter,
                cell: (rowData: any) =>
                            rowData.dataItem[rowData.field] ? (
                                <td>
                                    <div style={{minHeight:"50px", maxHeight:"130px", overflow:"auto"}}>
                                        {typeof (rowData.dataItem[rowData.field]) !== "string" ? rowData.dataItem[rowData.field].toString() : rowData.dataItem[rowData.field]}
                                    </div>
                                </td>
                            ) : (
                                <td>
                                </td>
                            ),
            });
            columns.push({
                title: `Version Create Date`,
                field: `VERSION_CREATE_DATE`,
                filter: TreeListTextFilter,
                cell: (rowData: any) =>
                            rowData.dataItem[rowData.field] ? (
                                <td>
                                    <div style={{minHeight:"50px", maxHeight:"130px", overflow:"auto"}}>
                                        {moment(returnUTCFormatedDate(rowData.dataItem[rowData.field])).tz(TIME_ZONE).format('DD-MMMM-YYYY  h:mm:ss A')} {TIME_ZONE_LABEL}
                                    </div>
                                </td>
                            ) : (
                                <td>
                                </td>
                            ),
            });
            columns.push({
                title: `Checkin User`,
                field: `CHECKIN_USER`,
                filter: TreeListTextFilter,
                cell: (rowData: any) =>
                            rowData.dataItem[rowData.field] ? (
                                <td>
                                    <div style={{minHeight:"50px", maxHeight:"130px", overflow:"auto"}}>
                                        {typeof (rowData.dataItem[rowData.field]) !== "string" ? rowData.dataItem[rowData.field].toString() : rowData.dataItem[rowData.field]}
                                    </div>
                                </td>
                            ) : (
                                <td>
                                </td>
                            ),
            });
            columns.push({
                title: `File ID`,
                field: `FILE_ID`,
                filter: TreeListTextFilter,
                cell: (rowData: any) =>
                            rowData.dataItem[rowData.field] ? (
                                <td>
                                    <div style={{minHeight:"50px", maxHeight:"130px", overflow:"auto"}}>
                                        {typeof (rowData.dataItem[rowData.field]) !== "string" ? rowData.dataItem[rowData.field].toString() : rowData.dataItem[rowData.field]}
                                    </div>
                                </td>
                            ) : (
                                <td>
                                </td>
                            ),
            });
        }
        return columns
    }


    const checkForStyle = (col: any) =>{
        if(col.indexOf("DESCRIPTION")!==-1 || col.indexOf("REASON FOR CHANGE")!==-1 ) return true;
        else return false;
    }

    return (
        <div style={{ padding: '12px', marginTop: '5px', background: '#ffffff' }}>
            {returnFormatedText(replaceNames(layout['LABEL_DISPLAY']))}
            <TreeHiraricalData title={''} columns={createColumns()} tableData={data} />
            {layout.TAB == 'Affected Items' && showRedlinesUI()}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body()}
            </Modal>
            <Dialog open={openAttachRev} onClose={()=>{setOpenAttachRev(false)}} fullWidth maxWidth="lg" PaperProps={{style:{width:"100%",backgroundColor:"#f5f2f0", overflow:"auto", fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif'}}}>
               <div style={{padding:"16px 9px 8px 16px",backgroundColor:"#f4f6f8", position:"relative"}}>
                    <Typography variant="h4" style={{ fontWeight: "normal", color: "#0066cc", fontSize: "26px", marginBottom:"8px" }}>
                        Attachment Revisions
                    </Typography>
                    {attachmentRevisionsProcessing ? <Loader/> :
                        <TreeHiraricalData title={''} columns={createColumns("modal").filter((col:any)=> checkForKey(col.field))} tableData={attachmentRevisions}/>
                    }
                    <Button
                        onClick={()=>{setOpenAttachRev(false)}}
                        color="secondary"
                        variant="contained"
                        style={{float:"right",right:"8px"}}
                        size={'medium'}>
                        Close
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}

export default withRouter(TreeDataHandler);
