import React from 'react';
import BuildIcon from '@material-ui/icons/Build';
const FeatureComingSoon = () => {
    return (
        <div style={{ padding: '120px', textAlign: 'center', color: '#8ea0c7' }}>
            <h1>Under development</h1>
            <div style={{ marginTop: '10px' }}>
                <BuildIcon style={{ height: 100, width: 100 }} />
            </div>
        </div>
    );
};

export default FeatureComingSoon;
