import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '4px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderColor: 'red',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

export default function CustomizedInputBase(props: any) {
    const classes = useStyles();
    const [searchVal, setFormState] = useState(props.searchValue);
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            props.handleSearch(searchVal);
        }
    };

    return (
        <Paper className={classes.root}>
            <InputBase
                className={classes.input}
                value={searchVal}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={(e: any) => {
                    setFormState(e.target.value);
                }}
                onKeyDown={handleKeyDown}
            />
            <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                    props.handleSearch(searchVal);
                }}
            >
                <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
        </Paper>
    );
}
