import {
    SET_NOTIFICATION_ERROR,
    SET_NOTIFICATION_EVENTS,
    SET_RECIPIENT_ROLES,
    SET_NOTIFICATION_TAGS,
    GET_ALL_NOTIFICATIONS,
    SET_EDIT_DATA,
    SET_NOTIFICATIONS_PROCESSING,
    SET_NOTIFICATION_ROLES,
} from '../action-types/notifications-action-types';

const initState = () => {
    return {
        notificationEvents: null,
        notificationRoles: null,
        recipientRoles: null,
        notificationTags: null,
        allNotifications: null,
        editData: null,
        error: null,
        processing: false,
    };
};
const notifications_reducer = (state = initState(), action: any) => {
    let newState = { ...state };
    switch (action.type) {
        case SET_NOTIFICATION_EVENTS:
            setData(newState, action.payload, 'notificationEvents');
            break;
        case SET_RECIPIENT_ROLES:
            setData(newState, action.payload, 'recipientRoles');
            break;
        case SET_NOTIFICATION_ROLES:
            setData(newState, action.payload, 'notificationRoles');
            break;
        case SET_NOTIFICATION_TAGS:
            setData(newState, action.payload, 'notificationTags');
            break;
        case GET_ALL_NOTIFICATIONS:
            setData(newState, action.payload, 'allNotifications');
            break;
        case SET_EDIT_DATA:
            setData(newState, action.payload, 'editData');
            break;
        case SET_NOTIFICATION_ERROR:
            setData(newState, action.payload, 'error');
            break;
        case SET_NOTIFICATIONS_PROCESSING:
            setData(newState, action.payload, 'processing');
            break;
        default:
            break;
    }
    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default notifications_reducer;
