import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import { setSelectedConfiguration } from '../../actions/configurations';
const styles = (theme: any) => ({
    listItemText: {
        fontSize: '1.3em',
        lineHeight: '30px',
    },
});

class ConfigurationTypesList extends Component<any> {
    render() {
        return (
            <div>
                <List component="nav" aria-label="main mailbox folders">
                    {this.props.configurations.map((configuration: any, index: number) => {
                        return (
                            <ListItem
                                button
                                key={index}
                                selected={configuration === this.props.selectedConfiguration}
                                onClick={() => this.props.setSelectedConfiguration(configuration)}
                            >
                                <ListItemText
                                    primary={configuration}
                                    classes={{ primary: this.props.classes.listItemText }}
                                />
                            </ListItem>
                        );
                    })}
                </List>
                <Divider />
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        configurations: state.configurations_reducer.configurations,
        selectedConfiguration: state.configurations_reducer.selectedConfiguration,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSelectedConfiguration: (selectedConfiguration: string) =>
            dispatch(setSelectedConfiguration(selectedConfiguration)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfigurationTypesList));

export default connected;
