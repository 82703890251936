import { Button, Grid, IconButton } from '@material-ui/core';
import { TreeListBooleanFilter, TreeListTextFilter } from '@progress/kendo-react-treelist';
import EditIcon from '@material-ui/icons/Edit';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Treefile from '../../components/KendoUICmpt/TreeList';
import CustomPagination from '../../components/CustomPagination';
import { fetchUsers, addUser, setUserEditData, setNewPageSizeUser } from '../../Services/userManagement';
import { containers } from '../../utils/style';
import theme from '../../theme';
import Loader from '../../components/Loader';
import NoDataFound from '../../components/Messages/NoDataFound';
import { BasePath } from '../../config';

interface props {
    processing: any;
    fetchUsers: any;
    userData: any;
    addUser: any;
    setUserEditData: any;
    editData: any;
    history: any;
    total: any;
    pageSizeUser: any;
    setNewPageSizeUser: any;
}

class Index extends Component<props> {
    state = {
        add: false,
        isValid: true,
        page: 1,
    };

    columns = [ {
        field: 'first_name',
        title: 'First Name',
        width: 250,
        filter: TreeListTextFilter,
        expandable: true,
    },
        {
            field: 'user_name',
            title: 'User Name',
            width: 250,
            filter: TreeListTextFilter,
            expandable: true,
        },
        { field: 'email', title: 'Email', width: 200, filter: TreeListTextFilter },
        {   title: 'Role',
            width: 30,
            cell: (rowData: any) => (
                <td>
                    <a>{rowData.dataItem.roles[0]['Name']}</a>
                </td>
            ),
        },
        {
            field: 'IsActive',
            title: 'Is Active',
            width: 200,
            filter: TreeListBooleanFilter,
        },
        {
            width: 20,
            cell: (rowData: any) => (
                <td> <IconButton aria-label="Edit" onClick={(e: any) => this.handleEdit(e, rowData)}>
                    <EditIcon />
                </IconButton></td>
            ),
        },
    ];

    handleEdit = (e: any, rowData: any) => {
        this.props.setUserEditData(rowData);
        this.props.history.push(BasePath + '/userManagement/Edit');
    };

    handleAdd = () => {
        this.props.history.push(BasePath + '/userManagement/Add');
    };

    componentDidMount() {
        this.props.fetchUsers(this.state.page);
    }

    handlePage = (value: any) => {
        this.setState({
            page: parseInt(value),
        });
        this.props.fetchUsers(value);
    };

    handleNewPageSize = (event: any) => {
        this.props.setNewPageSizeUser(event.target.value);
        this.props.fetchUsers(1);
    };

    render() {
        return (
            <div>
                <Grid container>
                    <Grid container justify="flex-end">
                        <Button
                            style={{ marginTop: '16px' }}
                            variant="contained"
                            onClick={() => {
                                this.setState({
                                    add: true,
                                });
                            }}
                            color="primary"
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                <div style={{ marginTop: 10 }}>
                    {this.props.processing && <Loader />}
                    {this.props.userData != null ? (
                        <div>
                            <Treefile columns={this.columns} tableData={this.props.userData} />
                            <div style={{ margin: theme.spacing(3) }}>
                                <CustomPagination
                                    total={this.props.total}
                                    page={this.state.page}
                                    handlePage={this.handlePage}
                                    pageSize={this.props.pageSizeUser}
                                    handleNewPageSize={this.handleNewPageSize}
                                />
                            </div>
                        </div>
                    ) : (
                        !this.props.processing && (
                            <div style={{ width: '100%' }}>
                                <NoDataFound title={'No Users found'} />
                            </div>
                        )
                    )}
                </div>

                {this.state.add ? this.handleAdd() : null}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        processing: state.userManagement_reducer.processing,
        userData: state.userManagement_reducer.userData,
        editData: state.userManagement_reducer.editData,
        pageSizeUser: state.userManagement_reducer.pageSizeUser,
        total: state.userManagement_reducer.total,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchUsers: (page: any) => dispatch(fetchUsers(page)),
        addUser: (data: any, cb: any) => dispatch(addUser(data, cb)),
        setUserEditData: (data: any) => dispatch(setUserEditData(data)),
        setNewPageSizeUser: (event: any) => dispatch(setNewPageSizeUser(event)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
