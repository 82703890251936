import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import {SHOW_GRID_FILTERS} from '../../config'
import {
    TreeList,
    orderBy,
    filterBy,
    mapTree,
    extendDataItem,
    TreeListTextFilter,
    TreeListNumericFilter,
    TreeListDateFilter,
    TreeListBooleanFilter,
} from '@progress/kendo-react-treelist';
import './tree.css'
const subItemsField = 'employees';
const expandField = 'expanded';

interface Interface {
    tableData: any;
    columns: any;
}

class Treefile extends Component<Interface> {
    state = {
        data: [...this.props.tableData],
        dataState: {
            sort: [],
            filter: [],
        },
        expanded: [],
    };

    onExpandChange = (e: any) => {
        this.setState({
            expanded: e.value
                ? this.state.expanded.filter(id => id !== e.dataItem.id)
                : [...this.state.expanded, e.dataItem.id],
        });
    };

    handleDataStateChange = (event: any) => {
        this.setState({
            dataState: event.dataState,
        });
    };

    addExpandField = (dataTree: any) => {
        const expanded = this.state.expanded;
        return mapTree(dataTree, subItemsField, item =>
            extendDataItem(item, subItemsField, {
                //@ts-ignore
                [expandField]: expanded.includes(item.id),
            }),
        );
    };

    processData = () => {
        let {  dataState } = this.state;
        let data = this.props.tableData.map((a:any) => {return {...a}});
        let filteredData = filterBy(data, dataState.filter, subItemsField);
        let sortedData = orderBy(filteredData, dataState.sort, subItemsField);
        return this.addExpandField(sortedData);
    };

    render() {
        const columns = this.props.columns;
        if(!SHOW_GRID_FILTERS){
            columns.forEach((col:any) => {
                delete col.filter
            });
        }
        return (
                <TreeList
                style={{ height: '100%',overflowX: "scroll", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
                expandField={expandField}
                subItemsField={subItemsField}
                onExpandChange={this.onExpandChange}
                sortable={{ mode: 'multiple' }}
                {...this.state.dataState}
                data={this.processData()}
                onDataStateChange={this.handleDataStateChange}
                resizable={true}
                columns={columns}
                noRecords={<div style={{ color: 'red' }}>No record found</div>}
            />
        );
    }
}

export default Treefile;
