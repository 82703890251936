import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import validate from 'validate.js';
import Loader from '../../components/Loader';
import SnackbarCmpt from '../../components/Snackbar';
import { addUser, testUserName } from '../../Services/userManagement';
import { containers } from '../../utils/style';
import { BasePath } from '../../config';

interface props {
    userData: any;
    addUser: any;
    testUserName: any;
    history: any;
    processing: any;
    user: any;
    roles:any
}

const schema = {
    userName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    firstName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    lastName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 50,
        },
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            minimum: 8,
        },
    },
    confirmPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            minimum: 8,
        },
        equality: 'password',
    },
};

class AddUser extends Component<props> {
    state = {
        disableAddBtn: false,
        isValid: false,
        duplicate: false,
        userName: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        IsActive: false,
        showErr: false,
        roles:[],
        touched: {
            userName: null,
            firstName: null,
            lastName: null,
            email: null,
            password: null,
            confirmPassword: null
        },
        errors: {
            userName: [],
            firstName: [],
            lastName: [],
            email: [],
            password: [],
            confirmPassword: [],
        },
    };

    componentDidMount() {

    }


    handleChange = (event: any) => {
        if ( event.target.name === 'IsActive') {
            this.setState({
                [event.target.name]: event.target.checked,
            });
        } else if(event.target.name === 'roles'){
            let roleData:any = null;
            this.props.roles && this.props.roles.map((role:any)=>{
                if(role.Name == event.target.value){
                    roleData = role;
                }
            })
            this.setState({
                [event.target.name]: [{
                    "ID":roleData.ID,
                    "Name":roleData.Name,
                    "RoleUserActive":roleData.IsActive,
                    "RoleUserCreatedBy": this.props.user.user_name ,
                    "RoleUserCreatedDate": new Date()
                }]

            });


        } else {
            this.setState(
                {
                    [event.target.name]: event.target.value.trim(),
                    touched: { [event.target.name]: true },
                },
                () => {
                    const errors = validate(this.state, schema);
                    this.setState({
                        isValid: errors ? false : true,
                        errors: errors || {},
                    });
                },
            );
        }
    };

    handleAddUser = () => {
        if (this.state.disableAddBtn) {
            return;
        }
        this.setState({
            disableAddBtn: true,
        });
        let formData = {
            user_name: this.state.userName,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            Password: this.state.password,
            IsActive: this.state.IsActive,
            CreatedBy: this.props.user.user_name,
            CreatedDate: new Date(),
            roles:this.getRolesData()
        };
        this.props.testUserName(formData, (res: any) => {
            if (res) {
                this.setState({
                    duplicate: true,
                    disableAddBtn: false,
                });
                setTimeout(() => {
                    this.setState({
                        duplicate: false,
                    });
                }, 3000);
            } else {
                this.props.addUser(formData, (res: any) => {
                    if (res) {
                        this.props.history.push(BasePath + '/userManagement');
                    } else {
                        this.setState({
                            showErr: true,
                            disableAddBtn: false,
                        });
                    }
                });
            }
        });
    };

    getRolesData = () => {
        if(this.state.roles.length > 0){
            return this.state.roles
        } else {
            return [{
                "ID":this.props.roles[0].ID,
                "Name":this.props.roles[0].Name,
                "RoleUserActive":this.props.roles[0].IsActive,
                "RoleUserCreatedBy": this.props.user.user_name ,
                "RoleUserCreatedDate": new Date()
            }]
        }
    }

    handleCancel = () => {
        this.props.history.push(BasePath + '/userManagement');
    };
    hasError = (field: any) =>
        //@ts-ignore
        this.state.touched[field] && this.state.errors[field] ? true : false;
    render() {
        const titleContainer = {
            marginTop: 10,
            marginBottom: 'auto',
            marginLeft: 10,
        };
        return (
            <Grid container>
                {this.props.processing ? (
                    <Loader />
                ) : (
                    <Grid container style={containers}>
                        <Grid container style={{ margin: 10 }}>
                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={'FirstName'}
                                        label="First Name"
                                        variant={'outlined'}
                                        name="firstName"
                                        onChange={this.handleChange}
                                        type="text"
                                        value={this.state.firstName || ''}
                                        style={{ margin: 10 }}
                                        error={this.hasError('firstName')}
                                        helperText={this.hasError('firstName') ? this.state.errors.firstName[0] : null}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={'LastName'}
                                        label="Last Name"
                                        variant={'outlined'}
                                        name="lastName"
                                        onChange={this.handleChange}
                                        type="text"
                                        value={this.state.lastName || ''}
                                        style={{ margin: 10 }}
                                        error={this.hasError('lastName')}
                                        helperText={this.hasError('lastName') ? this.state.errors.lastName[0] : null}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={'UserName'}
                                        label="User Name"
                                        variant={'outlined'}
                                        name="userName"
                                        onChange={this.handleChange}
                                        type="text"
                                        value={this.state.userName || ''}
                                        style={{ margin: 10 }}
                                        error={this.hasError('userName')}
                                        helperText={this.hasError('userName') ? this.state.errors.userName[0] : null}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={'Email'}
                                        label="Email"
                                        variant={'outlined'}
                                        name="email"
                                        onChange={this.handleChange}
                                        type="text"
                                        value={this.state.email || ''}
                                        style={{ margin: 10 }}
                                        error={this.hasError('email')}
                                        helperText={this.hasError('email') ? this.state.errors.email[0] : null}
                                    />
                                </Grid>
                            </Grid>
                            {this.props.roles && this.props.roles.length > 0 && <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <FormControl variant="outlined"fullWidth  style={{ margin: 10 }}>
                                        <InputLabel id="demo-simple-select-outlined-label" style={{background:"#ffffff", padding:"0px 5px"}}>{"  Role  "}</InputLabel>
                                        <Select
                                            name="roles"
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.roles.length > 0 ? this.state.roles[0]["Name"] : this.props.roles[0]["Name"]}
                                            onChange={this.handleChange}
                                        >
                                            {this.props.roles && this.props.roles.map((data: any, index:any) => {
                                                return <MenuItem key={index} value={data["Name"]}>{data.Name}</MenuItem>
                                            })}

                                        </Select>
                                    </FormControl>
                                </Grid></Grid>}

                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={'Password'}
                                        label="Password"
                                        variant={'outlined'}
                                        name="password"
                                        onChange={this.handleChange}
                                        type="password"
                                        value={this.state.password || ''}
                                        style={{ margin: 10 }}
                                        error={this.hasError('password')}
                                        helperText={this.hasError('password') ? this.state.errors.password[0] : null}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={'ConfirmPassword'}
                                        label="Confirm Password"
                                        variant={'outlined'}
                                        name="confirmPassword"
                                        onChange={this.handleChange}
                                        type="password"
                                        value={this.state.confirmPassword || ''}
                                        style={{ margin: 10 }}
                                        error={this.hasError('confirmPassword')}
                                        helperText={
                                            this.hasError('confirmPassword')
                                                ? this.state.errors.confirmPassword[0]
                                                : null
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.IsActive}
                                                onChange={this.handleChange}
                                                name={'IsActive'}
                                            />
                                        }
                                        style={{ margin: 10 }}
                                        label={'Is Active'}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={5} justify="flex-end" style={titleContainer}>
                                <Grid>
                                    <Button
                                        onClick={this.handleCancel}
                                        color="inherit"
                                        variant="contained"
                                        style={{ marginRight: 10 }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button
                                        disabled={!this.state.isValid || this.state.disableAddBtn}
                                        onClick={this.handleAddUser}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {this.state.duplicate && <SnackbarCmpt message={'Duplicate username not allowed'} severity={'error'} />}
            </Grid>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.userManagement_reducer.userData,
        user: state.user_reducer.user,
        processing: state.userManagement_reducer.processing,
        roles:state.userManagement_reducer.roles
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        addUser: (data: any, cb: any) => dispatch(addUser(data, cb)),
        testUserName: (name: any, cb: any) => dispatch(testUserName(name, cb)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(AddUser);

export default withRouter(connected);