import {
    SET_SELECTED_CONFIGURATION_TYPE,
    SET_CLASS_TYPE_PROCESSING,
    SET_CLASS_TYPE_DATA,
    SET_CLASS_TYPE_ERROR,
    SET_CLASS_TYPE_TABS_PROCESSING,
    SET_CLASS_TYPE_TABS_DATA,
    SET_CLASS_TYPE_TABS_ERROR,
    SET_GENERAL_CONFIG,
    SET_GENERAL_CONFIG_PROCESSING,
} from '../action-types/configurations-action-types';
import { TABS } from '../constants';
const configurations = TABS.split(",");
const initState = () => {
    return {
        configurations,
        selectedConfiguration: configurations[0],
        generalConfigurations:[],
        generalConfigurationProcessing:false
    };
};

const configurations_reducer = (state = initState(), action: any) => {
    let newState = { ...state };
    switch (action.type) {
        case SET_SELECTED_CONFIGURATION_TYPE:
            setData(newState, action.payload, 'selectedConfiguration');
            break;
        // class type configurations.
        case SET_CLASS_TYPE_PROCESSING:
            setData(newState, action.payload, newState.selectedConfiguration, 'classTypesProcessing');
            break;
        case SET_CLASS_TYPE_DATA:
            setData(newState, action.payload, newState.selectedConfiguration, 'classTypes');
            break;
        case SET_CLASS_TYPE_ERROR:
            setData(newState, action.payload, newState.selectedConfiguration, 'classTypesError');
            break;
        // class type tabs configurations.
        case SET_CLASS_TYPE_TABS_PROCESSING:
            setData(newState, action.payload, newState.selectedConfiguration, 'classTypeTabsprocessing');
            break;
        case SET_CLASS_TYPE_TABS_DATA:
            setData(newState, action.payload, newState.selectedConfiguration, 'classTypeTabs');
            break;
        case SET_CLASS_TYPE_TABS_ERROR:
            setData(newState, action.payload, newState.selectedConfiguration, 'classTypeTabsError');
            break;
        case SET_GENERAL_CONFIG:
            setData(newState, action.payload,'generalConfigurations');
            break;
        case SET_GENERAL_CONFIG_PROCESSING:
            setData(newState, action.payload,'generalConfigurationProcessing');
            break;
        default:
            break;
    }
    return newState;
};

const setData = (state: any, payload: any, key: any, childPropKey: any = null) => {
    if (childPropKey) {
        if (state[key]) {
            state[key][childPropKey] = payload;
        } else {
            state[key] = {};
            state[key][childPropKey] = payload;
        }
    } else {
        state[key] = payload;
    }
};

export default configurations_reducer;
