import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { containers } from '../../utils/style';
import { fetchUsergroups, addUsergroup, fetchSelectedUsers } from '../../Services/usergroup';
import { setUsergroupSelected, fetchTabs } from '../../Services/permission';
import TransferList from '../../components/TransferList/TransferList';
import { setActiveTabsTrue } from '../../utils/permission';
import moment from 'moment';
import Loader from '../../components/Loader';
import { BasePath } from '../../config';
interface props {
    history: any;
    fetchUsergroups: any;
    usergroup: any;
    selectedUsers: any;
    addUsergroup: any;
    setUsergroupSelected: any;
    selectedUsergroup: any;
    fetchSelectedUsers: any;
    fetchTabs: any;
    tabs: any;
    processing: any;
}

class TabPermissionAdd extends Component<props> {
    state = {
        disableAddBtn: false,
        isValid: false,
        groupSelected: null,
    };

    componentDidMount() {
        this.props.fetchUsergroups();
        // this.props.fetchTabs();
        this.props.fetchSelectedUsers(null);
    }

    handleCancel = () => {
        this.props.history.push(BasePath + '/permission');
    };

    handleChange = (event: any) => {
        if (event.target.name === 'groupSelected') {
            this.props.setUsergroupSelected(event.target.value);
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    checkingClassesEmpty = (flag: any) => {
        this.setState({
            disableAddBtn: flag,
        });
    };

    handleAddPermission = () => {
        if (this.state.disableAddBtn) {
            return;
        }
        this.setState({
            disableAddBtn: true,
        });
        if (this.props.selectedUsers.length != 0) {
            let tabsData = setActiveTabsTrue(this.props.selectedUsers, null);
            let formData = {
                id: this.props.selectedUsergroup.id,
                Name: this.props.selectedUsergroup.Name,
                description: this.props.selectedUsergroup.description,
                create_date: moment.utc().format(),
                created_by: 'Tom',
                IsActive: 1,
                ModifiedBy: 'Tom',
                ModifiedDate: moment.utc().format(),
                tabs: tabsData,
            };
            this.props.addUsergroup(formData, (res: any) => {
                if (res) {
                    this.props.history.push(BasePath + '/permission');
                } else {
                    this.setState({
                        showErr: true,
                        disableAddBtn: false,
                    });
                }
            });
        }
    };

    render() {
        const titleContainer = {
            marginTop: 10,
            marginBottom: 'auto',
            marginLeft: 10,
        };
        return (
            <Grid container>
                {this.props.processing ? (
                    <Loader />
                ) : (
                    <Grid container xs={12} style={containers}>
                        <Grid container style={{ margin: 10 }}>
                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            style={{ backgroundColor: '#ffffff' }}
                                            id="demo-simple-select-outlined-label"
                                        >
                                            Usergroup
                                        </InputLabel>
                                        <Select
                                            name="groupSelected"
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.groupSelected || ''}
                                            onChange={this.handleChange}
                                        >
                                            {this.props.usergroup &&
                                                this.props.usergroup.map((data: any) => {
                                                    return <MenuItem value={data}>{data.Name}</MenuItem>;
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {this.props.tabs ? (
                                <Grid container style={{ marginTop: 10, marginLeft: 10 }}>
                                    <Grid xs={5}>
                                        <TransferList
                                            leftData={this.props.tabs}
                                            rightData={[]}
                                            checkingClassesEmpty={this.checkingClassesEmpty}
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}

                            <Grid container xs={5} justify="flex-end" style={titleContainer}>
                                <Grid>
                                    <Button
                                        onClick={this.handleCancel}
                                        color="inherit"
                                        variant="contained"
                                        style={{ marginRight: 10 }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button
                                        disabled={!this.state.groupSelected || this.state.disableAddBtn}
                                        onClick={this.handleAddPermission}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        usergroup: state.usergroup_reducer.usergroup,
        selectedUsers: state.usergroup_reducer.selectedUsers,
        selectedUsergroup: state.permission_reducer.selectedUsergroup,
        tabs: state.permission_reducer.tabs,
        processing: state.usergroup_reducer.processing,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchUsergroups: () => dispatch(fetchUsergroups()),
        addUsergroup: (data: any, cb: any) => dispatch(addUsergroup(data, cb)),
        setUsergroupSelected: (data: any) => dispatch(setUsergroupSelected(data)),
        fetchSelectedUsers: (data: any) => dispatch(fetchSelectedUsers(data)),
        fetchTabs: () => dispatch(fetchTabs()),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(TabPermissionAdd);

export default withRouter(connected);
