import React, { Component } from 'react';
import { Grid, Paper } from '@material-ui/core';
import theme from '../../theme';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    fetchClassData,
    fetchDocumentClassTypeData,
    fetchPartClassTypeData,
    fetchChangeClassTypeData,
    fetchManufacturerClassTypeData,
    fetchManufacturerPartsClassTypeData,
    fetchSuppliersClassTypeData,
    fetchSuppliersItemsClassTypeData,
    setPageSize,
    fetchPSRClassTypeData,
    fetchQCRClassTypeData
} from '../../Services/browse';
import ClassTypeNameList from './ClassTypeNameList';
import ClassContent from './ClassContent';
import Loader from '../../components/Loader';
import { NotFound } from '../../views';
import NoDataFound from '../../components/Messages/NoDataFound';
import ClassContentPaging from './Components/ClassContentPaging';
import { BasePath, ClassTypes } from '../../config';
import { MIN_PAGE_SIZE } from '../../config';
import { returnRole } from "../../utils/ItemFormHelpers";
const styles = {
    root: {
        padding: theme.spacing(1),
        height: '82vh',
    },
    paperSearch: {
        height: theme.spacing(5),
    },
    paperBottom: {
        maxHeight: '70vh',
        minHeight: '70vh',
        overflow: 'auto',
    },

    paperSide: {
        maxHeight: '81vh',
        minHeight: '81vh',
        overflow: 'auto',
    },
};

interface props {
    setPageSize: any;
    fetchPartClassTypeData: any;
    fetchDocumentClassTypeData: any;
    PartsClassType: any;
    DocumentClassType: any;
    processingPart: any;
    errorPart: any;
    processingDocument: any;
    errorDocument: any;
    fetchClassData: any;
    SelectedClassData: any;
    ContentProcessing: any;
    fetchChangeClassTypeData: any;
    ChangesClassType: any;
    processingChange: any;
    errorChange: any;
    ManufacturersClassType: any;
    fetchManufacturerClassTypeData: any;
    ManufacturerPartsClassType: any;
    fetchManufacturerPartsClassTypeData: any;

    fetchSuppliersClassTypeData: any;
    SuppliersClassType: any;

    fetchSuppliersItemsClassTypeData: any;
    SuppliersItemsClassType: any;

    fetchPSRClassTypeData: any;
    PSRClassType: any;

    fetchQCRClassTypeData: any;
    QCRClassType: any;

    user: any;
    history: any;
}

class Index extends Component<props> {



    componentDidMount() {
        this.props.setPageSize(MIN_PAGE_SIZE);
        let userRole: any = returnRole(this.props.user.roles);
        if (userRole && userRole.Name == "Suppliers") {
            this.props.history.push(BasePath + '/suppliers');
        } else {
            if (
                (this.props.PartsClassType == null || this.props.PartsClassType.length == 0) &&
                ClassTypes.includes('Parts')
            ) {
                this.props.fetchPartClassTypeData();
            }
            if (
                (this.props.DocumentClassType == null || this.props.DocumentClassType.length == 0) &&
                ClassTypes.includes('Documents')
            ) {
                this.props.fetchDocumentClassTypeData();
            }
            if (
                (this.props.ChangesClassType == null || this.props.ChangesClassType.length == 0) &&
                ClassTypes.includes('Changes')
            ) {
                this.props.fetchChangeClassTypeData();
            }
            if (
                (this.props.ManufacturersClassType == null || this.props.ManufacturersClassType.length == 0) &&
                ClassTypes.includes('Manufacturers')
            ) {
                this.props.fetchManufacturerClassTypeData();
            }
            if (
                (this.props.PSRClassType == null || this.props.PSRClassType.length == 0) &&
                ClassTypes.includes('Product_Service_Requests')
            ) {
                this.props.fetchPSRClassTypeData();
            }
            if (
                (this.props.PSRClassType == null || this.props.QCRClassType.length == 0) &&
                ClassTypes.includes('Quality_Change_Requests')
            ) {
                this.props.fetchQCRClassTypeData();
            }
            if (
                (this.props.ManufacturerPartsClassType == null || this.props.ManufacturerPartsClassType.length == 0) &&
                ClassTypes.includes('Manufacturer_parts')
            ) {
                this.props.fetchManufacturerPartsClassTypeData();
            }

            if (
                (this.props.SuppliersClassType == null || this.props.SuppliersClassType.length == 0) &&
                ClassTypes.includes('Suppliers')
            ) {
                this.props.fetchSuppliersClassTypeData();
            }

            if (
                (this.props.SuppliersItemsClassType == null || this.props.SuppliersItemsClassType.length == 0) &&
                ClassTypes.includes('Supplier_Items')
            ) {
                this.props.fetchSuppliersItemsClassTypeData();
            }
        }
    }

    componentWillUnmount() {
        this.props.setPageSize(MIN_PAGE_SIZE);
    }

    render() {
        return (
            <div style={styles.root}>
                <Grid container spacing={2}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}></Grid>
                    <Grid item lg={3} sm={12} xl={3} xs={12}>
                        <Paper elevation={3} style={styles.paperSide}>
                            <ClassTypeNameList />
                        </Paper>
                    </Grid>
                    <Grid item lg={9} sm={12} xl={9} xs={12}>
                        <Paper elevation={3} style={styles.paperBottom}>
                            {this.props.ContentProcessing && <Loader />}
                            {this.props.SelectedClassData && (
                                <ClassContent
                                    //@ts-ignore
                                    layout={this.props.SelectedClassData.LAYOUT}
                                    data={this.props.SelectedClassData.DATA}
                                />
                            )}
                            {!this.props.ContentProcessing && !this.props.SelectedClassData && (
                                <NoDataFound title={'No data found'} />
                            )}
                        </Paper>
                        <Paper
                            elevation={3}
                            style={{
                                height: '10vh',
                                marginTop: theme.spacing(1),
                                padding: theme.spacing(1),
                            }}
                        >
                            <ClassContentPaging />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        PartsClassType: state.browse_reducer.PartsClassType,
        DocumentClassType: state.browse_reducer.DocumentClassType,
        processingPart: state.browse_reducer.processingPart,
        errorPart: state.browse_reducer.errorPart,
        processingDocument: state.browse_reducer.processingDocument,
        errorDocument: state.browse_reducer.errorDocument,
        SelectedClassData: state.browse_reducer.SelectedClassData,
        ContentProcessing: state.browse_reducer.ContentProcessing,

        ChangesClassType: state.browse_reducer.ChangesClassType,
        ManufacturersClassType: state.browse_reducer.ManufacturersClassType,
        SuppliersClassType: state.browse_reducer.SuppliersClassType,
        SuppliersItemsClassType: state.browse_reducer.SuppliersItemsClassType,
        PSRClassType: state.browse_reducer.PSRClassType,
        QCRClassType: state.browse_reducer.QCRClassType,
        ManufacturerPartsClassType: state.browse_reducer.ManufacturerPartsClassType,
        processingChange: state.browse_reducer.processingChange,
        errorChange: state.browse_reducer.errorChange,

        user: state.user_reducer.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchPartClassTypeData: () => dispatch(fetchPartClassTypeData()),
        fetchDocumentClassTypeData: () => dispatch(fetchDocumentClassTypeData()),
        fetchChangeClassTypeData: () => dispatch(fetchChangeClassTypeData()),
        fetchManufacturerClassTypeData: () => dispatch(fetchManufacturerClassTypeData()),
        fetchManufacturerPartsClassTypeData: () => dispatch(fetchManufacturerPartsClassTypeData()),
        fetchSuppliersClassTypeData: () => dispatch(fetchSuppliersClassTypeData()),
        fetchSuppliersItemsClassTypeData: () => dispatch(fetchSuppliersItemsClassTypeData()),
        fetchPSRClassTypeData: () => dispatch(fetchPSRClassTypeData()),
        fetchQCRClassTypeData: () => dispatch(fetchQCRClassTypeData()),
        setPageSize: (size: any) => dispatch(setPageSize(size)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
