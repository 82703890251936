import {
    GET_USERGROUPS,
    SET_USERGROUP_ERROR,
    SET_SELECTED_USERS,
    SET_EDIT_DATA,
    SET_SELECT_FROM_USERS,
    SET_PAGE_SIZE_USERGROUP,
    SET_TOTAL,
    SET_USERGROUP_PROCESSING,
} from '../action-types/user-group-action-types';
import { MIN_PAGE_SIZE } from '../config';
const initState = () => {
    return {
        usergroup: null,
        error: null,
        selectedUsers: null,
        selectFromUsers: null,
        editData: null,
        pageSizeUsergroup: MIN_PAGE_SIZE,
        total: null,
        processing: false,
    };
};

const usergroup_reducer = (state = initState(), action: any) => {
    let newState = { ...state };
    switch (action.type) {
        case GET_USERGROUPS:
            setData(newState, action.payload, 'usergroup');
            break;
        case SET_USERGROUP_PROCESSING:
            setData(newState, action.payload, 'processing');
            break;
        case SET_USERGROUP_ERROR:
            setData(newState, action.payload, 'error');
            break;
        case SET_SELECTED_USERS:
            setData(newState, action.payload, 'selectedUsers');
            break;
        case SET_SELECT_FROM_USERS:
            setData(newState, action.payload, 'selectFromUsers');
            break;
        case SET_EDIT_DATA:
            setData(newState, action.payload, 'editData');
            break;
        case SET_PAGE_SIZE_USERGROUP:
            setData(newState, action.payload, 'pageSizeUsergroup');
            break;
        case SET_TOTAL:
            setData(newState, action.payload, 'total');
            break;
        default:
            break;
    }
    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default usergroup_reducer;
