import React, { Component } from 'react';
import { Grid, Paper } from '@material-ui/core';
import theme from '../../theme';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/Loader';
import { NotFound } from '../../views';
import NoDataFound from '../../components/Messages/NoDataFound';
import SideBarImplement from '../PDSideBar';
import ConfigurationTypesList from './ConfigurationTypesList';
import ClassTypeConfigurations from './ClassTypeConfigurations';
import GeneralConfig from './GeneralConfig'
const styles = {
    root: {
        padding: theme.spacing(1),
        height: '82vh',
    },
    paperSearch: {
        height: theme.spacing(5),
    },
    paperBottom: {
        maxHeight: '64vh',
        minHeight: '64vh',
        overflow: 'auto',
    },

    paperSide: {
        maxHeight: '81vh',
        minHeight: '81vh',
        overflow: 'auto',
    },
};

interface props {}

class Index extends Component<any> {
    render() {
        return (
            <div style={styles.root}>

                <Grid container spacing={2}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}></Grid>
                    <Grid item lg={3} sm={12} xl={3} xs={12}>
                        <Paper elevation={3} style={styles.paperSide}>
                            <ConfigurationTypesList />
                        </Paper>
                    </Grid>
                    <Grid item lg={9} sm={12} xl={9} xs={12}>
                        {this.props.selectedConfiguration === "Tabs" && <ClassTypeConfigurations />}
                        {this.props.selectedConfiguration === "General Config" && <GeneralConfig />}
                    </Grid>
                </Grid>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        selectedConfiguration: state.configurations_reducer.selectedConfiguration,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
