import {
    getBookmarksData,
    setBookmarksData,
    getSavedSearchData,
    setSavedSearchData,
    setSavedSearchProcessing,
    getError,
    setProcessing,
    setTotal,
    setFavouritesModalState,
    setAdvanceSearchString,
    setCurrentTabInSearch,
    setBookmarksPageSize,
    setBookmarksTotal,
    setSearchesPageSize,
    setSearchesTotal,
    setCurentBookmarkPage,
    setCurrentSearchPage,
} from '../actions/favorites';
import axios from '../utils/axios';
import { setUserProcessing } from '../actions/userManagement';
import moment from 'moment';
import { MIN_PAGE_SIZE } from '../config';
export const addFavourites = (data: any, type: any, cb: any) => {
    return (dispatch: any, getState: any) => {
        const { user }: any = getState().user_reducer;

        let url = type == 'search' ? `/api/users/searches` : `/api/users/bookmarks`;

        let fromData = {
            ...data,
            CreatedBy: user.user_name,
            CreatedDate: new Date(),
            ModifiedBy: user.user_name,
            ModifiedDate: new Date(),
        };

        axios
            .post(url, fromData)
            .then(response => {
                if (response.data.success) {
                    cb(true);
                    dispatch(getFavouritesData(type, 0, MIN_PAGE_SIZE));
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const searchBookmark = (link: any, cb: any) => {
    return (dispatch: any, getState: any) => {
        const { user }: any = getState().user_reducer;

        let url = `/api/users/bookmarks/search`;

        let fromData = {
            BrowsingLink: link,
        };
        axios
            .post(url, fromData)
            .then(response => {
                if (response.data.result && response.data.result.length > 0) {
                    cb(true);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const updateFavourites = (id: any, data: any, type: any, cb: any) => {
    return (dispatch: any, getState: any) => {
        const { user }: any = getState().user_reducer;

        let url = type == 'search' ? `/api/users/searches/${id}` : `/api/users/bookmarks/${id}`;

        let fromData = { ...data, ModifiedBy: user.user_name, ModifiedDate: new Date() };

        axios
            .patch(url, fromData)
            .then(response => {
                if (response.data.success) {
                    cb(true);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const getFavouritesData = (type: any, page: any, pageSize: any) => {
    return (dispatch: any, getState: any) => {
        const { user }: any = getState().user_reducer;

        let url =
            type == 'search'
                ? `/api/users/favt/searches?page=${page}&pageSize=${pageSize}`
                : `/api/users/favt/bookmarks?page=${page}&pageSize=${pageSize}`;

        dispatch(setFavouritesDataLocal(type, null, 0));
        dispatch(setProcessing(true));
        axios
            .get(url)
            .then(response => {
                if (response.data.success && response.data.result && response.data.result.results) {
                    dispatch(setFavouritesDataLocal(type, response.data.result.results, response.data.result.total));
                    dispatch(setProcessing(false));
                } else {
                    dispatch(setFavouritesDataLocal(type, null, 0));
                    dispatch(setProcessing(false));
                }
            })
            .catch(err => {
                dispatch(setFavouritesDataLocal(type, null, 0));
                dispatch(setProcessing(false));
            });
    };
};

export const setFavouritesDataLocal = (type: any, data: any, total: any) => {
    return (dispatch: any) => {
        if (type == 'bookmark') {
            dispatch(setBookmarksData(data));
        } else {
            dispatch(setSavedSearchData(data));
        }
        dispatch(setDataCountFavt(type, total));
    };
};

export const deleteBookmark = (originalData: any, removeData: any) => {
    return (dispatch: any) => {
        const removedArray = originalData.filter((item: any) => item.id !== removeData.id);
        dispatch(setBookmarksData(removedArray));
    };
};

export const setSavedSearchDataLocal = (data: any) => {
    return (dispatch: any) => {
        dispatch(setSavedSearchData(data));
    };
};

export const setStateOfFavModal = (flag: any) => {
    return (dispatch: any) => {
        dispatch(setFavouritesModalState(flag));
    };
};

export const deleteSavedSearch = (originalData: any, removeData: any) => {
    return (dispatch: any) => {
        const removedArray = originalData.filter((item: any) => item.id !== removeData.id);
        dispatch(setSavedSearchData(removedArray));
    };
};

export const setSearchTabsProperty = (searchQuery: any, advanceSearchFlag: any) => {
    return (dispatch: any) => {
        dispatch(setAdvanceSearchString(null));
        dispatch(setAdvanceSearchString(searchQuery));
        dispatch(setCurrentTabInSearch(advanceSearchFlag));
    };
};

export const setPageSizeFavt = (type: any, data: any) => {
    return (dispatch: any) => {
        if (type == 'search') {
            dispatch(setSearchesPageSize(data));
        } else {
            dispatch(setBookmarksPageSize(data));
        }
    };
};

export const setDataCountFavt = (type: any, data: any) => {
    return (dispatch: any) => {
        if (type == 'search') {
            dispatch(setSearchesTotal(data));
        } else {
            dispatch(setBookmarksTotal(data));
        }
    };
};

export const setCurrentPageFavt = (type: any, data: any) => {
    return (dispatch: any) => {
        if (type == 'search') {
            dispatch(setCurrentSearchPage(data));
        } else {
            dispatch(setCurentBookmarkPage(data));
        }
    };
};
