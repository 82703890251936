import theme from '../theme';

export const containers: any = {
    minHeight: 50,
    background: '#fff',
    borderRadius: 2,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '7px 7px 5px #d4d3d4, -7px -7px 5px #efedf0',
};

export const tableHeaderSort: any = {
    minHeight: 50,
    background: '#fff',
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    boxShadow: 'rgba(236, 236, 236, 0.45) 7px 7px 5px, rgba(242, 242, 242, 0.64) -7px -7px 5px',
};

export const CardTile: any = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
};

export const shadowed: any = {
    backgroundColor: '#fefeff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
};

export const PoperIconStyle = {
    marginTop: '18px',
    color: ' #828282',
};
