import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import {
    TreeList,
    orderBy,
    filterBy,
    mapTree,
    extendDataItem,
    TreeListTextFilter,
    TreeListNumericFilter,
    TreeListDateFilter,
    TreeListBooleanFilter,
} from '@progress/kendo-react-treelist';
import { list_to_tree, make_tree} from '../../utils/browse';
import axios from 'axios';
import {SHOW_GRID_FILTERS} from '../../config'
import SnackbarCmpt from "../Snackbar";
import './tree.css'

const subItemsField = 'tableData';
const expandField = 'expanded';

interface Interface {
    tableData: any;
    columns: any;
    title: any;
}

class TreeHiraricalData extends Component<Interface> {
    state = {
        data: [...list_to_tree(this.props.tableData)],
        dataState: {
            sort: [],
            filter: [],
        },
        expanded: [],
        loading:false
    };


    onExpandChange = (e: any) => {

        if(!(this.state.expanded.find(it => it === e.dataItem.treeID)) && Object.keys(e.dataItem.tableData[0]).length === 1){
            console.log("called")
        let url = `/api/item/${e.dataItem.CLASS_TYPE}/${e.dataItem.CLASS_NAME}/${e.dataItem.ID_NUMBER}/${e.dataItem.REV}/${e.dataItem.GUID}?tab=BOM&recordsFrom=${1}&recordsTo=${2000}`;
       this.setState({loading:true})
        axios
            .get(url)
            .then((response: any) => {
                this.setState({loading:false})
                if (response.data.result) {  
                    this.setState({
                        data:[...make_tree(this.state.data,[...list_to_tree(response.data.result.DATA)],e.dataItem.treeID)]
                    })
                    }
                else {
                    console.log("no data found")
                }
            })
            .catch((error: any) => {
                this.setState({loading:false})
                console.log("ERRORR")
            });
        }
        
        this.setState({
            expanded: e.value
                ? this.state.expanded.filter(id => id !== e.dataItem.treeID)
                : [...this.state.expanded, e.dataItem.treeID],
        });

    };

    handleDataStateChange = (event: any) => {
        this.setState({
            dataState: event.dataState,
        });
    };

    addExpandField = (dataTree: any) => {
        // ;
        const expanded = this.state.expanded;
        return mapTree(dataTree, subItemsField, item =>
            extendDataItem(item, subItemsField, {   
                //@ts-ignore
                [expandField]: expanded.includes(item.treeID),
            }),
        );
    };

    processData = () => {
        let { data, dataState } = this.state;
        let filteredData = filterBy(data, dataState.filter, subItemsField);
        let sortedData = orderBy(filteredData, dataState.sort, subItemsField);
        return this.addExpandField(sortedData);
    };

    render() {

        const columns = [{ field: '', title: '', expandable: true, width: '1%' }, ...this.props.columns];
        if(!SHOW_GRID_FILTERS){
            columns.forEach((col:any) => {
                delete col.filter
            });
        }
        return (
            <div style={{height:"100%",width:"100%", overflow:"scroll" }}>
                <TreeList
                style={{ height: '100%',minWidth:"100%", width:"max-content",fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
                expandField={expandField}
                subItemsField={subItemsField}
                onExpandChange={this.onExpandChange}
                sortable={{ mode: 'multiple' }}
                {...this.state.dataState}
                data={this.processData()}
                onDataStateChange={this.handleDataStateChange}
                columns={columns}
                resizable={true}
                noRecords={<div style={{ color: 'red' }}>No record found</div>}
            />
                {this.state.loading && (
                    <SnackbarCmpt message={'Loading Bom data'} severity={'info'} />
                )}
            </div>
        );
    }
}

export default TreeHiraricalData;
