export const TRANSFORM_REVISION = (data:any) => {
    let result = data;
    let Transformer:any = {
        "?":"QuestionMark",
        "/":"ForwardSlash"
    }
    if(Transformer[data]){
        result = Transformer[data];
    }

    return result

}