import { Button, Grid, IconButton } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Treefile from '../../components/KendoUICmpt/TreeList';
import { containers } from '../../utils/style';
import { fetchClassPermission, setClassEdit, fetchClasses, setNewPageSizeClass } from '../../Services/permission';
import { TreeListDateFilter, TreeListTextFilter } from '@progress/kendo-react-treelist';
import EditIcon from '@material-ui/icons/Edit';
import theme from '../../theme';
import CustomPagination from '../../components/CustomPagination';
import Loader from '../../components/Loader';
import NoDataFound from '../../components/Messages/NoDataFound';
import { BasePath } from '../../config';

interface props {
    classProcessing: any;
    fetchClassPermission: any;
    classPermissionData: any;
    setClassEdit: any;
    classEditData: any;
    fetchClasses: any;
    classes: any;
    history: any;
    setNewPageSizeClass: any;
    pageSizeClass: any;
    total: any;
}

class ClassPermission extends Component<props> {
    state = {
        add: false,
        page: 1,
    };

    columns = [
        {
            field: 'Name',
            title: 'Usergroup Name',
            width: 250,
            filter: TreeListTextFilter,
            expandable: true,
        },
        {
            field: 'description',
            title: 'Description',
            width: 250,
            filter: TreeListTextFilter,
        },
        {
            field: 'create_date',
            title: 'Created Date',
            width: 200,
            filter: TreeListTextFilter,
        },
        {
            field: 'created_by',
            title: 'Created By',
            width: 200,
            filter: TreeListTextFilter,
        },
        {
            title: 'Total Classes',
            field: 'classes',
            width: 20,
            cell: (rowData: any) => (
                <td>
                    {rowData.dataItem.classes && rowData.dataItem.classes.length ? rowData.dataItem.classes.length : 0}
                </td>
            ),
        },
        {
            width: 20,
            cell: (rowData: any) => (
                <IconButton aria-label="Edit" onClick={(e: any) => this.handleEdit(e, rowData)}>
                    <EditIcon />
                </IconButton>
            ),
        },
    ];

    componentDidMount() {
        this.props.fetchClassPermission(this.state.page);
        this.props.fetchClasses();
    }

    handleAdd = () => {
        this.props.history.push(BasePath + '/permission/class-add');
    };

    handleEdit = (e: any, rowData: any) => {
        this.props.setClassEdit(rowData);
        console.log(rowData);
        this.props.history.push(BasePath + '/permission/class-edit');
    };

    handlePage = (value: any) => {
        this.setState({
            page: parseInt(value),
        });
        this.props.fetchClassPermission(value);
    };

    handleNewPageSize = (event: any) => {
        this.props.setNewPageSizeClass(event.target.value);
        this.props.fetchClassPermission(1);
    };

    render() {
        return (
            <div>
                <Grid container>
                    <Grid container justify="flex-end">
                        <Button
                            style={{ marginTop: '16px' }}
                            variant="contained"
                            onClick={() => {
                                this.setState({
                                    add: true,
                                });
                            }}
                            color="primary"
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                <div style={{ marginTop: 20 }}>
                    {this.props.classProcessing && <Loader />}

                    {this.props.classPermissionData != null ? (
                        <div>
                            <Treefile columns={this.columns} tableData={this.props.classPermissionData} />
                            <div style={{ margin: theme.spacing(3) }}>
                                <CustomPagination
                                    total={this.props.total}
                                    page={this.state.page}
                                    handlePage={this.handlePage}
                                    pageSize={this.props.pageSizeClass}
                                    handleNewPageSize={this.handleNewPageSize}
                                />
                            </div>
                        </div>
                    ) : (
                        !this.props.classProcessing && (
                            <div style={{ width: '100%' }}>
                                <NoDataFound title={'No Classes found'} />
                            </div>
                        )
                    )}
                </div>
                {this.state.add ? this.handleAdd() : null}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        classProcessing: state.permission_reducer.classProcessing,
        classPermissionData: state.permission_reducer.classPermissionData,
        classEditData: state.permission_reducer.classEditData,
        classes: state.permission_reducer.classes,
        pageSizeClass: state.permission_reducer.pageSizeClass,
        total: state.permission_reducer.total,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchClassPermission: (page: any) => dispatch(fetchClassPermission(page)),
        setClassEdit: (data: any) => dispatch(setClassEdit(data)),
        fetchClasses: () => dispatch(fetchClasses()),
        setNewPageSizeClass: (event: any) => dispatch(setNewPageSizeClass(event)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(ClassPermission);

export default withRouter(connected);
