import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { separateDataForFiles, sortArrayObjectViewSeq, sortArrayObjectViewSeqSearch } from '../../../../utils/browse';
import { Avatar, Tooltip, Typography } from '@material-ui/core';
import { TreeListTextFilter } from '@progress/kendo-react-treelist';
import Treefile from '../../../../components/KendoUICmpt/TreeList';
import { BasePath, BUCKET_BASE_URL, ORACLE_CLOUD_BASE_URL, TEXT_SEARCH, TIME_ZONE_LABEL, TIME_ZONE, DATA_LABELS } from '../../../../config';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { downloadFileFromVault } from "../../../../Services/browse";
import { filterDataANDLayout, returnUTCFormatedDate, filterAndRearrange } from "../../../../utils";
import moment from "moment-timezone";
import { provideIconForTab } from "../../../../utils/IconProvider";
import { replaceNames } from "../../../../utils/ItemFormHelpers";
import SearchTiles from "../../Components/SearchTiles";

interface props {
    layout: any;
    data: any;
    fetchClassData: any;
    selectedClass: any;
    selectedClassType: any;
    setPageSize: any;
    history: any;
    tileView: any;
}
class SearchClassContent extends Component<props> {

    state = {
        show: false,
        dataColumns: [],
        data: {}
    };


    componentDidMount() {
        let { data } = this.props;
        let val: any = [];

        let dataSegregated = filterDataANDLayout(data)
        for (let key in dataSegregated) {
            let newData: any;
            dataSegregated[key].forEach((obj: any, index: any) => {
                newData = filterAndRearrange(obj)
                dataSegregated[key][index] = newData
            })
        }
        this.setState({
            show: true,
            dataColumns: val,
            data: dataSegregated
        });
    }


    downloadFile = (event: any, data: any) => {
        if (data.dataItem['file_path']) {
            let path = data.dataItem['file_path'];
            if (data.dataItem['DID']) {
                let val = `${ORACLE_CLOUD_BASE_URL}/fscmUI/content/conn/FusionAppsContentRepository/uuid/dDocID:${data.dataItem['DID']}?download`;
                window.open(`${val}`);
            } else if (path.includes('http')) {
                window.open(`${path}`);
            } else {
                downloadFileFromVault(path, (data: any) => {
                    if (data) {
                        window.open(`${data}`);
                    }
                })

            }
        }
    };

    getLayout = (data: any) => {

        let val: any = [];
        if (data["File Name"]) {
            data = { "File Name": data["File Name"], ...data }

        }
        debugger;
        for (const property in data) {
            if (property != 'custom_id' && property != 'CLASS_NAME' && property != 'CLASS_TYPE' && property != 'isAttachment' && property != 'Guid' && property != 'attachment' && property != 'isAffectedItem' && property != 'isLatest' && property != 'Rev Release Date') {
               
                if (property == 'Number' || (property == 'ID') || property == 'Item Name') {
                    val.push({
                        title: `${property.toUpperCase()}`,
                        field: `${property}`,
                        cell: (rowData: any) => (
                            <Tooltip title="Open details">
                                <td>
                                    <span
                                        style={{ color: 'blue', cursor: 'pointer' }}
                                        onClick={(e: any) => this.showDetails(e, rowData)}
                                    >
                                        <span>{rowData.dataItem['isLatest'] ? ' *' : ''}</span>
                                        {rowData.dataItem[rowData.field]}

                                    </span>
                                </td>
                            </Tooltip>
                        ),
                    });
                } else if (property == 'file_path') {
                    val.push({
                        title: `DOWNLOAD FILE`,
                        field: `${property}`,
                        cell: (rowData: any) =>
                            rowData.dataItem[rowData.field] || true ? (

                                <td>
                                    <Tooltip title={rowData.dataItem["file_path"].split('.')[1] ? rowData.dataItem["file_path"].split('.')[1] : 'File Type'}>

                                        <Avatar
                                            onClick={(e: any) => {
                                                this.downloadFile(e, rowData);
                                            }}
                                            src={provideIconForTab(rowData.dataItem["file_path"].split('.')[1])}
                                            style={{ borderRadius: "10%", maxWidth: "30px", maxHeight: "30px", cursor: 'pointer' }} />

                                    </Tooltip>
                                </td>
                            ) : (
                                <div></div>
                            ),
                    });
                }
                else if (property == 'affected_items') {
                    val.push({
                        title: `AFFECTED OBJECTS`,
                        children: [{
                            title: `Item Number`,
                            field: `affected_items.Item Number`
                        },
                        {
                            title: `Lifecycle Phase`,
                            field: `affected_items.Lifecycle Phase`
                        },
                        {
                            title: `VPAP Level`,
                            field: `affected_items.VPAP Level`
                        },
                        {
                            title: `Roche Material Number`,
                            field: `affected_items.Roche Material Number`
                        },
                        {
                            title: `Planning Type`,
                            field: `affected_items.planning_type`
                        }

                        ]
                    });
                }
                else if (property.includes('Date')) {
                    val.push({
                        title: `${property.toUpperCase()}`,
                        field: `${property}`,
                        cell: (rowData: any) =>
                            rowData.dataItem[rowData.field] ? (
                                <td>
                                    {moment(returnUTCFormatedDate(rowData.dataItem[rowData.field])).tz(TIME_ZONE).format('DD-MMMM-YYYY  h:mm:ss A')} {TIME_ZONE_LABEL}
                                </td>
                            ) : (
                                <td>
                                    <span></span>
                                </td>
                            ),
                    });

                }
                else {
                    val.push({
                        title: `${property.toUpperCase()}`,
                        field: `${property}`,
                        filter: TreeListTextFilter,
                        cell: (rowData: any) =>
                            rowData.dataItem[rowData.field] != null && rowData.dataItem[rowData.field] != undefined ? (
                                <td>
                                    <div style={{ minHeight: "50px", maxHeight: "130px", overflow: "auto" }}>
                                        {typeof (rowData.dataItem[rowData.field]) == "number" ? rowData.dataItem[rowData.field].toString() : replaceNames(rowData.dataItem[rowData.field])}
                                    </div>
                                </td>
                            ) : (
                                <td>
                                    <span></span>
                                </td>
                            ),
                    });
                }

            }

        }

        return val;

    }

    showDetails = (event: any, data: any) => {
        let classType = data.dataItem['CLASS_TYPE'];
        let className = data.dataItem['CLASS_NAME'];
        let id = '';

        if (data.dataItem['CLASS_TYPE'] == 'Manufacturers') {
            id = data.dataItem['Number'];
        } else if (data.dataItem['Number']) {
            id = data.dataItem['Number'];
        }
        else if (data.dataItem['Item Name']) {
            id = data.dataItem['Item Name'];
        }

        let url = `${BasePath}/item/${classType}/${className}/${id}`;
        let rev = data.dataItem[DATA_LABELS.REVISION];
        let guid = data.dataItem.Guid;
        if (rev) {
            url = url + `/${rev}`;
        } else {
            url = url + `/1`;
        }

        if (guid) {
            url = url + `/${guid}`;
        }
        //window.open(/class/item?${classType}#${className}#${id}, "")
        this.props.history.push(url);
    };

    render() {
        return (
            <div >
                {this.props.tileView.IsActive ? (<div style={{ paddingTop: "10px" }}>

                    {this.state.show && this.props.data.map((mainData: any, i: any) => {

                        //@ts-ignore
                        return this.state.data ? <SearchTiles data={mainData} /> : null
                    })
                    }
                </div>) :
                    <div style={{ height: '100%', overflowX: 'auto' }}>
                        <div>
                            {
                                this.state.show && Object.entries(this.state.data).map((key: any, i: any) => {

                                    //@ts-ignore
                                    return this.state.data && this.state.data[key[0]][0] ? <Treefile columns={this.getLayout(this.state.data[key[0]][0])} tableData={this.state.data[key[0]]} /> : null
                                })
                            }
                        </div>
                    </div>}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedClass: state.browse_reducer.selectedClass,
        selectedClassType: state.browse_reducer.selectedClassType,
        tileView: state.configurations_reducer.generalConfigurations.find((obj: any) => obj.ConfigurationName === "CARD_VIEW")
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(SearchClassContent);

export default withRouter(connected);
