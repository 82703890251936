import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { editConfigurations, getConfigurations } from '../../Services/user';
import { sortArrayObjectViewSeq } from '../../utils/browse';
import theme from '../../theme';
import { Divider, Grid, Typography } from '@material-ui/core';
import { containers } from '../../utils/style';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ConfigurationViews from './ConfigurationsViewRedline';
import moment from 'moment';
import SnackbarCmpt from '../../components/Snackbar';
import { fetchNotificationDashboard } from '../../Services/dashboard';

const AttributeData = (props: any) => {
    const [formState, setFormState]: any = useState({
        edit: false,
        editData: {},
        open: {},
    });

    const showHeader = () => {
        return (
            <Grid container style={containers}>
                <Grid
                    sm={12}
                    md={3}
                    style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h3">{props.tab}</Typography>
                </Grid>
            </Grid>
        );
    };

    const showData = () => {
        //   ;
        //console.log(props,"props");

        if (props.layout.subClass && props.layout.subClass == true) {
            return (
                <ConfigurationViews
                    data={props.data}
                    layout={sortArrayObjectViewSeq(props.layout)}
                    page={props.tab}
                    subClass={props.layout.subClass}
                />
            );
        } else {
            return (
                <ConfigurationViews data={props.data} layout={sortArrayObjectViewSeq(props.layout)} page={props.tab} />
            );
        }
    };

    return (
        <div>
            {/* {showHeader()} */}
            <div style={{ padding: theme.spacing(3) }}>{props.data && showData()}</div>
        </div>
    );
};

export default AttributeData;
