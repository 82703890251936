import {
    GET_CLASS_PERMISSION,
    SET_PERMISSION_ERROR,
    GET_TABS_PERMISSION,
    GET_USERGROUP_CLASS,
    SET_SELECTED_USERGROUP,
    SET_CLASS_EDIT,
    GET_CLASSES,
    GET_TABS,
    SET_TABS_EDIT,
    SET_PAGE_SIZE_CLASS,
    SET_PAGE_SIZE_TABS,
    SET_TOTAL,
    SET_CLASS_PROCESSING,
    SET_TABS_PROCESSING,
} from '../action-types/permission-action-types';

export const getClassPermissionData = (data: any) => {
    return {
        type: GET_CLASS_PERMISSION,
        payload: data,
    };
};

export const setClassProcessing = (flag: any) => {
    return {
        type: SET_CLASS_PROCESSING,
        payload: flag,
    };
};

export const setTabsProcessing = (flag: any) => {
    return {
        type: SET_TABS_PROCESSING,
        payload: flag,
    };
};

export const getTabsPermissionData = (data: any) => {
    return {
        type: GET_TABS_PERMISSION,
        payload: data,
    };
};

export const getUsergroupClassData = (data: any) => {
    return {
        type: GET_USERGROUP_CLASS,
        payload: data,
    };
};

export const setSelectedUsergroup = (data: any) => {
    return {
        type: SET_SELECTED_USERGROUP,
        payload: data,
    };
};

export const setClassEditData = (data: any) => {
    return {
        type: SET_CLASS_EDIT,
        payload: data,
    };
};

export const getClasses = (data: any) => {
    return {
        type: GET_CLASSES,
        payload: data,
    };
};

export const getTabs = (data: any) => {
    let sortedData = [];
    if (data) {
        sortedData = data.sort(function(a: any, b: any) {
            if (a.class_type < b.class_type) {
                return -1;
            }
            if (a.class_type > b.class_type) {
                return 1;
            }
            return 0;
        });
    }
    return {
        type: GET_TABS,
        payload: sortedData,
    };
};

export const setTabsEditData = (data: any) => {
    return {
        type: SET_TABS_EDIT,
        payload: data,
    };
};

export const setPageSizeClass = (data: any) => {
    return {
        type: SET_PAGE_SIZE_CLASS,
        payload: data,
    };
};

export const setPageSizeTabs = (data: any) => {
    return {
        type: SET_PAGE_SIZE_TABS,
        payload: data,
    };
};

export const setTotal = (data: any) => {
    return {
        type: SET_TOTAL,
        payload: data,
    };
};

export const getError = (error: any) => {
    return {
        type: SET_PERMISSION_ERROR,
        payload: error,
    };
};
