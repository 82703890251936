import React, { Component } from 'react';
import { ADD_CATEGORIES, checkPermissionForCategories } from '../../utils/categories';
import { connect } from 'react-redux';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { containers } from '../../utils/style';
import { addNewCategory } from '../../Services/categories';
import SnackbarCmpt from '../../components/Snackbar';
import CategoryForm from '../../components/Categories/CategoryForm';
import moment from 'moment';

interface propsInterface {
    views: any;
    user: any;
    history: any;
    addNewCategory: any;
}

class AddCategories extends Component<propsInterface> {
    state = {
        show: false,
        showErr: false,
    };

    handleSubmit = (category: any) => {
        let formData = {
            ...category,
            CreatedDate: moment.utc().format(),
            CreatedBy: this.props.user.UserName,
        };
        this.props.addNewCategory(formData, (res: any) => {
            if (res) {
                this.setState({
                    show: true,
                });

                setTimeout(() => {
                    this.props.history.push('/categories');
                }, 1000);
            } else {
                this.setState({
                    showErr: true,
                });
            }
        });
    };

    render() {
        return (
            <div>
                {checkPermissionForCategories(this.props.views).includes(ADD_CATEGORIES) ? (
                    <div>
                        <Grid container style={containers}>
                            <Grid>
                                <CategoryForm title={'Add Category'} submitAction={this.handleSubmit} />
                            </Grid>
                            {this.state.show && (
                                <SnackbarCmpt message={'Added Category successfully'} severity={'success'} />
                            )}
                            {this.state.showErr && (
                                <SnackbarCmpt message={'Could not add Category'} severity={'error'} />
                            )}
                        </Grid>
                    </div>
                ) : (
                    <Redirect to="/not-found" />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        views: state.user_reducer.views,
        user: state.user_reducer.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        addNewCategory: (data: any, cb: any) => dispatch(addNewCategory(data, cb)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(AddCategories);

export default withRouter(connected);
