import {
    SET_PART_PROCESSING,
    SET_PART_ERROR,
    SET_DOCUMENT_PROCESSING,
    SET_DOCUMENT_ERROR,
    GET_PART_CLASS_MAINDATA,
    GET_DOCUMENT_CLASS_MAINDATA,
    PROCESSING_SELECTED_CLASSNAME,
    GET_SELECTED_CLASSNAME,
    ERR_SELECTED_CLASSNAME,
    SELECTED_CLASS_NAME,
    SET_CLASS_CONTENT_PAGE_SIZE,
    SET_CLASS_CONTENT_TOTAL,
    SET_CLASS_CONTENT_TOTAL_BROWSE,
    SET_SELECTED_CLASS_TYPE,
    SET_SEARCHED_DATA,
    SET_SEARCHED_PROCESSING,
    SET_ADVANCE_SEARCHED_DATA,
    SET_ADVANCE_SEARCHED_PROCESSING,
    SET_TEXT_SEARCH_DATA,
    TEXT_SEARCH_PROCESSING,
    GET_CHANGE_CLASS_MAINDATA,
    SET_CHANGE_ERROR,
    SET_CHANGE_PROCESSING,
    GET_MANUFACTURER_CLASS_MAINDATA,
    GET_MANUFACTURER_PARTS_CLASS_MAINDATA,
    SET_MANUFACTURER_ERROR,
    SET_MANUFACTURER_PARTS_ERROR,
    SET_MANUFACTURER_PARTS_PROCESSING,
    SET_MANUFACTURER_PROCESSING,
    GET_SUPPLIERS_CLASS_MAINDATA,
    SET_SUPPLIERS_ERROR,
    SET_SUPPLIERS_PROCESSING,
    GET_SUPPLIERS_ITEMS_CLASS_MAINDATA,
    SET_SUPPLIERS_ITEMS_ERROR,
    SET_SUPPLIERS_ITEMS_PROCESSING,
    GET_PSR_MAINDATA,
    SET_PSR_ERROR,
    SET_PSR_PROCESSING,

    GET_QCR_MAINDATA,
    SET_QCR_ERROR,
    SET_QCR_PROCESSING,

    SET_SIMPLE_SEARCH_PREV_STATE,
    SET_ADVANCE_SEARCH_PREV_STATE, GET_SELECTED_CLASSLIST,
    SET_STATUS_LIST
} from '../action-types/browse-action-types';

export const setDocumentTypeClassData = (data: any) => {
    return {
        type: GET_DOCUMENT_CLASS_MAINDATA,
        payload: data,
    };
};
export const setDocumentTypeClassProcessing = (data: any) => {
    return {
        type: SET_DOCUMENT_PROCESSING,
        payload: data,
    };
};

export const setDocumentTypeClassError = (data: any) => {
    return {
        type: SET_DOCUMENT_ERROR,
        payload: data,
    };
};

export const setChangeTypeClassData = (data: any) => {
    return {
        type: GET_CHANGE_CLASS_MAINDATA,
        payload: data,
    };
};
export const setChangeTypeClassProcessing = (data: any) => {
    return {
        type: SET_CHANGE_PROCESSING,
        payload: data,
    };
};

export const setChangeTypeClassError = (data: any) => {
    return {
        type: SET_CHANGE_ERROR,
        payload: data,
    };
};

//manufacturer
export const setManufacturerTypeClassData = (data: any) => {
    return {
        type: GET_MANUFACTURER_CLASS_MAINDATA,
        payload: data,
    };
};
export const setManufacturerTypeClassProcessing = (data: any) => {
    return {
        type: SET_MANUFACTURER_PROCESSING,
        payload: data,
    };
};

export const setManufacturerTypeClassError = (data: any) => {
    return {
        type: SET_MANUFACTURER_ERROR,
        payload: data,
    };
};

//manufacturer parts
export const setManufacturerPartsTypeClassData = (data: any) => {
    return {
        type: GET_MANUFACTURER_PARTS_CLASS_MAINDATA,
        payload: data,
    };
};
export const setManufacturerPartsTypeClassProcessing = (data: any) => {
    return {
        type: SET_MANUFACTURER_PARTS_PROCESSING,
        payload: data,
    };
};

export const setManufacturerPartsTypeClassError = (data: any) => {
    return {
        type: SET_MANUFACTURER_PARTS_ERROR,
        payload: data,
    };
};

//suppliers parts
export const setSuppliersTypeClassData = (data: any) => {
    return {
        type: GET_SUPPLIERS_CLASS_MAINDATA,
        payload: data,
    };
};
export const setSuppliersTypeClassProcessing = (data: any) => {
    return {
        type: SET_SUPPLIERS_PROCESSING,
        payload: data,
    };
};

export const setSuppliersTypeClassError = (data: any) => {
    return {
        type: SET_SUPPLIERS_ERROR,
        payload: data,
    };
};

//suppliers items
export const setSuppliersItemsTypeClassData = (data: any) => {
    return {
        type: GET_SUPPLIERS_ITEMS_CLASS_MAINDATA,
        payload: data,
    };
};
export const setSuppliersItemsTypeClassProcessing = (data: any) => {
    return {
        type: SET_SUPPLIERS_ITEMS_PROCESSING,
        payload: data,
    };
};

export const setSuppliersItemsTypeClassError = (data: any) => {
    return {
        type: SET_SUPPLIERS_ITEMS_ERROR,
        payload: data,
    };
};

//PSR
export const setPSRTypeClassData = (data: any) => {
    return {
        type: GET_PSR_MAINDATA,
        payload: data,
    };
};
export const setPSRClassProcessing = (data: any) => {
    return {
        type: SET_PSR_PROCESSING,
        payload: data,
    };
};

export const setPSRTypeClassError = (data: any) => {
    return {
        type: SET_PSR_ERROR,
        payload: data,
    };
};
//////////


//QCR
export const setQCRTypeClassData = (data: any) => {
    return {
        type: GET_QCR_MAINDATA,
        payload: data,
    };
};
export const setQCRClassProcessing = (data: any) => {
    return {
        type: SET_QCR_PROCESSING,
        payload: data,
    };
};

export const setQCRTypeClassError = (data: any) => {
    return {
        type: SET_QCR_ERROR,
        payload: data,
    };
};
//////////

export const setPartTypeClassData = (data: any) => {
    return {
        type: GET_PART_CLASS_MAINDATA,
        payload: data,
    };
};

export const setPartTypeClassProcessing = (data: any) => {
    return {
        type: SET_PART_PROCESSING,
        payload: data,
    };
};
export const setPartTypeClassError = (data: any) => {
    return {
        type: SET_PART_ERROR,
        payload: data,
    };
};

export const setClassData = (data: any) => {
    return {
        type: GET_SELECTED_CLASSNAME,
        payload: data,
    };
};
export const setClassNameList = (data: any) => {
    return {
        type: GET_SELECTED_CLASSLIST,
        payload: data,
    };
};


export const setClassProcessing = (data: any) => {
    return {
        type: PROCESSING_SELECTED_CLASSNAME,
        payload: data,
    };
};

export const setSelectedClass = (data: any) => {
    return {
        type: SELECTED_CLASS_NAME,
        payload: data,
    };
};

export const setSelectedClassType = (data: any) => {
    return {
        type: SET_SELECTED_CLASS_TYPE,
        payload: data,
    };
};

export const setClassContentPageSize = (data: any) => {
    return {
        type: SET_CLASS_CONTENT_PAGE_SIZE,
        payload: data,
    };
};

export const setClassContentTotal = (data: any) => {
    return {
        type: SET_CLASS_CONTENT_TOTAL,
        payload: data,
    };
};

export const setClassContentTotalBrowse = (data: any) => {
    return {
        type: SET_CLASS_CONTENT_TOTAL_BROWSE,
        payload: data,
    };
};

export const setSearchedData = (data: any) => {
    return {
        type: SET_SEARCHED_DATA,
        payload: data,
    };
};

export const setSearchedDataProcessing = (data: any) => {
    return {
        type: SET_SEARCHED_PROCESSING,
        payload: data,
    };
};

export const setSearchedAdvanceData = (data: any) => {
    return {
        type: SET_ADVANCE_SEARCHED_DATA,
        payload: data,
    };
};

export const setSearchedAdvanceDataProcessing = (data: any) => {
    return {
        type: SET_ADVANCE_SEARCHED_PROCESSING,
        payload: data,
    };
};

export const setTextSearchData = (data: any) => {
    return {
        type: SET_TEXT_SEARCH_DATA,
        payload: data,
    };
};

export const setTextSearchDataProcessing = (data: any) => {
    return {
        type: TEXT_SEARCH_PROCESSING,
        payload: data,
    };
};


export const setSimpleSearchPrevState = (data: any) => {
    return {
        type: SET_SIMPLE_SEARCH_PREV_STATE,
        payload: data,
    };
};

export const setAdvanceSearchPrevState = (data: any) => {
    return {
        type: SET_ADVANCE_SEARCH_PREV_STATE,
        payload: data,
    };
};


export const setSelectedClassName = (data: any) => {
    return {
        type: SELECTED_CLASS_NAME,
        payload: data,
    };
};


export const setStatusList = (data: any) => {
    return {
        type: SET_STATUS_LIST,
        payload: data,
    };
};