import React, { Component } from 'react';
import { Grid, Paper } from '@material-ui/core';
import theme from '../../../theme';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/Loader';
import NoDataFound from '../../../components/Messages/NoDataFound';
import SearchingComponent from './Components/SearchingComponent';
import { searchClassData, setDataSize } from '../../../Services/browse';
import SearchContentPaging from './Components/SearchContentPaging';
import SearchClassContent from './Components/SearchClassContent';
import ExportDataCSV from '../../../components/ExportDataCSV';
import {MIN_EXPORT_SIMPLE_SEARCH} from "../../../config";

const styles = {
    root: {
        marginTop: theme.spacing(1),
        height: '100vh',
    },
    paperSearch: {
        padding: theme.spacing(2),
        maxHeight: '22vh',
        minHeight: '22vh',
        overflow: 'auto',
    },

    paperSide: {
        minHeight: '65vh',
    },
};

interface props {
    fetchPartClassTypeData: any;
    fetchDocumentClassTypeData: any;
    PartsClassType: any;
    DocumentClassType: any;
    processingPart: any;
    errorPart: any;
    processingDocument: any;
    errorDocument: any;
    fetchClassData: any;
    SelectedClassData: any;
    ContentProcessing: any;
    searchClassData: any;
    pageSizeClassContent: any;
    searchedResults: any;
    searchProcessing: any;
    setDataSize: any;
    simpleSearchPrevState:any;
}

class Index extends Component<props> {
    state = {
        filter: '',
    };

    componentDidMount() {
        if (this.props.searchedResults && this.props.searchedResults.COUNT) {
            this.props.setDataSize(this.props.searchedResults.COUNT);
            if(this.props.simpleSearchPrevState && this.props.simpleSearchPrevState.searchValue ){
                this.setState({filter:`&searchValue=${this.props.simpleSearchPrevState.searchValue}`})
            }
        } else {
            this.props.setDataSize(0);
        }
    }

    componentWillUnmount() {
        this.props.setDataSize(0);
    }

    callWithFilters = (filter: any) => {
        this.setState({
            filter: filter,
        });

        this.props.searchClassData(filter, 0, this.props.pageSizeClassContent);
    };

    render() {
        return (
            <div style={styles.root}>
                <Grid container spacing={2}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Paper elevation={3} style={styles.paperSearch}>
                            <SearchingComponent callWithFilters={this.callWithFilters} />
                        </Paper>
                    </Grid>
                    {this.props.searchedResults && (
                        <Grid
                            item
                            lg={12}
                            sm={12}
                            xl={12}
                            xs={12}
                            container
                            direction="column"
                            justify="center"
                            alignItems="flex-end"
                        >
                            <ExportDataCSV
                                method={'get'}
                                name={'Simple_Search_Data'}
                                url={`/api/search/simple?pageSize=${MIN_EXPORT_SIMPLE_SEARCH}&page=${0}${this.state.filter}`}
                                body={null}
                                size={MIN_EXPORT_SIMPLE_SEARCH}
                            />
                        </Grid>
                    )}
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Paper elevation={3} style={styles.paperSide}>
                            {this.props.searchProcessing && <Loader />}
                            {this.props.searchedResults && this.props.searchedResults.DATA && (
                                <SearchClassContent
                                    //@ts-ignore
                                    data={this.props.searchedResults.DATA['data']}
                                />
                            )}
                            {!this.props.searchProcessing && !this.props.searchedResults && (
                                <NoDataFound title={'No Results To Display'} />
                            )}
                        </Paper>
                        <Paper
                            elevation={3}
                            style={{ height: '10vh', marginTop: theme.spacing(1), padding: theme.spacing(1) }}
                        >
                            <SearchContentPaging
                                //@ts-ignore
                                filter={this.state.filter}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        PartsClassType: state.browse_reducer.PartsClassType,
        DocumentClassType: state.browse_reducer.DocumentClassType,
        processingPart: state.browse_reducer.processingPart,
        errorPart: state.browse_reducer.errorPart,
        processingDocument: state.browse_reducer.processingDocument,
        errorDocument: state.browse_reducer.errorDocument,
        SelectedClassData: state.browse_reducer.SelectedClassData,
        pageSizeClassContent: state.browse_reducer.pageSizeClassContent,
        searchedResults: state.browse_reducer.searchedResults,
        searchProcessing: state.browse_reducer.searchProcessing,
        simpleSearchPrevState: state.browse_reducer.simpleSearchPrevState
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        searchClassData: (filter: any, offset: any, records: any) => dispatch(searchClassData(filter, offset, records)),
        setDataSize: (size: any) => dispatch(setDataSize(size)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
