import { Button, Grid, IconButton } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Treefile from '../../components/KendoUICmpt/TreeList';
import { containers } from '../../utils/style';
import { fetchTabsPermission, setTabsEdit, fetchTabs, setNewPageSizeTabs } from '../../Services/permission';
import { TreeListDateFilter, TreeListTextFilter } from '@progress/kendo-react-treelist';
import EditIcon from '@material-ui/icons/Edit';
import CustomPagination from '../../components/CustomPagination';
import theme from '../../theme';
import NoDataFound from '../../components/Messages/NoDataFound';
import Loader from '../../components/Loader';
import { BasePath } from '../../config';

interface props {
    tabsProcessing: any;
    fetchTabsPermission: any;
    tabsPermissionData: any;
    setTabsEdit: any;
    tabsEditData: any;
    fetchTabs: any;
    tabs: any;
    history: any;
    pageSizeTabs: any;
    setNewPageSizeTabs: any;
    total: any;
}

class TabsPermission extends Component<props> {
    state = {
        add: false,
        page: 1,
    };

    columns = [
        {
            field: 'Name',
            title: 'Usergroup Name',
            width: 250,
            filter: TreeListTextFilter,
            expandable: true,
        },
        {
            field: 'description',
            title: 'Description',
            width: 250,
            filter: TreeListTextFilter,
        },
        {
            field: 'create_date',
            title: 'Created Date',
            width: 200,
            filter: TreeListTextFilter,
        },
        {
            field: 'created_by',
            title: 'Created By',
            width: 200,
            filter: TreeListTextFilter,
        },
        {
            title: 'Total Tab(s)',
            field: 'tabs',
            width: 20,
            cell: (rowData: any) => (
                <td>{rowData.dataItem.tabs && rowData.dataItem.tabs.length ? rowData.dataItem.tabs.length : 0}</td>
            ),
        },
        {
            width: 20,
            cell: (rowData: any) => (
                <IconButton aria-label="Edit" onClick={(e: any) => this.handleEdit(e, rowData)}>
                    <EditIcon />
                </IconButton>
            ),
        },
    ];

    componentDidMount() {
        this.props.fetchTabsPermission(this.state.page);
        this.props.fetchTabs();
    }

    handleAdd = () => {
        this.props.history.push(BasePath + '/permission/tabs-add');
    };

    handleEdit = (e: any, rowData: any) => {
        this.props.setTabsEdit(rowData);
        this.props.history.push(BasePath + '/permission/tabs-edit');
    };

    handlePage = (value: any) => {
        this.setState({
            page: parseInt(value),
        });
        this.props.fetchTabsPermission(value);
    };

    handleNewPageSize = (event: any) => {
        this.props.setNewPageSizeTabs(event.target.value);
        this.props.fetchTabsPermission(1);
    };

    render() {
        return (
            <div>
                <Grid container>
                    <Grid container justify="flex-end">
                        <Button
                            style={{ marginTop: '16px' }}
                            variant="contained"
                            onClick={() => {
                                this.setState({
                                    add: true,
                                });
                            }}
                            color="primary"
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                <div style={{ marginTop: 20 }}>
                    {this.props.tabsProcessing && <Loader />}

                    {this.props.tabsPermissionData != null ? (
                        <div>
                            <Treefile columns={this.columns} tableData={this.props.tabsPermissionData} />
                            <div style={{ margin: theme.spacing(3) }}>
                                <CustomPagination
                                    total={this.props.total}
                                    page={this.state.page}
                                    handlePage={this.handlePage}
                                    pageSize={this.props.pageSizeTabs}
                                    handleNewPageSize={this.handleNewPageSize}
                                />
                            </div>
                        </div>
                    ) : (
                        !this.props.tabsProcessing && (
                            <div style={{ width: '100%' }}>
                                <NoDataFound title={'No Tabs found'} />
                            </div>
                        )
                    )}
                </div>
                {this.state.add ? this.handleAdd() : null}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        tabsProcessing: state.permission_reducer.tabsProcessing,
        tabsPermissionData: state.permission_reducer.tabsPermissionData,
        tabsEditData: state.permission_reducer.tabsEditData,
        tabs: state.permission_reducer.tabs,
        pageSizeTabs: state.permission_reducer.pageSizeTabs,
        total: state.permission_reducer.total,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchTabsPermission: (page: any) => dispatch(fetchTabsPermission(page)),
        setTabsEdit: (data: any) => dispatch(setTabsEdit(data)),
        fetchTabs: () => dispatch(fetchTabs()),
        setNewPageSizeTabs: (event: any) => dispatch(setNewPageSizeTabs(event)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(TabsPermission);

export default withRouter(connected);
