import theme from '../../theme';
import {Avatar, Button, Grid, Radio, TextField, Tooltip, Typography} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TimelineIcon from '@material-ui/icons/Timeline';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import IconButton from '@material-ui/core/IconButton';
import { sortArrayObjectViewSeq } from '../../utils/browse';
import { withRouter } from 'react-router-dom';
import { TreeListTextFilter } from '@progress/kendo-react-treelist';
import TreeHiraricalData from '../../components/KendoUICmpt/TreeHiraricalData';
import Treefile from '../../components/KendoUICmpt/TreeList';
import { containers } from '../../utils/style';
import HTMLPreview from '../../components/HTMLPreview';
import { useDispatch, useSelector } from 'react-redux';
import { resetConfiguration, fetchRedlines } from '../../Services/user';
import RedlinesUI from '../../Containers/RedlinesUI';
import PlainData from "../ItemTabsForms/Components/PlainData";
import {replaceNames} from "../../utils/ItemFormHelpers";
import moment from "moment-timezone";
import {returnUTCFormatedDate} from "../../utils";
import {provideIconForTab} from "../../utils/IconProvider";
import {ORACLE_CLOUD_BASE_URL, TIME_ZONE,TIME_ZONE_LABEL} from "../../config";
import {downloadFileFromVault} from "../../Services/browse";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '4px 4px',
            display: 'flex',
            // alignItems: 'center',
            width: '100%',
            borderColor: 'red',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

const ConfigurationViews = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [formState, setFormState]: any = useState({
        edit: false,
        editData: {},
        open: {},
    });

    const returnFormatedText = (text: any) => {
        return (
            <Typography variant={'h5'} style={{ marginTop: '8px', marginBottom: '8px' }}>
                {text}
            </Typography>
        );
    };

    const handleChange = (event: any) => {
        let oldState = { ...formState };
        let newState = {
            ...formState,
            editData: {
                ...oldState.editData,
                [event.target.name]: event.target.value,
            },
            edit: true,
        };
        setFormState(newState);
    };

    const handleToggle = (event: any) => {
        let oldState = { ...formState };
        let newState = {
            ...formState,
            editData: {
                ...oldState.editData,
                [event.target.name]: event.target.checked ? 'true' : 'false',
            },
            edit: true,
        };
        setFormState(newState);
    };

    const handleAction = () => {
        confirmAlert({
            message: 'Are you sure you want to save changes?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let editData = { ...formState.editData };
                        props.handleSubmitData(editData);
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return false;
                    },
                },
            ],
        });
    };

    const discard = () => {
        confirmAlert({
            //   title: "Confirm to discard changes",
            message: 'Are you sure you want to discard canges?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let formData = { ...formState };
                        setFormState({
                            edit: false,
                            editData: {},
                            open: formData.open,
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return false;
                    },
                },
            ],
        });
    };

    // data layout for value
    const showRedlines = (oldData: any, newData: any, operation: any) => {
        switch (operation) {
            case 'Added':
                return <div style={{ color: 'red' }}>{newData}</div>;
                break;
            case 'Modified':
                return oldData != newData ? (
                    <span>
                        <span
                            style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid', color: 'red' }}
                        >
                            {oldData}
                        </span>
                        <span style={{ marginLeft: '6px', color: 'red' }}>{newData}</span>
                    </span>
                ) : (
                    <span>
                        <span style={{ marginLeft: '6px' }}>{newData}</span>
                    </span>
                );
                break;
            case 'Delete':
                return (
                    <div style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid', color: 'red' }}>
                        {oldData} <span style={{ marginLeft: '6px' }}>{newData}</span>
                    </div>
                );
                break;
            default:
                return <div>{newData}</div>;
                break;
        }
    };

    const showDetails = (event: any, data: any) => {
        // ;
        let classType = data.dataItem['CLASS_TYPE'];
        let className = data.dataItem['CLASS_NAME'];

        if (!classType || !className) {
            if (data.dataItem.tableData && data.dataItem.tableData.length > 1) {
            }
        }

        let id = data.dataItem.ID_NUMBER;

        let url = `/Client/details/${classType}/${className}/${id}`;
        let rev = data.dataItem.REV;
        if (rev) {
            url = url + `/${rev}`;
        } else {
            url = url + `/null`;
        }
        dispatch(resetConfiguration());
        //  dispatch(getConfigurations(classType, className, rev,id))
        props.history.push(url);
    };

    const textFieldData = (dataArr: any, type: any, attribute: any) => {
        let TableTitle: any;
        let control = type;
        let columns: any = { [`${attribute}`]: [] };
        for (let index in props.layout) {
            if (props.layout[index].HEADERS && (control == 'Dependent Grid' || control == 'Tree Data')) {
                sortArrayObjectViewSeq(props.layout[index].HEADERS);
                TableTitle = props.layout[index].LABEL_DISPLAY;
                for (let each in props.layout[index].HEADERS){
                    if (
                        props.layout[index].HEADERS[each].LABEL_DISPLAY &&
                        props.layout[index].HEADERS[each].TYPE != 'Hidden' &&
                        (props.layout[index].LABEL_DISPLAY === attribute || attribute === 'BOM')
                    ) {
                        if (props.layout[index].HEADERS[each].ATTRIBUTE_NAME == 'ID_NUMBER' && control == 'Tree Data') {
                            columns[`${attribute}`].push({
                                title: `${props.layout[index].HEADERS[each].LABEL_DISPLAY}`,
                                field: `${props.layout[index].HEADERS[each].ATTRIBUTE_NAME}`,
                                filter: TreeListTextFilter,
                                cell: (rowData: any) => (
                                    <Tooltip title="Open details">
                                        <td>
                                            <span
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                onClick={(e: any) => {
                                                    showDetails(e, rowData);
                                                }}
                                            >
                                                {rowData.dataItem[rowData.field]}
                                            </span>
                                        </td>
                                    </Tooltip>
                                ),
                            });
                        }
                        else if (props.layout[index].HEADERS[each].ATTRIBUTE_NAME == 'FILE_PATH') {
                            columns[`${attribute}`].push({
                                title: `${props.layout[index].HEADERS[each].LABEL_DISPLAY}`,
                                field: `${props.layout[index].HEADERS[each].ATTRIBUTE_NAME}`,
                                filter: TreeListTextFilter,
                                cell: (rowData: any) =>
                                    rowData.dataItem[rowData.field] || true ? (
                                        <Tooltip title="Download">
                                            <td>
                                  <span>  <Avatar
                                      src={provideIconForTab('Attachments')}
                                      onClick={(e: any) => {
                                          downloadFile(e, rowData);
                                      }}
                                      style={{ borderRadius: "10%", maxWidth: "25px", maxHeight: "25px" , cursor:'pointer'}} />
                                  </span>
                                            </td>
                                        </Tooltip>
                                    ) : (
                                        <td>
                                            <span></span>
                                        </td>
                                    ),
                            });
                        }
                        else if(props.layout[index].HEADERS[each].TYPE == 'Date'){
                            columns[`${attribute}`].push({
                                title: `${props.layout[index].HEADERS[each].LABEL_DISPLAY}`,
                                field: `${props.layout[index].HEADERS[each].ATTRIBUTE_NAME}`,
                                filter: TreeListTextFilter,
                                cell: (rowData: any) =>
                                    rowData.dataItem[rowData.field] ? (
                                            <td>
                                                <span> {rowData.dataItem[rowData.field] && moment(returnUTCFormatedDate(rowData.dataItem[rowData.field])).tz(TIME_ZONE).format('DD-MMMM-YYYY  h:mm:ss A')} {TIME_ZONE_LABEL} </span>
                                            </td>
                                    ) : (
                                        <td>
                                            <span></span>
                                        </td>
                                    ),
                            });
            
                        }
                        else if (props.layout[index].HEADERS[each].ATTRIBUTE_NAME.includes('NEW')) {
                            let str = props.layout[index].HEADERS[each].ATTRIBUTE_NAME;

                            let oldNewStr = str.substr(0, str.indexOf('_')); // "72"
                            let attributeName = str.substr(str.indexOf('_') + 1);

                            columns[`${attribute}`].push({
                                title: `${props.layout[index].HEADERS[each].LABEL_DISPLAY}`,
                                field: `${props.layout[index].HEADERS[each].ATTRIBUTE_NAME}`,
                                filter: TreeListTextFilter,
                                cell: (rowData: any) => (
                                    <Tooltip title="Open details">
                                        <td>
                                            <span>
                                                {showRedlines(
                                                    rowData.dataItem[`OLD_${attributeName}`],
                                                    rowData.dataItem[`NEW_${attributeName}`],
                                                    rowData.dataItem[`REDLINE_ACTION`],
                                                )}
                                            </span>
                                        </td>
                                    </Tooltip>
                                ),
                            });
                        } else if (props.layout[index].HEADERS[each].ATTRIBUTE_NAME.includes('OLD_')) {
                        } else {
                            columns[`${attribute}`].push({
                                title: props.layout[index].HEADERS[each].LABEL_DISPLAY,
                                field: props.layout[index].HEADERS[each].ATTRIBUTE_NAME,
                                filter: TreeListTextFilter,
                            });
                        }
                    }
                }
            }
        }

        let possibleValues: any = '';

        switch (control) {
            case 'Row Data':
                return <Treefile tableData={dataArr[attribute]} columns={columns[`${attribute}`]} />;
                break;
            case 'Dependent Grid':
                if (dataArr[attribute]) {
                    return [
                        returnFormatedText(TableTitle),
                        <TreeHiraricalData
                            title={''}
                            columns={columns[`${attribute}`]}
                            tableData={dataArr[attribute]}
                        />,
                    ];
                } else if (dataArr.Changes[attribute]) {
                    return (
                        <div style={{ padding: '12px', background: '#ffffff' }}>
                            {returnFormatedText(attribute)}
                            <TreeHiraricalData
                                title={''}
                                columns={columns[`${attribute}`]}
                                tableData={dataArr.Changes[attribute]}
                            />
                        </div>
                    );
                }
                break;
            case 'HTML':
                return (
                    <div>
                        <HTMLPreview data={dataArr[attribute]} />
                    </div>
                );
                break;
            default:
                return (
                    <Grid xs={12}>
                        <Typography variant="body1"> {dataArr[attribute]}</Typography>
                    </Grid>
                );
                break;
        }
    };

    const downloadFile = (event: any, data: any) => {
        if (data.dataItem['FILE_PATH']) {
            let path = data.dataItem['FILE_PATH'];
            if(data.dataItem['DID']){
                let val =  `${ORACLE_CLOUD_BASE_URL}/fscmUI/content/conn/FusionAppsContentRepository/uuid/dDocID:${data.dataItem['DID']}?download`;
                window.open(`${val}`);
            }
            else if (path.includes('http')) {
                window.open(`${path}`);
            } else {
                downloadFileFromVault(path, (data:any)=>{
                    if(data){
                        window.open(`${data}`);
                    }
                })

            }
        }
    };

    const returnBodyText = (text: any) => {
        return (
            <div>
                <Typography
                    variant={'h5'}
                    style={{
                        color: theme.palette.primary.main,
                        marginBottom: 'auto',
                        marginTop: 'auto',
                    }}
                >
                    {text} :
                </Typography>
            </div>
        );
    };

    const returnHeaderText = (text: any) => {
        return (
            <div>
                <Typography
                    variant={'h4'}
                    style={{
                        color: theme.palette.primary.main,
                        marginBottom: 'auto',
                        marginTop: 'auto',
                    }}
                >
                    {text}
                </Typography>
            </div>
        );
    };

    const showPlainData = (dataObject: any, type: any, data: any) => {
        return (
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid sm={4} md={4}>
                    {returnBodyText(dataObject.LABEL_DISPLAY)}
                </Grid>
                <Grid sm={8} md={8}>
                    {textFieldData(data, type, dataObject.LABEL_DISPLAY)}
                </Grid>
            </Grid>
        );
    };

    const showFormatedLine = (dataObject: any, data: any) => {
        let type = dataObject.TYPE;

        return (
            <Grid container direction="row" justify="center" alignItems="center">
                {type == 'Dependent Grid' || type == 'Tree Data' ? (
                    <Grid sm={12} md={12}>
                        {textFieldData(
                            data,
                            type,
                            dataObject.ATTRIBUTE_NAME === 'BOM' ? dataObject.ATTRIBUTE_NAME : dataObject.LABEL_DISPLAY,
                        )}
                    </Grid>
                ) : (
                    // dataObject.LABEL_DISPLAY && dataObject.DISPLAY_SEQ? showPlainData(dataObject,type,data):null)}
                    [
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid sm={3} md={3} style={{ paddingRight: '18px', textAlign: 'right' }}>
                                {returnBodyText(dataObject.LABEL_DISPLAY)}
                            </Grid>
                            <Grid sm={9} md={9}>
                                {textFieldData(data, type, dataObject.ATTRIBUTE_NAME)}
                                {/*<Divider style={{backgroundColor:"#8080801a", width:"100%", margin:theme.spacing(0.5)}}/>*/}
                            </Grid>
                        </Grid>,
                        <Divider style={{ backgroundColor: '#8080801a', width: '100%', margin: theme.spacing(0.5) }} />,
                    ]
                )}
            </Grid>
        );
    };

    const showSubGroup = (key: any, IndivisualRecord: any) => {
        return (
            <div>
                {key != '' && (
                    <div style={{ padding: '8px' }}>
                        <Chip
                            id={key}
                            color={'primary'}
                            size={'medium'}
                            style={{ fontSize: '16px' }}
                            label={`${key}`}
                            variant={'outlined'}
                        />
                    </div>
                )}

                <Divider style={{ marginTop: 2, marginBottom: 2, color: 'grey' }} />
                <div style={{ margin: '8px' }}>
                    {IndivisualRecord.map((val: any, indexval: any) => {
                        return <div style={{ marginTop: '8px' }}>{showFormatedLine(val.Data, props.data)}</div>;
                    })}
                </div>
            </div>
        );
    };

    const LoppOverSubgroupData = (Key: any, Data: any) => {
        let returnUI: any = [];

        let GroupData: any = { ...Data };
        //delete GroupData["Tab"];
        //delete GroupData["Display Order"];
        //Object.entries(GroupData).forEach(([key, value]) => {
        if (Key == 'subClass') {
            return '';
        } else {
            returnUI.push(showSubGroup(Key, Data));
            //  });

            return returnUI;
        }
    };

    let LoadedUI: any = [];
    const showHeader = () => {
        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                // style={{
                //   background: index % 2 == 1 ? "#fff" : "#f9f9f9",
                //   padding: "8px",
                //   minHeight: "50px",
                // }}
            >
                <Grid sm={3} md={3}>
                    {returnHeaderText('Attribute Name')}
                </Grid>
                <Grid sm={3} md={3}>
                    {returnHeaderText('Attribute Value')}
                    {/* {textFieldData("Attribute Value")} */}
                </Grid>
                <Grid sm={3} md={3}>
                    {returnHeaderText('Description')}
                    {/* {returnBodyText("Description")} */}
                </Grid>
                <Grid sm={3} md={3}>
                    {returnHeaderText('API Name')}
                </Grid>
            </Grid>
        );
    };

    const showRedlinesData = (layout:any , dataOfTab:any) => {
        let UIToReturn = <div></div>;
        dataOfTab && dataOfTab.map((data:any, index:any)=>{
            if(data.ATTRIBUTE_NAME == layout.ATTRIBUTE_NAME && layout.TYPE != 'Hidden'){
                if(data.REDLINE_ACTION){

                    let oldData = (layout.TYPE == 'Date' && data.OLD_VALUE) ? moment(returnUTCFormatedDate(data.OLD_VALUE)).tz(TIME_ZONE).format('DD-MMMM-YYYY  h:mm:ss A')+ " " + TIME_ZONE_LABEL : data.OLD_VALUE
                    let newData = (layout.TYPE == 'Date' && data.NEW_VALUE) ? moment(returnUTCFormatedDate(data.NEW_VALUE)).tz(TIME_ZONE).format('DD-MMMM-YYYY  h:mm:ss A')+ " " + TIME_ZONE_LABEL : data.NEW_VALUE
                    UIToReturn =  <div  style={{ padding: '10px' }}>
                        <Grid container direction={'row'} >
                            <Grid
                                lg={3}
                                md={3}
                                xl={3}
                                xs={12}
                                spacing={4}
                                style={{
                                    textAlign: 'end',
                                    fontWeight: 'bold',
                                    color: "#4F4F4F",
                                    fontSize: '14px',
                                }}
                            >
                                {layout['LABEL_DISPLAY']}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Grid>
                            <Grid
                                lg={9}
                                md={9}
                                xl={9}
                                xs={12}
                                style={{
                                    fontSize: '14px',
                                    color:"#333333"
                                }}
                            >
                                {showRedlines(oldData, newData, data.REDLINE_ACTION)}
                            </Grid>
                        </Grid>
                    </div>
                }else {
                    UIToReturn =  <div  style={{ padding: '10px' }}>
                        <Grid container direction={'row'} >
                            <Grid
                                lg={3}
                                md={3}
                                xl={3}
                                xs={12}
                                spacing={4}
                                style={{
                                    textAlign: 'end',
                                    fontWeight: 'bold',
                                    color: "#4F4F4F",
                                    fontSize: '14px',
                                }}
                            >
                                {layout['LABEL_DISPLAY']}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Grid>
                            <Grid
                                lg={9}
                                md={9}
                                xl={9}
                                xs={12}
                                style={{
                                    fontSize: '14px',
                                    color:"#333333"
                                }}
                            >
                                {layout['TYPE'] == "Date" && data && data.NEW_VALUE ? moment(returnUTCFormatedDate(data.NEW_VALUE)).tz(TIME_ZONE).format('DD-MMMM-YYYY  h:mm:ss A')+" "+ TIME_ZONE_LABEL : data.NEW_VALUE}

                            </Grid>
                        </Grid>
                    </div>
                }
            }
        })
        return UIToReturn;
    }

    const showHeading = (value: any, data: any) => {
        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                onClick={() => {
                    let formData = { ...formState };
                    setFormState({
                        ...formState,
                        open: {
                            ...formData.open,
                            [value]: !formState.open[value],
                        },
                    });
                }}
                style={{
                    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
                    height: '60px',
                    padding: '8px',
                    cursor: 'pointer',
                }}
            >
                <Grid>
                    <div>{formState.open[value] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
                </Grid>
                <Grid></Grid>
                <Typography
                    variant={'h6'}
                    style={{
                        color: theme.palette.primary.main,
                        marginBottom: 'auto',
                        marginTop: 'auto',
                    }}
                >
                    {value}
                </Typography>
            </Grid>
        );
    };

    return (
        <div style={{ marginTop: '8px' }}>
            {props.subClass
                ? Object.keys(props.layout).map((index: any, value: any) => {
                      LoadedUI.push(
                          <div style={{ marginTop: '8px' }}>
                              <div style={{ backgroundColor: '#fff' }}>
                                  {LoppOverSubgroupData(index, props.layout[index])}
                                  <Divider style={{ marginTop: 2, marginBottom: 2, color: 'grey' }} />
                              </div>
                          </div>,
                      );
                  })
                : props.layout.map((value: any, index: any) => {

                    if (LoadedUI[value.PLAIN_ATTRIBUTE_HEADING] && value.PLAIN_ATTRIBUTE_HEADING && props.data[value.TAB]) {
                        LoadedUI[value.PLAIN_ATTRIBUTE_HEADING].push(<div>{showRedlinesData(value, props.data[value.TAB])}</div>)
                    } else if (value.PLAIN_ATTRIBUTE_HEADING && props.data[value.TAB]) {
                        LoadedUI[value.PLAIN_ATTRIBUTE_HEADING] = [<div>{showRedlinesData(value, props.data[value.TAB])}</div>];
                    }else {
                          LoadedUI.push(
                              <div style={{ marginTop: '8px' }}>
                                  <div>{showFormatedLine(value, props.data)}</div>
                              </div>,
                          );
                      }
                  })}
            <div>  <div>{LoadedUI ? Object.keys(LoadedUI
            ).map((item: any, index: any) => {


                return [
                    item != 0 ? <div>
                            <Typography color="primary" style={{ fontWeight: "normal", color: "#0066cc", fontSize: "24px", margin: theme.spacing(1) }}>
                                {replaceNames(item)}
                            </Typography>
                            <div>{LoadedUI[item]}</div>
                        </div>: <div>{LoadedUI}</div>
                ]
            }) : null}</div></div>
        </div>
    );
};

export default withRouter(ConfigurationViews);
