import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Typography, Chip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import CategoryIcon from '@material-ui/icons/Category';
import theme from '../../theme';
import Slide from '@material-ui/core/Slide';
import '../../utils/BoxStyle.css';
import TileIconAndTitle from '../TileIconAndTitle';
import { showDataTitleAndValue, showDescriptionData } from '../../utils';
import CustomCardBody from '../CustomCardBody';
import moment from 'moment';
//@ts-ignore
const useStyles = makeStyles((theme: any) => ({
    container: {
        minWidth: '250px',
        minHeight: '170px',
        margin: theme.spacing(1),
    },
    root: {
        backgroundColor: '#fefeff',
        minWidth: '250px',
        minHeight: '170px',
        borderRadius: '4px',
        borderColor: '#ffffff',
        borderWidth: '1px',
        border: '1px solid',
        '&:hover': {
            boxShadow: '5px 5px 16px #bbbbbb, -5px -5px 16px #ffffff',
        },
    },
    name: {
        fontSize: '17px',
        color: theme.palette.primary.main,
        margin: theme.spacing(1),
    },
    chips: {
        margin: theme.spacing(1),
    },
}));

const CategoryBox = (props: any) => {
    const classes = useStyles();

    const edit = () => {
        props.handleEdit(props.data);
    };

    const deleteData = () => {
        props.handleDelete(props.data);
    };

    const info = () => {
        props.handleDetails(props.data);
    };

    let category = props.data;
    return (
        <Slide direction="right" in={true} mountOnEnter unmountOnExit>
            <div className="flip-card">
                <div className={classes.container}>
                    <Paper elevation={3} className={classes.root}>
                        <div style={{ minHeight: '150px' }}>
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <CustomCardBody primaryText={category.CategoryName} />
                                </div>
                                <div className="flip-card-back">
                                    {showDataTitleAndValue('Created By', category.CreatedBy)}

                                    {showDataTitleAndValue(
                                        'Created Date',
                                        moment(category.CreatedDate).format('MMMM Do YYYY'),
                                    )}

                                    {showDescriptionData('Description', category.Description)}
                                </div>
                            </div>
                        </div>

                        <Divider style={{ marginTop: 2, marginBottom: 2 }} />
                        <div style={{ padding: '8px 4px 8px 4px' }}>
                            <Grid container>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-end"
                                    sm={6}
                                    md={8}
                                    lg={9}
                                >
                                    {/* <Chip
                                        disabled={!props.editPerm}
                                        label={"Edit"}
                                        size="small"
                                        icon={<EditIcon/>}
                                        onClick={edit}
                                        color="primary"
                                    /> */}
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-end"
                                    md={4}
                                    sm={6}
                                    lg={3}
                                >
                                    <Chip
                                        label={'Info'}
                                        size="small"
                                        icon={<InfoIcon />}
                                        onClick={info}
                                        color="default"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </div>
            </div>
        </Slide>
    );
};

export default CategoryBox;
