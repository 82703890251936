import React, { Component } from 'react';
import { Grid, Paper, TextField} from '@material-ui/core';
import theme from '../../theme';
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import Loader from '../../components/Loader';
import Button from '@material-ui/core/Button';
import {updateGeneralConfig,fetchGeneralConfigurations } from '../../Services/configurations';
import { TreeListTextFilter } from '@progress/kendo-react-treelist';
import Treefile from '../../components/KendoUICmpt/TreeList';

class GeneralConfig extends Component<any> {
    styles = {
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '20%',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        paperSide: {
            height: '81vh',
        },
        table: {
            minWidth: 650,
        },
        menuPaper: {
            maxHeight: 250
        }
    }
    state = {
        configData: [],
        updated: [],
    }
    columns = [
        {
            title: `Title`,
            field: `DisplayName`,
        },
        {
            title: 'IsActive',
            field: 'IsActive',
            cell: (rowData: any) => {
                return (
                    <td>
                        <Switch
                            checked={rowData.dataItem.IsActive}
                            onChange={(event:any)=>{this.handleSwitchChange(rowData.dataItem)}}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </td>
                );
            },
        },
    ];
    async componentDidMount() {
        this.setState({
            configData:[]
        })
        this.props.fetchGeneralConfigurations()
   }
    handleSwitchChange = (rowData: any) => {
        let tempUpdated = this.state.updated.map((a:any) => {return {...a}})
        let tempData =  this.state.configData.map((a:any) => {return {...a}})

        tempData.forEach((dataRow :any)=>{
            delete dataRow.expanded
            if(dataRow._id===rowData._id){
                dataRow.IsActive = !rowData.IsActive
            }
        })
        const alreadyExist = tempUpdated.filter((a:any)=>a._id == rowData._id)
        if(alreadyExist.length>0){
            tempUpdated = tempUpdated.filter((a:any)=>a._id!=rowData._id)
        }
        else{
            const newData= {...rowData,IsActive:!rowData.IsActive}
            delete newData.expanded
            tempUpdated.push(newData)
        }
        this.setState({
            configData: tempData,
            updated:tempUpdated
        })

    }
    handleSave = () => {
        this.props.updateGeneralConfig(this.state.configData,this.state.updated);
        this.setState({
            configData:[],
            updated:[]
        })
    }
   
    setTabs = () =>{
        if (this.state.configData.length===0 && this.props.generalConfig.length>0){
            this.setState({ configData: this.props.generalConfig }) }
    }
    
    render() {
        return (
            <div>
                <Grid container spacing={3}>                    
                    <Grid item xs={12}>
                        <Paper elevation={3} style={this.styles.paperSide}>
                            <Button style={{margin:"10px 0px 10px 10px"}} variant="contained" color="secondary" onClick={() => { this.handleSave()}} disabled={this.state.updated.length==0}>
                                Save
                            </Button>
                            {this.props.processing?<Loader/>
                            :<div style={{overflow:'auto',maxHeight:"75vh"}} key={this.state.configData.length} onMouseEnter={this.setTabs}>
                                <Treefile columns ={this.columns} tableData={this.state.configData.length > 0 ? this.state.configData:this.props.generalConfig} />
                            </div>}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        generalConfig : state.configurations_reducer.generalConfigurations,
        processing : state.configurations_reducer.generalConfigurationProcessing,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateGeneralConfig: (config:any,updated:any)=> dispatch(updateGeneralConfig(config,updated)),
        fetchGeneralConfigurations : ()=>dispatch(fetchGeneralConfigurations())
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(GeneralConfig);

export default connected;