import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { containers } from '../../utils/style';
import './style.css';
import theme from '../../theme';

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: '10px',
        textAlign: 'center',
    },
});

export default function Animations() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className="lds-grid">
                <div style={{ background: theme.palette.primary.main }}></div>
                <div style={{ background: theme.palette.primary.main }}></div>
                <div style={{ background: theme.palette.primary.main }}></div>
                <div style={{ background: theme.palette.primary.main }}></div>
                <div style={{ background: theme.palette.primary.main }}></div>
                <div style={{ background: theme.palette.primary.main }}></div>
                <div style={{ background: theme.palette.primary.main }}></div>
                <div style={{ background: theme.palette.primary.main }}></div>
                <div style={{ background: theme.palette.primary.main }}></div>
            </div>
        </div>
    );
}
