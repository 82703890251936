import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import validate from 'validate.js';
import Loader from '../../components/Loader';
import { editUser } from '../../Services/userManagement';
import { containers } from '../../utils/style';
import { BasePath } from '../../config';
interface props {
    history: any;
    editUser: any;
    editData: any;
    processing: any;
    user: any;
    roles:any;
}

const MainSchema = {
    userName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    firstName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    lastName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 50,
        },
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            minimum: 8,
        },
    },
    confirmPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            minimum: 8,
        },
        equality: 'password',
    },
};

const SocialSchema = {
    userName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 50,
        },
    },
    firstName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    lastName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
};

class EditUser extends Component<props> {
    state = {
        disableEditBtn: false,
        isValid: true,
        social: false,
        formData: {
            userName: '',
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            IsActive: false,
            roles:[],
            id: null,
        },
        showErr: false,
        touched: {
            userName: null,
            firstName: null,
            lastName: null,
            email: null,
            password: null,
            confirmPassword: null,
        },
        errors: {
            userName: [],
            firstName: [],
            lastName: [],
            email: [],
            password: [],
            confirmPassword: [],
        },
    };

    componentDidMount() {


        if(this.props.editData && this.props.editData.dataItem){
            this.setState({
                ...this.state,
                formData: {
                    userName: this.props.editData.dataItem.user_name,
                    id: this.props.editData.dataItem.id,
                    email: this.props.editData.dataItem.email,
                    firstName: this.props.editData.dataItem.first_name,
                    lastName: this.props.editData.dataItem.last_name,
                    IsActive: this.props.editData.dataItem.IsActive,
                    password: this.props.editData.dataItem.Password,
                    confirmPassword: this.props.editData.dataItem.Password,
                    roles: this.props.editData.dataItem.roles,
                },
                disableEditBtn: false,
            });

            this.setState({
                social: this.props.editData.dataItem.provider ? true : false,
            });
        } else {
            this.props.history.push(BasePath + '/userManagement');
        }

    }

    handleChange = (event: any) => {
        let schema = this.state.social ? SocialSchema : MainSchema;

        if (event.target.name === 'IsActive') {
            this.setState(
                {
                    ...this.state,
                    formData: { ...this.state.formData, [event.target.name]: event.target.checked },
                },
                () => {
                    const errors = validate(this.state.formData, schema);
                    this.setState({
                        ...this.state,
                        isValid: errors ? false : true,
                        errors: errors || {},
                    });
                },
            );
        } else if(event.target.name === 'roles'){
            let roleData:any = null;
            this.props.roles.map((role:any)=>{
                if(role.Name == event.target.value){
                    roleData = role;
                }
            })
            this.setState({
                formData: { ...this.state.formData,[event.target.name]: [{
                    "ID":roleData.ID,
                    "Name":roleData.Name,
                    "RoleUserActive":roleData.IsActive,
                    "RoleUserCreatedBy": this.props.user.user_name ,
                    "RoleUserCreatedDate": new Date()
                }]
            }
            });

        } else {
            this.setState(
                {
                    ...this.state,
                    formData: { ...this.state.formData, [event.target.name]: event.target.value.trim() },
                    touched: { [event.target.name]: true },
                },
                () => {
                    const errors = validate(this.state.formData, schema);
                    this.setState({
                        ...this.state,
                        isValid: errors ? false : true,
                        errors: errors || {},
                    });
                },
            );
        }
    };

    handleSave = () => {
        if (this.state.disableEditBtn) {
            return;
        }
        this.setState({
            disableEditBtn: true,
        });
        let formData = {
            id: this.state.formData.id,
            user_name: this.state.formData.userName,
            first_name: this.state.formData.firstName,
            last_name: this.state.formData.lastName,
            email: this.state.formData.email,
            Password: this.state.formData.password,
            IsActive: this.state.formData.IsActive,
            ModifiedBy: this.props.user.user_name,
            ModifiedDate: new Date(),
            roles: this.getRolesData()
        };
        this.props.editUser(formData, (res: any) => {
            if (res) {
                this.props.history.push(BasePath + '/userManagement');
            } else {
                this.setState({
                    showErr: true,
                    disableEditBtn: false,
                });
            }
        });
    };
    getRolesData = () => {

        let newRolesData:any = this.state.formData.roles;
        let oldRolesData = this.props.editData.dataItem.roles;


        let resultData: any = [];
        oldRolesData.forEach((oldData: any) => {
            if (newRolesData.some((user: any) => user.ID === oldData.ID)) {
                resultData.push({
                    ...oldData,
                    IsActive: 1,
                    RoleUserActive: 1,
                    RoleUserModifiedBy: this.props.user.user_name,
                    RoleUserModifiedDate: new Date(),
                });
            } else {
                resultData.push({
                    ...oldData,
                    IsActive: 1,
                    RoleUserActive: 0,
                    RoleUserModifiedBy: this.props.user.user_name,
                    RoleUserModifiedDate: new Date(),
                });
            }
        });
        newRolesData.forEach((newData: any) => {
            if (oldRolesData.some((user: any) => user.ID === newData.ID)) {
            } else {
                resultData.push({
                    ...newData,
                    IsActive: 1,
                    RoleUserActive: 1,
                    RoleUserCreatedBy: this.props.user.user_name,
                    RoleUserCreatedDate:new Date(),
                });
            }
        });

        return resultData
    }

    handleCancel = () => {
        this.props.history.push(BasePath + '/userManagement');
    };

    hasError = (field: any) => {
        //@ts-ignore
        return !this.state.touched[field] && this.state.errors[field] && this.state.errors[field].length ? true : false;
    };

    render() {
        if(this.props.editData && this.props.editData.dataItem){
            const titleContainer = {
                marginTop: 10,
                marginBottom: 'auto',
                marginLeft: 10,
            };
    
            return (
                <Grid container>
                    {this.props.processing ? (
                        <Loader />
                    ) : (
                        <Grid container style={containers}>
                            <Grid container style={{ margin: 10 }}>
                                <Grid container style={titleContainer}>
                                    <Grid xs={5}>
                                        <TextField
                                         disabled={this.state.social}
                                            required
                                            fullWidth
                                            id={'FirstName'}
                                            label="First Name"
                                            variant={'outlined'}
                                            name="firstName"
                                            onChange={this.handleChange}
                                            type="text"
                                            value={this.state.formData.firstName || ''}
                                            style={{ margin: 10 }}
                                            error={this.hasError('firstName')}
                                            helperText={this.hasError('firstName') ? this.state.errors.firstName[0] : null}
                                        />
                                    </Grid>
                                </Grid>
    
                                <Grid container style={titleContainer}>
                                    <Grid xs={5}>
                                        <TextField
                                         disabled={this.state.social}
                                            required
                                            fullWidth
                                            id={'LastName'}
                                            label="Last Name"
                                            variant={'outlined'}
                                            name="lastName"
                                            onChange={this.handleChange}
                                            type="text"
                                            value={this.state.formData.lastName || ''}
                                            style={{ margin: 10 }}
                                            error={this.hasError('lastName')}
                                            helperText={this.hasError('lastName') ? this.state.errors.lastName[0] : null}
                                        />
                                    </Grid>
                                </Grid>
    
                                <Grid container style={titleContainer}>
                                    <Grid xs={5}>
                                        <TextField
                                              disabled={this.state.social}
                                            fullWidth
                                            id={'UserName'}
                                            label="User Name"
                                            variant={'outlined'}
                                            name="userName"
                                            onChange={this.handleChange}
                                            type="text"
                                            value={this.state.formData.userName || ''}
                                            style={{ margin: 10 }}
                                            error={this.hasError('userName')}
                                            helperText={this.hasError('userName') ? this.state.errors.userName[0] : null}
                                        />
                                    </Grid>
                                </Grid>
    
                                <Grid container style={titleContainer}>
                                    <Grid xs={5}>
                                        <TextField
                                            fullWidth
                                            disabled={this.props.editData.dataItem.provider}
                                            id={'Email'}
                                            label="Email"
                                            variant={'outlined'}
                                            name="email"
                                            onChange={this.handleChange}
                                            type="text"
                                            value={this.state.formData.email || ''}
                                            style={{ margin: 10 }}
                                            error={this.hasError('email')}
                                            helperText={this.hasError('email') ? this.state.errors.email[0] : null}
                                        />
                                    </Grid>
                                </Grid>
    
                                {  this.props.roles && this.props.roles.length > 0 && <Grid container style={titleContainer}>
                                    <Grid xs={5}>
                                        <FormControl variant="outlined"fullWidth  style={{ margin: 10 }}  disabled={this.state.social}>
                                            <InputLabel id="demo-simple-select-outlined-label" style={{background:"#ffffff", padding:"0px 5px"}}>{"  Role  "}</InputLabel>
                                            <Select
                                                name="roles"
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={this.state.formData.roles.length > 0 ? this.state.formData.roles[0]['Name'] : this.props.roles[0]['Name']}
                                                onChange={this.handleChange}
                                            >
                                                {this.props.roles && this.props.roles.map((data: any, index:any) => {
                                                    return <MenuItem key={index} value={data['Name']}>{data.Name}</MenuItem>
                                                })}
    
                                            </Select>
                                        </FormControl>
                                    </Grid></Grid>}
    
                                {!this.state.social ? (
                                    <Grid container style={titleContainer}>
                                        <Grid xs={5}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={'Password'}
                                                label="Password"
                                                variant={'outlined'}
                                                name="password"
                                                onChange={this.handleChange}
                                                type="password"
                                                value={this.state.formData.password || ''}
                                                style={{ margin: 10 }}
                                                error={this.hasError('password')}
                                                helperText={
                                                    this.hasError('password') ? this.state.errors.password[0] : null
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                ) : null}
    
                                {!this.state.social ? (
                                    <Grid container style={titleContainer}>
                                        <Grid xs={5}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={'ConfirmPassword'}
                                                label="Confirm Password"
                                                variant={'outlined'}
                                                name="confirmPassword"
                                                onChange={this.handleChange}
                                                type="password"
                                                value={this.state.formData.confirmPassword || ''}
                                                style={{ margin: 10 }}
                                                error={this.hasError('confirmPassword')}
                                                helperText={
                                                    this.hasError('confirmPassword')
                                                        ? this.state.errors.confirmPassword[0]
                                                        : null
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                ) : null}
                                <Grid container style={titleContainer}>
                                    <Grid xs={5}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                     disabled={this.state.social}
                                                    checked={this.state.formData.IsActive}
                                                    onChange={this.handleChange}
                                                    name={'IsActive'}
                                                />
                                            }
                                            style={{ margin: 10 }}
                                            label={'Is Active'}
                                        />
                                    </Grid>
                                </Grid>
    
                                <Grid container xs={5} justify="flex-end" style={titleContainer}>
                                    <Grid>
                                        <Button
                                            onClick={this.handleCancel}
                                            color="inherit"
                                            variant="contained"
                                            style={{ marginRight: 10 }}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid>
                                        <Button
                                            disabled={!this.state.isValid || this.state.disableEditBtn}
                                            onClick={this.handleSave}
                                            color="primary"
                                            variant="contained"
                                        >
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            );
        }
        else{
            this.props.history.push(BasePath + '/userManagement');
            return(<a></a>)
        }  
    }
}

const mapStateToProps = (state: any) => {
    return {
        processing: state.userManagement_reducer.processing,
        user: state.user_reducer.user,
        editData: state.userManagement_reducer.editData,
        roles: state.userManagement_reducer.roles
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        editUser: (data: any, cb: any) => dispatch(editUser(data, cb)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(EditUser);

export default withRouter(connected);