import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
    Filter,
    Operators,
    TextFilter
} from '@progress/kendo-react-data-tools';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
    returnClassName,
    setClassNameFromDrop,
    setClassTypeFromDrop,
    setPrevStateForAdvanceSearch
} from '../../../../Services/browse';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import SearchClassContent from './AdvanceSearchContent';
import NoDataFound from '../../../../components/Messages/NoDataFound';
import { MIN_EXPORT_ADVANCE_SEARCH, TEXT_SEARCH } from '../../../../config';

import FavouritesModals from '../../../../components/FavouritesModals';
import SearchSaveForm from './SearchSaveForm';
import ExportDataCSV from '../../../../components/ExportDataCSV';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
// import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { replaceNames } from "../../../../utils/ItemFormHelpers";
import { setSearchTabsProperty } from '../../../../Services/favorites';
import conditionsData from "./consts";
import './advanceSearch.css'

class QueryBuilder extends React.Component {

    state = {
        filter: { logic: 'and', filters: [] },
        // dialogVisibility: false,
        currentData: "",
        currentFieldValue: ""
    };

    componentDidMount() {
        if (this.props.advanceSearchQuery) {
            let newFilter = JSON.parse(this.props.advanceSearchQuery);
            this.setState({ filter: this.detachClassTypeAndName(newFilter) });
            this.props.callWithFilters(this.detachClassTypeAndName(newFilter));
            this.props.setSearchTabsProperty(null, true);

        } else {
            this.setState({
                filter: this.props.advanceSearchPrevState ? this.detachClassTypeAndName(this.props.advanceSearchPrevState) : {
                    logic: 'and',
                    filters: []
                },
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let oldProps = { ...prevProps };
        let newProps = { ...this.props };

        if (
            newProps.advanceSearchQuery && (newProps.advanceSearchQuery != oldProps.advanceSearchQuery)
        ) {
            let newFilter = JSON.parse(newProps.advanceSearchQuery);
            this.setState({ filter: this.detachClassTypeAndName(newFilter) });
            this.props.callWithFilters(this.detachClassTypeAndName(newFilter));
            this.props.setPrevStateForAdvanceSearch(newFilter);
            this.props.setSearchTabsProperty(null, true);
        }
    }

    createKey(className, field, entry){
        let temp = null;
        if(className === "Select All"){
            let classVal = entry.class_type === 'Changes' ? 'Changes' : 'Parts'
            temp = classVal + ' ' +field;
            temp=temp.split(' ').join('_');
            return temp.toUpperCase();
        } else {
            temp = className + ' ' +field;
            temp=temp.split(' ').join('_');
            return temp.toUpperCase();
        }
        return temp

    }
    // toggleDialog = () => {
    //     this.setState({ dialogVisibility: !this.state.dialogVisibility })

    // }

    setDataValueFromDropDown = (event) => {
        const { value } = event.target;
        if (value) {
            this.setState({ currentData: value }, () => {
                this.setValueRecurFucntion(this.state.filter.filters);
                let filter = this.state.filter;
                this.setState({ filter: filter });
            });
        }
    }

    setValueRecurFucntion(filter) {
        for (let i = 0; i < filter.length; i++) {
            if (filter[i]) {
                if (filter[i].filters) {
                    this.setValueRecurFucntion(filter[i].filters)
                    continue;
                }
                if  (this.props.statusList.find((entry)=>(entry.attribute_name===filter[i].field)) && filter[i].value === "Select From Dropdown" && filter[i].field===this.state.currentFieldValue){
                    filter[i].value = this.state.currentData;
                }
            }
        }
    }

    async recurFilter(filter, comp) {
        for (let i = 0; i < filter.length; i++) {
            if (comp[i] && filter[i]) {
                if (filter[i].filters) {
                    this.recurFilter(filter[i].filters, comp[i].filters)
                    continue;
                }

                if (this.props.statusList && this.props.statusList.find((entry)=>(entry.attribute_name===filter[i].field)) && filter[i].field !== comp[i].field) {
                    this.setState({
                        currentFieldValue: filter[i].field
                    });
                }
            }
        }
    }

    onFilterChange = event => {

        if (this.state.filter.filters.length === event.filter.filters.length) {
            this.recurFilter(event.filter.filters, this.state.filter.filters)
        }
        this.setState({ filter: event.filter, searchQuery: JSON.stringify(this.concatClassFilters(event.filter)) });
        this.props.setPrevStateForAdvanceSearch(this.concatClassFilters(event.filter));
    };

    onSearch = () => {
        let Filter = this.state.filter;
        this.props.callWithFilters(Filter);
        this.props.setPrevStateForAdvanceSearch(this.concatClassFilters(Filter));
        this.props.setSearchTabsProperty(null, true);
    };

    detachClassTypeAndName = (filter) => {
        let realFilter = { logic: 'and', filters: [] };

        filter && filter['filters'].length && filter['filters'].map((filterObject) => {
            if (!filterObject.filters) {
                if (filterObject.field == 'CLASS_TYPE') {
                    let data = filterObject.value;
                    if (filterObject.value === 'Items') {
                        data = 'Parts';
                    } else if (filterObject.value === 'AML') {
                        data = 'Manufacturers';
                    } else if (filterObject.value === 'Manufacturer Parts') {
                        data = 'Manufacturer_parts';
                    }

                    this.props.setClassTypeFromDrop(data, true)

                } else if (filterObject.field == 'CLASS_NAME') {
                    this.props.setClassNameFromDrop(filterObject.value)
                }
            } else {
                realFilter = filterObject;
            }
        })

        return realFilter;
    }


    concatClassFilters = (filter) => {
        let addFilter = null;

        if (this.props.selectedClassType && this.props.selectedClassType !== "Select All") {
            addFilter = {
                "logic": "and",
                "filters": [
                    {
                        "field": "CLASS_TYPE",
                        "operator": "contains",
                        "value": this.props.selectedClassType
                    },
                    {
                        ...filter
                    }
                ]
            }
        }
        if (this.props.selectedClass && this.props.selectedClass !== "Select All" &&  typeof this.props.selectedClass == 'string') {
            addFilter.filters.push(
                {
                    "field": "CLASS_NAME",
                    "operator": "contains",
                    "value": returnClassName(this.props.selectedClass, this.props.SelectedClassList)
                },
            )
        }
        return addFilter ? addFilter : filter;
    }

    saveSearch = () => {
        return (
            <div>
                <FavouritesModals title={'Save Search'} type={'search'}>
                    <div style={{ width: '100%' }}>
                        <SearchSaveForm SearchQuery={JSON.stringify(this.concatClassFilters(this.state.filter))} />
                    </div>
                </FavouritesModals>
            </div>
        );
    };

    onChangeClass = (event) => {
        this.props.setClassNameFromDrop(event.value)
    }

    handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            let Filter = this.state.filter;
            this.props.callWithFilters(Filter);
            this.props.setPrevStateForAdvanceSearch(this.concatClassFilters(Filter));
            this.props.setSearchTabsProperty(null, true);
        }
    };

    onChangeClassType = (event) => {
        let data = event.value;
        if (event.value === 'Items') {
            data = 'Parts';
        } else if (event.value === 'AML') {
            data = 'Manufacturers';
        } else if (event.value === 'Manufacturer Parts') {
            data = 'Manufacturer_parts';
        } 
        this.props.setClassTypeFromDrop(data, false)
        this.props.setClassNameFromDrop("Select All")
        this.setState({
            filter: { logic: 'and', filters: [] }
        })

    }

    showClassTypeAndNames = () => {

        let classesData = this.props.SelectedClassList ? this.props.SelectedClassList.map((classData) => {
            return classData['DISPLAY-NAME']
        }) : []

        classesData = ["Select All", ...classesData];
        let classesType = ["Select All", ...this.props.classesType]
        return (<Grid container direction={'row'}>
            <Grid lg={3} md={4} xl={2} xs={12}>
                <DropDownList
                    data={classesType}
                    value={this.props.selectedClassType ? replaceNames(this.props.selectedClassType) : ""}
                    onChange={this.onChangeClassType}
                    style={{ width: "100%" }}
                />
            </Grid>
            <Grid lg={1} md={1} xl={1} xs={1}></Grid>
            {this.props.selectedClass && <Grid lg={3} md={4} xl={2} xs={12}>
                <DropDownList
                    data={classesData}
                    value={this.props.selectedClass && typeof (this.props.selectedClass) == "string" ? this.props.selectedClass : ""}
                    onChange={this.onChangeClass}
                    placeholder=" Class Name"
                    disabled={this.props.selectedClassType === "Select All"}
                    style={{ width: "100%" }}
                />
            </Grid>}
        </Grid>)
    }

    getData = (name) => {
        const data = []
        if(this.props.statusList && this.state.currentFieldValue){
            debugger;
            this.props.statusList.forEach((entry)=>{
                if(entry.key === this.createKey(this.props.selectedClassType, name, entry)){
                    if(entry.class_name){
                        if(entry.class_name.split(',').includes(this.props.selectedClass)){
                            data.push(entry.value)
                        }
                        return;
                    }
                    data.push(entry.value)
                }
            })
        }
        return data;
    }

    render() {
        let classname = this.props.selectedClass;
        let conditions = (this.props.selectedClassType) ? conditionsData[this.props.selectedClassType] : [];
        conditions = conditions.map(c => {
            if (this.props.statusList && this.props.statusList.find((entry)=>(entry.attribute_name === c.name))) {
                const name = c.name;
                const data = this.getData(name);                
                c.filter = (props) => <CustomFilter data={data} name={name} {...props} />;
            }
            return c;
        });
        if (TEXT_SEARCH && conditions && !conditions.find(condtn => condtn.label == 'Attachment Text')) {
            conditions.push({
                name: 'attachment.content',
                label: 'Attachment Text',
                filter: TextFilter,
                operators: Operators.text,
            });
        }
        if (TEXT_SEARCH && conditions && !conditions.find(condtn => condtn.label == 'Search All Attributes')) {
            conditions.push({
                name: 'simple',
                label: 'Search All Attributes',
                filter: TextFilter,
                operators: Operators.text,
            });
        }
        return (
            <React.Fragment>
                <Grid container direction={'row'} style={{ marginBottom: "16px" }}>
                    <Grid lg={12} md={12} xl={12} xs={12}>
                        {this.props.selectedClass && this.props.SelectedClassList && this.showClassTypeAndNames()}
                    </Grid>
                </Grid>

                <Grid xs={12} sm={12} md={12} item>
                    {conditions && <Filter
                        value={this.state.filter}
                        onChange={this.onFilterChange}
                        fields={conditions}
                        onKeyPress={this.handleKeypress}
                    />}
                </Grid>
                <Grid container direction={'row'}>
                    <Grid lg={1} md={1} xl={4} xs={12}>
                        <Button
                            style={{ marginBottom: '8px' }}
                            color="primary"
                            size="small"
                            type="submit"
                            variant="contained"
                            onClick={this.onSearch}
                        >
                            Search
                        </Button>
                    </Grid>
                    <Grid lg={11} md={11} xl={4} xs={12}>
                        {this.saveSearch()}
                    </Grid>
                </Grid>
                {this.props.advanceSearchData && (
                    <Grid xs={12} sm={12} md={12} container direction="column" justify="center" alignItems="flex-end">
                        <ExportDataCSV
                            method={'post'}
                            name={'Advance_Search_Data'}
                            url={`/api/search`}
                            body={{
                                filter: this.concatClassFilters(this.state.filter),
                                pageSize: MIN_EXPORT_ADVANCE_SEARCH,
                                page: 0,
                            }}
                            size={MIN_EXPORT_ADVANCE_SEARCH}
                        />
                    </Grid>
                )}
                {this.props.advanceSearchDataProcessing && <Loader />}
                {this.props.advanceSearchData && this.props.advanceSearchData.DATA && (
                    <SearchClassContent
                        //@ts-ignore
                        layout={this.props.advanceSearchData.LAYOUT}
                        data={this.props.advanceSearchData.DATA}
                    />
                )}
                {!this.props.advanceSearchDataProcessing && !this.props.advanceSearchData && (
                    <NoDataFound title={'No Results To Display'} />
                )}
                {/* {this.state.dialogVisibility && (<Dialog height={350} width={300} title={"Select a value"} onClose={(e) => {
                    this.setDataValueFromDropDown(e, this.props.statusList.find((entry)=>(entry.attribute_name===this.state.currentFieldValue && entry.class_type===this.props.selectedClassType)));
                }}>
                    <List >
                        {this.props.statusList && this.props.statusList.map((data) => {
                            if(this.state.currentFieldValue && data.key === this.createKey(this.props.selectedClassType,this.state.currentFieldValue)){
                                return([<ListItem disablePadding style={{ cursor: "pointer" }} onClick={(e) => {
                                             this.setDataValueFromDropDown(e, data);
                                         }}>
                                             <ListItemText primary={data.value} />
                                         </ListItem>,
                                         <Divider />])
                            }
                            
                        })}
                    </List>
                </Dialog>)} */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        advanceSearchData: state.browse_reducer.advanceSearchData,
        advanceSearchDataProcessing: state.browse_reducer.advanceSearchDataProcessing,
        advanceSearchQuery: state.favorites_reducer.advanceSearchQuery,
        advanceSearchPrevState: state.browse_reducer.advanceSearchPrevState,
        selectedClass: state.browse_reducer.selectedClass,
        selectedClassName: state.browse_reducer.selectedClassName,
        selectedClassType: state.browse_reducer.selectedClassType,
        SelectedClassList: state.browse_reducer.SelectedClassList,
        classesType: state.browse_reducer.classesType,
        statusList: state.browse_reducer.statusList

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setPrevStateForAdvanceSearch: (filter) =>
            dispatch(setPrevStateForAdvanceSearch(filter)),
        setClassTypeFromDrop: (data, flag) =>
            dispatch(setClassTypeFromDrop(data, flag)),
        setClassNameFromDrop: (data) =>
            dispatch(setClassNameFromDrop(data)),
        setSearchTabsProperty: (searchQuery, advanceSearchFlag) =>
            dispatch(setSearchTabsProperty(searchQuery, advanceSearchFlag)),

    };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(QueryBuilder);

export default withRouter(connected);



const CustomFilter = (props) => {
    const [value, setValue] = React.useState('Select a value');
    const [data = [], setData] = React.useState(props.data.slice());
    let { filter } = props;


    const onChange = (event) => {
      setValue(event.target.value);
      props.onFilterChange.call(undefined, {
        nextFilter: { ...props.filter, value: event.target.value },
      });
    };

    const filterData = (filter) => {
        const data = props.data.slice();
        return filterBy(data, filter);
    };

    const filterChange = (event) => {
        setData(filterData(event.filter));
    };

    return <DropDownList
        value={filter.value ? filter.value : 'Select a value'}
        onChange={onChange}
        data={data}
        filterable={true}
        onFilterChange={filterChange}
    />;
  };