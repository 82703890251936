import { Button, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TransferList from '../../components/TransferList/TransferList';
import { containers } from '../../utils/style';
import { fetchUsers } from '../../Services/userManagement';
import validate from 'validate.js';
import { setActiveMemeberTrue } from '../../utils/users';

import { addUsergroup, fetchSelectedUsers, fetchUsergroups, testUserGroupName } from '../../Services/usergroup';
import moment from 'moment';
import SnackbarCmpt from '../../components/Snackbar';
import Loader from '../../components/Loader';
import { BasePath } from '../../config';
interface props {
    fetchUsergroups: any;
    usergroup: any;
    fetchUsers: any;
    userData: any;
    addUsergroup: any;
    fetchSelectedUsers: any;
    selectedUsers: any;
    testUserGroupName: any;
    history: any;
    processing: any;
    user: any;
}

const schema = {
    groupName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    groupDesc: {
        presence: { allowEmpty: true },
        length: {
            maximum: 250,
        },
    },
};

class AddUsergroup extends Component<props> {
    state = {
        disableAddBtn: false,
        duplicate: false,
        isValid: false,
        showErr: false,
        groupName: '',
        groupDesc: '',
        touched: {
            groupName: null,
            groupDesc: null,
        },
        errors: {
            groupName: [],
            groupDesc: [],
        },
    };

    componentDidMount() {
        // this.props.fetchUsers();
    }

    handleChange = (event: any) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
                touched: { [event.target.name]: true },
            },
            () => {
                const errors = validate(this.state, schema);
                this.setState({
                    isValid: errors ? false : true,
                    errors: errors || {},
                });
            },
        );
    };

    handleAddUsergroup = () => {
        if (this.state.disableAddBtn) {
            return;
        }
        this.setState({
            disableAddBtn: true,
        });
        let usersData = setActiveMemeberTrue(this.props.selectedUsers, null);
        let formData = {
            Name: this.state.groupName.trim(),
            description: this.state.groupDesc.trim(),
            create_date: moment.utc().format(),
            created_by: this.props.user.first_name+' '+this.props.user.last_name,
            IsActive: 1,
            ModifiedBy: null,
            ModifiedDate: null,
            users: usersData,
        };
        this.props.testUserGroupName(formData, (res: any) => {
            if (res) {
                this.setState({
                    duplicate: true,
                    disableAddBtn: false,
                });
                setTimeout(() => {
                    this.setState({
                        duplicate: false,
                    });
                }, 3000);
            } else {
                this.props.addUsergroup(formData, (res: any) => {
                    if (res) {
                        this.props.history.push(BasePath + '/userGroup');
                    } else {
                        this.setState({
                            showErr: true,
                            disableAddBtn: false,
                        });
                    }
                });
            }
        });
    };

    checkingClassesEmpty = (flag: any) => {
        this.setState({
            disableAddBtn: flag,
        });
    };

    
    handleCancel = () => {
        this.props.history.push(BasePath + '/userGroup');
    };

    hasError = (field: any) =>
        //@ts-ignore
        this.state.touched[field] && this.state.errors[field] ? true : false;
    render() {
        const titleContainer = {
            marginTop: 10,
            marginBottom: 'auto',
            marginLeft: 10,
        };
        return (
            <Grid container>
                {this.props.processing ? (
                    <Loader />
                ) : (
                    <Grid container xs={12} style={containers}>
                        <Grid container style={{ margin: 10 }}>
                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        id={'GroupName'}
                                        label="Usergroup Name"
                                        variant={'outlined'}
                                        required
                                        fullWidth
                                        name="groupName"
                                        onChange={this.handleChange}
                                        type="text"
                                        value={this.state.groupName || ''}
                                        style={{ margin: 10 }}
                                        error={this.hasError('groupName')}
                                        helperText={this.hasError('groupName') ? this.state.errors.groupName[0] : null}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        id={'GroupDescription'}
                                        label="Description"
                                        variant={'outlined'}
                                        multiline
                                        fullWidth
                                        rows={3}
                                        name="groupDesc"
                                        onChange={this.handleChange}
                                        type="text"
                                        value={this.state.groupDesc || ''}
                                        style={{ margin: 10 }}
                                        error={this.hasError('groupDesc')}
                                        helperText={this.hasError('groupDesc') ? this.state.errors.groupDesc[0] : null}
                                    />
                                </Grid>
                            </Grid>
                            {this.props.userData ? (
                                <Grid container style={{ marginLeft: 20 }}>
                                    <Grid xs={5}>
                                        <TransferList
                                            leftData={this.props.userData.filter((user: any)=>{return user.IsActive})}
                                            rightData={[]}
                                            checkingClassesEmpty={this.checkingClassesEmpty}
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}

                            <Grid container xs={5} justify="flex-end" style={titleContainer}>
                                <Grid>
                                    <Button
                                        onClick={this.handleCancel}
                                        color="inherit"
                                        variant="contained"
                                        style={{ marginRight: 10 }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button
                                        disabled={!this.state.isValid || this.state.disableAddBtn}
                                        onClick={this.handleAddUsergroup}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {this.state.duplicate && (
                    <SnackbarCmpt message={'Duplicate usergroup name not allowed'} severity={'error'} />
                )}
            </Grid>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        processing: state.usergroup_reducer.processing,
        userData: state.userManagement_reducer.userData,
        user: state.user_reducer.user,
        selectedUsers: state.usergroup_reducer.selectedUsers,
        usergroup: state.usergroup_reducer.usergroup,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchUsers: () => dispatch(fetchUsers()),
        addUsergroup: (data: any, cb: any) => dispatch(addUsergroup(data, cb)),
        fetchSelectedUsers: (data: any) => dispatch(fetchSelectedUsers(data)),
        fetchUsergroups: () => dispatch(fetchUsergroups()),
        testUserGroupName: (name: any, cb: any) => dispatch(testUserGroupName(name, cb)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(AddUsergroup);

export default withRouter(connected);
