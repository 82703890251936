import React, { useState, useEffect } from 'react';
//import {setUserValue} from '../../Services/user';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, TextField, Link, Typography, Box } from '@material-ui/core';
import Loader from '../../components/Loader';
import { AuthenticateUser } from '../../Services/SignIn';
import SnackbarCmpt from '../../components/Snackbar';
import { BasePath, SERVER_URL } from '../../config';

const schema = {
    //   email: {
    //     presence: { allowEmpty: false, message: "is required" },
    //     email: true,
    //     length: {
    //       maximum: 64,
    //     },
    //   },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
};

const useStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '102%',
        marginTop: '-0.8%',
    },
    grid: {
        height: '100%',
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        background: '#525252',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/3.svg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '700px',
    },
    quoteText: {
        color: theme.palette.primary.main,
        fontWeight: 400,
        backgroundColor: '#F4F6F8',
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white,
    },
    bio: {
        color: theme.palette.white,
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    logoImage: {
        marginLeft: theme.spacing(4),
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    title: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
    },
    socialButtons: {
        marginTop: theme.spacing(3),
    },
    socialIcon: {
        marginRight: theme.spacing(1),
    },
    sugestion: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    signInButton: {
        margin: theme.spacing(2, 0),
    },
    person: {},
}));

const SignIn = (props: any) => {
    const { history } = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        invalidUser: false,
        values: { email: null, password: null },
        touched: { password: false, email: false },
        errors: { email: [], password: [] },
    });

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid : errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);

    const handleSignIn = () => {
        console.log(formState.values.email)
        console.log(formState.values.password)
        let { email, password } = formState.values;
        let formData = {
            user_name: email,
            password: password,
        };

        setLoader(true);

        dispatch(
            AuthenticateUser(formData, (res: any) => {
                setLoader(false);
                if (res) {
                    // ;
                    let path = `${BasePath}/search`;

                    props.history.push(path);
                    localStorage.setItem('isAuthenticated', 'true');
                    // localStorage.setItem("user", JSON.stringify(res));
                    // window.location.replace(
                    //     `${SERVER_URL}/api/auth/login`
                    // );
                } else {
                    setFormState(formState => ({
                        ...formState,
                        values: { ...formState.values, [`password`]: null },
                        touched: { ...formState.touched, [`password`]: true },
                        invalidUser: true,
                    }));
                    setTimeout(() => {
                        setFormState({ ...formState, invalidUser: false });
                    }, 3000);
                }
            }),
        );
    };

    const handleEnter = (event: any) => {
        // ;
    };
    const checkFields = (fields: any) =>{
        return (fields.email === null ? true: fields.email.length > 0 ? false : true)
    }

    const handleChange = (event: any) => {
        let value = event.target.value;
        let name = event.target.name;

        setFormState(formState => ({
            ...formState,
            values: { ...formState.values, [`${name}`]: value },
            touched: { ...formState.touched, [`${name}`]: true },
            isValid: formState.isValid,
        }));
    };

    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.quoteContainer} item lg={5}>
                    <div className={classes.quote}>
                        <div className={classes.quoteInner}>
                            <div className={classes.person}>
                                <Typography className={classes.name} variant="body1"></Typography>
                                <Typography className={classes.bio} variant="h3"></Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid className={classes.content} item lg={7} xs={12}>
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            <div style={{ textAlign: 'center', marginLeft: 250 }}>
                                <Typography className={classes.quoteText} variant="h2">
                                    Product Data Portal
                                </Typography>
                                <TextField
                                    error={Boolean(formState.touched.email && formState.errors.email)}
                                    fullWidth
                                    helperText={formState.touched.email && formState.errors.email}
                                    label="Username"
                                    margin="normal"
                                    name="email"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.values.email}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(formState.touched.password && formState.errors.password)}
                                    fullWidth
                                    helperText={formState.touched.password && formState.errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onChange={handleChange}
                                    type="password"
                                    value={formState.values.password}
                                    variant="outlined"
                                />
                                {loader ? (
                                    <Loader />
                                ) : (
                                    <div>
                                        <Box my={2}>
                                            <Button
                                                type={'submit'}
                                                color="primary"
                                                disabled={!formState.isValid || checkFields(formState.values)} 
                                                onKeyPress={handleEnter}
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                onClick={handleSignIn}
                                            >
                                                Sign in now
                                            </Button>
                                        </Box>

                                        {/* <GoogleButton
                                            style={{ width: 'auto' }}
                                            onClick={() => {
                                                window.location.replace(`${SERVER_URL}/api/auth/google`);
                                            }}
                                        /> */}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Grid>
                {formState.invalidUser && (
                    <SnackbarCmpt message={'Username and/or Password incorrect'} severity={'error'} />
                )}
            </Grid>
        </div>
    );
};

SignIn.propTypes = {
    history: PropTypes.object,
};

// @ts-ignore
export default withRouter(SignIn);
