import {
    SET_NOTIFICATION_ERROR,
    SET_NOTIFICATION_EVENTS,
    SET_RECIPIENT_ROLES,
    SET_NOTIFICATION_TAGS,
    GET_ALL_NOTIFICATIONS,
    SET_EDIT_DATA,
    SET_NOTIFICATIONS_PROCESSING,
    SET_NOTIFICATION_ROLES,
} from '../action-types/notifications-action-types';

export const setNotificationsProcessing = (flag: any) => {
    return {
        type: SET_NOTIFICATIONS_PROCESSING,
        payload: flag,
    };
};

export const setNotificationEvents = (data: any) => {
    return {
        type: SET_NOTIFICATION_EVENTS,
        payload: data,
    };
};

export const setRecipientRoles = (data: any) => {
    return {
        type: SET_RECIPIENT_ROLES,
        payload: data,
    };
};

export const setNotificationRoles = (data: any) => {
    return {
        type: SET_NOTIFICATION_ROLES,
        payload: data,
    };
};

export const setNotificationTags = (data: any) => {
    return {
        type: SET_NOTIFICATION_TAGS,
        payload: data,
    };
};

export const getAllNotifications = (data: any) => {
    return {
        type: GET_ALL_NOTIFICATIONS,
        payload: data,
    };
};

export const setEditData = (data: any) => {
    return {
        type: SET_EDIT_DATA,
        payload: data,
    };
};

export const getError = (error: any) => {
    return {
        type: SET_NOTIFICATION_ERROR,
        payload: error,
    };
};
