import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TransferList from '../../components/TransferList/TransferList';
import { setStatusForActiveClasses } from '../../utils/permission';
import { containers } from '../../utils/style';
import { addUsergroup } from '../../Services/usergroup';
import Loader from '../../components/Loader';
import { BasePath } from '../../config';
interface props {
    history: any;
    classEditData: any;
    classes: any;
    selectedUsers: any;
    addUsergroup: any;
    processing: any;
}

class ClassPermissionEdit extends Component<props> {
    state = {
        disableEditBtn: false,
        flag: false,
        showErr: false,
        groupSelected: null,
        selectFromArr: new Array(),
        selectToArr: new Array(),
    };

    componentDidMount() {
        if(this.props.classEditData && this.props.classEditData.dataItem){
            this.setState(
            {
                groupSelected: this.props.classEditData.dataItem.Name,
                selectToArr: [...this.props.classEditData.dataItem.classes],
                selectFromArr: [...this.props.classes],
            },
            () => {
                this.removeDuplicates(this.state.selectToArr, this.state.selectFromArr);
            },
        );}
        else{
                this.props.history.push(BasePath + '/permission');
        }
    }

    handleChange = (event: any) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleCancel = () => {
        this.props.history.push(BasePath + '/permission');
    };

    removeDuplicates = (a: any, b: any) => {
        b = b.filter(function(item: any) {
            for (var i = 0, len = a.length; i < len; i++) {
                if (a[i].class_name == item.class_name) {
                    return false;
                }
            }
            return true;
        });
        this.setState({
            selectFromArr: [...b],
            selectToArr: [...a],
            flag: true,
        });
    };

    checkingClassesEmpty = (flag: any) => {
        this.setState({
            disableEditBtn: flag,
        });
    };

    handleEditPermission = () => {
        if (this.state.disableEditBtn) {
            return;
        }
        this.setState({
            disableEditBtn: true,
        });
        let oldData = { ...this.props.classEditData.dataItem };
        let resultantData = setStatusForActiveClasses(oldData.classes, this.props.selectedUsers, null);

        let formData = {
            id: this.props.classEditData.dataItem.id,
            Name: this.props.classEditData.dataItem.Name,
            description: this.props.classEditData.dataItem.groupDesc,
            create_date: moment.utc().format(),
            created_by: null,
            IsActive: 1,
            ModifiedBy: null,
            ModifiedDate: null,
            classes: resultantData,
        };
        this.props.addUsergroup(formData, (res: any) => {
            if (res) {
                this.props.history.push(BasePath + '/permission');
            } else {
                this.setState({
                    showErr: true,
                    disableEditBtn: false,
                });
            }
        });
    };

    render() {
        if(this.props.classEditData && this.props.classEditData.dataItem){
        const titleContainer = {
            marginTop: 10,
            marginBottom: 'auto',
            marginLeft: 10,
        };
        return (
            <Grid container>
                {this.props.processing ? (
                    <Loader />
                ) : (
                    <Grid container xs={12} style={containers}>
                        <Grid container style={{ margin: 10 }}>
                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            style={{ backgroundColor: '#ffffff' }}
                                            id="demo-simple-select-outlined-label"
                                        >
                                            Usergroup
                                        </InputLabel>
                                        <Select
                                            disabled
                                            name="groupSelected"
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.groupSelected || ''}
                                            onChange={this.handleChange}
                                        >
                                            {this.props.classEditData.dataItem ? (
                                                <MenuItem value={this.props.classEditData.dataItem.Name}>
                                                    {this.props.classEditData.dataItem.Name}
                                                </MenuItem>
                                            ) : null}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {this.state.flag ? (
                                <Grid container style={{ marginTop: 10, marginLeft: 10 }}>
                                    <Grid xs={5}>
                                        <TransferList
                                            leftData={this.state.selectFromArr}
                                            rightData={this.state.selectToArr}
                                            checkingClassesEmpty={this.checkingClassesEmpty}
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}

                            <Grid container xs={5} justify="flex-end" style={titleContainer}>
                                <Grid>
                                    <Button
                                        onClick={this.handleCancel}
                                        color="inherit"
                                        variant="contained"
                                        style={{ marginRight: 10 }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button
                                        disabled={this.state.disableEditBtn}
                                        onClick={this.handleEditPermission}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Edit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );}
        else{
            this.props.history.push(BasePath + '/permission');
            return(<a></a>)
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        classEditData: state.permission_reducer.classEditData,
        classes: state.permission_reducer.classes,
        selectedUsers: state.usergroup_reducer.selectedUsers,
        processing: state.usergroup_reducer.processing,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        addUsergroup: (data: any, cb: any) => {
            dispatch(addUsergroup(data, cb));
        },
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(ClassPermissionEdit);

export default withRouter(connected);
