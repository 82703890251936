import {
    GET_USERGROUPS,
    SET_USERGROUP_ERROR,
    SET_SELECTED_USERS,
    SET_EDIT_DATA,
    SET_SELECT_FROM_USERS,
    SET_PAGE_SIZE_USERGROUP,
    SET_TOTAL,
    SET_USERGROUP_PROCESSING,
} from '../action-types/user-group-action-types';

export const getUsergroups = (data: any) => {
    return {
        type: GET_USERGROUPS,
        payload: data,
    };
};

export const setUsergroupProcessing = (flag: any) => {
    return {
        type: SET_USERGROUP_PROCESSING,
        payload: flag,
    };
};

export const setSelectedUsers = (data: any) => {
    return {
        type: SET_SELECTED_USERS,
        payload: data,
    };
};

export const setSelectFromUsers = (data: any) => {
    return {
        type: SET_SELECT_FROM_USERS,
        payload: data,
    };
};

export const setEditData = (data: any) => {
    return {
        type: SET_EDIT_DATA,
        payload: data,
    };
};

export const setPageSizeUsergroup = (data: any) => {
    return {
        type: SET_PAGE_SIZE_USERGROUP,
        payload: data,
    };
};

export const setTotal = (data: any) => {
    return {
        type: SET_TOTAL,
        payload: data,
    };
};

export const getError = (error: any) => {
    return {
        type: SET_USERGROUP_ERROR,
        payload: error,
    };
};
