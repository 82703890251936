import React, { Component } from 'react';
import theme from '../../../theme';
import {addAttachment, setCurrentUserGroup} from '../../../Services/suppliers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Button, Chip, Grid, IconButton, TextField, Tooltip, Typography} from "@material-ui/core";
import MaterialDropAttachments from '../../../components/MaterialDropAttachments';
import {Autocomplete} from "@material-ui/lab";
import {fetchUsergroups} from "../../../Services/usergroup";
import SnackbarCmpt from "../../../components/Snackbar";
import {BasePath} from "../../../config";
import {confirmAlert} from "react-confirm-alert";


const styles = {
    root: {
        width:"600px"
    },

};

interface props {
    fetchAllSuppliersAttachments:any;
    processingAttachments:any;
    supplierAttachment:any;
    fetchUsergroups:any;
    usergroup:any;
    selectedUsergroup:any;
    addAttachment:any
    history:any;
    user:any;
    setCurrentUserGroup:any
}

class Index extends Component<props> {
    state = {
        show: false,
        success: false,
        attachment:null,
        groupValue:null,
        description:"",
        loading:false
    };


    componentDidMount() {
        if(!this.props.usergroup){
            this.props.fetchUsergroups();
        }

    }


    setUsergroupValue = (data:any) => {
        if(data){
            this.setState({
                groupValue:data
            })
             ;
            this.props.setCurrentUserGroup(data)
        }
    }

    getTitle = () => {
        return(
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                 <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    xs={12} md={12}>
                     <Typography variant="h3">
                         Add Attachments
                     </Typography>
                </Grid>
            </Grid>
        )
    }

    getDropdownAndAddAttachmentButton = () => {
        return(
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                {this.props.usergroup && <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    xs={12} md={12}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={this.props.usergroup}
                        getOptionLabel={(option:any) => option.Name}
                        style={{ width: "100%" }}
                        onChange={(event: any, newValue: any | null) => {
                            this.setUsergroupValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="User Group" variant="outlined" />}
                    />
                </Grid>}
            </Grid>
        )
    }

     handleAttachment = (attachments: any) => {
        this.setState({
            attachment:attachments
        })
    };

    getAttachmentUploadComponent = () => {
        return (
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    xs={12} md={12}
                >
                    <MaterialDropAttachments
                        //@ts-ignore
                        handleAttachment={this.handleAttachment}
                        user={this.props.user}
                    />
                </Grid>
            </Grid>
        )
    }

    handleDeleteAttachment = (data: any) => {
        let attachmentVal:any = this.state.attachment;
        confirmAlert({
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        this.setState({
                            attachment:null
                        })
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                        return false;
                    },
                },
            ],
        });
    };

    showAttachments = () => {

       let  attachmentVal:any = this.state.attachment;
        return (
            <div>
                {attachmentVal && attachmentVal.map((data: any) => {
                    return (
                        <Grid container>
                            <Grid xs={12} sm={4} md={2}>
                                {data.IsActive == "1" && (
                                    <Tooltip title={data.Title}>
                                        <Chip
                                            style={{ margin: "4px", maxWidth: 500 }}
                                            label={data.Title}
                                            onDelete={() => {
                                                this.handleDeleteAttachment(data);
                                            }}
                                            color="primary"
                                        />
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                    );
                })}
            </div>
        )
    }

    handleSubmitButton = () => {

        return (
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    xs={4} md={4}
                >
                    <Button
                        disabled={(this.state.attachment && this.state.groupValue? false:true)|| this.state.loading}
                        variant="contained"
                        onClick={this.submitForm}
                        color="primary"
                    >
                        Submit
                    </Button>
                </Grid>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    xs={8} md={8}
                >
                    <Button
                        variant="contained"
                        onClick={() => {
                            this.props.history.push(BasePath + '/suppliers');
                        }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        )
    }


    submitForm = () => {

        this.setState({
            loading:true
        })
        let usergroup:any = this.state.groupValue;
        let stateAttachment:any = this.state.attachment;
        let attachment:any = [{ ...stateAttachment[0], Description:this.state.description}]

        let formData = {
            "Name":usergroup.Name,
            "attachments":attachment
        }

        this.props.addAttachment(usergroup.id,formData, (res:any)=>{
            if (res) {
                this.setState({ show: true, success: true, loading:false});
                this.props.history.push(BasePath + '/suppliers');
            } else {
                this.setState({ show: true, success: false ,  loading:false});
            }
        } );

    }


    handleChange = (event: any) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    getDescriptionComponent = () => {
            return(
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        xs={12} md={12}
                    >
                        <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            onChange={this.handleChange}
                            type="text"
                            multiline
                            rowsMax="20"
                            rows={4}
                            value={this.state.description}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            )
    }

    render() {
        return (
            <div style={styles.root}>
                <div style={{margin:theme.spacing(2)}}>
                    {this.getTitle()}
                </div>
                <div style={{margin:theme.spacing(2)}}>
                    {this.getDropdownAndAddAttachmentButton()}
                </div>

                <div style={{margin:theme.spacing(2)}}>
                    {this.getDescriptionComponent()}
                </div>

                <div style={{margin:theme.spacing(2)}}>
                    {this.getAttachmentUploadComponent()}
                </div>

                <div style={{margin:theme.spacing(2)}}>
                    {this.showAttachments()}
                </div>

                <div style={{margin:theme.spacing(2)}}>
                    {this.handleSubmitButton()}
                </div>


                {this.state.show && (
                    <SnackbarCmpt
                        message={this.state.success ? 'Added successfully' : 'Could not add'}
                        severity={this.state.success ? 'success' : 'error'}
                    />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        usergroup: state.usergroup_reducer.usergroup,
        user: state.user_reducer.user,
        selectedUsergroup: state.suppliers_reducer.selectedUsergroup
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        addAttachment: (id:any, formData:any, cb:any) => dispatch(addAttachment(id, formData, cb)),
        fetchUsergroups: (page: any) => dispatch(fetchUsergroups(page)),
        setCurrentUserGroup:(usergroup: any) => dispatch(setCurrentUserGroup(usergroup)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
