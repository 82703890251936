import { Grid, Typography } from '@material-ui/core';
import { containers } from '../../utils/style';
import { withRouter } from 'react-router';
import React from 'react';
import NotFound from './PDP.svg';

const NoDataFound = (props: any) => {
    return (
        <Grid>
            <div style={{ textAlign: 'center' }}>
                <Typography style={{ margin: 20 , color:"#8a8a8a"}} variant="h4">
                    {' '}
                    {props.title}
                </Typography>
            </div>
        </Grid>
    );
};

export default withRouter(NoDataFound);
