import React, { Component } from 'react';
import { getCategoryForDetails, setValueForCategory, setNewPageSizeCategories } from '../../Services/categories';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/Loader';
import { Divider, Grid, Typography } from '@material-ui/core';
import { containers } from '../../utils/style';
import CustomizedInputBase from '../../components/SearchBar';
import Button from '@material-ui/core/Button';
import { ADD_CATEGORIES, checkPermissionForCategories, EDIT_CATEGORIES } from '../../utils/categories';
import AddIcon from '@material-ui/icons/AddCircle';
import CourseRecords from '../../components/Categories/CourseRecords';
import moment from 'moment';
import NoDataFound from '../../components/Messages/NoDataFound';
import { checkPermissionForTrainingItems, EDIT_TRAINING_ITEMS } from '../../utils/TrainingItems';
import EditIcon from '@material-ui/icons/Edit';
import theme from '../../theme';
import CustomPagination from '../../components/CustomPagination';
import CardHeaderCmpt from '../../components/CardHeader/CardHeader';

interface propsInterface {
    views: any;
    history: any;
    location: any;
    getCategoryForDetails: any;
    processing: any;
    detailCategory: any;
    user: any;
    setValueForCategory: any;
    total: any;
    setNewPageSizeCategories: any;
    pageSizeCategories: any;
}

class DetailsCategories extends Component<propsInterface> {
    state = {
        page: 1,
    };

    componentDidMount(): void {
        if (this.props.location && this.props.location.search) {
            let id = this.props.location.search.split('?')[1];
            this.props.getCategoryForDetails(id, this.state.page);
        } else {
            this.props.history.push('/not-found');
        }
    }

    handlePage = (value: any) => {
        this.setState({
            page: parseInt(value),
        });
        let id = this.props.location.search.split('?')[1];
        this.props.getCategoryForDetails(id, value);
    };

    handleNewPageSize = (event: any) => {
        this.props.setNewPageSizeCategories(event.target.value);
    };

    goToCourses = () => {
        this.props.setValueForCategory(this.props.detailCategory.ID);
        this.props.history.push('/courses/add');
    };

    handleEdit = () => {
        if (this.props.detailCategory && this.props.detailCategory.ID) {
            this.props.history.push(`/categories/edit?${this.props.detailCategory.ID}`);
        }
    };

    openCourseDetails = (data: any) => {
        if (data.ID) {
            this.props.history.push(`/courses/details?${data.ID}`);
        }
    };

    showHeader = () => {
        return (
            <Grid container direction="row" style={containers}>
                <Grid
                    container
                    xs={6}
                    style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        // justifyContent: "center",
                    }}
                >
                    <Typography variant="h3">
                        {this.props.detailCategory && this.props.detailCategory.CategoryName
                            ? this.props.detailCategory.CategoryName
                            : null}
                    </Typography>
                </Grid>

                <Grid container xs={6} justify="flex-end">
                    <Grid>
                        {checkPermissionForCategories(this.props.views).includes(EDIT_CATEGORIES) ? (
                            <Button
                                variant="outlined"
                                color="default"
                                startIcon={<EditIcon />}
                                style={{
                                    flex: 1,
                                    height: '95%',
                                    marginRight: theme.spacing(1),
                                }}
                                onClick={this.handleEdit}
                            >
                                Edit
                            </Button>
                        ) : (
                            ''
                        )}
                    </Grid>

                    <Grid style={{ textAlign: 'right' }}>
                        <Button
                            disabled={!checkPermissionForCategories(this.props.views).includes(ADD_CATEGORIES)}
                            variant="contained"
                            color="primary"
                            style={{
                                flex: 1,
                                height: '95%',
                            }}
                            startIcon={<AddIcon />}
                            onClick={this.goToCourses}
                        >
                            Related Courses
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    returnItemProp = (title: any, value: any) => {
        return (
            <Grid container style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>
                <Grid xs={3}>
                    <Typography variant="h5"> {title}</Typography>
                </Grid>
                <Grid xs={9}>
                    <Typography variant="body1"> {value}</Typography>
                </Grid>
            </Grid>
        );
    };

    showContent = () => {
        let detailCategory = this.props.detailCategory;
        return (
            <div style={{ ...containers, minHeight: 350, paddingTop: 16 }}>
                {this.returnItemProp('Category Name', detailCategory.CategoryName)}

                {this.returnItemProp(
                    'Category Description',
                    <pre
                        style={{
                            fontFamily: 'inherit',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                        }}
                    >
                        {detailCategory.Description}
                    </pre>,
                )}
            </div>
        );
    };

    render() {
        let columns = [
            { title: 'Course Code', field: 'CourseCode' },
            { title: 'Course Title', field: 'CourseTitle' },
            { title: 'Default Duration (Days)', field: 'DefaultDurationinDays' },
            { title: 'Revision Sequence #', field: 'RevisionSequenceNumber' },
        ];

        return (
            <div>
                {this.props.processing && <Loader />}
                {this.props.detailCategory && (
                    <div>
                        {this.showHeader()}

                        <div style={{ padding: theme.spacing(3) }}>
                            {this.showContent()}
                            {this.props.detailCategory.courses &&
                            this.props.detailCategory.courses.results &&
                            this.props.detailCategory.courses.results.length ? (
                                <CardHeaderCmpt title={'Related Courses'}>
                                    <CourseRecords
                                        showDetails={this.openCourseDetails}
                                        title={''}
                                        tableData={this.props.detailCategory.courses.results}
                                        columns={columns}
                                    />
                                    <div style={{ marginTop: theme.spacing(3) }}>
                                        <CustomPagination
                                            total={this.props.total}
                                            page={this.state.page}
                                            handlePage={this.handlePage}
                                            pageSize={this.props.pageSizeCategories}
                                            handleNewPageSize={this.handleNewPageSize}
                                        />
                                    </div>
                                </CardHeaderCmpt>
                            ) : (
                                <NoDataFound title={'No Course related to this category found'} />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        processing: state.categories_reducer.processing,
        detailCategory: state.categories_reducer.detailCategory,
        total: state.categories_reducer.totalCategoryCourses,
        views: state.user_reducer.views,
        user: state.user_reducer.user,
        pageSizeCategories: state.categories_reducer.pageSizeCategories,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getCategoryForDetails: (id: any, page: any) => dispatch(getCategoryForDetails(id, page)),
        setValueForCategory: (data: any) => dispatch(setValueForCategory(data)),
        setNewPageSizeCategories: (event: any) => dispatch(setNewPageSizeCategories(event)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(DetailsCategories);

export default withRouter(connected);
