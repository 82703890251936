import {
    getError,
    setNotificationEvents,
    setRecipientRoles,
    setNotificationTags,
    getAllNotifications,
    setEditData,
    setNotificationsProcessing,
    setNotificationRoles,
} from '../actions/notifications';
import axios from '../utils/axios';

export const fetchNotificationEvents = () => {
    return (dispatch: any) => {
        dispatch(setNotificationEvents(null));
        let url = `/api/notificationEvents?IsActive=1&Expand=[roles]`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(setNotificationEvents(response.data.result));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const fetchRecipientRoles = (ID: any) => {
    return (dispatch: any) => {
        dispatch(setRecipientRoles(null));
        let url = `/api/notificationEvents?IsActive=1&Expand=[roles]&ID=${ID}`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result[0].roles) {
                    dispatch(setRecipientRoles(response.data.result[0].roles));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const fetchNotificationRoles = () => {
    return (dispatch: any) => {
        dispatch(setNotificationRoles(null));
        let url = `/api/roles?IsActive=1`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(setNotificationRoles(response.data.result));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const fetchNotificationTags = (ID: any) => {
    return (dispatch: any) => {
        dispatch(setNotificationTags(null));
        let url = `/api/notificationTags/${ID}?IsActive=1&Expand=[ notificationTags]`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.notificationTags) {
                    dispatch(setNotificationTags(response.data.result.notificationTags));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const addNotification = (data: any, cb: any) => {
    return (dispatch: any) => {
        let url = '/api/notifications';

        axios
            .patch(url, data)
            .then(response => {
                if (response.data.success) {
                    cb(response.data.result);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const fetchAllNotifications = (page: any, filter: any, sortBy: any, order: any) => {
    return (dispatch: any) => {
        dispatch(getAllNotifications(null));
        let url = `/api/notificationTypes?${filter}&Expand=[notificationEvents,notificationEventRoles.roles]&Page=${page}&OrderByDirection=${order}&OrderBy=${sortBy}`;
        dispatch(setNotificationsProcessing(true));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.results) {
                    dispatch(getAllNotifications(response.data.result.results));
                }
                dispatch(setNotificationsProcessing(false));
            })
            .catch(err => {
                dispatch(getError(err));
                dispatch(setNotificationsProcessing(false));
            });
    };
};

export const editNotification = (data: any, cb: any) => {
    return (dispatch: any) => {
        let url = '/api/notificationTypes';

        axios
            .patch(url, data)
            .then(response => {
                if (response.data.success) {
                    cb(response.data.result);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const fetchEditData = (data: any) => {
    return (dispatch: any) => {
        dispatch(setEditData(data));
    };
};

export const resetRedux = () => {
    return (dispatch: any) => {
        dispatch(setNotificationEvents(null));
        dispatch(setRecipientRoles(null));
        dispatch(setNotificationTags(null));
        dispatch(getAllNotifications(null));
    };
};
