export const GET_PART_CLASS_MAINDATA = 'GET_PART_CLASS_MAINDATA';
export const SET_SELECTED_CLASS_TYPE = 'SET_SELECTED_CLASS_TYPE';

export const GET_DOCUMENT_CLASS_MAINDATA = 'GET_DOCUMENT_CLASS_MAINDATA';
export const SET_DOCUMENT_PROCESSING = 'SET_DOCUMENT_PROCESSING';
export const SET_PART_PROCESSING = 'SET_PART_PROCESSING';
export const SET_PART_ERROR = 'SET_PART_ERROR';
export const SET_DOCUMENT_ERROR = 'SET_DOCUMENT_ERROR';

export const GET_MANUFACTURER_CLASS_MAINDATA = 'GET_MANUFACTURER_CLASS_MAINDATA';
export const SET_MANUFACTURER_PROCESSING = 'SET_MANUFACTURER_PROCESSING';
export const SET_MANUFACTURER_ERROR = 'SET_MANUFACTURER_ERROR';

export const GET_MANUFACTURER_PARTS_CLASS_MAINDATA = 'GET_MANUFACTURER_PARTS_CLASS_MAINDATA';
export const SET_MANUFACTURER_PARTS_PROCESSING = 'SET_MANUFACTURER_PARTS_PROCESSING';
export const SET_MANUFACTURER_PARTS_ERROR = 'SET_MANUFACTURER_PARTS_ERROR';

export const GET_SUPPLIERS_CLASS_MAINDATA = 'GET_SUPPLIERS_CLASS_MAINDATA';
export const SET_SUPPLIERS_PROCESSING = 'SET_SUPPLIERS_PROCESSING';
export const SET_SUPPLIERS_ERROR = 'SET_SUPPLIERS_ERROR';

export const GET_SUPPLIERS_ITEMS_CLASS_MAINDATA = 'GET_SUPPLIERS_ITEMS_CLASS_MAINDATA';
export const SET_SUPPLIERS_ITEMS_PROCESSING = 'SET_SUPPLIERS_ITEMS_PROCESSING';
export const SET_SUPPLIERS_ITEMS_ERROR = 'SET_SUPPLIERS_ITEMS_ERROR';

export const GET_CHANGE_CLASS_MAINDATA = 'GET_CHANGE_CLASS_MAINDATA';
export const SET_CHANGE_PROCESSING = 'SET_CHANGE_PROCESSING';
export const SET_CHANGE_ERROR = 'SET_CHANGE_ERROR';

export const GET_PSR_MAINDATA = 'GET_PSR_MAINDATA';
export const SET_PSR_PROCESSING = 'SET_PSR_PROCESSING';
export const SET_PSR_ERROR = 'SET_PSR_ERROR';

export const GET_QCR_MAINDATA = 'GET_QCR_MAINDATA';
export const SET_QCR_PROCESSING = 'SET_QCR_PROCESSING';
export const SET_QCR_ERROR = 'SET_QCR_ERROR';

export const GET_SELECTED_CLASSNAME = 'GET_SELECTED_CLASSNAME';
export const GET_SELECTED_CLASSLIST = 'GET_SELECTED_CLASSLIST';
export const PROCESSING_SELECTED_CLASSNAME = 'PROCESSING_SELECTED_CLASSNAME';
export const ERR_SELECTED_CLASSNAME = 'ERR_SELECTED_CLASSNAME';
export const SELECTED_CLASS_NAME = 'ERR_SELECTED_CLASSNAME';

export const SET_CLASS_CONTENT_PAGE_SIZE = 'SET_CLASS_CONTENT_PAGE_SIZE';
export const SET_CLASS_CONTENT_TOTAL = 'SET_CLASS_CONTENT_TOTAL';
export const SET_CLASS_CONTENT_TOTAL_BROWSE = 'SET_CLASS_CONTENT_TOTAL_BROWSE';

export const SET_SEARCHED_DATA = 'SET_SEARCHED_DATA';
export const SET_SEARCHED_PROCESSING = 'SET_SEARCHED_PROCESSING';

export const SET_ADVANCE_SEARCHED_DATA = 'SET_ADVANCE_SEARCHED_DATA';
export const SET_ADVANCE_SEARCHED_PROCESSING = 'SET_ADVANCE_SEARCHED_PROCESSING';

export const SET_TEXT_SEARCH_DATA = 'SET_TEXT_SEARCH_DATA';
export const TEXT_SEARCH_PROCESSING = 'TEXT_SEARCH_PROCESSING';



export const SET_SIMPLE_SEARCH_PREV_STATE = "SET_SIMPLE_SEARCH_PREV_STATE";
export const SET_ADVANCE_SEARCH_PREV_STATE = "SET_ADVANCE_SEARCH_PREV_STATE";

export const SET_STATUS_LIST = "SET_STATUS_LIST";

