import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Bookmarks from './Bookmarks';
import SavedSearches from './SavedSearches';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FeatureComingSoon from '../../components/FeatureComingSoon';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function a11yProps(index: any) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
}

export default function SimpleTabs(props: any) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleCloseDrawer = () => {
        props.onClose();
    };

    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: '#ffffff' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="standard"
                    scrollButtons="off"
                    aria-label="scrollable prevent tabs example"
                    textColor="primary"
                >
                    <Tab label={'Bookmarks'} aria-label="phone" {...a11yProps(0)} />
                    <Tab label={'Saved Searches'} aria-label="phone" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <div style={{ backgroundColor: '#f4f6f8', padding: '8px' }}>
                <TabPanel value={value} index={0}>
                    <Bookmarks
                        //@ts-ignore
                        handleCloseDrawer={handleCloseDrawer}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SavedSearches
                        //@ts-ignore
                        handleCloseDrawer={handleCloseDrawer}
                    />
                </TabPanel>
            </div>
        </div>
    );
}
