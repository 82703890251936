import { transformUserDeptRole } from "./users";

export const sortArrayObjectViewSeq = (layout: any) => {
    // ;

    let layoutAttr = layout;
    layoutAttr.sort(function(a: any, b: any) {
        return a.DISPLAY_SEQ - b.DISPLAY_SEQ;
    });

    return layoutAttr;
};

export const sortArrayObjectViewSeqSearch = (layout: any) => {
    let layoutAttr = layout;
    layoutAttr.sort(function(a: any, b: any) {
        return a.DISPLAY_SEQ - b.DISPLAY_SEQ;
    });

    return layoutAttr;
};

export const separateDataForFiles = (data: any) => {
    let filesData: any = [];
    let otherData: any = [];

    data.forEach((record: any) => {
        if (record.attachment) {
            filesData.push(record);
        } else {
            otherData.push(record);
        }
    });

    return { filesData, otherData };
};

export const setHirarchy = (params: any) => {
    let recievedData = [...params];
    recievedData.forEach((data: any) => {});
};

export function list_to_tree(list: any) {
    var map: any = {},
        node,
        tableData:any = [],
        i;

    for (i = 0; i < list.length; i += 1) {
        // ;
        if (list[i]) {
            map[list[i].ID_NUMBER] = i; // initialize the map
            list[i].tableData = []; // initialize the children
            list[i].treeID = i
            if( list[i].IS_PARENT){
                list[i].tableData = [{}]; // initialize the children
            }
        }
    }
    return list;
}

export function make_tree(list: any, bom:any, treeID:any, stack:any={}){
    let tree = list
    tree.forEach((row:any)=>{
        if(row.tableData.length===1 && row.treeID === treeID){
            bom.forEach((child:any) => {
                child.treeID=treeID+","+child.treeID
                if(stack[child.ID_NUMBER+child.TSITEM]){
                    child.tableData=[]
                    child.IS_PARENT=false
                }
            });
            row.tableData = [...bom]
            return list;
        }
        
        if(row.tableData.length>0 && Object.keys(row.tableData[0]).length > 0 && row.treeID !== treeID){
            stack[row.ID_NUMBER+row.TSITEM]=true
            row.tableData = [...make_tree(row.tableData,bom,treeID,stack)]
            delete stack[row.ID_NUMBER+row.TSITEM];
        }
    })
    return list;
}
