import {
    GET_DOCUMENT_CLASS_MAINDATA,
    GET_PART_CLASS_MAINDATA,
    SET_DOCUMENT_ERROR,
    SET_DOCUMENT_PROCESSING,
    SET_PART_ERROR,
    SET_PART_PROCESSING,
    ERR_SELECTED_CLASSNAME,
    GET_SELECTED_CLASSNAME,
    PROCESSING_SELECTED_CLASSNAME,
    SELECTED_CLASS_NAME,
    SET_CLASS_CONTENT_PAGE_SIZE,
    SET_CLASS_CONTENT_TOTAL,
    SET_SELECTED_CLASS_TYPE,
    SET_SEARCHED_PROCESSING,
    SET_SEARCHED_DATA,
    SET_CHANGE_PROCESSING,
    SET_CHANGE_ERROR,
    GET_CHANGE_CLASS_MAINDATA,
    SET_MANUFACTURER_PARTS_ERROR,
    SET_MANUFACTURER_PARTS_PROCESSING,
    GET_MANUFACTURER_PARTS_CLASS_MAINDATA,
    SET_MANUFACTURER_ERROR,
    SET_MANUFACTURER_PROCESSING,
    GET_MANUFACTURER_CLASS_MAINDATA,
    GET_PSR_MAINDATA,
    SET_PSR_PROCESSING,
    SET_PSR_ERROR,
    SET_SUPPLIERS_ERROR,
    SET_SUPPLIERS_PROCESSING,
    GET_SUPPLIERS_CLASS_MAINDATA,
    GET_SUPPLIERS_ITEMS_CLASS_MAINDATA,
    SET_SUPPLIERS_ITEMS_PROCESSING,
    SET_SUPPLIERS_ITEMS_ERROR,
    SET_ADVANCE_SEARCHED_PROCESSING,
    SET_ADVANCE_SEARCHED_DATA,
    SET_CLASS_CONTENT_TOTAL_BROWSE,
    SET_TEXT_SEARCH_DATA,
    TEXT_SEARCH_PROCESSING,
    SET_SIMPLE_SEARCH_PREV_STATE,
    SET_ADVANCE_SEARCH_PREV_STATE,
    GET_SELECTED_CLASSLIST,
    SET_STATUS_LIST,
    GET_QCR_MAINDATA,
    SET_QCR_ERROR,
    SET_QCR_PROCESSING
} from '../action-types/browse-action-types';
import { MIN_PAGE_SIZE } from '../config';
const initState = () => {
    return {
        classesType: ['Items', 'Documents', 'Changes', 'AML', 'Manufacturer Parts',],
        PartsClassType: [],
        DocumentClassType: [],
        processingPart: false,
        errorPart: null,

        ChangesClassType: [],
        processingChange: false,
        errorChange: null,

        ManufacturersClassType: [],
        processingManufacturer: false,
        errorManufacturer: null,

        ManufacturerPartsClassType: [],
        processingManufacturerParts: false,
        errorManufacturerParts: null,

        SuppliersClassType: [],
        processingSuppliers: false,
        errorSuppliers: null,

        SuppliersItemsClassType: [],
        processingSuppliersItems: false,
        errorSuppliersItems: null,
        SelectedClassList: null,
        PSRClassType: [],
        processingPSR: false,
        errorPSR: null,


        QCRClassType: [],
        processingQCR: false,
        errorQCR: null,

        processingDocument: false,
        errorDocument: null,
        SelectedClassData: null,
        ContentProcessing: false,
        selectedClass: '',
        selectedClassType: '',
        totalClassContent: 0,
        totalClassContentBrowse: 0,
        pageSizeClassContent: MIN_PAGE_SIZE,
        searchedResults: null,
        searchProcessing: false,

        advanceSearchData: null,
        advanceSearchDataProcessing: false,

        textSearchData: null,
        textSearchDataProcessing: false,

        simpleSearchPrevState: {},
        advanceSearchPrevState: null,

        statusList: null
    };
};

const browse_reducer = (state = initState(), action: any) => {
    let newState = { ...state };

    switch (action.type) {
        case GET_PART_CLASS_MAINDATA:
            setData(newState, action.payload, 'PartsClassType');
            break;
        case SET_PART_PROCESSING:
            setData(newState, action.payload, 'processingPart');
            break;
        case SET_PART_ERROR:
            setData(newState, action.payload, 'errorPart');
            break;
        case GET_CHANGE_CLASS_MAINDATA:
            setData(newState, action.payload, 'ChangesClassType');
            break;
        case SET_CHANGE_PROCESSING:
            setData(newState, action.payload, 'processingChange');
            break;
        case SET_CHANGE_ERROR:
            setData(newState, action.payload, 'errorChange');
            break;

        //manufacturer
        case GET_MANUFACTURER_CLASS_MAINDATA:
            setData(newState, action.payload, 'ManufacturersClassType');
            break;
        case SET_MANUFACTURER_PROCESSING:
            setData(newState, action.payload, 'processingManufacturer');
            break;
        case SET_MANUFACTURER_ERROR:
            setData(newState, action.payload, 'errorManufacturer');
            break;

        //suppliers
        case GET_SUPPLIERS_CLASS_MAINDATA:
            setData(newState, action.payload, 'SuppliersClassType');
            break;
        case SET_SUPPLIERS_PROCESSING:
            setData(newState, action.payload, 'processingSuppliers');
            break;
        case SET_SUPPLIERS_ERROR:
            setData(newState, action.payload, 'errorSuppliers');
            break;

        //suppliers items
        case GET_SUPPLIERS_ITEMS_CLASS_MAINDATA:
            setData(newState, action.payload, 'SuppliersItemsClassType');
            break;
        case SET_SUPPLIERS_ITEMS_PROCESSING:
            setData(newState, action.payload, 'processingSuppliersItems');
            break;
        case SET_SUPPLIERS_ITEMS_ERROR:
            setData(newState, action.payload, 'errorSuppliersItems');
            break;

        //manufacturer parts
        case GET_MANUFACTURER_PARTS_CLASS_MAINDATA:
            setData(newState, action.payload, 'ManufacturerPartsClassType');
            break;
        case SET_MANUFACTURER_PARTS_PROCESSING:
            setData(newState, action.payload, 'processingManufacturerParts');
            break;
        case SET_MANUFACTURER_PARTS_ERROR:
            setData(newState, action.payload, 'errorManufacturerParts');
            break;

        //psr
        case GET_PSR_MAINDATA:
            setData(newState, action.payload, 'PSRClassType');
            break;
        case SET_PSR_PROCESSING:
            setData(newState, action.payload, 'processingPSR');
            break;
        case SET_PSR_ERROR:
            setData(newState, action.payload, 'errorPSR');
            break;

        ////QCR
        case GET_QCR_MAINDATA:
            setData(newState, action.payload, 'QCRClassType');
            break;
        case SET_QCR_PROCESSING:
            setData(newState, action.payload, 'processingQCR');
            break;
        case SET_QCR_ERROR:
            setData(newState, action.payload, 'errorQCR');
            break;

        ////

        case GET_DOCUMENT_CLASS_MAINDATA:
            setData(newState, action.payload, 'DocumentClassType');
            break;
        case SET_DOCUMENT_PROCESSING:
            setData(newState, action.payload, 'processingDocument');
            break;
        case SET_DOCUMENT_ERROR:
            setData(newState, action.payload, 'errorDocument');
            break;
        case GET_SELECTED_CLASSNAME:
            setData(newState, action.payload, 'SelectedClassData');
            break;
        case GET_SELECTED_CLASSLIST:
            setData(newState, action.payload, 'SelectedClassList');
            break;

        case PROCESSING_SELECTED_CLASSNAME:
            setData(newState, action.payload, 'ContentProcessing');
            break;
        case SELECTED_CLASS_NAME:
            setData(newState, action.payload, 'selectedClass');
            break;

        case SET_CLASS_CONTENT_PAGE_SIZE:
            setData(newState, action.payload, 'pageSizeClassContent');
            break;
        case SET_CLASS_CONTENT_TOTAL:
            setData(newState, action.payload, 'totalClassContent');
            break;
        case SET_CLASS_CONTENT_TOTAL_BROWSE:
            setData(newState, action.payload, 'totalClassContentBrowse');
            break;
        case SET_SELECTED_CLASS_TYPE:
            setData(newState, action.payload, 'selectedClassType');
            break;
        case SET_SEARCHED_DATA:
            setData(newState, action.payload, 'searchedResults');
            break;
        case SET_SEARCHED_PROCESSING:
            setData(newState, action.payload, 'searchProcessing');
            break;

        case SET_ADVANCE_SEARCHED_DATA:
            setData(newState, action.payload, 'advanceSearchData');
            break;
        case SET_ADVANCE_SEARCHED_PROCESSING:
            setData(newState, action.payload, 'advanceSearchDataProcessing');
            break;

        case SET_TEXT_SEARCH_DATA:
            setData(newState, action.payload, 'textSearchData');
            break;
        case TEXT_SEARCH_PROCESSING:
            setData(newState, action.payload, 'textSearchDataProcessing');
            break;

        case SET_SIMPLE_SEARCH_PREV_STATE:
            setData(newState, action.payload, 'simpleSearchPrevState');
            break;
        case SET_ADVANCE_SEARCH_PREV_STATE:
            setData(newState, action.payload, 'advanceSearchPrevState');
            break;
        case SET_STATUS_LIST:
            setData(newState, action.payload, 'statusList');
            break;
        default:
            break;
    }

    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default browse_reducer;
