import React from 'react';
import ItemTabsFormsContainer from '../../Containers/ItemTabsForms';
import RoleWraper from '../../HOC/RoleWraper';
import { Grid } from '@material-ui/core';

const ItemTabsForms = (props: any) => {
    return (
        // @ts-ignore
        <RoleWraper view="ItemTabsFormsContainer">
            <Grid>
                <Grid container>
                    <Grid xs={12}>{<ItemTabsFormsContainer />}</Grid>
                </Grid>
            </Grid>
        </RoleWraper>
    );
};

export default ItemTabsForms;
