import React, {useEffect, useState} from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Chip, Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import {connect, useSelector} from 'react-redux';

const useStyles = makeStyles((theme: any) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content',
    },
    avatar: {
        width: 60,
        height: 60,
    },
    name: {
        marginRight: theme.spacing(1),
        color: '#ffffff',
    },
}));

const Profile = (props: any) => {
    const { className, ...rest } = props;

    const classes = useStyles();
    interface userType {
        name: string;
        avatar: string;
        bio: string;
    }

    const [user, setUser] = useState({});


    useEffect(() => {
    if(props.user && props.user.first_name){
        setUser({
            name: `${props.user.first_name || ''} ${props.user.last_name || ''}`,
            avatar: '/images/avatars/avatar_11.png',
            bio: ''
        })
    }

    }, [props.user]);

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            {
                //@ts-ignore
                user && user.name && <Chip color="default" avatar={<Avatar>{`${user.name[0]}`}</Avatar>} label={user.name} />
            }
            {/*<Typography*/}
            {/*   className={classes.name}*/}
            {/*   variant="h4"*/}
            {/* >*/}
            {/*   {user.name}*/}
            {/* </Typography>*/}
        </div>
    );
};


const mapStateToProps = (state: any) => {
    return {
        user: state.user_reducer.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default withRouter(connected);



