import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthorizeUser } from '../Services/SignIn';
import { withStyles } from '@material-ui/core/styles';
import theme from '../theme';
import { withRouter } from 'react-router-dom';
import {returnRole} from "../utils/ItemFormHelpers";
import Cookies from 'js-cookie';
import {logoutUser} from '../Services/user';
import { fetchGeneralConfigurations } from '../Services/configurations';
import {ORACLE_CLOUD_BASE_URL, SERVER_URL, UIExperince,TOP_BAR_LOGO} from '../config';

const styles: any = {
    root: {
        padding: theme.spacing(1),
        minHeight: '88vh',
    },
};

interface roleWraperProps {
    setUserValue: any;
    classes: any;
    user: any;
    children: any;
    view: any;
    history: any;
    fetchProgressTypes: any;
    setCourseTypeContent: any;
    progressStatuses: any;
    type: any;
    getConfigurations: any;
    configurationData: any;
    views: any;
    serverError: any;
    serverErrorMessage: any;
    AuthorizeUser: any;
    location:any;
    fetchGeneralConfigurations:any;
}

class RoleWraper extends Component<roleWraperProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.fetchGeneralConfigurations()
        
        if (!this.props.user) {
        
            let CurrentPath = this.props.location.pathname;
            this.props.AuthorizeUser((res:any)=>{
                    if(res){
                        let userRole:any = returnRole(res.roles);
                        if(userRole.Name == "Admin"){
                               this.props.history.push(CurrentPath)
                        } else {
                             let basePath = ''
                              switch(CurrentPath){
                                case '/Client/search':
                                this.props.history.push(CurrentPath)
                                break;
                                case '/Client/browse':
                                this.props.history.push(CurrentPath)
                                break;
                                default:
                                this.props.history.goBack()
                                break;
                              }
                        }
                    } else {
                            this.logout(this.props)
                    }
            });
        }
    }


     async logout(props:any){

            if(props && props.location.search && props.location.search && props.location.search.includes("SSOFLAG")){
                    let pathName = this.props.location.pathname;
                    this.props.history.push(pathName);
                    window.location.reload();
            } else {
            localStorage.removeItem('isAuthenticated');
            Cookies.remove('SessionId');
            localStorage.removeItem('user');
            // localStorage.removeItem("views");
            logoutUser();
            window.location.replace(`${SERVER_URL}/`);
            }

        };

    render() {
        const { classes } = this.props;
        return <div className={classes.root}>{this.props.user && this.props.children}</div>;
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user_reducer.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        AuthorizeUser: (cb:any) => dispatch(AuthorizeUser(cb)),
        fetchGeneralConfigurations :()=>dispatch(fetchGeneralConfigurations())
    };
};

let returned = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RoleWraper));
//@ts-ignore
export default withRouter(returned);
