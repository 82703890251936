import SearchIcon from '@material-ui/icons/Search';
import PublicIcon from '@material-ui/icons/Public';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import LockIcon from '@material-ui/icons/Lock';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import ConfigurationIcon from '@material-ui/icons/Settings';
import StarsIcon from '@material-ui/icons/Stars';
import React from 'react';
import { BasePath, SHOW_BROWSE_TAB } from '../../../../config';
import { useSelector } from 'react-redux';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import {returnRole} from "../../../../utils/ItemFormHelpers";

const returnSideBar = (user: any) => {
    let view: any = [];

    let userRole:any = returnRole(user.roles);

    let AdminData: any = {
        UserManagement: {
            id: 12,
            key: 12,
            title: 'Users',
            href: BasePath + '/userManagement',
            icon: <PersonIcon />,
        },
        Usergroup: {
            id: 11,
            key: 11,
            title: 'Usergroup',
            href: BasePath + '/userGroup',
            icon: <GroupIcon />,
        },
        Permission: {
            id: 10,
            key: 10,
            title: 'Permission',
            href: BasePath + '/permission',
            icon: <LockIcon />,
        },
        Configurations: {
            id: 9,
            key: 9,
            title: 'Configuration',
            href: BasePath + '/configuration',
            icon: <ConfigurationIcon />,
        }
    };


    let SupplierView: any = {
        Supplier: {
            id: 8,
            key: 8,
            title: 'Suppliers',
            href: BasePath + '/suppliers',
            icon: <EmojiTransportationIcon />,
        },
    };

     if(userRole.Name == "Basic" || userRole.Name == "Admin"){
         Object.keys(sideBarMenu).map((key: any) => {
             view.push(sideBarMenu[key]);
         });
     }
    if (userRole.Name == "Suppliers") {
        Object.keys(SupplierView).map((key: any) => {
            view.push(SupplierView[key]);
        });
    }

    if (userRole.Name == "Admin") {
        Object.keys(AdminData).map((key: any) => {
            view.push(AdminData[key]);
        });
    }
    if (SHOW_BROWSE_TAB){
        view.push({
            id: 14,
                key: 14,
                title: 'Browse',
                href: BasePath + '/browse',
                icon: <PublicIcon />,
        })
    }
    return view;
};



export let sideBarMenu: any = {

    Search: {
        id: 13,
        key: 13,
        title: 'Search',
        href: BasePath + '/search',
        icon: <SearchIcon />,
    }

};

export default returnSideBar;
