import React, { Component } from 'react';
import { Grid, Typography, Button, Switch } from '@material-ui/core';
import { containers, tableHeaderSort } from '../../utils/style';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationTable from '../../components/Table/NotificationTable';
import {
    fetchAllNotifications,
    editNotification,
    fetchEditData,
    fetchNotificationRoles,
} from '../../Services/notifications';
import AddIcon from '@material-ui/icons/AddCircle';
import moment from 'moment';
import NoDataFound from '../../components/Messages/NoDataFound';
import Loader from '../../components/Loader';
import { MANAGE_NOTIFICATIONS, checkPermissionForNotifications } from '../../utils/notifications';
import { fetchNotificationDashboard } from '../../Services/dashboard';
import NotificationsFilter from '../../components/Notifications/NotificationsFilter';
import theme from '../../theme';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
interface props {
    processing: any;
    history: any;
    fetchAllNotifications: any;
    allNotifications: any;
    editNotification: any;
    fetchNotificationRoles: any;
    notificationRoles: any;
    user: any;
    editData: any;
    fetchEditData: any;
    views: any;
    notificationDashboard: any;
    fetchNotificationDashboard: any;
}

class Index extends Component<props> {
    state = {
        filter: '',
        switch: false,
    };
    handleAddNotification = () => {
        this.props.history.push(`/notifications/add`);
    };

    handleEditNotification = (rowData: any) => {
        this.props.fetchEditData(rowData);
        //console.log(rowData);
        this.props.history.push(`/notifications/edit`);
    };

    handleToggleNotification = (event: any, rowData: any) => {
        this.setState({
            switch: event.target.checked,
        });
        let formData = {
            NotificationType: {
                IsActive: event.target.checked ? 1 : 0,
                Name: rowData.Name,
                Body: rowData.Body,
                ModifiedDate: moment.utc().format(),
                ModifiedBy: this.props.user.UserName,
                Description: rowData.Description,
                ID: rowData.ID,
            },
        };
        this.props.editNotification(formData, (res: any) => {
            if (res) {
                this.props.fetchAllNotifications(0, this.state.filter, 'ModifiedDate', 'desc');
            } else {
                this.setState({
                    showErr: true,
                });
            }
        });
    };

    componentDidMount(): void {
        // if (
        //   !checkPermissionForNotifications(this.props.views).includes(
        //     MANAGE_NOTIFICATIONS
        //   )
        // ) {
        //   this.props.history.push("/dashboard");
        // } else
        {
            this.props.fetchAllNotifications(0, this.state.filter, 'ModifiedDate', 'desc');
            this.props.fetchNotificationDashboard(1, 10);
            this.props.fetchNotificationRoles();
        }
    }

    dataColumns = [
        {
            title: 'Notification Type Name',
            field: 'Name',
        },
        {
            title: 'Events',
            field: 'notificationEvents',
            render: (rowData: any) => <div>{rowData.notificationEvents ? rowData.notificationEvents.Name : null}</div>,
        },
        {
            title: 'Roles',
            field: 'notificationEventRoles',
            render: (rowData: any) => (
                <div>{rowData.notificationEventRoles ? rowData.notificationEventRoles.roles.Name : null}</div>
            ),
        },
        {
            title: 'Description',
            field: 'Description',
            cellStyle: {
                width: '50%',
            },
        },
        {
            title: 'Dashboard Notification',
            field: 'DashboardNotification',
            render: (rowData: any) => (
                <div>
                    {rowData.DashboardNotification == 0 ? (
                        <NotificationsOffOutlinedIcon />
                    ) : (
                        <NotificationsNoneOutlinedIcon />
                    )}
                </div>
            ),
        },
        {
            title: 'Active',
            field: 'IsActive',
            render: (rowData: any) => (
                <div>
                    <Switch
                        checked={rowData.IsActive == 1 ? true : false}
                        onChange={(event: any) => {
                            this.handleToggleNotification(event, rowData);
                        }}
                        name="switch"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </div>
            ),
        },
    ];

    showItems = (dataColumns: any) => {
        return (
            <div>
                <NotificationTable
                    columns={dataColumns}
                    tableData={this.props.allNotifications}
                    editNotification={this.handleEditNotification}
                />
            </div>
        );
    };

    callWithFilters = (filter: any) => {
        this.setState({ filter: filter });
        if (filter) {
            this.props.fetchAllNotifications(0, filter, 'ModifiedDate', 'desc');
        } else if (filter == '') {
            this.props.fetchAllNotifications(0, '', 'ModifiedDate', 'desc');
        }
    };

    render() {
        // if (
        //   checkPermissionForNotifications(this.props.views).includes(
        //     MANAGE_NOTIFICATIONS
        //   )
        // )
        // {
        return (
            <Grid container>
                <Grid container style={containers}>
                    <Grid
                        xs={12}
                        md={8}
                        style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="h2">Product Data Portal</Typography>
                    </Grid>
                    {/* <Grid
              xs={12}
              md={4}
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
            >
              <Button
                variant="contained"
                color="primary"
                size={"medium"}
                style={{
                  flex: 1,
                  height: "100%",
                }}
                startIcon={<AddIcon />}
                onClick={this.handleAddNotification}
              >
                Add
              </Button>
            </Grid> */}
                </Grid>
                <NotificationsFilter callWithFilters={this.callWithFilters} />
                {this.props.processing && <Loader />}
                {this.props.allNotifications && this.props.allNotifications.length ? (
                    <div
                        style={{
                            ...tableHeaderSort,
                            width: '100%',
                            padding: theme.spacing(3),
                        }}
                    >
                        {this.showItems(this.dataColumns)}
                    </div>
                ) : (
                    !this.props.processing && (
                        <div style={{ width: '100%' }}>
                            <NoDataFound title={'No Items found'} />
                        </div>
                    )
                )}
            </Grid>
        );
        // } else {
        //   return (
        //     <Grid container style={containers}>
        //       <Grid
        //         xs={10}
        //         style={{
        //           marginBottom: "10",
        //           justifyContent: "center",
        //         }}
        //       >
        //         <Typography variant="h3">Notifications</Typography>
        //       </Grid>
        //     </Grid>
        //   );
        // }
    }
}

const mapStateToProps = (state: any) => {
    return {
        processing: state.notifications_reducer.processing,
        allNotifications: state.notifications_reducer.allNotifications,
        editData: state.notifications_reducer.editData,
        user: state.user_reducer.user,
        views: state.user_reducer.views,
        notificationDashboard: state.dashboard_reducer.notificationDashboard,
        notificationRoles: state.notifications_reducer.notificationRoles,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAllNotifications: (page: any, filter: any, sortBy: any, order: any) =>
            dispatch(fetchAllNotifications(page, filter, sortBy, order)),
        editNotification: (data: any, cb: any) => dispatch(editNotification(data, cb)),
        fetchEditData: (data: any) => dispatch(fetchEditData(data)),
        fetchNotificationDashboard: (page: any, pageSize: any) => dispatch(fetchNotificationDashboard(page, pageSize)),
        fetchNotificationRoles: () => dispatch(fetchNotificationRoles()),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
