import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { Component, useEffect } from 'react';
import SimpleSearch from './SimpleSearch';
import AdvancedSearch from './AdvancedSearch';
import { TEXT_SEARCH } from '../../config';
import { connect, useSelector, useDispatch } from 'react-redux';
import Simple from '@material-ui/icons/InsertDriveFileOutlined';
import Advance from '@material-ui/icons/FileCopyOutlined';
import favorites_reducer from '../../reducers/favorites_reducer';
import { withRouter } from 'react-router';
import { fetchStatusList } from '../../Services/browse';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function a11yProps(index: any) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
}

function  SimpleTabs(){


    let { searchAdvanceTab, advanceSearchQuery } = useSelector((state: any) => ({
        ...state.favorites_reducer,
    }));

    const dispatch = useDispatch();
   

    const [value, setValue] = React.useState(searchAdvanceTab ? 1 : 0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(fetchStatusList());
        if (searchAdvanceTab) {
            setValue(1);
        }
    }, [advanceSearchQuery, searchAdvanceTab]);

    return (
        <div>
            <AppBar position="static" style={{ marginTop: 4, backgroundColor: '#ffffff' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="off"
                    aria-label="scrollable prevent tabs example"
                    textColor="primary"
                >
                    <Tab  label={'Basic Search'} aria-label="phone" {...a11yProps(0)} />
                    <Tab  label={'Advanced Search'} aria-label="favorite" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <div style={{ backgroundColor: '#f4f6f8' }}>
                <TabPanel value={value} index={0}>
                    <SimpleSearch />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AdvancedSearch />
                </TabPanel>
            </div>
        </div>
    );
}



const mapStateToProps = (state: any) => {
    return {
        statusList: state.browse_reducer.statusList
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchStatusList: () => dispatch(fetchStatusList()),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(SimpleTabs);

export default withRouter(connected);

