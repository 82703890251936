import React from 'react';
import UsergroupContainer from '../../Containers/Usergroup';
import AddUsergroup from '../../Containers/Usergroup/AddUsergroup';
import EditUsergroup from '../../Containers/Usergroup/EditUsergroup';
import RoleWraper from '../../HOC/RoleWraper';
import { Grid } from '@material-ui/core';
import { BasePath } from '../../config';
import Breadcrumbs from '../../HOC/Breadcrumbs';
const Usergroup = (props: any) => {
    return (
        // @ts-ignore
        <RoleWraper view="userGroup">
            <Grid>
                <Grid container>
                    <Grid xs={12}>
                        {props.location && props.location.pathname === `${BasePath}/userGroup` && (
                            <UsergroupContainer />
                        )}
                        {props.location && props.location.pathname === `${BasePath}/userGroup/add` && <AddUsergroup />}
                        {props.location && props.location.pathname === `${BasePath}/userGroup/edit` && (
                            <EditUsergroup />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </RoleWraper>
    );
};

export default Usergroup;
