/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {List, ListItem, Button, colors, Grid, Divider} from '@material-ui/core';
import { sort_by } from '../../../../../../utils';

const useStyles = makeStyles((theme: any) => ({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    active: {
        color: theme.palette.primary.main,
        background: theme.palette.primary.selected,
        fontWeight: 'bold',
        '& $icon': {
            color: theme.palette.primary.main,
        },
    },
}));

const CustomRouterLink = forwardRef((props: any, ref: any) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
));

const SidebarNav = (props: any) => {
    const { pages, className, ...rest } = props;

    const classes = useStyles();
    // ;
    return (
        <Grid container style={{ flexDirection: 'row' }}>
            {pages &&
                pages.sort(sort_by('id', true, parseInt)).map((page: any) => (
                    [<Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={{ minWidth:"140px" }} md={1} lg={1} sm={2}>
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={page.href}
                            disabled={false}
                        >
                            <div className={classes.icon}>{page.icon}</div>
                            {page.title}
                        </Button>
                    </Grid>,
                <Divider orientation="vertical" flexItem />]
                ))}
        </Grid>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired,
};

export default SidebarNav;
