import { colors } from '@material-ui/core';
import {UIExperince} from '../config';

const white = '#FFFFFF';
const black = '#000000';

let oracleCloudPalette = {
    black,
    white,
    primary: {
        contrastText: white,
        dark: "#2c2926",
        main: "#2c2926",
        light: "#444444",
    },
    secondary: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400],
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400],
    },
    info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400],
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400],
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400],
    },
    text: {
        primary: "#4f4f4f",
        secondary: "#4f4f4f",
        link: colors.blue[600],
    },
    background: {
        default: '#f3f3f3',
        paper: white,
    },
    icon: colors.blueGrey[600],
    divider: colors.grey[200],
};


let agilrPalette  = {
    black,
    white,
    primary: {
        contrastText: white,
        dark: colors.indigo[900],
        main: colors.indigo[600],
        light: colors.indigo[200],
    },
    secondary: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue['A400'],
        light: colors.blue['A400'],
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400],
    },
    info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400],
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400],
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400],
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600],
    },
    background: {
        default: '#F4F6F8',
        paper: white,
    },
    icon: colors.blueGrey[600],
    divider: colors.grey[200],
};
 export default( UIExperince.agile ? agilrPalette : oracleCloudPalette);