import {
    setItemData,
    setUserError,
    setUserProcessing,
    setUserSearch,
    setAllTheUsers,
    setUserEdit,
    setDepartments,
    setUserRoles,
    setViews,
    setTotalUsers,
    setApprovableCourses,
    setApprovableTrainings,
    setConfigurations,
    setAuditProcessing,
    setAuditTrails,
    setEvent,
    setTotalAudit,
    setModules,
    setPageSizeUser,
    setDataFormLoading,
    redlineDataLoading,
    setRedlinesData,
    setRedlinesConfigurations, setRedlinesItem,
} from '../actions/user';
//import axios from "../utils/axios";
import axios from '../utils/axios';
import { SortDataGroupsByTab, SortTabs } from '../utils/users';
import Cookies from 'js-cookie';
import {TRANSFORM_REVISION} from "../utils/Transformation";
//import { setPageSizeAudit } from "../actions/auditTrail";
export const getUserView = (userData: any) => {
    let roles = userData.roles;
    let View: any = {};
    roles.forEach((role: any) => {
        role.permissions.forEach((permission: any) => {
            if (View[permission.Group]) {
                if (!View[permission.Group].some((user: any) => user.ID === permission.ID)) {
                    View[permission.Group].push(permission);
                }
            } else {
                View[permission.Group] = [];
                View[permission.Group].push(permission);
            }
        });
    });

    return View;
};

export const getConfigurations = (classtype?: any, classname?: any, rev?: any, id?: any, guid?: any) => {
    console.log('guid', guid);
    return (dispatch: any) => {
        let url = `/api/item/${classtype}/${classname}/${id}/${rev}/null`;
        if (guid) {
            url = `/api/item/${classtype}/${classname}/${id}/${rev}/${guid}`;
        }
        // let url = `api/item/Parts/RING_GASKET/H300221-2`;
        //  let url = `/api/item/Parts/PC_GENERAL/382523`;
        dispatch(setConfigurations(null));
        //@ts-ignore
        dispatch(setItemData(null));
        dispatch(setDataFormLoading(true));

        axios
            .get(url)
            .then(response => {
                if (response) {
                    let OrderedTabs = SortTabs(response.data.result.LAYOUT, response.data.result.TABS);
                    let formattedData = SortDataGroupsByTab(OrderedTabs);

                    dispatch(setConfigurations(formattedData));
                    dispatch(setItemData(response.data.result.DATA));
                }
                dispatch(setDataFormLoading(false));
                dispatch(setUserProcessing(false));
            })
            .catch(err => {
                dispatch(setDataFormLoading(false));
                dispatch(setUserProcessing(false));
            });
    };
};

export const resetRedlinesData = () => {
    return (dispatch: any) => {
        dispatch(setRedlinesConfigurations(null));
    };
};

export const fetchRedlines = (classtype?: any, classname?: any, ChangeId?: any, itemId?: any, rev?: any,  itemClassName?:any, itemGuid?:any) => {
    console.log(classname, classtype, ChangeId, rev, itemId);
    return (dispatch: any) => {
        rev = TRANSFORM_REVISION(rev);
        let url = `/api/redlines/${classtype}/${classname}/${ChangeId}/${itemClassName}/${itemId}/${rev}/${itemGuid}`;
        // let url = `api/item/Parts/RING_GASKET/H300221-2`;
        //  let url = `/api/item/Parts/PC_GENERAL/382523`;
        dispatch(setRedlinesConfigurations(null));
        //@ts-ignore
        dispatch(setRedlinesData(null));
        dispatch(redlineDataLoading(true));

        axios
            .get(url)
            .then(response => {
                if (response) {
                    let OrderedTabs = SortTabs(response.data.result.LAYOUT, response.data.result.TABS);
                    let formattedData = SortDataGroupsByTab(OrderedTabs);

                    dispatch(setRedlinesConfigurations(formattedData));
                    dispatch(setRedlinesData(response.data.result.DATA));
                }
                dispatch(redlineDataLoading(false));
            })
            .catch(err => {
                dispatch(redlineDataLoading(false));
            });
    };
};

export const resetConfiguration = () => {
    return (dispatch: any) => {
        dispatch(setConfigurations(null));
        //@ts-ignore
        dispatch(setItemData(null));
        dispatch(setDataFormLoading(true));
    };
};

export const setRedlinedItemNumber = (number:any) => {
    return (dispatch: any) => {
        dispatch(setRedlinesItem(number));
    };
};

export const resetRedlines = () => {
    return (dispatch: any) => {
        dispatch(setRedlinesConfigurations(null));
        //@ts-ignore
        dispatch(setRedlinesData(null));
    };
};

export const getUsers = (page: any, expand: any, sortBy: any, order: any) => {
    return (dispatch: any, getState: any) => {
        const { pageSizeUser }: any = getState().user_reducer;
        dispatch(setAllTheUsers(null));
        let url = `/api/users?Expand=${expand}&OrderByDirection=${order}&OrderBy=${sortBy}&ActiveRoles=1`;
        if (page) {
            url = `/api/users?Page=${page -
                1}&PageSize=${pageSizeUser}&Expand=${expand}&OrderByDirection=${order}&OrderBy=${sortBy}&ActiveRoles=1`;
        }
        if (page == null && expand == '[]') {
            url = `/api/users?OrderByDirection=${order}&OrderBy=${sortBy}&ActiveRoles=1&Role=Trainee`;
        }
        dispatch(setUserProcessing(true));
        axios
            .get(url)
            .then(response => {
                if (response.data.result.results) {
                    dispatch(setAllTheUsers(response.data.result.results));
                    dispatch(setTotalUsers(response.data.result.total));
                } else {
                    dispatch(setAllTheUsers(response.data.result));
                }
                dispatch(setUserProcessing(false));
            })
            .catch(err => {
                console.log('err', err);
                dispatch(setUserProcessing(false));
            });
    };
};

export const setSearchedUsers = (data: any, cb: any) => {
    return (dispatch: any) => {
        if (data) {
            dispatch(setUserSearch(data));
            cb(true);
        } else {
            let userdata: any = [
                {
                    ID: '1',
                    FirstName: 'manager',
                    LastName: 'one',
                    Email: 'abc@gmail.com',
                    UserName: 'myname',
                    Password: 'null',
                    IsActive: '1',
                    CreatedBy: null,
                    CreatedDate: null,
                    ModifiedBy: null,
                },
                {
                    ID: '2',
                    FirstName: 'manager',
                    LastName: 'one',
                    Email: 'abc@gmail.com',
                    UserName: 'myname',
                    Password: 'null',
                    IsActive: '1',
                    CreatedBy: null,
                    CreatedDate: null,
                    ModifiedBy: null,
                },
                {
                    ID: '3',
                    FirstName: 'manager',
                    LastName: 'one',
                    Email: 'abc@gmail.com',
                    UserName: 'myname',
                    Password: 'null',
                    IsActive: '1',
                    CreatedBy: null,
                    CreatedDate: null,
                    ModifiedBy: null,
                },
                {
                    ID: '3',
                    FirstName: 'manager',
                    LastName: 'one',
                    Email: 'abc@gmail.com',
                    UserName: 'myname',
                    Password: 'null',
                    IsActive: '1',
                    CreatedBy: null,
                    CreatedDate: null,
                    ModifiedBy: null,
                },
            ];
            dispatch(setUserProcessing(true));
            dispatch(setUserSearch(userdata));
            dispatch(setUserProcessing(false));
        }
    };
};

export const addUser = (data: any, cb: any) => {
    return (dispatch: any) => {
        let url = '/api/users';
        axios
            .post(url, data)
            .then(response => {
                if (response.data.success) {
                    dispatch(getUsers(1, '[departments,roles,userSources]', 'ModifiedDate', 'desc'));
                    cb(true);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
                console.log('err', err);
                dispatch(setUserProcessing(false));
            });
    };
};

export const editUser = (data: any, cb: any) => {
    return (dispatch: any, getState: any) => {
        let url = `/api/users/${data.ID}`;
        delete data['ID'];
        delete data['departments'];
        delete data['tableData'];
        let currentUser = getState().user_reducer.user;
        data = { ...data, ModifiedBy: currentUser.UserName };
        axios
            .patch(url, data, false)
            .then(response => {
                if (response.data.success) {
                    dispatch(getUsers(1, '[departments,roles,userSources]', 'ModifiedDate', 'desc'));
                    cb(true);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
                console.log('err', err);
                dispatch(setUserProcessing(false));
            });
    };
};

export const setUserForEdition = (user: any) => {
    return (dispatch: any) => {
        dispatch(setUserEdit(user));
    };
};

export const setUserDepartments = () => {
    return (dispatch: any) => {
        dispatch(
            setDepartments([
                { Name: 'Department 1', ID: '1' },
                {
                    Name: 'Department 2',
                    ID: '2',
                },
                { Name: 'Department 3', ID: '3' },
            ]),
        );
    };
};

export const setAllUserRoles = () => {
    return (dispatch: any) => {
        let url = '/api/roles?IsActive=1';
        dispatch(setUserRoles(null));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(setUserRoles(response.data.result));
                } else {
                    dispatch(setUserRoles(null));
                }
            })
            .catch(err => {
                console.log('err', err);
                dispatch(setUserRoles(null));
            });
    };
};

export const deleteUser = (data: any, cb: any) => {
    return (dispatch: any, getState: any) => {
        let url = `/api/users/${data.ID}`;
        delete data['ID'];
        delete data['tableData'];
        let user = getState().user_reducer.user;
        data = { ...data, ModifiedBy: user.UserName, IsActive: 0 };

        axios
            .patch(url, data, false)
            .then(response => {
                if (response.data.success) {
                    dispatch(getUsers(1, '[departments,roles,userSources]', 'ModifiedDate', 'desc'));
                    cb(true);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
                console.log('err', err);
                dispatch(setUserProcessing(false));
            });
    };
};

export const getLoggedUserData = (cb: any) => {
    let url = '/api/auth/user';
    axios
        .get(url, false)
        .then(data => {
            cb(data);
        })
        .catch(err => {
            Cookies.remove('connect.sid');
            cb(false);
        });
};

export const logoutUser = async () => {
    let url = '/api/auth/logout';
    Cookies.remove('connect.sid');
    Cookies.remove('_session');
    Cookies.remove('_session.sig');
    axios
        .post(url, false)
        .then(data => {})
        .catch(err => {});
};

export const testUserName = (name: any, cb: any) => {
    let url = `/api/users?UserName=${name.UserName}`;
    axios
        .get(url)
        .then((response: any) => {
            if (response.data.result && response.data.result.length) {
                if (name.ID) {
                    if (response.data.result.some((cat: any) => cat.ID === name.ID)) {
                        cb(false);
                    } else {
                        cb(true);
                    }
                } else {
                    cb(true);
                }
            } else {
                cb(false);
            }
        })
        .catch(err => {
            cb(false);
        });
};

// get courses for approvel

export const fetchCoursesToBeApprovedByUser = (id: any) => {
    return (dispatch: any) => {
        let url = `/api/users/${id}/approvecourses`;

        dispatch(setUserProcessing(true));
        dispatch(setApprovableCourses(null));

        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result && response.data.result.approvers) {
                    dispatch(setApprovableCourses(response.data.result.pendingCourses));
                } else {
                    dispatch(setApprovableCourses(null));
                }
                dispatch(setUserProcessing(false));
            })
            .catch(err => {
                dispatch(setApprovableCourses(null));
                dispatch(setUserProcessing(false));
            });
    };
};

const authenticateUser = (username: any, password: any) => {
    let url = `/api/auth/user/authenticate`;
    return axios.post(url, { UserName: username, Password: password });
};

export const approveCourse = (formData: any, courseId: any, cb: any) => {
    return (dispatch: any, getState: any) => {
        authenticateUser(formData.approvers[0].UserName, formData.approvers[0].Password).then(response => {
            if (response.data.success == true) {
                delete formData.approvers[0].UserName;
                delete formData.approvers[0].Password;
                let url = `/api/courses/${courseId}/approvecourse`;
                axios
                    .patch(url, formData, false)
                    .then(response => {
                        //    ;
                        if (response.data.success) {
                            //      ;
                            let user = getState().user_reducer.user;
                            dispatch(fetchCoursesToBeApprovedByUser(user.ID));
                            cb(true);
                        } else {
                            cb(false);
                        }
                    })
                    .catch(err => {
                        cb(false);
                        console.log('err', err);
                    });
            } else {
                cb(false);
            }
        });

        // let url = `/api/courses/${courseId}/approvecourse`;
        // axios
        //   .patch(url, formData, false)
        //   .then((response) => {
        //     if (response.data.success) {
        //       let user = getState().user_reducer.user;
        //       dispatch(fetchCoursesToBeApprovedByUser(user.ID));
        //       cb(true);
        //     } else {
        //       cb(false);
        //     }
        //   })
        //   .catch((err) => {
        //     cb(false);
        //     console.log("err", err);
        //   });
    };
};

export const approveTraining = (formData: any, cb: any) => {
    //alert(JSON.stringify(formData))
    return (dispatch: any, getState: any) => {
        authenticateUser(formData[0].UserName, formData[0].Password).then(response => {
            if (response.data.success == true) {
                delete formData[0].UserName;
                delete formData[0].Password;
                let url = `/api/trainings`;
                axios
                    .patch(url, formData, false)
                    .then(response => {
                        if (response.data.success) {
                            let user = getState().user_reducer.user;
                            dispatch(fetchTrainingsToBeApprovedByUser(user.ID));
                            cb(true);
                        } else {
                            cb(false);
                        }
                    })
                    .catch(err => {
                        cb(false);
                        console.log('err', err);
                    });
            } else {
                cb(false);
            }
        });
    };
};

export const fetchTrainingsToBeApprovedByUser = (id: any) => {
    return (dispatch: any) => {
        let url = `/api/users/${id}/approvetrainings`;

        dispatch(setUserProcessing(true));
        dispatch(setApprovableTrainings(null));

        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result && response.data.result.length) {
                    let approverTrainings: any = [];
                    let returnData = response.data.result;
                    returnData.map((data: any) => {
                        data.approvalTrainings.map((approvalTrain: any) => {
                            approverTrainings.push({
                                CourseCode: data.CourseCode,
                                CourseTitle: data.CourseTitle,
                                CourseID: data.ID,
                                ...approvalTrain,
                            });
                        });
                    });
                    dispatch(setApprovableTrainings(approverTrainings));
                } else {
                    dispatch(setApprovableTrainings(null));
                }
                dispatch(setUserProcessing(false));
            })
            .catch(err => {
                dispatch(setApprovableTrainings(null));
                dispatch(setUserProcessing(false));
            });
    };
};

export const editConfigurations = (formData: any, cb: any) => {
    return (dispatch: any, getState: any) => {
        let url = `/api/configurations`;
        axios
            .patch(url, formData, false)
            .then(response => {
                if (response.data.success) {
                    cb(true);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const fetchAuditTrails = (page: any, filter: any, sortBy: any, order: any) => {
    return (dispatch: any, getState: any) => {
        const { pageSizeAudit }: any = getState().auditTrail_reducer;
        dispatch(setAuditTrails(null));
        dispatch(setAuditProcessing(true));
        let url = `/api/auditTrail?Page=${page -
            1}&Expand=[auditEvents]&PageSize=${pageSizeAudit}${filter}&OrderByDirection=${order}&OrderBy=${sortBy}`;
        axios
            .get(url)
            .then(response => {
                if (response.data.result.results) {
                    dispatch(setAuditTrails(response.data.result.results));
                    dispatch(setTotalAudit(response.data.result.total));
                }
                dispatch(setAuditProcessing(false));
            })
            .catch(err => {
                console.log('err', err);
                dispatch(setAuditProcessing(false));
            });
    };
};

export const fetchEvents = () => {
    return (dispatch: any) => {
        dispatch(setEvent(null));
        let url = `/api/auditEvents`;
        axios
            .get(url)
            .then(response => {
                if (response.data.result) {
                    dispatch(setEvent(response.data.result));
                }
            })
            .catch(err => {});
    };
};

export const fetchModules = () => {
    return (dispatch: any) => {
        dispatch(setEvent(null));
        let url = `/api/eventModules`;
        axios
            .get(url)
            .then(response => {
                if (response.data.result) {
                    dispatch(setModules(response.data.result));
                }
            })
            .catch(err => {});
    };
};

export const setNewPageSizeUser = (value: any) => {
    return (dispatch: any) => {
        dispatch(setPageSizeUser(value));
    };
};

export const setNewPageSizeAudit = (value: any) => {
    return (dispatch: any) => {
        //  dispatch(setPageSizeAudit(value));
    };
};
