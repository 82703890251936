import {
    GET_CLASS_PERMISSION,
    GET_TABS_PERMISSION,
    SET_PERMISSION_ERROR,
    GET_USERGROUP_CLASS,
    SET_SELECTED_USERGROUP,
    SET_CLASS_EDIT,
    GET_CLASSES,
    GET_TABS,
    SET_TABS_EDIT,
    SET_PAGE_SIZE_CLASS,
    SET_PAGE_SIZE_TABS,
    SET_TOTAL,
    SET_CLASS_PROCESSING,
    SET_TABS_PROCESSING,
} from '../action-types/permission-action-types';
import { MIN_PAGE_SIZE } from '../config';
const initState = () => {
    return {
        classPermissionData: null,
        tabsPermissionData: null,
        usergroupClassData: null,
        selectedUsergroup: null,
        classEditData: null,
        tabsEditData: null,
        classes: null,
        tabs: null,
        pageSizeClass: MIN_PAGE_SIZE,
        pageSizeTabs: MIN_PAGE_SIZE,
        total: null,
        error: null,
        classProcessing: false,
        tabsProcessing: false,
    };
};

const permission_reducer = (state = initState(), action: any) => {
    let newState = { ...state };
    switch (action.type) {
        case GET_CLASS_PERMISSION:
            setData(newState, action.payload, 'classPermissionData');
            break;
        case SET_CLASS_PROCESSING:
            setData(newState, action.payload, 'classProcessing');
            break;
        case SET_TABS_PROCESSING:
            setData(newState, action.payload, 'tabsProcessing');
            break;
        case GET_TABS_PERMISSION:
            setData(newState, action.payload, 'tabsPermissionData');
            break;
        case GET_USERGROUP_CLASS:
            setData(newState, action.payload, 'usergroupClassData');
            break;
        case SET_SELECTED_USERGROUP:
            setData(newState, action.payload, 'selectedUsergroup');
            break;
        case SET_CLASS_EDIT:
            setData(newState, action.payload, 'classEditData');
            break;
        case SET_TABS_EDIT:
            setData(newState, action.payload, 'tabsEditData');
            break;
        case GET_CLASSES:
            setData(newState, action.payload, 'classes');
            break;
        case GET_TABS:
            setData(newState, action.payload, 'tabs');
            break;
        case SET_PAGE_SIZE_CLASS:
            setData(newState, action.payload, 'pageSizeClass');
            break;
        case SET_PAGE_SIZE_TABS:
            setData(newState, action.payload, 'pageSizeTabs');
            break;
        case SET_TOTAL:
            setData(newState, action.payload, 'total');
            break;
        case SET_PERMISSION_ERROR:
            setData(newState, action.payload, 'error');
            break;
        default:
            break;
    }
    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default permission_reducer;
