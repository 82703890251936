export const MANAGE_NOTIFICATIONS = 'MANAGE_NOTIFICATIONS';

export const checkPermissionForNotifications = (views: any) => {
    let viewsUser: any = views['Notifications'];
    let permissions: any = [];
    if (viewsUser && viewsUser.some((perm: any) => perm.Name == 'Manage Notifications')) {
        permissions.push(MANAGE_NOTIFICATIONS);
    }
    return permissions;
};
