import {
    SET_COURSE_STATS,
    COURSE_STATS_PROCESSING,
    SET_REVISED_COURSES,
    REVISED_COURSES_PROCESSING,
    REVISIONS_AVAILABLE,
    REV_AVL_PROCESSING,
    OVERDUE_PROCESSING,
    OVERDUE_TRAININGS,
    DEADLINE_PROCESSING,
    TRAINING_DEADLINE,
    NOTIFICATION_PROCESSING,
    DASHBOARD_NOTIFICATIONS,
    PIE_COURSE_STATS_PROCESSING,
    SET_COURSE_PIE_STATS,
} from '../action-types/dashboard-action-types';

const initState = () => {
    return {
        statsProcessing: false,
        courseStats: null,
        revisedCourseProcessing: false,
        revisedCourses: null,
        revisionAvailableProcessing: false,
        revisionsAvailable: 0,
        overdueTrainings: 0,
        overdueProcessing: false,
        deadlineTrainings: 0,
        deadlineProcessing: false,
        notificationDashboard: null,
        notificationProcessing: false,
        pieStatsProcessing: false,
        pieCourseStats: null,
    };
};

const dashboard_reducer = (state = initState(), action: any) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_COURSE_STATS:
            setData(newState, action.payload, 'courseStats');
            break;
        case COURSE_STATS_PROCESSING:
            setData(newState, action.payload, 'statsProcessing');
            break;
        case SET_REVISED_COURSES:
            setData(newState, action.payload, 'revisedCourses');
            break;
        case REVISED_COURSES_PROCESSING:
            setData(newState, action.payload, 'revisedCourseProcessing');
            break;
        case REVISIONS_AVAILABLE:
            setData(newState, action.payload, 'revisionsAvailable');
            break;
        case REV_AVL_PROCESSING:
            setData(newState, action.payload, 'revisionAvailableProcessing');
            break;
        case OVERDUE_TRAININGS:
            setData(newState, action.payload, 'overdueTrainings');
            break;
        case OVERDUE_PROCESSING:
            setData(newState, action.payload, 'overdueProcessing');
            break;
        case TRAINING_DEADLINE:
            setData(newState, action.payload, 'deadlineTrainings');
            break;
        case DEADLINE_PROCESSING:
            setData(newState, action.payload, 'deadlineProcessing');
            break;

        case DASHBOARD_NOTIFICATIONS:
            setData(newState, action.payload, 'notificationDashboard');
            break;
        case NOTIFICATION_PROCESSING:
            setData(newState, action.payload, 'notificationProcessing');
            break;
        case SET_COURSE_PIE_STATS:
            setData(newState, action.payload, 'pieCourseStats');
            break;
        case PIE_COURSE_STATS_PROCESSING:
            setData(newState, action.payload, 'pieStatsProcessing');
            break;
        default:
            break;
    }

    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default dashboard_reducer;
