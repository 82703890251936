import React, { Component } from 'react';
import theme from '../../../theme';
import {deleteAttachment, fetchAllSuppliersAttachments} from '../../../Services/suppliers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Button, Grid, IconButton, TextField, Tooltip} from "@material-ui/core";
import Loader from "../../../components/Loader";
import Treefile from "../../../components/KendoUICmpt/TreeList";
import NoDataFound from "../../../components/Messages/NoDataFound";
import ConfirmationPopover from "../../Favorites/Components/ConfirmationPopover";
import Download from '@material-ui/icons/GetApp';
import {BasePath, BUCKET_BASE_URL, SUPPLIER_BUCKET} from "../../../config";
import moment from "moment";
import {Autocomplete} from "@material-ui/lab";
import {fetchUsergroups} from "../../../Services/usergroup";
import SnackbarCmpt from "../../../components/Snackbar";


const styles = {
    root: {

    },

};

interface props {
    fetchAllSuppliersAttachments:any;
    processingAttachments:any;
    supplierAttachment:any;
    fetchUsergroups:any;
    usergroup:any;
    selectedUsergroup:any;
    deleteAttachment:any;
    history:any;
}

class Index extends Component<props> {
    state = {
        show: false,
        success: false,
        value:""
    };

    columns = [
        {title:"Title",
            field:"Title"
        },
        {
            title:"Description",
            field:"Description"
        },
        {
            title:"Created By",
            field:"CreatedBy"
        },
        {title: 'Created Date',
            cell: (rowData: any) => (
                <td>
                    <Tooltip title="Download Attachment">
                        <div>{moment(rowData.dataItem['CreatedDate']).format("MMMM Do YYYY")}</div>
                    </Tooltip>
                </td>
            )
        },
        {
            title: 'Download',
            cell: (rowData: any) => (
                <td>
                <Tooltip title="Download Attachment">
                    <IconButton aria-label="Download" onClick={(e: any) => this.handleDownload(e, rowData)}>
                        <Download />
                    </IconButton>
                </Tooltip>
                </td>
            ),
        },
        {
            title: 'Remove',
            cell: (rowData: any) => (
                <td>
                    <ConfirmationPopover handleYes={(e: any) => this.handleRemove(e, rowData)} />
                </td>
            ),
        },
    ];

    componentDidMount() {
        this.props.fetchUsergroups();
        if(this.props.selectedUsergroup){
            let data = {...this.props.selectedUsergroup};
            this.setState({
                value:data
            });
            this.props.fetchAllSuppliersAttachments(data);
        }
    }



    handleRemove = (e: any, rowData: any) => {
        let data = rowData.dataItem;
        let usergroup = this.props.selectedUsergroup;

        if (data) {
            let ID = data.ID;
            let formData = {
                "Name": usergroup.Name,
                "attachments": [
                    {
                        "ID": ID,
                        "IsActive": "0",
                    }
                ]
            }

            this.props.deleteAttachment(usergroup.id, formData, (res:any)=>{
                if (res) {
                    this.setState({ show: true, success: true });
                    let data = {...this.props.selectedUsergroup};

                    this.props.fetchAllSuppliersAttachments(data);
                } else {
                    this.setState({ show: true, success: false });
                }
            })

        };
    };

    handleDownload = (e: any, rowData: any) => {
        if (rowData.dataItem['Path']) {
            let path = rowData.dataItem['Path'];
            window.open(`${SUPPLIER_BUCKET}${path}`);
        }
    }

    setUsergroupValue = (data:any) => {
        if(data && data.id && (!this.props.selectedUsergroup || (data.id != this.props.selectedUsergroup.id) )){
            this.props.fetchAllSuppliersAttachments(data);
        }
    }


    getDropdownAndAddAttachmentButton = () => {
        return(
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                {this.props.usergroup && <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    xs={6} md={6}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={this.props.usergroup}
                        getOptionLabel={(option:any) => option.Name}
                        style={{ width: 300 }}
                        onChange={(event: any, newValue: any | null) => {
                            this.setUsergroupValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="User Group" variant="outlined" />}
                    />
                </Grid>}
                <Grid
                    xs={6}
                    md={6}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-end"
                >
                    <Button
                        variant="contained"
                        onClick={() => {
                            this.props.history.push(BasePath + '/suppliers/addAttachment');
                        }}
                        color="primary"
                    >
                        Add Attachment
                    </Button>
                </Grid>
            </Grid>
        )
    }



    render() {
        return (
            <div style={styles.root}>
               <div style={{marginTop:theme.spacing(2)}}>
                   {this.getDropdownAndAddAttachmentButton()}
               </div>

                {this.props.processingAttachments || !this.props.usergroup && <Loader />}

                {this.props.usergroup && this.props.supplierAttachment && this.props.supplierAttachment.length > 0 ? (
                    <div style={{marginTop:theme.spacing(2)}}>
                        <Treefile columns={this.columns} tableData={this.props.supplierAttachment} />
                    </div>
                ) : (
                    !this.props.processingAttachments && this.props.usergroup && (
                        <div style={{ width: '100%' }}>
                            <NoDataFound title={'No Attachments found'} />
                        </div>
                    )
                )}

                {this.state.show && (
                    <SnackbarCmpt
                        message={this.state.success ? 'Deleted successfully' : 'Could not delete'}
                        severity={this.state.success ? 'success' : 'error'}
                    />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        supplierAttachment: state.suppliers_reducer.supplierAttachment,
        processingAttachments:state.suppliers_reducer.processingAttachments,
        usergroup: state.usergroup_reducer.usergroup,
        selectedUsergroup: state.suppliers_reducer.selectedUsergroup
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAllSuppliersAttachments: (usergroupID:any) => dispatch(fetchAllSuppliersAttachments(usergroupID)),
        fetchUsergroups: (page: any) => dispatch(fetchUsergroups(page)),
        deleteAttachment: (id:any, formData:any, cb:any) => dispatch(deleteAttachment(id, formData, cb))
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
