import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { editConfigurations, getConfigurations } from '../../Services/user';
import { formatDataGroupsForConfigurations } from '../../utils/users';
import theme from '../../theme';
import { Divider, Grid, Typography } from '@material-ui/core';
import { containers } from '../../utils/style';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ConfigurationViews from '../../components/Configurations/ConfigurationViews';
import moment from 'moment';
import SnackbarCmpt from '../../components/Snackbar';
import { fetchNotificationDashboard } from '../../Services/dashboard';

interface propsInterface {
    configurationData: any;
    getConfigurations: any;
    user: any;
    editConfigurations: any;
    notificationDashboard: any;
    fetchNotificationDashboard: any;
}

class index extends Component<propsInterface> {
    state = {
        formatedData: null,
        show: false,
        showErr: false,
    };

    componentDidMount(): void {
        let result = this.props.getConfigurations();
        // let result = formatDataGroupsForConfigurations(
        //   this.props.configurationData
        // );
        this.setState({
            formatedData: result,
        });
    }

    showHeader = () => {
        return (
            <Grid container style={containers}>
                <Grid
                    sm={12}
                    md={3}
                    style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h3">Page Three</Typography>
                </Grid>
            </Grid>
        );
    };

    handleSubmitData = (recievedData: any) => {
        let formData: any = [];
        Object.entries(recievedData).forEach(([key, value]) => {
            let splitData = key.split('-');
            formData.push({
                ID: splitData[1],
                Key: splitData[0],
                Value: value,
                ModifiedBy: this.props.user.UserName,
                ModifiedDate: moment.utc().format(),
            });
        });

        this.props.editConfigurations(formData, (res: any) => {
            if (res) {
                this.setState({
                    show: true,
                });

                setTimeout(() => {
                    this.setState({
                        show: false,
                    });
                    this.props.getConfigurations((res: any) => {});
                }, 3000);
            } else {
                this.setState({
                    showErr: true,
                });
                setTimeout(() => {
                    this.setState({
                        showErr: false,
                    });
                }, 3000);
            }
        });
    };

    showData = () => {
        return (
            <ConfigurationViews
                data={this.props.configurationData}
                handleSubmitData={this.handleSubmitData}
                page={'Page Three'}
            />
        );
    };

    render() {
        return (
            <div>
                {this.showHeader()}
                <div style={{ padding: theme.spacing(3) }}>
                    {this.props.configurationData &&
                        // this.state.formatedData &&
                        this.showData()}
                    {this.state.show && (
                        <SnackbarCmpt message={'Configuration updated successfully'} severity={'success'} />
                    )}
                    {this.state.showErr && <SnackbarCmpt message={'Could not add configurations'} severity={'error'} />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        configurationData: state.user_reducer.configurationData,
        user: state.user_reducer.user,
        notificationDashboard: state.dashboard_reducer.notificationDashboard,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getConfigurations: (cb: any) => dispatch(getConfigurations(cb)),
        editConfigurations: (formData: any, cb: any) => dispatch(editConfigurations(formData, cb)),
        fetchNotificationDashboard: (page: any, pageSize: any) => dispatch(fetchNotificationDashboard(page, pageSize)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(index);

export default withRouter(connected);
