import * as React from 'react';
import { Grid}  from '@material-ui/core';
import * as ReactDOM from 'react-dom';
import { Editor, EditorTools, ProseMirror } from '@progress/kendo-react-editor';

const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;
const { Bold, Italic, Underline } = EditorTools;

class HTMLPreview extends React.Component {
    state = { editable: false };

    editable = () => this.state.editable;

    onMount = event => {
        const state = event.viewProps.state;
        const plugins = [
            ...state.plugins,
            new Plugin({
                key: new PluginKey('readonly'),
                props: { editable: this.editable },
                filterTransaction: (tr, _st) => this.editable() || !tr.docChanged,
            }),
        ];

        return new EditorView(
            { mount: event.dom },
            {
                ...event.viewProps,
                state: EditorState.create({ doc: state.doc, plugins }),
            },
        );
    };

    toggleEditable = () => {
        this.setState(
            state => ({ editable: false }),
            () => {
                if (this.view && this.state.editable) {
                    this.view.updateState(this.view.state);
                }
            },
        );
    };

    render() {
        const { editable } = this.state;
        return (
            <div style={{ padding: '10px' }}>
            <Grid container direction={'row'} >
                {this.props.title && <Grid
                    lg={3}
                    md={3}
                    xl={3}
                    xs={12}
                    spacing={4}
                    style={{
                        textAlign: 'end',
                        fontWeight: 'bold',
                        color: "#4F4F4F",
                        fontSize: '14px',
                    }}
                >
                    {this.props.title}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Grid>}
                <Grid
                    lg={9}
                    md={9}
                    xl={9}
                    xs={12}
                    style={{
                        fontSize: '14px',
                        color: "#333333"
                    }}
                >
                    <Editor contentStyle={{ height: 300 }} onMount={this.onMount} defaultContent={this.props.data} />
                </Grid>
            </Grid>
        </div>
        );
    }
}

export default HTMLPreview;