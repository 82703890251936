import React from 'react';
import { Grid , Checkbox} from '@material-ui/core';
import moment from 'moment-timezone';
import theme from '../../../theme';
import { returnUTCFormatedDate } from "../../../utils";
import { TIME_ZONE,  TIME_ZONE_LABEL } from '../../../config';

function ShowValue(layout: any, value: any) {
    let returnValue: any = value
    if (layout == 'Date' && value) {
            returnValue = moment(returnUTCFormatedDate(value)).tz(TIME_ZONE).format('DD-MMMM-YYYY  h:mm:ss A') + " " +TIME_ZONE_LABEL;
    } else if (layout == 'Checkbox') {
            returnValue =  <Checkbox style={{marginTop: "-11px"}} disabled checked={value} />
    }if(value && typeof(value)!=="string" && (layout !== 'Checkbox' && layout !== 'Date')){
        returnValue = returnValue.toString()
    }
    return returnValue;
}

function PlainData(props: any) {
    return (
        <div style={{ padding: '10px' }}>
            <Grid container direction={'row'} >
                <Grid
                    lg={3}
                    md={3}
                    xl={3}
                    xs={12}
                    spacing={4}
                    style={{
                        textAlign: 'end',
                        fontWeight: 'bold',
                        color: "#4F4F4F",
                        fontSize: '14px',
                    }}
                >
                    {props.layout['LABEL_DISPLAY']}
                </Grid>
                <Grid
                    lg={9}
                    md={9}
                    xl={9}
                    xs={12}
                    style={{
                        fontSize: '14px',
                        color: "#333333",
                        paddingLeft:"10px"
                    }}
                >
                    {ShowValue(props.layout['TYPE'], props.data)}
                </Grid>
            </Grid>
        </div>
    );
}

export default PlainData;
