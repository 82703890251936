import theme from '../../theme';
import { Button, Grid, Radio, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TimelineIcon from '@material-ui/icons/Timeline';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import IconButton from '@material-ui/core/IconButton';
import { sortArrayObjectViewSeq } from '../../utils/browse';
import { withRouter } from 'react-router-dom';
import { TreeListTextFilter } from '@progress/kendo-react-treelist';
import TreeHiraricalData from '../KendoUICmpt/TreeHiraricalData';
import Treefile from '../KendoUICmpt/TreeList';
import { containers } from '../../utils/style';
import HTMLPreview from '../HTMLPreview';
import { useDispatch, useSelector } from 'react-redux';
import { resetConfiguration, fetchRedlines } from '../../Services/user';
import RedlinesUI from '../../Containers/RedlinesUI';
import {BUCKET_BASE_URL, ORACLE_CLOUD_BASE_URL} from '../../config';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '4px 4px',
            display: 'flex',
            // alignItems: 'center',
            width: '100%',
            borderColor: 'red',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

const ConfigurationViews = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [formState, setFormState]: any = useState({
        edit: false,
        editData: {},
        open: {},
    });

    const returnFormatedText = (text: any) => {
        return (
            <Typography variant={'h5'} style={{ marginTop: '8px', marginBottom: '8px' }}>
                {text}
            </Typography>
        );
    };

    const handleChange = (event: any) => {
        let oldState = { ...formState };
        let newState = {
            ...formState,
            editData: {
                ...oldState.editData,
                [event.target.name]: event.target.value,
            },
            edit: true,
        };
        setFormState(newState);
    };

    const handleToggle = (event: any) => {
        let oldState = { ...formState };
        let newState = {
            ...formState,
            editData: {
                ...oldState.editData,
                [event.target.name]: event.target.checked ? 'true' : 'false',
            },
            edit: true,
        };
        setFormState(newState);
    };

    const handleAction = () => {
        confirmAlert({
            message: 'Are you sure you want to save changes?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let editData = { ...formState.editData };
                        props.handleSubmitData(editData);
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return false;
                    },
                },
            ],
        });
    };

    const discard = () => {
        confirmAlert({
            //   title: "Confirm to discard changes",
            message: 'Are you sure you want to discard canges?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let formData = { ...formState };
                        setFormState({
                            edit: false,
                            editData: {},
                            open: formData.open,
                        });
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return false;
                    },
                },
            ],
        });
    };

    const showRedlines = (event: any, data: any) => {
        // let classType = "Changes" // data.dataItem['CLASS_TYPE'];
        // let className = data.dataItem['CLASS_NAME'];
        let itemId = data.dataItem.ID_NUMBER;
        let rev = data.dataItem.REV;
        let { productId, classType, className } = props.match.params;

        dispatch(fetchRedlines(classType, className, productId, itemId, rev));
    };

    const downloadFile = (event: any, data: any) => {
        let path = data.dataItem['FILE_PATH'];
        if(data.dataItem['DID']){
            let val =  `${ORACLE_CLOUD_BASE_URL}/fscmUI/content/conn/FusionAppsContentRepository/uuid/dDocID:${data.dataItem['DID']}?download`;
            window.open(`${val}`);
        }
        else if (path.includes('http')) {
            window.open(`${path}`);
        } else {
            window.open(`${BUCKET_BASE_URL}${path}`);
        }
    };

    const showDetails = (event: any, data: any) => {
        let classType = data.dataItem['CLASS_TYPE'];
        let className = data.dataItem['CLASS_NAME'];

        if (!classType || !className) {
            if (data.dataItem.tableData && data.dataItem.tableData.length > 1) {
            }
        }

        let id = data.dataItem.ID_NUMBER;

        let url = `/Client/details/${classType}/${className}/${id}`;
        let rev = data.dataItem.REV;
        let guid = data.dataItem.GUID;

        if (rev) {
            url = url + `/${rev}`;
        } else {
            url = url + `/1`;
        }

        if (guid) {
            url = url + `/${guid}`;
        }

        // dispatch(resetConfiguration() );
        //  dispatch(getConfigurations(classType, className, rev,id))
        props.history.push(url);
    };

    const textFieldData = (dataArr: any, type: any, attribute: any) => {
        let TableTitle: any;
        let control = type;
        let columns: any = { [`${attribute}`]: [] };
        for (let index in props.layout) {
            if (props.layout[index].HEADERS && (control == 'Row Data' || control == 'Tree Data')) {
                sortArrayObjectViewSeq(props.layout[index].HEADERS);
                TableTitle = props.layout[index].LABEL_DISPLAY;

                for (let each in props.layout[index].HEADERS)
                    if (
                        props.layout[index].HEADERS[each].LABEL_DISPLAY &&
                        props.layout[index].HEADERS[each].TYPE != 'Hidden' &&
                        (props.layout[index].LABEL_DISPLAY === attribute || attribute === 'BOM')
                    ) {
                        if (props.layout[index].HEADERS[each].ATTRIBUTE_NAME == 'ID_NUMBER' && control == 'Tree Data') {
                            columns[`${attribute}`].push({
                                title: `${props.layout[index].HEADERS[each].LABEL_DISPLAY}`,
                                field: `${props.layout[index].HEADERS[each].ATTRIBUTE_NAME}`,
                                filter: TreeListTextFilter,
                                cell: (rowData: any) => (
                                    <Tooltip title="Open details">
                                        <td>
                                            <span
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                onClick={(e: any) => {
                                                    showDetails(e, rowData);
                                                }}
                                            >
                                                {rowData.dataItem[rowData.field]}
                                            </span>
                                        </td>
                                    </Tooltip>
                                ),
                            });
                        } else if (
                            props.layout[index].TAB == 'Affected Items' &&
                            props.layout[index].HEADERS[each].ATTRIBUTE_NAME == 'HAS_BEEN_REDLINED'
                        ) {
                            columns[`${attribute}`].push({
                                title: `${props.layout[index].HEADERS[each].LABEL_DISPLAY}`,
                                field: `${props.layout[index].HEADERS[each].ATTRIBUTE_NAME}`,
                                filter: TreeListTextFilter,
                                cell: (rowData: any) =>
                                    rowData.dataItem[rowData.field] ? (
                                        <Tooltip title="Open redlines">
                                            <td>
                                                <IconButton
                                                    style={{ width: '100%' }}
                                                    onClick={(e: any) => {
                                                        showRedlines(e, rowData);
                                                    }}
                                                >
                                                    <TimelineIcon style={{ color: 'red' }} />
                                                </IconButton>
                                            </td>
                                        </Tooltip>
                                    ) : (
                                        <div></div>
                                    ),
                            });
                        } else if (
                            props.layout[index].TAB == 'Attachments' &&
                            props.layout[index].HEADERS[each].ATTRIBUTE_NAME == 'FILE_PATH'
                        ) {
                            columns[`${attribute}`].push({
                                title: `${props.layout[index].HEADERS[each].LABEL_DISPLAY}`,
                                field: `${props.layout[index].HEADERS[each].ATTRIBUTE_NAME}`,
                                filter: TreeListTextFilter,
                                cell: (rowData: any) =>
                                    rowData.dataItem[rowData.field] || true ? (
                                        <Tooltip title="Download">
                                            <td>
                                                <IconButton
                                                    style={{ width: '100%' }}
                                                    onClick={(e: any) => {
                                                        downloadFile(e, rowData);
                                                    }}
                                                >
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            </td>
                                        </Tooltip>
                                    ) : (
                                        <div></div>
                                    ),
                            });
                        } else {
                            columns[`${attribute}`].push({
                                title: props.layout[index].HEADERS[each].LABEL_DISPLAY,
                                field: props.layout[index].HEADERS[each].ATTRIBUTE_NAME,
                                filter: TreeListTextFilter,
                            });
                        }
                    }
            }

            // if( props.layout[index].HEADERS && ( control == "Tree Data")){
            //    for (let each in props.layout[index].HEADERS)
            //  columns.push({title: props.layout[index].HEADERS[each].ATTRIBUTE_NAME, field: props.layout[index].HEADERS[each].ATTRIBUTE_NAME})
            // }
        }
        //console.log(dataArr[attribute],"COLUMNS",columns)

        let possibleValues: any = '';

        switch (control) {
            case 'Row Data':
                return <Treefile tableData={dataArr[attribute]} columns={columns[`${attribute}`]} />;
                break;
            case 'Tree Data':
                if (dataArr[attribute]) {
                    return [
                        returnFormatedText(TableTitle),
                        <TreeHiraricalData
                            title={''}
                            columns={columns[`${attribute}`]}
                            tableData={dataArr[attribute]}
                        />,
                    ];
                } else if (dataArr.Changes[attribute]) {
                    return (
                        <div style={{ padding: '12px', background: '#ffffff' }}>
                            {returnFormatedText(attribute)}
                            <TreeHiraricalData
                                title={''}
                                columns={columns[`${attribute}`]}
                                tableData={dataArr.Changes[attribute]}
                            />
                        </div>
                    );
                }
                break;
            case 'HTML':
                return (
                    <div>
                        <HTMLPreview data={dataArr[attribute]} />
                    </div>
                );
                break;
            default:
                return (
                    <Grid xs={12}>
                        <Typography variant="body1"> {dataArr[attribute]}</Typography>
                    </Grid>
                );
                break;
        }
    };

    const returnBodyText = (text: any) => {
        return (
            <div>
                <Typography
                    variant={'h5'}
                    style={{
                        color: theme.palette.primary.main,
                        marginBottom: 'auto',
                        marginTop: 'auto',
                    }}
                >
                    {text} :
                </Typography>

                {/* // <Typography variant={"body2"}>{text}</Typography> */}
            </div>
        );
    };

    const returnHeaderText = (text: any) => {
        return (
            <div>
                <Typography
                    variant={'h4'}
                    style={{
                        color: theme.palette.primary.main,
                        marginBottom: 'auto',
                        marginTop: 'auto',
                    }}
                >
                    {text}
                </Typography>
            </div>
        );
    };

    const showPlainData = (dataObject: any, type: any, data: any) => {
        return (
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid sm={4} md={4}>
                    {returnBodyText(dataObject.LABEL_DISPLAY)}
                </Grid>
                <Grid sm={8} md={8}>
                    {textFieldData(data, type, dataObject.LABEL_DISPLAY)}
                </Grid>
            </Grid>
        );
    };

    const showRedlinesUI = () => {
        return (
            <div style={{ marginTop: '16px' }}>
                <RedlinesUI />
            </div>
        );
    };

    const showFormatedLine = (dataObject: any, data: any) => {
        let type = dataObject.TYPE;

        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                // style={{
                //   background: index % 2 == 1 ? "#fff" : "#f9f9f9",
                //   padding: "8px",
                //   minHeight: "50px",
                // }}
            >
                {type == 'Row Data' || type == 'Tree Data' ? (
                    <Grid sm={12} md={12}>
                        {/* <div style={{ padding: "8px" }}>
            <Chip
              id={dataObject["attribute_name"]}
              color={"primary"}
              size={"medium"}
              style={{ fontSize: "16px" }}
              label={`${dataObject["LABEL_DISPLAY"]}`}
              variant={"outlined"}
            />
          </div> */}
                        {textFieldData(
                            data,
                            type,
                            dataObject.ATTRIBUTE_NAME === 'BOM' ? dataObject.ATTRIBUTE_NAME : dataObject.LABEL_DISPLAY,
                        )}
                        {dataObject.TAB == 'Affected Items' && showRedlinesUI()}
                    </Grid>
                ) : (
                    // dataObject.LABEL_DISPLAY && dataObject.DISPLAY_SEQ? showPlainData(dataObject,type,data):null)}
                    [
                        dataObject.TYPE != 'Hidden' && (
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid sm={3} md={3} style={{ paddingRight: '18px', textAlign: 'right' }}>
                                    {returnBodyText(dataObject.LABEL_DISPLAY)}
                                </Grid>
                                <Grid sm={9} md={9}>
                                    {textFieldData(data, type, dataObject.ATTRIBUTE_NAME)}
                                    {/*<Divider style={{backgroundColor:"#8080801a", width:"100%", margin:theme.spacing(0.5)}}/>*/}
                                </Grid>
                            </Grid>
                        ),
                        <Divider style={{ backgroundColor: '#8080801a', width: '100%', margin: theme.spacing(0.5) }} />,
                    ]
                )}
            </Grid>
        );
    };

    const showSubGroup = (key: any, IndivisualRecord: any) => {
        return (
            <div>
                {key != '' && (
                    <div style={{ padding: '8px' }}>
                        <Chip
                            id={key}
                            color={'primary'}
                            size={'medium'}
                            style={{ fontSize: '16px' }}
                            label={`${key}`}
                            variant={'outlined'}
                        />
                    </div>
                )}

                <Divider style={{ marginTop: 2, marginBottom: 2, color: 'grey' }} />
                <div style={{ margin: '8px' }}>
                    {IndivisualRecord.map((val: any, indexval: any) => {
                        return <div style={{ marginTop: '8px' }}>{showFormatedLine(val.Data, props.data)}</div>;
                    })}
                </div>
            </div>
        );
    };

    const LoppOverSubgroupData = (Key: any, Data: any) => {
        let returnUI: any = [];

        let GroupData: any = { ...Data };
        //delete GroupData["Tab"];
        //delete GroupData["Display Order"];
        //Object.entries(GroupData).forEach(([key, value]) => {
        if (Key == 'subClass') {
            return '';
        } else {
            returnUI.push(showSubGroup(Key, Data));
            //  });

            return returnUI;
        }
    };

    let LoadedUI: any = [];
    const showHeader = () => {
        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                // style={{
                //   background: index % 2 == 1 ? "#fff" : "#f9f9f9",
                //   padding: "8px",
                //   minHeight: "50px",
                // }}
            >
                <Grid sm={3} md={3}>
                    {returnHeaderText('Attribute Name')}
                </Grid>
                <Grid sm={3} md={3}>
                    {returnHeaderText('Attribute Value')}
                    {/* {textFieldData("Attribute Value")} */}
                </Grid>
                <Grid sm={3} md={3}>
                    {returnHeaderText('Description')}
                    {/* {returnBodyText("Description")} */}
                </Grid>
                <Grid sm={3} md={3}>
                    {returnHeaderText('API Name')}
                </Grid>
            </Grid>
        );
    };

    const showHeading = (value: any, data: any) => {
        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                onClick={() => {
                    let formData = { ...formState };
                    setFormState({
                        ...formState,
                        open: {
                            ...formData.open,
                            [value]: !formState.open[value],
                        },
                    });
                }}
                style={{
                    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
                    height: '60px',
                    padding: '8px',
                    cursor: 'pointer',
                }}
            >
                <Grid>
                    <div>{formState.open[value] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
                </Grid>
                <Grid></Grid>
                <Typography
                    variant={'h6'}
                    style={{
                        color: theme.palette.primary.main,
                        marginBottom: 'auto',
                        marginTop: 'auto',
                    }}
                >
                    {value}
                </Typography>
            </Grid>
        );
    };

    return (
        <div style={{ marginTop: '8px' }}>
            {props.subClass
                ? Object.keys(props.layout).map((index: any, value: any) => {
                      LoadedUI.push(
                          <div style={{ marginTop: '8px' }}>
                              <div style={{ backgroundColor: '#fff' }}>
                                  {LoppOverSubgroupData(index, props.layout[index])}
                                  <Divider style={{ marginTop: 2, marginBottom: 2, color: 'grey' }} />
                              </div>
                          </div>,
                      );
                  })
                : props.layout.map((value: any, index: any) => {
                      {
                          LoadedUI.push(
                              <div style={{ marginTop: '8px' }}>
                                  <div>{showFormatedLine(value, props.data)}</div>
                              </div>,
                          );
                      }
                  })}
            <div>{LoadedUI}</div>
        </div>
    );
};

export default withRouter(ConfigurationViews);
