import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchCategories, setInActiveCategory, setNewPageSizeCategories } from '../../Services/categories';
import Loader from '../../components/Loader';
import { Grid, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { containers } from '../../utils/style';
import CustomizedInputBase from '../../components/SearchBar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircle';
import {
    ADD_CATEGORIES,
    checkPermissionForCategories,
    DELETE_CATEGORIES,
    EDIT_CATEGORIES,
} from '../../utils/categories';
import CategoryBox from '../../components/Categories/CategoryBox';
import SnackbarCmpt from '../../components/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { userFilter } from '../../utils/users';
//import { clearReduxCourse } from "../../Services/courses";
import theme from '../../theme';
import { fetchNotificationDashboard } from '../../Services/dashboard';
import CustomPagination from '../../components/CustomPagination';
import NoDataFound from '../../components/Messages/NoDataFound';

interface props {
    fetchCategories: any;
    setNewPageSizeCategories: any;
    pageSizeCategories: any;
    views: any;
    history: any;
    allCategories: any;
    processing: any;
    total: any;
    setInActiveCategory: any;
    clearReduxCourse: any;
    notificationDashboard: any;
    fetchNotificationDashboard: any;
}

class Index extends Component<props> {
    state = {
        page: 1,
        searchText: '',
        status: 'Active',
        expand: '',
        deleteSuccess: false,
        deleteError: false,
    };

    componentDidMount(): void {
        this.props.fetchCategories(this.state.page, '&IsActive=1');
        this.props.clearReduxCourse();
        this.props.fetchNotificationDashboard(1, 10);
    }

    addCategory = () => {
        this.props.history.push('/categories/add');
    };

    handleDetails = (data: any) => {
        this.props.history.push(`/categories/details?${data.ID}`);
    };

    handleEdit = (data: any) => {
        this.props.history.push(`/categories/edit?${data.ID}`);
    };

    handleDelete = (data: any) => {
        this.props.setInActiveCategory(data.ID, (res: any) => {
            if (res) {
                this.setState({
                    deleteSuccess: true,
                });
            } else {
                this.setState({
                    deleteError: true,
                });
            }
            this.resetState();
        });
    };

    resetState = () => {
        setTimeout(() => {
            this.setState({
                deleteSuccess: false,
                deleteError: false,
            });
        }, 1000);
    };

    handleSearch = (data: any) => {
        this.setState({
            searchText: data,
        });
        let newExpand = userFilter(this.state.status);
        if (data !== '') {
            this.props.fetchCategories(null, `Name=${data}${newExpand}`);
        } else {
            this.props.fetchCategories(this.state.page, `${this.state.expand}${newExpand}`);
        }
    };

    handlePage = (value: any) => {
        let newExpand = userFilter(this.state.status);
        this.setState({
            page: parseInt(value),
        });
        this.props.fetchCategories(value, `${this.state.expand}${newExpand}`);
    };

    handleChange = (event: any) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
        let newExpand = userFilter(event.target.value);
        let searchVal = '';
        if (this.state.searchText !== '') {
            searchVal = `&Name=${this.state.searchText}`;
        }
        this.props.fetchCategories(this.state.page, this.state.expand + newExpand + searchVal);
    };

    handleNewPageSize = (event: any) => {
        this.props.setNewPageSizeCategories(event.target.value);
    };

    showHeader = () => {
        return (
            <Grid container style={containers}>
                <Grid
                    sm={12}
                    md={9}
                    style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h3">Categories</Typography>
                </Grid>

                <Grid sm={12} md={3} style={{ textAlign: 'right' }}>
                    <Button
                        disabled={!checkPermissionForCategories(this.props.views).includes(ADD_CATEGORIES)}
                        variant="contained"
                        color="primary"
                        style={{
                            flex: 1,
                            height: '95%',
                        }}
                        startIcon={<AddIcon />}
                        onClick={this.addCategory}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        );
    };

    showFiltersInHeader = () => {
        return (
            <Grid container direction={'row'} style={{ margin: theme.spacing(3) }}>
                <Grid container direction="column" justify="center" alignItems="center" xs={12} sm={12} md={6} lg={3}>
                    <CustomizedInputBase searchValue={this.state.searchText} handleSearch={this.handleSearch} />
                </Grid>
                <Grid container direction="column" justify="center" alignItems="center" xs={12} sm={12} md={6} lg={3}>
                    <FormControl style={{ width: '98%' }} variant="outlined">
                        <InputLabel style={{ backgroundColor: '#ffffff' }} id="demo-simple-select-outlined-label">
                            Status
                        </InputLabel>
                        <Select
                            name="status"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.status}
                            onChange={this.handleChange}
                            style={{ backgroundColor: '#ffffff' }}
                        >
                            <MenuItem value={'Active'}>Active</MenuItem>
                            <MenuItem value={'InActive'}>Inactive</MenuItem>
                            <MenuItem value={'All'}>All</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    };

    showItems = () => {
        return (
            <Grid style={{ marginTop: '0px', minHeight: '600px' }}>
                <Grid container>
                    {this.props.allCategories.map((data: any) => {
                        return (
                            <Grid xs={12} sm={6} md={4} lg={3} xl={3} style={{ padding: theme.spacing(2) }}>
                                <CategoryBox
                                    data={data}
                                    deletePerm={checkPermissionForCategories(this.props.views).includes(
                                        DELETE_CATEGORIES,
                                    )}
                                    // editPerm={checkPermissionForCategories(
                                    //   this.props.views
                                    // ).includes(EDIT_CATEGORIES)}
                                    handleDetails={this.handleDetails}
                                    handleDelete={this.handleDelete}
                                    // handleEdit={this.handleEdit}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        );
    };

    render() {
        return (
            <Grid container>
                {this.showHeader()}
                {this.showFiltersInHeader()}
                {this.props.processing && <Loader />}
                {this.props.allCategories && this.props.allCategories.length ? (
                    <div style={{ width: '100%' }}>
                        <Grid xs={12}>{this.showItems()}</Grid>
                        <div style={{ margin: theme.spacing(3) }}>
                            <CustomPagination
                                total={this.props.total}
                                page={this.state.page}
                                handlePage={this.handlePage}
                                pageSize={this.props.pageSizeCategories}
                                handleNewPageSize={this.handleNewPageSize}
                            />
                        </div>
                        {this.state.deleteSuccess && (
                            <SnackbarCmpt message={'Set Category status successfully'} severity={'success'} />
                        )}
                        {this.state.deleteError && (
                            <SnackbarCmpt message={'Could not Set Inactive'} severity={'error'} />
                        )}
                    </div>
                ) : (
                    !this.props.processing && (
                        <div style={{ width: '100%' }}>
                            <NoDataFound title={'No Categories Found'} />
                        </div>
                    )
                )}
            </Grid>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        allCategories: state.categories_reducer.allCategories,
        processing: state.categories_reducer.processing,
        total: state.categories_reducer.total,
        error: state.categories_reducer.error,
        views: state.user_reducer.views,
        notificationDashboard: state.dashboard_reducer.notificationDashboard,
        pageSizeCategories: state.categories_reducer.pageSizeCategories,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setInActiveCategory: (id: any, cb: any) => dispatch(setInActiveCategory(id, cb)),
        fetchCategories: (page: any, expand: any) => dispatch(fetchCategories(page, expand)),
        //clearReduxCourse: () => dispatch(clearReduxCourse()),
        fetchNotificationDashboard: (page: any, pageSize: any) => dispatch(fetchNotificationDashboard(page, pageSize)),
        setNewPageSizeCategories: (event: any) => dispatch(setNewPageSizeCategories(event)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
