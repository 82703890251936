import React from 'react';
import SignalCellularNoSimIcon from '@material-ui/icons/SignalCellularNoSim';
const ErrorComponent = (props: any) => {
    return (
        <div style={{ padding: '120px', textAlign: 'center', color: '#8ea0c7' }}>
            <h1>{props.text}</h1>
            <div style={{ marginTop: '10px' }}>
                <SignalCellularNoSimIcon style={{ height: 100, width: 100 }} />
            </div>
        </div>
    );
};

export default ErrorComponent;
