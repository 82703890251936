import {
    GET_BOOKMARKS_DATA,
    SET_BOOKMARKS_DATA,
    SET_PROCESSING,
    GET_SAVED_SEARCH_DATA,
    SET_SAVED_SEARCH_DATA,
    SET_SAVED_SEARCH_PROCESSING,
    SET_TOTAL,
    SET_FAVORITES_ERROR,
    SET_FVAOURITES_MODAL_STATE,
    SET_ADVANCE_SEARCH_STRING,
    SET_CURRENT_TAB_SEARCH,
    SET_BOOKMARKS_RECORD_PAGE_SIZE,
    SET_BOOKMARKS_RECORD_TOTAL,
    SET_SEARCH_RECORD_PAGE_SIZE,
    SET_SEARCH_RECORD_TOTAL,
    SET_BOOKMARKS_PAGE,
    SET_SEARCH_PAGE,
} from '../action-types/favorites-action-types';

export const getBookmarksData = (data: any) => {
    return {
        type: GET_BOOKMARKS_DATA,
        payload: data,
    };
};

export const getSavedSearchData = (data: any) => {
    return {
        type: GET_SAVED_SEARCH_DATA,
        payload: data,
    };
};

export const setBookmarksData = (data: any) => {
    return {
        type: SET_BOOKMARKS_DATA,
        payload: data,
    };
};

export const setSavedSearchData = (data: any) => {
    return {
        type: SET_SAVED_SEARCH_DATA,
        payload: data,
    };
};

export const setProcessing = (flag: any) => {
    return {
        type: SET_PROCESSING,
        payload: flag,
    };
};

export const setSavedSearchProcessing = (flag: any) => {
    return {
        type: SET_SAVED_SEARCH_PROCESSING,
        payload: flag,
    };
};

export const setTotal = (data: any) => {
    return {
        type: SET_TOTAL,
        payload: data,
    };
};

export const getError = (error: any) => {
    return {
        type: SET_FAVORITES_ERROR,
        payload: error,
    };
};

export const setFavouritesModalState = (error: any) => {
    return {
        type: SET_FVAOURITES_MODAL_STATE,
        payload: error,
    };
};

export const setAdvanceSearchString = (data: any) => {
    return {
        type: SET_ADVANCE_SEARCH_STRING,
        payload: data,
    };
};

export const setCurrentTabInSearch = (data: any) => {
    return {
        type: SET_CURRENT_TAB_SEARCH,
        payload: data,
    };
};

export const setBookmarksTotal = (data: any) => {
    return {
        type: SET_BOOKMARKS_RECORD_TOTAL,
        payload: data,
    };
};

export const setBookmarksPageSize = (data: any) => {
    return {
        type: SET_BOOKMARKS_RECORD_PAGE_SIZE,
        payload: data,
    };
};

export const setSearchesTotal = (data: any) => {
    return {
        type: SET_SEARCH_RECORD_TOTAL,
        payload: data,
    };
};

export const setSearchesPageSize = (data: any) => {
    return {
        type: SET_SEARCH_RECORD_PAGE_SIZE,
        payload: data,
    };
};

export const setCurrentSearchPage = (data: any) => {
    return {
        type: SET_SEARCH_PAGE,
        payload: data,
    };
};

export const setCurentBookmarkPage = (data: any) => {
    return {
        type: SET_BOOKMARKS_PAGE,
        payload: data,
    };
};
