import axios from '../utils/axios';
import { setCurrentUserData } from '../actions/user';
import {fetchRoles} from "./userManagement";
 import {returnRole} from "../utils/ItemFormHelpers";


export const AuthenticateUser = (data: any, cb: any) => {
    return (dispatch: any) => {
        let url = `/api/auth/login`;
        axios.post(url, data).then(response => {
            if (response.data.isAuthenticated) {
                dispatch(setCurrentUserData(response.data.user));
                dispatch(fetchRoles((res:any)=>{
                    if(res){
                        cb(response.data.user);
                    }
                }))
            } else {
                cb(false);
            }
        });
    };
};


export const AuthorizeUser = (cb:any) => {
    return (dispatch: any) => {
        let url = `/api/auth/CurrentUser`;
        axios.get(url).then(response => {

            dispatch(fetchRoles((res:any)=>{
            }))
            if (response.data.success) {
                localStorage.setItem('isAuthenticated', 'true');
                dispatch(setCurrentUserData(response.data.user));
                cb(response.data.user)
            } else {
                dispatch(setCurrentUserData(null));
                cb(false)
            }
        });
    };
};
