export const GET_CLASS_PERMISSION = 'GET_CLASS_PERMISSION';
export const GET_TABS_PERMISSION = 'GET_TABS_PERMISSION';
export const SET_PERMISSION_ERROR = 'SET_PERMISSION_ERROR';
export const GET_USERGROUP_CLASS = 'GET_USERGROUP_CLASS';
export const SET_SELECTED_USERGROUP = 'SET_SELECTED_USERGROUP';
export const SET_CLASS_EDIT = 'SET_CLASS_EDIT';
export const GET_CLASSES = 'GET_CLASSES';
export const GET_TABS = 'GET_TABS';
export const SET_TABS_EDIT = 'SET_TABS_EDIT';
export const SET_PAGE_SIZE_CLASS = 'SET_PAGE_SIZE_CLASS';
export const SET_PAGE_SIZE_TABS = 'SET_PAGE_SIZE_TABS';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_CLASS_PROCESSING = 'SET_CLASS_PROCESSING';
export const SET_TABS_PROCESSING = 'SET_TABS_PROCESSING';
