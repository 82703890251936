import React, { useLayoutEffect } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';

import { RouteWithLayout } from '../components';
import { BasePath } from '../config';
import { Main as MainLayout, Minimal as MinimalLayout } from '../layouts';

import {
    SignIn as SignInView,
    ItemTabsForm,
    Browse,
    Search,
    UserManagement,
    Usergroup,
    Permission,
    Favorites,
    Configurations,
    Suppliers
} from '../views';

const Routes = () => {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Switch>
            <Redirect key={'Redirect'} exact from={`/`} to={`${BasePath}/sign-in`} />
            <RouteWithLayout
                //@ts-ignore
                component={ItemTabsForm}
                exact
                layout={MainLayout}
                path={[
                    BasePath + '/item/:classType/:className/:productId/:rev/:guid',
                    BasePath + '/item/:classType/:className/:productId/:rev',
                    BasePath + '/item/:classType/:className/:productId/',
                ]}
                key={'ItemTabsForm'}
            />

            <RouteWithLayout
                //@ts-ignore
                component={Browse}
                exact
                layout={MainLayout}
                path={[BasePath + '/browse']}
                // "/dashboard/approve-course",
                // "/dashboard/approve-training",
                key={'browse'}
            />



            <RouteWithLayout
                //@ts-ignore
                component={Search}
                exact
                layout={MainLayout}
                path={`${BasePath}/search`}
                // "/dashboard/approve-course",
                // "/dashboard/approve-training",

                key={'search'}
            />

            <RouteWithLayout
                //@ts-ignore
                component={UserManagement}
                exact
                layout={MainLayout}
                path={[
                    BasePath + '/userManagement',
                    BasePath + '/userManagement/Add',
                    BasePath + '/userManagement/Edit',
                ]}
                key={'userManagement'}
            />

            <RouteWithLayout
                //@ts-ignore
                component={Usergroup}
                exact
                layout={MainLayout}
                path={[BasePath + '/userGroup', BasePath + '/userGroup/add', BasePath + '/userGroup/edit']}
                key={'userGroup'}
            />

            <RouteWithLayout
                //@ts-ignore
                component={Permission}
                exact
                layout={MainLayout}
                path={[
                    BasePath + '/permission',
                    BasePath + '/permission/class-add',
                    BasePath + '/permission/class-edit',
                    BasePath + '/permission/tabs-add',
                    BasePath + '/permission/tabs-edit',
                    BasePath + '/rule/details/:ruleID',
                    BasePath + '/rule/assign/:ruleID',
                    BasePath + '/rule/edit/:ruleID',
                    BasePath + '/rule/add',
                ]}
                key={'permission'}
            />
            <RouteWithLayout
                //@ts-ignore
                component={Configurations}
                exact
                layout={MainLayout}
                path={[BasePath + '/configuration']}
                // "/dashboard/approve-course",
                // "/dashboard/approve-training",
                key={'configuration'}
            />

            <RouteWithLayout
                //@ts-ignore
                component={Suppliers}
                exact
                layout={MainLayout}
                path={[BasePath + '/suppliers', BasePath +'/suppliers/addAttachment']}
                // "/dashboard/approve-course",
                // "/dashboard/approve-training",
                key={'suppliers'}
            />


            <RouteWithLayout
                //@ts-ignore
                component={SignInView}
                exact
                layout={MinimalLayout}
                path={`${BasePath}/sign-in`}
                key={'sign-in'}
            />
        </Switch>
    );
};

// @ts-ignore
export default Routes;
