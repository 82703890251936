import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './SideBar.css'

import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import '@progress/kendo-react-intl';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import 'react-router-dom'
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { Grid, Typography, Avatar, Tab, Tooltip, Paper, Box } from "@material-ui/core";
import { provideIconForTab } from "../../utils/IconProvider";
import theme from '../../theme';
import { replaceNames } from '../../utils/ItemFormHelpers';

const data = ['top', 'bottom', 'left', 'right']

const App = (props: any) => {
    const [selected, setSelected] = React.useState<number>(0);
    const [position, setPosition] = React.useState<string>('left');

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
        props.handleChangeTab(props.tabs[e.selected]);
    };

    const handleChange = (e: DropDownListChangeEvent) => {
        setPosition(e.value);
    };

    const tabTitle = (tab: any) => {
        return <div>
            <Tooltip title={replaceNames(tab)}><Grid

                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ opacity: tab == props.tabs[selected] ? "1" : "0.6", 
            }}
            >
                <Grid xs={12} md={12} justify="center" alignItems="center" style={{ maxWidth: "35px" }}>
                    <Avatar src={provideIconForTab(tab)} style={{ borderRadius: "10%", maxWidth: "35px", maxHeight: "35px" }} />
                </Grid>
                {/*<Grid xs={12} md={8} justify="center" alignItems="center"  style={{padding:"8px 8px 8px 10px"}}>*/}
                {/*    <Typography variant="h4" style={{color:"grey"}}>*/}
                {/*        {tab}*/}
                {/*    </Typography>*/}
                {/*</Grid>*/}
            </Grid></Tooltip>
        </div>
    }

    return (
    
            <Box>
                <Paper variant="outlined" square 
                //@ts-ignore
                spacing={4}
                style={{border:"2px solid #D6DFE6"}}>
                    <TabStrip
                        selected={selected}
                        onSelect={handleSelect}
                        tabPosition={position}
                    >
                        {props.tabs.map((tab: any, index: any) => {
                            return (
                                <TabStripTab title={<div>{tabTitle(tab)}</div>} key={index}>
                                    <Grid xs={12}>
                                        <Typography color="primary" style={{ fontWeight: "normal", color: "#0066cc", fontSize: "24px", margin: theme.spacing(1) }}>
                                       { replaceNames(tab) }
                                        </Typography>
                                    </Grid>

                                    {props.children}
                                </TabStripTab>
                            );
                        })}
                    </TabStrip>
                </Paper>
            </Box>
        
    )
}

export default App;

