import {
    SET_ITEM_DATA,
    SET_USERS_ERROR,
    SET_USERS_PROCESSING,
    SET_SEARCHED_USERS,
    GET_ALL_USERS,
    SET_EDIT_USER,
    SET_DEPARTMENTS,
    SET_USER_ROLES,
    SET_VIEWS,
    SET_TOTAL_USERS,
    GET_COURSES_TO_APPROVE,
    GET_TRAININGS_TO_APPROVE,
    SET_CONFIGURATION_DATA,
    SET_EVENT,
    SET_AUDIT_TRAIL_DATA_PROCESSING,
    SET_AUDIT_TRAIL_DATA,
    SET_AUDIT_TOTAL,
    SET_MODULES,
    SET_CONFIGURATION_TABS_STATE,
    SET_PAGE_SIZE_USER,
    SET_SS_ERROR_MSG,
    SET_SS_ERROR_FLAG,
    SET_USER,
    LOADING_DATA_FORM,
    SET_REDLINE_PROCESSING,
    SET_REDLINES_CONFIG,
    SET_REDLINES_DATA,
    SET_REEDLINES_CONFIGURATION_TABS_STATE,
    SET_REDLINED_ITEM
} from '../action-types/user-action-types';

const initState = () => {
    return {
        user: null,
        processing: false,
        message: 'Unable to find user data',
        error: null,
        ItemData: null,
        allUsers: [],
        searchedUsers: null,
        editingUser: null,
        RolesArray: null,
        Departments: null,
        views: null,
        totalUsers: null,
        coursesToApprove: null,
        trainingsToApprove: null,
        configurationData: null,
        configurationDataLoading: false,
        events: null,
        modules: null,
        auditTrailProcessing: false,
        auditTrail: null,
        auditTotel: null,
        pageSizeUser: 8,
        serverError: false,
        serverErrorMessage: '',
        configurationTabState: 0,

        redlinesConfigurationData: null,
        redlinesData: null,
        redlineProcessing: false,
        redisConfigurationTabState: 0,
        redlinedItem:null
    };
};

const user_reducer = (state = initState(), action: any) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_USER:
            setData(newState, action.payload, 'user');
            break;
        case SET_ITEM_DATA:
            setData(newState, action.payload, 'ItemData');
            break;
        case SET_VIEWS:
            setData(newState, action.payload, 'views');
            break;
        case GET_ALL_USERS:
            setData(newState, action.payload, 'allUsers');
            break;
        case SET_USERS_ERROR:
            setData(newState, action.payload, 'error');
            break;
        case SET_USERS_PROCESSING:
            setData(newState, action.payload, 'processing');
            break;
        case SET_SEARCHED_USERS:
            setData(newState, action.payload, 'searchedUsers');
            break;
        case SET_EDIT_USER:
            setData(newState, action.payload, 'editingUser');
            break;
        case SET_DEPARTMENTS:
            setData(newState, action.payload, 'Departments');
            break;
        case SET_CONFIGURATION_DATA:
            setData(newState, action.payload, 'configurationData');
            break;

        case LOADING_DATA_FORM:
            setData(newState, action.payload, 'configurationDataLoading');
            break;

        case SET_USER_ROLES:
            setData(newState, action.payload, 'RolesArray');
            break;
        case SET_TOTAL_USERS:
            setData(newState, action.payload, 'totalUsers');
            break;

        case GET_COURSES_TO_APPROVE:
            setData(newState, action.payload, 'coursesToApprove');
            break;
        case GET_TRAININGS_TO_APPROVE:
            setData(newState, action.payload, 'trainingsToApprove');
            break;

        case SET_EVENT:
            setData(newState, action.payload, 'events');
            break;

        case SET_AUDIT_TRAIL_DATA_PROCESSING:
            setData(newState, action.payload, 'auditTrailProcessing');
            break;
        case SET_AUDIT_TRAIL_DATA:
            setData(newState, action.payload, 'auditTrail');
            break;
        case SET_AUDIT_TOTAL:
            setData(newState, action.payload, 'auditTotel');
            break;
        case SET_MODULES:
            setData(newState, action.payload, 'modules');
            break;
        case SET_PAGE_SIZE_USER:
            setData(newState, action.payload, 'pageSizeUser');
            break;
        case SET_SS_ERROR_FLAG:
            setData(newState, action.payload, 'serverError');
            break;
        case SET_SS_ERROR_MSG:
            setData(newState, action.payload, 'serverErrorMessage');
            break;
        case SET_CONFIGURATION_TABS_STATE:
            setData(newState, action.payload, 'configurationTabState');
            break;

        case SET_REDLINES_DATA:
            setData(newState, action.payload, 'redlinesData');
            break;
        case SET_REDLINES_CONFIG:
            setData(newState, action.payload, 'redlinesConfigurationData');
            break;
        case SET_REDLINE_PROCESSING:
            setData(newState, action.payload, 'redlineProcessing');
            break;

        case SET_REEDLINES_CONFIGURATION_TABS_STATE:
            setData(newState, action.payload, 'redisConfigurationTabState');
            break;
        case SET_REDLINED_ITEM:
            setData(newState, action.payload, 'redlinedItem');
            break;
        default:
            break;
    }

    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default user_reducer;
