import moment from 'moment';

export const setActiveClassTrue = (classes: any, pers: any) => {
    let classData: any = [];
    classes.forEach((user: any) => {
        classData.push({
            ...user,
            ClassActive: 1,
            ClassCreatedBy: 'Tom',
            ClassCreatedDate: moment.utc().format(),
            ClassModifiedBy: 'Tom',
            ClassModifiedDate: moment.utc().format(),
        });
    });

    return classData;
};

export const setStatusForActiveClasses = (oldClassData: any, newClassData: any, user: any) => {
    let resultData: any = [];
    oldClassData.forEach((oldData: any) => {
        if (newClassData.some((classes: any) => classes.class_name === oldData.class_name)) {
            resultData.push({
                ...oldData,
                ClassActive: 1,
                ClassModifiedBy: 'Tom',
                ClassModifiedDate: moment.utc().format(),
            });
        } else {
            resultData.push({
                ...oldData,
                ClassActive: 0,
                ClassModifiedBy: 'Tom',
                ClassModifiedDate: moment.utc().format(),
            });
        }
    });
    newClassData.forEach((newData: any) => {
        if (oldClassData.some((classes: any) => classes.class_name === newData.class_name)) {
        } else {
            resultData.push({
                ...newData,
                ClassActive: 1,
                ClassCreatedBy: 'Tom',
                ClassCreatedDate: moment.utc().format(),
                ClassModifiedBy: 'Tom',
                ClassModifiedDate: moment.utc().format(),
            });
        }
    });

    return resultData;
};

export const setActiveTabsTrue = (tabs: any, pers: any) => {
    let tabsData: any = [];
    tabs.forEach((user: any) => {
        tabsData.push({
            ...user,
            IsActive: 1,
            TabActive: 1,
            TabCreatedBy: 'Tom',
            TabCreatedDate: moment.utc().format(),
            CreatedBy: 'Tom',
            CreatedDate: moment.utc().format(),
        });
    });

    return tabsData;
};

export const setStatusForActiveTabs = (oldTabsData: any, newTabsData: any, user: any) => {
    let resultData: any = [];

    oldTabsData.map((oldData : any)=>{
        if(!newTabsData.find((newData: any) => newData.id === oldData.id))
        {resultData.push({
                         ...oldData,
                         TabActive: 0,
                         ModifiedDate: moment.utc().format(),
                         ModifiedBy: 'Tom',
                         TabModifiedBy: 'Tom',
                         TabModifiedDate: moment.utc().format(),
                     })}}
      )
      
      newTabsData.map((newData : any)=>{
        if(!oldTabsData.find((oldData:any) => oldData.id === newData.id))
        {resultData.push({
            ...newData,
            TabActive: 1,
            ModifiedDate: moment.utc().format(),
            ModifiedBy: 'Tom',
            TabModifiedBy: 'Tom',
            TabModifiedDate: moment.utc().format(),
        })}}
      )
    
    return resultData;
};
