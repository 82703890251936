import React, { Component } from 'react';

import { setPageSizeTab } from '../../../Services/itemForm';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import theme from '../../../theme';
import CustomPagination from '../../../components/CustomPagination';

interface props {
    totalTabData: any;
    pageSizeTab: any;
    fetchClassData: any;
    setPageSizeTab: any;
    searchClassData: any;
    filter: any;
    handlePageResults: any;
    handleRecordSizeChange: any;
}
class Pageing extends Component<props> {
    state = {
        show: false,
        dataColumns: [],
        page: 1,
    };

    handlePage = (value: any) => {
        this.setState({
            page: parseInt(value),
        });

        let pageNumbr = parseInt(value);

        let from = 1;
        let to = 8;
        if (pageNumbr > 1) {
            to = pageNumbr * this.props.pageSizeTab;
            from = (pageNumbr - 1) * this.props.pageSizeTab;
        } else {
            to = this.props.pageSizeTab;
        }

        this.props.handlePageResults(from, to);
    };

    handleNewPageSize = (event: any) => {
        this.setState({
            page: 1,
        });

        this.props.setPageSizeTab(parseInt(event.target.value));
        let pageSizeTab = parseInt(event.target.value);

        let from = 1;
        this.props.handlePageResults(from, pageSizeTab);
    };

    render() {
        return (
            <div>
                <div style={{ marginTop:"20px", marginLeft:"12px",marginBottom:0 }}>
                    <CustomPagination
                        total={this.props.totalTabData}
                        page={this.state.page}
                        handlePage={this.handlePage}
                        pageSize={this.props.pageSizeTab}
                        handleNewPageSize={this.handleNewPageSize}
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        totalTabData: state.itemForm_reducer.totalTabData,
        pageSizeTab: state.itemForm_reducer.pageSizeTab,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setPageSizeTab: (size: any) => dispatch(setPageSizeTab(size)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Pageing);

export default withRouter(connected);
