import React, {useState} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, IconButton } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),
        },
    }),
);

export default function ConfirmationPopover(props: any) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [yesClicked, handleYesClicked] = useState(false);



    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleYes = () => {
        handleYesClicked(true)
        props.handleYes();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton aria-label="Remove" onClick={handleClick}>
                <DeleteIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: '8px', maxWidth: '216px' }}>
                    <Grid container direction={'row'}>
                        <Grid lg={12} md={12} xl={12} xs={12} style={{ marginBottom: '8px' }}>
                            <Typography variant={'body2'} style={{ color: 'red', fontWeight: 'bold' }}>
                                Please confirm if you want to delete
                            </Typography>
                        </Grid>
                        <Grid lg={4} md={4} xl={6} xs={12}>
                            <Button disabled={yesClicked} variant="contained" color="primary" size="small" onClick={handleYes}>
                                Yes
                            </Button>
                        </Grid>
                        <Grid lg={4} md={4} xl={6} xs={12}>
                            <Button variant="contained" color="default" size="small" onClick={handleClose}>
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Popover>
        </div>
    );
}
