import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import GridOnIcon from '@material-ui/icons/GridOn';
import axios from '../../utils/axios';
import { sortArrayObjectViewSeqSearch } from '../../utils/browse';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import SnackbarCmpt from '../Snackbar';
import {filterDataANDLayout, filterAndRearrange, returnUTCFormatedDate} from "../../utils";
import moment from "moment";
import Tooltip, { TooltipProps} from '@material-ui/core/Tooltip';
import * as XLSX from 'xlsx';
interface propsInterface {
    method: any;
    body: any;
    url: any;
    name: any;
    size:any;
}

class Index extends Component<propsInterface> {
    state = {
        headers: [],
        data: [],
        error: false,
        loading: false,
        nameConcated: '',
    };

    csvLinkEl: any = React.createRef();

    callWithGetReqParams = () => {
        axios
            .get(this.props.url)
            .then((response: any) => {
                let responseData = response.data.result;
                 if(this.props.name == 'Simple_Search_Data'){
                       if(responseData && responseData.DATA && responseData.DATA.data){
                           this.formatDataForCSVOnlyData(responseData.DATA.data, '');
                       }
                } else {
                    if (responseData && responseData.DATA && responseData.LAYOUT) {
                        if (responseData.DATA[0]) {
                            this.formatDataForCSV(responseData.DATA, responseData.LAYOUT, '');
                        } else {
                            let changeHistoryLayout =
                                responseData.LAYOUT[0]['ATTRIBUTE_NAME'] == 'CHANGE_HISTORY'
                                    ? responseData.LAYOUT[0]['HEADERS']
                                    : responseData.LAYOUT[1]['HEADERS'];
                            let pendingChangeLayout =
                                responseData.LAYOUT[0]['ATTRIBUTE_NAME'] == 'PENDING_CHANGES'
                                    ? responseData.LAYOUT[0]['HEADERS']
                                    : responseData.LAYOUT[1]['HEADERS'];
                            this.formatDataForCSV(
                                responseData.DATA['Change History'],
                                changeHistoryLayout,
                                'Change History',
                            );

                            setTimeout(() => {
                                this.formatDataForCSV(
                                    responseData.DATA['Pending Changes'],
                                    pendingChangeLayout,
                                    'Pending Changes',
                                );
                            }, 5000);
                        }
                    } else {
                        this.setDataProcessState(false, true);
                    }
                }

            })
            .catch((err: any) => {
                this.setDataProcessState(false, true);
            });
    };

    formatDataForCSV = (rawData: any, layout: any, additionalName?: any) => {
        let data: any = [];

        rawData.forEach((dataValue: any) => {
            let newValue = { ...dataValue };
            for (const property in newValue) {
                console.log(`${property}: ${newValue[property]}`);
                if (typeof newValue[property] == 'string') {
                    newValue[property] = newValue[property].replace(/(\r\n|\n|\r|,)/gm, ' ');
                }
            }
            data.push(newValue);
        });

        let sortedLayout: any = [];

        if (layout && layout[0] && layout[0]['HEADERS']) {
            sortedLayout = sortArrayObjectViewSeqSearch(layout[0]['HEADERS']);
        } else {
            sortedLayout = sortArrayObjectViewSeqSearch(layout);
        }

        let headers: any = [];

        sortedLayout.forEach((data: any, index: any) => {
            if ((data.grid_visible || data.GRID_VISIBLE) && (data.type != 'Hidden' || data.TYPE != 'Hidden')) {
                if (data.label_display) {
                    headers.push({
                        label: `${data.label_display}`,
                        key: `${data.attribute_name}`,
                    });
                } else if (data.LABEL_DISPLAY) {
                    headers.push({
                        label: `${data.LABEL_DISPLAY}`,
                        key: `${data.ATTRIBUTE_NAME}`,
                    });
                }
            }
        });

        this.setState(
            { data: [...data], headers: [...headers], nameConcated: additionalName != '' ? `_${additionalName}` : '' },
            () => {
                setTimeout(() => {
                    console.log('here we have state', this.state);
                    this.csvLinkEl.current.link.click();
                    this.setDataProcessState(false, false);
                });
            },
        );
    };

    formatDataForCSVOnlyData = (rawData: any, additionalName?: any) => {
        let data: any = [];
        let headers: any = [];
        rawData.forEach((dataValue: any) => {
            let newValue = filterAndRearrange({ ...dataValue },true);
            for (const property in newValue) {
                if (typeof newValue[property] == 'string') {
                    newValue[property] = newValue[property].replace(/(\r\n|\n|\r|,)/gm, ' ');
                }
                if(property.toLowerCase().includes('date')){
                    if(newValue[property]){
                    newValue[property] = `${moment(returnUTCFormatedDate(newValue[property])).tz('MST').format('DD-MMMM-YYYY  h:mm:ss A')} MST`
                }}
            }
            data.push(newValue);
        });
        var wb = XLSX.utils.book_new()
        var ws = XLSX.utils.json_to_sheet(data)
        XLSX.utils.book_append_sheet(wb,ws,this.props.name)
        XLSX.writeFile(wb,`${this.props.name}.xlsx`)
        this.setDataProcessState(false, false);
    };

   

    callWithPostReqParams = () => {
        axios
            .post(this.props.url, this.props.body)
            .then((response: any) => {
                let responseData = response.data.result;
                if (responseData && responseData.DATA) {
                    this.formatDataForCSVOnlyData(responseData.DATA, '');
                } else {
                    this.setDataProcessState(false, true);
                }
            })
            .catch((err: any) => {
                this.setDataProcessState(false, true);
            });
    };

    setDataProcessState = (loading: any, error: any) => {
        this.setState({ loading, error });
    };

    exportCSV = () => {
        this.setDataProcessState(true, false);
        switch (this.props.method) {
            case 'get':
                this.callWithGetReqParams();
                break;
            case 'post':
                this.callWithPostReqParams();
                break;
            default:
                break;
        }
    };

    render() {
        return (
            <div style={{marginBottom:"6px"}}>
                <Tooltip title={<span>You will only be able to export first {this.props.size} records, regardless of what page you're on</span>}>
                <Button
                    onClick={this.exportCSV}
                    disabled={this.state.loading}
                    color="secondary"
                    variant="contained"
                    size={'small'}
                    startIcon={this.state.loading ? <HourglassEmptyIcon /> : <GridOnIcon />}
                >
                    {this.state.loading ? 'Exporting Data (takes 1 Min approx)' : `Export ${this.props.size? `First ${this.props.size}` : ""} Records`}
                </Button>
                </Tooltip>
                <CSVLink
                    headers={this.state.headers}
                    filename={`${this.props.name}${this.state.nameConcated}.csv`}
                    data={this.state.data}
                    //@ts-ignore
                    ref={this.csvLinkEl}
                />

                {this.state.error ? <SnackbarCmpt message={`Could not export data`} severity={'error'} /> : null}
            </div>
        );
    }
}

export default Index;
