/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import theme from '../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClassData } from '../../../Services/browse';

export default function SearchBox(props: any) {
    let dataVal = [
        ...props.partClasses,
        ...props.documentClasses,
        ...props.changeClasses,
        ...props.manufacturersClasses,
        ...props.manufacturerPartsClasses,
        ...props.SuppliersClasses,
        ...props.SuppliersItemsClasses,
        ...props.PSRClasses,
    ];
    const dispatch = useDispatch();
    let { pageSizeClassContent } = useSelector((state: any) => ({
        ...state.browse_reducer,
    }));

    const handleClick = (event: any, options: any) => {
        if (options) {
            dispatch(fetchClassData(options['CLASS-TYPE'], options, 0, pageSizeClassContent));
        }
    };

    return (
        <Autocomplete
            id="combo-box-demo"
            options={dataVal}
            //@ts-ignore
            getOptionLabel={option => option['DISPLAY-NAME']}
            style={{ margin: theme.spacing(1) }}
            renderInput={params => <TextField {...params} label="Search class" variant="outlined" />}
            onChange={(event: any, option: any) => handleClick(event, option)}
        />
    );
}
