const Url:any={
    production: 'https://myplm-archive.gosaas.io',
    development:'http://localhost:3000'
    };
    
    export const BasePath='/Client';
    export const ClassTypes=[
    'Parts',
    'Documents',
    'Changes',
    'Manufacturers',
    'Manufacturer_parts'
    
    ];
    
    //"Suppliers","Supplier_Items"'Product_Service_Requests',"Suppliers","Supplier_Items"'Product_Service_Requests',
    //'Quality_Change_Requests'
    //@ts-ignore
    export const SERVER_URL=Url[process.env.NODE_ENV];
    //export const BUCKET_BASE_URL="https://portal-rnh-bucket.us-east-1.linodeobjects.com/"
    export const TOP_BAR_LOGO="/images/logos/myplmlogo.png";
    export const FAV_ICON="/images/logos/rocheLogo.png"
    export const TIME_ZONE="MST";
    export const TIME_ZONE_LABEL="MST";
    export const BUCKET_BASE_URL='https://agilevault.s3.amazonaws.com/';
    export const SUPPLIER_BUCKET=`https://gs-ets.s3.amazonaws.com/`;
    export const ORACLE_CLOUD_BASE_URL=`https://ehxf.fa.us6.oraclecloud.com`;
    export const MULTIPLE_ITEM=true;
    export const TEXT_SEARCH=true;
    export const MANDATORY_NULL_BLANKS=2;
    export const MIN_BLANk_OPTIONS=3;
    export const MIN_DRAG_OPTIONS=3;
    export const MIN_MCQ_OPTIONS=3;
    export const INSTRUCTOR_LED_COURSE='InstructorLedCourse';
    export const SELF_LED_COURSE='SelfLearningCourse';
    export const DAYS_LIMIT_TRAINING_DEADLINE=3;
    export const UIExperince={
    agile:false,
    oraclePD:true
    };
    export const MIN_PAGE_SIZE=50;
    export const MIN_EXPORT_SIMPLE_SEARCH=500;
    export const MIN_EXPORT_ADVANCE_SEARCH=2000;
    export const paginationList=[
    50,100,150,200
    ];
    
    export const SHOW_BROWSE_TAB=false;
    export const DIRECT_TO_CLOUD=true;
    export const DATA_LABELS={
        REVISION: "Rev"
    };
    export const PAGEINATION_ON_TOP=false;
    export const SHOW_GRID_FILTERS=false;
    export const SUPPORT_REDLINES = true;