import {
    COURSE_STATS_PROCESSING,
    SET_COURSE_STATS,
    REVISED_COURSES_PROCESSING,
    SET_REVISED_COURSES,
    REV_AVL_PROCESSING,
    REVISIONS_AVAILABLE,
    OVERDUE_TRAININGS,
    OVERDUE_PROCESSING,
    TRAINING_DEADLINE,
    DEADLINE_PROCESSING,
    DASHBOARD_NOTIFICATIONS,
    NOTIFICATION_PROCESSING,
    PIE_COURSE_STATS_PROCESSING,
    SET_COURSE_PIE_STATS,
} from '../action-types/dashboard-action-types';

export const setCourseStats = (stats: any) => {
    return {
        type: SET_COURSE_STATS,
        payload: stats,
    };
};

export const statsProcessing = (processing: any) => {
    return {
        type: COURSE_STATS_PROCESSING,
        payload: processing,
    };
};

export const setRevisedCourses = (stats: any) => {
    return {
        type: SET_REVISED_COURSES,
        payload: stats,
    };
};

export const reviseCourseProcessing = (processing: any) => {
    return {
        type: REVISED_COURSES_PROCESSING,
        payload: processing,
    };
};

export const setRevisionsAvailable = (stats: any) => {
    return {
        type: REVISIONS_AVAILABLE,
        payload: stats,
    };
};

export const revisionAvailProcessing = (processing: any) => {
    return {
        type: REV_AVL_PROCESSING,
        payload: processing,
    };
};

export const setOverdueTrainings = (stats: any) => {
    return {
        type: OVERDUE_TRAININGS,
        payload: stats,
    };
};

export const setOverdueProcessing = (processing: any) => {
    return {
        type: OVERDUE_PROCESSING,
        payload: processing,
    };
};

export const setDeadlineTrainings = (stats: any) => {
    return {
        type: TRAINING_DEADLINE,
        payload: stats,
    };
};

export const setDeadlineProcessing = (processing: any) => {
    return {
        type: DEADLINE_PROCESSING,
        payload: processing,
    };
};

export const setNotificationDashboard = (stats: any) => {
    return {
        type: DASHBOARD_NOTIFICATIONS,
        payload: stats,
    };
};

export const setNotitficationProcessing = (processing: any) => {
    return {
        type: NOTIFICATION_PROCESSING,
        payload: processing,
    };
};

export const setPieCourseStats = (stats: any) => {
    return {
        type: SET_COURSE_PIE_STATS,
        payload: stats,
    };
};

export const statsPieProcessing = (processing: any) => {
    return {
        type: PIE_COURSE_STATS_PROCESSING,
        payload: processing,
    };
};
