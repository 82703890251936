import {
    setProcessing,
    getError,
    getCategories,
    setTotal,
    setEditionCategory,
    setDetailCategory,
    setCategoryForCourse,
    setTotalCourseCategory,
    setPageSizeCategories,
} from '../actions/categories';
import axios from '../utils/axios';

export const fetchCategories = (page: any, expand: any) => {
    return (dispatch: any, getState: any) => {
        const { pageSizeCategories }: any = getState().categories_reducer;

        dispatch(setProcessing(true));
        dispatch(getCategories(null));
        let url = `/api/categories?${expand}&OrderByDirection=asc&OrderBy=CategoryName`;
        if (page) {
            url = `/api/categories?Page=${page -
                1}&PageSize=${pageSizeCategories}${expand}&OrderByDirection=desc&OrderBy=ModifiedDate`;
        }
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.results) {
                    dispatch(getCategories(response.data.result.results));
                    dispatch(setTotal(response.data.result.total));
                } else {
                    dispatch(getCategories(response.data.result));
                }
                dispatch(setProcessing(false));
            })
            .catch(err => {
                dispatch(setProcessing(false));
                dispatch(getError(err));
            });
    };
};

export const fetchCategoriesForDropDown = (page: any, expand: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setProcessing(true));
        dispatch(getCategories(null));
        let url = `/api/categories?${expand}&OrderByDirection=asc&OrderBy=CategoryName&IsActive=1`;

        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.results) {
                    dispatch(getCategories(response.data.result.results));
                    dispatch(setTotal(response.data.result.total));
                } else {
                    dispatch(getCategories(response.data.result));
                }
                dispatch(setProcessing(false));
            })
            .catch(err => {
                dispatch(setProcessing(false));
                dispatch(getError(err));
            });
    };
};

export const addNewCategory = (data: any, cb: any) => {
    return (dispatch: any) => {
        let url = '/api/categories';
        axios
            .post(url, data)
            .then(response => {
                if (response.data.success) {
                    dispatch(fetchCategories(1, ''));
                    cb(true);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
                dispatch(setProcessing(false));
            });
    };
};

export const setInActiveCategory = (id: any, cb: any) => {
    return (dispatch: any) => {
        let url = `/api/categories/${id}`;
        axios
            .patch(url, { IsActive: '0' })
            .then(response => {
                if (response.data.result && response.data.result.IsActive == '0') {
                    dispatch(fetchCategories(1, ''));
                    cb(true);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const editCategory = (data: any, cb: any) => {
    return (dispatch: any) => {
        let url = `/api/categories/${data['ID']}`;
        delete data['ID'];
        axios
            .patch(url, data)
            .then(response => {
                if (response.data.success) {
                    dispatch(fetchCategories(1, ''));
                    cb(true);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const testCategoryName = (name: any, cb: any) => {
    let url = `/api/categories?CategoryName=${name.CategoryName}`;
    axios
        .get(url)
        .then((response: any) => {
            if (response.data.result && response.data.result.length) {
                if (name.ID) {
                    if (response.data.result.some((cat: any) => cat.ID === name.ID)) {
                        cb(false);
                    } else {
                        cb(true);
                    }
                } else {
                    cb(true);
                }
            } else {
                cb(false);
            }
        })
        .catch(err => {
            cb(false);
        });
};

export const getCategoryForEdition = (id: any) => {
    return (dispatch: any) => {
        dispatch(setProcessing(true));
        dispatch(setEditionCategory(null));
        let url = `/api/categories/${id}`;

        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(setEditionCategory(response.data.result));
                    dispatch(setProcessing(false));
                } else {
                    dispatch(setProcessing(false));
                }
            })
            .catch(err => {
                dispatch(setProcessing(false));
            });
    };
};

export const getCategoryForDetails = (id: any, page: any) => {
    return (dispatch: any, getState: any) => {
        const { pageSizeCategories }: any = getState().categories_reducer;
        dispatch(setProcessing(true));
        dispatch(setDetailCategory(null));
        let url = `/api/categories/${id}?Expand=[courses]&Page=${page - 1}&PageSize=${pageSizeCategories}`;

        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    let data = response.data.result;
                    dispatch(setDetailCategory(data));
                    if (data.courses.results) {
                        dispatch(setTotalCourseCategory(data.courses.total));
                    }
                    dispatch(setProcessing(false));
                } else {
                    dispatch(setProcessing(false));
                }
            })
            .catch(err => {
                dispatch(setProcessing(false));
            });
    };
};

export const setValueForCategory = (data: any) => {
    return (dispatch: any) => {
        dispatch(setCategoryForCourse(data));
    };
};

export const setNewPageSizeCategories = (value: any) => {
    return (dispatch: any) => {
        dispatch(setPageSizeCategories(value));
    };
};
