import React, { Component } from 'react';
import { ADD_CATEGORIES, checkPermissionForCategories, EDIT_CATEGORIES } from '../../utils/categories';
import { connect } from 'react-redux';
import { getCategoryForEdition, editCategory } from '../../Services/categories';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import Loader from '../../components/Loader';
import CategoryForm from '../../components/Categories/CategoryForm';
import SnackbarCmpt from '../../components/Snackbar';
import { Grid } from '@material-ui/core';
import { containers } from '../../utils/style';
import moment from 'moment';

interface propsInterface {
    views: any;
    history: any;
    location: any;
    getCategoryForEdition: any;
    processing: any;
    editingCategory: any;
    editCategory: any;
    user: any;
}

class EditCategories extends Component<propsInterface> {
    state = {
        editSuccess: false,
        editError: false,
    };

    componentDidMount(): void {
        if (this.props.location && this.props.location.search) {
            let id = this.props.location.search.split('?')[1];
            this.props.getCategoryForEdition(id);
        } else {
            this.props.history.push('/not-found');
        }
    }

    handleEdit = (data: any) => {
        let formData = {
            ...data,
            ModifiedBy: this.props.user.UserName,
            ModifiedDate: moment.utc().format(),
        };

        this.props.editCategory(formData, (res: any) => {
            if (res) {
                this.setState({
                    editSuccess: true,
                });
                setTimeout(() => {
                    this.props.history.push(`/categories/details?${this.props.editingCategory.ID}`);
                }, 1000);
            } else {
                this.setState({
                    editError: true,
                });
            }
        });
    };

    render() {
        return (
            <div>
                {checkPermissionForCategories(this.props.views).includes(EDIT_CATEGORIES) ? (
                    <div>
                        {this.props.processing && <Loader />}
                        {this.props.editingCategory && (
                            <Grid container style={containers}>
                                <Grid>
                                    <CategoryForm
                                        editData={this.props.editingCategory}
                                        edit={true}
                                        title={'Edit Category'}
                                        submitAction={this.handleEdit}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {this.state.editSuccess && (
                            <SnackbarCmpt message={'Edited successfully'} severity={'success'} />
                        )}
                        {this.state.editError && <SnackbarCmpt message={'Could not Edit'} severity={'error'} />}
                    </div>
                ) : (
                    <Redirect to="/not-found" />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        processing: state.categories_reducer.processing,
        editingCategory: state.categories_reducer.editingCategory,
        views: state.user_reducer.views,
        user: state.user_reducer.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getCategoryForEdition: (id: any) => dispatch(getCategoryForEdition(id)),
        editCategory: (data: any, cb: any) => dispatch(editCategory(data, cb)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(EditCategories);

export default withRouter(connected);
