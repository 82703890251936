import {
    SET_CURRENT_TAB,
    SET_TABS_DATA,
    TABS_ERROR,
    TABS_PROCESSING,
    SET_TABS_STORED_DATA,
    SET_CURRENT_TAB_DATA,
    SET_CURRENT_TAB_DATA_ERROR,
    SET_CURRENT_TAB_DATA_PROCESSING,
    SET_TAB_DATA_COUNT,
    SET_PAGE_SIZE,
    SET_CURRENT_REVISIONS,
    SET_SELECTED_REVISION,
    SET_ATTACHMENT_REVISIONS,
    SET_ATTACHMENT_REVISIONS_PROCESSING
} from '../action-types/item-form-action-types';

export const setTabsData = (data: any) => {
    return {
        type: SET_TABS_DATA,
        payload: data,
    };
};

export const setCurrentTabData = (data: any) => {
    return {
        type: SET_CURRENT_TAB,
        payload: data,
    };
};

export const setTabError = (data: any) => {
    return {
        type: TABS_ERROR,
        payload: data,
    };
};

export const setTabProcessing = (data: any) => {
    return {
        type: TABS_PROCESSING,
        payload: data,
    };
};

export const setTabsStoredData = (data: any) => {
    return {
        type: SET_TABS_STORED_DATA,
        payload: data,
    };
};

export const setCurrentTabFormData = (data: any) => {
    return {
        type: SET_CURRENT_TAB_DATA,
        payload: data,
    };
};

export const setCurrentTabFormProcessing = (data: any) => {
    return {
        type: SET_CURRENT_TAB_DATA_PROCESSING,
        payload: data,
    };
};

export const setCurrentTabFormError = (data: any) => {
    return {
        type: SET_CURRENT_TAB_DATA_ERROR,
        payload: data,
    };
};

export const setTabDataCount = (data: any) => {
    return {
        type: SET_TAB_DATA_COUNT,
        payload: data,
    };
};

export const setTabPageSize = (data: any) => {
    return {
        type: SET_PAGE_SIZE,
        payload: data,
    };
};

export const setCurrentRevisions = (data: any) => {
    return {
        type: SET_CURRENT_REVISIONS,
        payload: data,
    };
};

export const setSelectedRevision = (data: any) => {
    return {
        type: SET_SELECTED_REVISION,
        payload: data,
    };
};

export const setAttachmentRevisions = (data:any) =>{
    return {
        type: SET_ATTACHMENT_REVISIONS,
        payload:data
    }
}

export const setAttachmentRevisionsProcessing = (data:any) =>{
    return {
        type: SET_ATTACHMENT_REVISIONS_PROCESSING,
        payload:data
    }
}