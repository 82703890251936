import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import './App.css';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes/Routes';
import '@progress/kendo-theme-material';
import { FAV_ICON } from './config';
//import '@progress/kendo-theme-default/dist/all.css';


const browserHistory = createBrowserHistory();

validate.validators = {
    ...validate.validators,
    ...validators,
};

function App() {
    let $favicon = document.createElement("link")
    $favicon.rel = "icon"
    $favicon.href = FAV_ICON
    document.head.appendChild($favicon)
    return (
        <ThemeProvider theme={theme}>
            <Router history={browserHistory}>
                <Routes key={'ApplicationRoutes'} />
            </Router>
        </ThemeProvider>
    );
}

export default App;
