export const ModelValidation = 'ModelValidation';
export const RelationExpression = 'RelationExpression';
export const UnallowedRelation = 'UnallowedRelation';
export const InvalidGraph = 'InvalidGraph';
export const UnknownValidationError = 'UnknownValidationError';
export const NotFound = 'NotFound';
export const UniqueViolation = 'UniqueViolation';
export const NotNullViolation = 'NotNullViolation';
export const ForeignKeyViolation = 'ForeignKeyViolation';
export const CheckViolation = 'CheckViolation';
export const InvalidData = 'InvalidData';
export const UnknownDatabaseError = 'UnknownDatabaseError';
export const UnknownError = 'UnknownError';

export const constructErrorMesssage = (error: any) => {
    let type = error.type;
    let returnString = '';
    let { columns, table } = getTableAndColumn(error);
    switch (type) {
        case UniqueViolation:
            if (columns === 'TrainingItemCode and RevisionLabel') returnString = 'Revision already exists';
            else returnString = `${table} already exists.`;
            break;
        case NotNullViolation:
            returnString = `${table} cannot have null values for ${columns}`;
            break;
        case UnknownValidationError:
            returnString = `Some validations failed, Please try again.`;
            break;
        case UnknownError:
            returnString = `Some unknown error occurred.`;
            break;
        case UnknownDatabaseError:
            returnString = `Could not complete your request, Please try again`;
            break;
        case InvalidData:
            returnString = `Could not complete request due to some invalid data`;
            break;
        case CheckViolation:
            returnString = `Some Check violation occurred, Please try again`;
            break;
        case ForeignKeyViolation:
            returnString = `Foreign Key violation occurred`;
            break;
        case NotFound:
            returnString = `Your request could not be completed`;
            break;
        default:
            returnString = 'Some error occurred while processing your request';
            break;
    }
    return returnString;
};

const getTableAndColumn = (error: any) => {
    let result = {
        columns: 'unknown',
        table: 'unknown',
    };
    if (error.data && error.data.table) {
        result.table = error.data.table;
    }

    if (error.data && error.data.columns) {
        if (error.data.columns.length == 1) {
            result.columns = error.data.columns[0];
        } else if (error.data.columns.length == 2) {
            result.columns = `${error.data.columns[0]} and ${error.data.columns[1]}`;
        }
    }

    return result;
};
