import React, { Component } from 'react';

import { fetchClassData, searchClassData, setPageSize } from '../../../../Services/browse';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import theme from '../../../../theme';
import CustomPagination from '../../../../components/CustomPagination';
interface props {
    totalClassContent: any;
    pageSizeClassContent: any;
    fetchClassData: any;
    selectedClass: any;
    selectedClassType: any;
    setPageSize: any;
    searchClassData: any;
    filter: any;
    handlePageResults: any;
}
class Pageing extends Component<props> {
    state = {
        show: false,
        dataColumns: [],
        page: 1,
    };

    handlePage = (value: any) => {
        if(parseInt(value) !== this.state.page){
            this.setState({
                page: parseInt(value),
            });
    
            let pageNumbr = parseInt(value) - 1;
            let offset = pageNumbr * this.props.pageSizeClassContent;
            this.props.handlePageResults(offset,this.props.pageSizeClassContent);}
        
    };

    handleNewPageSize = (event: any) => {
        let oldContentSize = Math.floor((this.props.pageSizeClassContent * (this.state.page - 1))/event.target.value)
        this.setState({
            page: oldContentSize+1,
        });
        this.props.setPageSize(event.target.value);
        this.props.handlePageResults(oldContentSize*event.target.value, event.target.value);
    };

    render() {
        return (
            <div>
                <div style={{ margin: theme.spacing(2) }}>
                    <CustomPagination
                        total={this.props.totalClassContent}
                        page={this.state.page}
                        handlePage={this.handlePage}
                        pageSize={this.props.pageSizeClassContent}
                        handleNewPageSize={this.handleNewPageSize}
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        totalClassContent: state.browse_reducer.totalClassContent,
        pageSizeClassContent: state.browse_reducer.pageSizeClassContent,
        selectedClass: state.browse_reducer.selectedClass,
        selectedClassType: state.browse_reducer.selectedClassType,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        searchClassData: (filter: any, offset: any, records: any) => dispatch(searchClassData(filter, offset, records)),

        setPageSize: (size: any) => dispatch(setPageSize(size)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Pageing);

export default withRouter(connected);
