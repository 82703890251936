import React from 'react';
import UserManagementContainer from '../../Containers/UserManagement';
import RoleWraper from '../../HOC/RoleWraper';
import { Grid } from '@material-ui/core';
import Breadcrumbs from '../../HOC/Breadcrumbs';
import AddUser from '../../Containers/UserManagement/AddUser';
import EditUser from '../../Containers/UserManagement/EditUser';
import { BasePath } from '../../config';
const UserManagement = (props: any) => {
    return (
        // @ts-ignore
        <RoleWraper view="userManagement">
            <Grid>
                <Grid container>
                    <Grid xs={12}>
                        {props.location && props.location.pathname === `${BasePath}/userManagement` && (
                            <UserManagementContainer />
                        )}
                        {props.location && props.location.pathname === `${BasePath}/userManagement/Add` && <AddUser />}
                        {props.location && props.location.pathname === `${BasePath}/userManagement/Edit` && (
                            <EditUser />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </RoleWraper>
    );
};

export default UserManagement;
