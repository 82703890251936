import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchAllRules } from '../../../Services/rules';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/Loader';
import NoDataFound from '../../../components/Messages/NoDataFound';
import { TreeListDateFilter, TreeListTextFilter } from '@progress/kendo-react-treelist';
import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import Treefile from '../../../components/KendoUICmpt/TreeList';
import { BasePath } from "../../../config";

function Index(props: any) {

    useEffect(function () {
        props.fetchAllRules()
    }, []);

    const handleInfo = (e: any, rowData: any) => {
        let data = rowData.dataItem;
        let ruleID = data.id;
        props.history.push(`${BasePath}/rule/details/${ruleID}`);
    }

    const columns: any = [
        {
            field: 'rule_name',
            title: 'Rule Name',
            filter: TreeListTextFilter
        },
        {
            field: 'rule_expression',
            title: 'Rule Expression',
            filter: TreeListTextFilter
        },
        {
            field: 'rule_code',
            title: 'Rule Code',
            filter: TreeListTextFilter
        },
        {
            field: 'IsActive',
            title: 'Active',
        },
        {
            title: 'Open Details',
            cell: (rowData: any) => (
                <td>
                    <Tooltip title={"Open Details"}>
                        <IconButton aria-label="Edit" onClick={(e: any) => handleInfo(e, rowData)}>
                            <Info />
                        </IconButton>
                    </Tooltip>
                </td>

            ),
        },
    ]

    const addRule = () => {
        props.history.push(`${BasePath}/rule/add`);
    };

    return (
        <div>
            <div style={{ marginTop: 10 }}>
                <Grid container>
                    <Grid container justify="flex-end">

                        <Button
                            style={{ marginTop: '16px' }}
                            variant="contained"
                            onClick={() => {
                                addRule()
                            }}
                            color="primary"
                        >
                            Add Rule
                        </Button>

                    </Grid>
                </Grid>
                <div style={{ marginTop: 10 }}>
                    {props.processing && <Loader />}
                    {props.rules && props.rules.length > 0 ?
                        <div>
                            <Treefile columns={columns} tableData={props.rules} />
                        </div> :
                        !props.processing && <div>
                            <NoDataFound title={'No Permission Rules found'} />
                        </div>
                    }
                </div>

            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        rules: state.rules_reducer.rules,
        processing: state.rules_reducer.processing
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAllRules: () => dispatch(fetchAllRules()),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);