export const GET_USER_DATA = 'GET_USER_DATA';
export const SET_USER_ERROR = 'SET_USER_ERROR';
export const SET_USER_DATA = 'SET_USER_DATA';
export const ADD_USER = 'ADD_USER';
export const SET_EDIT_DATA = 'SET_EDIT_DATA';
export const SET_PAGE_SIZE_USER = 'SET_PAGE_SIZE_USER';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_USER_PROCESSING = 'SET_USER_PROCESSING';

export const GET_ROLES = "GET_ROLES";

