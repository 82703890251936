import {
    statsProcessing,
    setCourseStats,
    setRevisedCourses,
    reviseCourseProcessing,
    revisionAvailProcessing,
    setRevisionsAvailable,
    setOverdueTrainings,
    setOverdueProcessing,
    setDeadlineProcessing,
    setDeadlineTrainings,
    setNotificationDashboard,
    setNotitficationProcessing,
    setPieCourseStats,
    statsPieProcessing,
} from '../actions/dashboard';
import axios from '../utils/axios';
import moment from 'moment';
import { DAYS_LIMIT_TRAINING_DEADLINE } from '../config';
import { formatRevisedCourses } from '../utils/dashboard';
import { getConfigKeyValue } from '../utils/courses';

export const fetchCourseStats = () => {
    return (dispatch: any) => {
        let url = `/api/dashboardData`;
        dispatch(setCourseStats(null));
        dispatch(statsProcessing(true));
        dispatch(setPieCourseStats(null));
        dispatch(statsPieProcessing(true));

        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result && response.data.result.length > 0) {
                    let result = response.data.result;
                    if (result[0].Key == 'CourseStatistics' && result[1].Key == 'TrainingStatistics') {
                        dispatch(setCourseStats(result[0].Value));
                        dispatch(statsProcessing(false));
                        dispatch(setPieCourseStats(result[1].Value));
                        dispatch(statsPieProcessing(false));
                    } else {
                        dispatch(setCourseStats(result[1].Value));
                        dispatch(statsProcessing(false));
                        dispatch(setPieCourseStats(result[0].Value));
                        dispatch(statsPieProcessing(false));
                    }
                } else {
                    dispatch(statsProcessing(false));
                }
            })
            .catch(err => {
                dispatch(statsProcessing(false));
            });
    };
};

export const fetchPieCourseStats = () => {
    return (dispatch: any) => {
        let url = `/api/trainingStatistics`;
        dispatch(setPieCourseStats(null));
        dispatch(statsPieProcessing(true));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(setPieCourseStats(response.data.result));
                    dispatch(statsPieProcessing(false));
                } else {
                    dispatch(statsPieProcessing(false));
                }
            })
            .catch(err => {
                dispatch(statsPieProcessing(false));
            });
    };
};

export const fetchRevisedCourses = () => {
    return (dispatch: any) => {
        let url = `/api/revisedCourses`;
        dispatch(setRevisedCourses([]));
        dispatch(reviseCourseProcessing(false));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    let resultvalue = formatRevisedCourses(response.data.result);
                    dispatch(setRevisedCourses(resultvalue));
                    dispatch(reviseCourseProcessing(false));
                } else {
                    dispatch(reviseCourseProcessing(false));
                }
            })
            .catch(err => {
                dispatch(reviseCourseProcessing(false));
            });
    };
};

export const fetchRevisionsAvailable = () => {
    return (dispatch: any) => {
        let url = `/api/availableRevisions?IsActive=1&IsLatest=1&IsApproved=1`;
        dispatch(setRevisionsAvailable(0));
        dispatch(revisionAvailProcessing(false));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(setRevisionsAvailable(response.data.result));
                    dispatch(revisionAvailProcessing(false));
                } else {
                    dispatch(revisionAvailProcessing(false));
                }
            })
            .catch(err => {
                dispatch(revisionAvailProcessing(false));
            });
    };
};

export const fetchOverdueTrainings = () => {
    return (dispatch: any) => {
        let url = `/api/reports/slt/overdueTrainingReport?ActiveTrainings=1`;
        dispatch(setOverdueTrainings(0));
        dispatch(setOverdueProcessing(false));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(setOverdueTrainings(response.data.result.length));
                    dispatch(setOverdueProcessing(false));
                } else {
                    dispatch(setOverdueProcessing(false));
                }
            })
            .catch(err => {
                dispatch(setOverdueProcessing(false));
            });
    };
};
export const trainingApproachingDeadline = () => {
    return (dispatch: any, getState: any) => {
        let configurationData = getState().user_reducer.configurationData;
        let dayLimit = parseInt(getConfigKeyValue(configurationData, 'WarningBeforeDueDate'));
        let startdate = moment().format('YYYY-MM-DD');
        let lastDate = moment(startdate, 'YYYY-MM-DD')
            .add(dayLimit, 'days')
            .format('YYYY-MM-DD');

        let url = `/api/reports/slt/trainingReport?DueDateFrom=${startdate}&DueDateTo=${lastDate}&ActiveTrainings=1`;
        dispatch(setDeadlineTrainings(0));
        dispatch(setDeadlineProcessing(false));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(setDeadlineTrainings(response.data.result.length));
                    dispatch(setDeadlineProcessing(false));
                } else {
                    dispatch(setDeadlineProcessing(false));
                }
            })
            .catch(err => {
                dispatch(setDeadlineProcessing(false));
            });
    };
};

export const fetchNotificationDashboard = (page: any, pageSize: any) => {
    return (dispatch: any, getState: any) => {
        //let userId = getState().user_reducer.user.ID;
        // let url = `/api/users/${userId}/notifications?Page=${page -
        //   1}&PageSize=${pageSize}&OrderByDirection=desc&OrderBy=CreatedDate`;
        dispatch(setNotificationDashboard(null));
        dispatch(setNotitficationProcessing(false));
        // axios
        //   .get(url)
        //   .then((response: any) => {
        //     if (response.data.result) {
        //       dispatch(setNotificationDashboard(response.data.result));
        //       dispatch(setNotitficationProcessing(false));
        //     } else {
        //       dispatch(setNotitficationProcessing(false));
        //     }
        //   })
        //   .catch((err) => {
        //     dispatch(setNotitficationProcessing(false));
        //   });
    };
};

export const markNotitfications = (formData: any, page: any, pageSize: any) => {
    return (dispatch: any) => {
        let url = `/api/queueNotifications`;
        axios
            .patch(url, formData)
            .then(response => {
                if (response.data.success) {
                    // dispatch(fetchNotificationDashboard(page, pageSize));
                }
            })
            .catch(err => {});
    };
};
