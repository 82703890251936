import React, { Component } from 'react';

import { fetchClassData, setPageSize } from '../../../Services/browse';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import theme from '../../../theme';
import CustomPagination from '../../../components/CustomPagination';
interface props {
    totalClassContentBrowse: any;
    pageSizeClassContent: any;
    fetchClassData: any;
    selectedClass: any;
    selectedClassType: any;
    setPageSize: any;
}
class ClassContentPaging extends Component<props> {
    state = {
        show: false,
        dataColumns: [],
        page: 1,
    };

    handlePage = (value: any) => {
        if(value == this.state.page){
            return
        }
        this.setState({
            page: parseInt(value),
        });
        let pageNumbr = parseInt(value) - 1;
        let classType = this.props.selectedClassType;
        let className = this.props.selectedClass;
        this.props.fetchClassData(classType, className, pageNumbr, this.props.pageSizeClassContent);
    };

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<{}>, snapshot?: any) {
        let previousSelectedClass = prevProps.selectedClass;
        let selectedClass = this.props.selectedClass;
        if (
            previousSelectedClass['CLASS-NAME'] &&
            selectedClass['CLASS-NAME'] &&
            previousSelectedClass['CLASS-NAME'] != selectedClass['CLASS-NAME']
        ) {
            this.setState({
                page: 1,
            });
        }
    }

    handleNewPageSize = (event: any) => {
        let oldContentSize = Math.floor((this.props.pageSizeClassContent * (this.state.page - 1))/event.target.value)
        this.setState({
            page: oldContentSize+1,
        });

        this.props.setPageSize(event.target.value);
        let classType = this.props.selectedClassType;
        let className = this.props.selectedClass;
        this.props.fetchClassData(classType, className, oldContentSize, event.target.value);
    };

    render() {
        return (
            <div>
                <div style={{ margin: theme.spacing(2) }}>
                    <CustomPagination
                        total={this.props.totalClassContentBrowse}
                        page={this.state.page}
                        handlePage={this.handlePage}
                        pageSize={this.props.pageSizeClassContent}
                        handleNewPageSize={this.handleNewPageSize}
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        totalClassContentBrowse: state.browse_reducer.totalClassContentBrowse,
        pageSizeClassContent: state.browse_reducer.pageSizeClassContent,
        selectedClass: state.browse_reducer.selectedClass,
        selectedClassType: state.browse_reducer.selectedClassType,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchClassData: (classtype: any, classname: any, offset: any, records: any) =>
            dispatch(fetchClassData(classtype, classname, offset, records)),

        setPageSize: (size: any) => dispatch(setPageSize(size)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(ClassContentPaging);

export default withRouter(connected);
