import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import theme from '../theme';

const StyledBreadcrumb: any = withStyles((theme: any) => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

const CustomizedBreadcrumbs = (props: any) => {
    //console.log(props)
    let disabled = props.paths[2] === 'attempt-quiz' ? true : false;
    return (
        <div
            style={{
                minHeight: 50,
                background: '#fff',
                padding: theme.spacing(3, 1),
                boxShadow: '7px 7px 5px #d4d3d4, -7px -7px 5px #efedf0',
            }}
        >
            <Grid>
                <Grid xs={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        {props.paths.map((val: any, i: any) => {
                            return (
                                val !== '' && (
                                    <StyledBreadcrumb
                                        key={val}
                                        disabled={disabled}
                                        label={val}
                                        onClick={() => {
                                            let redirectTo = '';
                                            for (let j = 0; j < i + 1; j++) {
                                                if (props.paths[j] !== '') {
                                                    redirectTo = redirectTo + '/' + props.paths[j];
                                                }
                                            }
                                            props.history.replace(redirectTo);
                                        }}
                                    />
                                )
                            );
                        })}
                    </Breadcrumbs>
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(CustomizedBreadcrumbs);
