import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import StarsIcon from '@material-ui/icons/Stars';
import FavoritesContainer from '../../../../Containers/Favorites';
import theme from '../../../../theme';
import {UIExperince} from "../../../../config";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

type Anchor = 'right';

export default function SwipeableTemporaryDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <div style={{color: theme.palette.primary.main}}>
            {(['right'] as Anchor[]).map(anchor => (
                <React.Fragment key={anchor}>
                    <IconButton onClick={toggleDrawer(anchor, true)} color="inherit">
                        <Badge style={{color :UIExperince.agile ? "#fff":"secondary"}}>
                            <StarsIcon />
                        </Badge>
                    </IconButton>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        <div style={{ minWidth: '650px', maxWidth: '650px' }}>
                            <Tooltip title={'Open Favourites'}>
                                <FavoritesContainer onClose={toggleDrawer(anchor, false)} />
                            </Tooltip>
                        </div>
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}
