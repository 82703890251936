import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import {
    fetchDataForTabs,
    getDataForPageination,
    setCurrentTab,
    fetchRevisionsOfItem,
    settingSelectedRevision,
    resetTheFormRedux,
} from '../../Services/itemForm';
import Loader from '../../components/Loader';
import ItemTabs from './ItemTabs';
import ItemFrom from './ItemsForm';
import ErrorComponent from '../../components/Error';
import {
    Avatar,
    Box,
    Button,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    NativeSelect,
    Paper,
    Select,
    Typography,
} from '@material-ui/core';
import theme from '../../theme';
import SubjectIcon from '@material-ui/icons/Subject';
import Pageing from './Components/Pageing';
import { BasePath, UIExperince ,PAGEINATION_ON_TOP} from '../../config';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import FavouritesModals from '../../components/FavouritesModals';
import BookmarksForm from './Components/BookmarksForm';
import { searchBookmark } from '../../Services/favorites';
import SaveIcon from '@material-ui/icons/Save';
import StarIcon from '@material-ui/icons/Star';
import PDSideBar from "../PDSideBar";
import { replaceNames } from '../../utils/ItemFormHelpers';

interface propsInterface {
    children?: React.ReactNode;
    index: any;
    location: any;
    history: any;
    match: any;
    fetchDataForTabs: any;
    tabsProcessing: any;
    tabsData: any;
    setCurrentTab: any;
    tabsError: any;
    getDataForPageination: any;
    pageSizeTab: any;
    currentTabData: any;
    fetchRevisionsOfItem: any;
    currentRevisions: any;
    settingSelectedRevision: any;
    selectedRevision: any;
    resetTheFormRedux: any;
    bookmarksData: any;
    searchBookmark: any;
}

class index extends Component<propsInterface> {
    state = {
        currentRevision: '',
        bookmarked: false,
    };

    componentDidMount(): void {

        this.handleBookMarkSeach();
        this.getTabsForCurrentData();
    }

    componentWillUnmount() {
        this.props.resetTheFormRedux();
    }

    handleBookMarkSeach = () => {
        this.props.searchBookmark(this.props.match.url, (res: any) => {
            this.setState({
                bookmarked: res,
            });
        });
    };

    setCurrentRevision = (rev: any) => {
        this.props.settingSelectedRevision(rev);
    };

    getTabsForCurrentData = () => {
        //@ts-ignore
        let ItemParams = this.props.match.params;
        let { classType, className, productId, rev, guid } = ItemParams;

        this.props.fetchRevisionsOfItem(ItemParams, (response: any) => {
            if (response && response.length > 0 && !rev) {
                let url = `${BasePath}/item/${classType}/${className}/${productId}`;
                if (response[0]) {
                    url = url + `/${response[0].rev}`;
                } else {
                    url = url + `/1`;
                }

                if (response[0].guid) {
                    url = url + `/${response[0].guid}`;
                }

                let selectedRevision = `${response[0].rev} : ${response[0].change_number}`;

                this.setCurrentRevision(selectedRevision);
                this.props.history.push(url);
                this.props.fetchDataForTabs({ ...ItemParams, rev: response[0].rev, guid: response[0].guid });
            } else {
                if (ItemParams.classType && ItemParams.className) {

                    if (response && response.length > 0) {
                        let revisionData: any = null;
                        let done = false;
                        response.map((data: any, index: any) => {
                            if (!guid && data.rev == rev && data.guid && !done) {
                                done = true;
                                let url = `${BasePath}/item/${classType}/${className}/${productId}/${rev}/${data.guid}`;
                                this.props.history.push(url);
                                return;
                            }
                            else if (data.rev == rev && data.guid && guid && data.guid == guid) {
                                revisionData = data;
                            }
                        });
                        if (revisionData) {
                            let selectedRevision = `${revisionData.rev} : ${revisionData.change_number ? revisionData.change_number : ''
                                }`;
                            this.setCurrentRevision(selectedRevision);
                        }
                    } else {
                        this.setCurrentRevision(rev);
                    }

                    this.props.fetchDataForTabs(ItemParams);
                }
            }
        });
    };

    componentDidUpdate(prevProps: Readonly<propsInterface>, prevState: Readonly<{}>, snapshot?: any) {
        let CurrentPath = this.props.location.pathname;
        let { classType, className, productId, rev, guid } = this.props.match.params;
        let HistoryPath = prevProps.location.pathname;

        if (CurrentPath != HistoryPath && (classType && className && productId)) {
            this.getTabsForCurrentData();
            this.handleBookMarkSeach();
        }
    }

    handleChangeTab = (tab: any) => {
        let ItemParams = this.props.match.params;
        this.props.setCurrentTab(tab, ItemParams);
    };

    navigateToURL = (ItemParams: any) => {
        let { classType, className, productId, rev, guid } = ItemParams;
        let url = `${BasePath}/item/${classType}/${className}/${productId}`;
        if (rev) {
            url = url + `/${rev}`;
        } else {
            url = url + `/1`;
        }

        if (guid) {
            url = url + `/${guid}`;
        }

        this.props.history.push(url);
    };

    handleChange = (event: any) => {
        let selectedIndex: any = event.target.selectedIndex;
        let currentRevisions = this.props.currentRevisions;
        this.setCurrentRevision(event.target.value);
        let ItemParams = this.props.match.params;
        this.navigateToURL({
            ...ItemParams,
            rev: currentRevisions[selectedIndex].rev,
            guid: currentRevisions[selectedIndex].guid,
        });
    };

    showHeader = () => {
        let { classType, className, productId, rev } = this.props.match.params;
        if(!rev && this.props.selectedRevision){
            rev =  this.props.selectedRevision.split(":")[0].trim()
        }
        return classType ? (
            UIExperince.agile ? <div style={{ marginTop: theme.spacing(2) }}>
                <Typography variant="h5" color={'primary'}>
                    <Chip
                        variant="outlined"
                        label={`${classType} / ${className} / ${productId}`}
                        color="primary"
                        icon={<SubjectIcon />}
                    />
                </Typography>
            </div> :
                <div style={{ marginTop: theme.spacing(2) }}>
                    <Typography variant="h4" style={{ fontWeight: "normal", color: "#0066cc", fontSize: "24px", margin: theme.spacing(1) }}>
                        {classType && (classType == "Parts" || classType == "Documents") ?
                            `${replaceNames(classType)} : ${className} - ${productId}(${rev})` :
                            `${replaceNames(classType)} : ${className} - ${productId}`
                        }

                    </Typography>
                </div>
        ) : null;
    };

    showDropDownRevision = () => {
        return this.props.currentRevisions && this.props.currentRevisions.length > 0 ? (
            <FormControl size={'small'} style={{ minWidth: 120, marginTop: theme.spacing(1) }}>
                <InputLabel htmlFor="outlined-age-native-simple">Revision</InputLabel>
                <NativeSelect value={this.props.selectedRevision} onChange={this.handleChange}>
                    {this.props.currentRevisions.map((rev: any, index: any) => {
                        return (
                            <option
                                key={index}
                                id={rev.change_number}
                                value={`${rev.rev} : ${rev.change_number ? rev.change_number : ''}`}
                            >
                                {`${rev.rev_view} : ${rev.change_number ? rev.change_number : ''}`}
                            </option>
                        );
                    })}
                </NativeSelect>
            </FormControl>
        ) : null;
    };

    closeModal = () => { };

    showBookmarkButton = () => {
        return this.state.bookmarked ? (
            <div style={{ marginTop: theme.spacing(2), textAlign: 'right' }}>
                <Button disabled={true} variant="contained" color="secondary" size="small" startIcon={<StarIcon />}>
                    Bookmarked
                </Button>
            </div>
        ) : (
            <div style={{ marginTop: theme.spacing(2), textAlign: 'right' }}>
                <FavouritesModals title={'Bookmark'} type={'bookmark'}>
                    <div style={{ width: '100%' }}>
                        <BookmarksForm handleBookMarkSeach={this.handleBookMarkSeach} />
                    </div>
                </FavouritesModals>
            </div>
        );
    };

    handlePageResults = (from: any, to: any) => {
        let ItemParams = this.props.match.params;

        if (ItemParams.classType && ItemParams.className) {
            this.props.getDataForPageination(ItemParams, from, to);
        }
    };

    render() { 
        let { classType } = this.props.match.params;

        let layoutData =
            this.props.currentTabData && this.props.currentTabData.LAYOUT ? this.props.currentTabData.LAYOUT : null;

        return (

            <div style={{
                margin: theme.spacing(2),
                padding: theme.spacing(2),
                border: "2px solid #D6DFE6",
                backgroundColor: "#fff"
            }}>
                <Grid container direction={'row'}>
                    <Grid lg={5} md={5} xl={5} xs={12}>
                        {this.showHeader()}
                    </Grid>
                    <Grid lg={3} md={3} xl={3} xs={12}>
                        {classType && (classType == "Parts" || classType == "Documents") ? this.showDropDownRevision() : null}
                    </Grid>
                    <Grid lg={4} md={4} xl={4} xs={12}>
                        {this.showBookmarkButton()}
                    </Grid>
                </Grid>

                {this.props.tabsProcessing && <Loader />}

                {UIExperince.agile ?
                    <div>
                        {this.props.tabsData && this.props.tabsData.length > 0 && (
                            <ItemTabs tabs={this.props.tabsData} handleChangeTab={this.handleChangeTab}>
                                {!PAGEINATION_ON_TOP && <ItemFrom />}
                                {layoutData && layoutData[0]['TYPE'] == 'Text' ? null : (
                                    <Pageing
                                        //@ts-ignore
                                        handlePageResults={this.handlePageResults}
                                    />
                                )}
                                {PAGEINATION_ON_TOP && <ItemFrom />}
                            </ItemTabs>
                        )}
                    </div> :
                    <div style={{ marginTop: "12px" }}>
                        {this.props.tabsData && this.props.tabsData.length > 0 && (
                            <PDSideBar tabs={this.props.tabsData} handleChangeTab={this.handleChangeTab}>
                                {!PAGEINATION_ON_TOP && <ItemFrom />}
                                {layoutData && layoutData[0]['TYPE'] == 'Text' ? null : (
                                    <Pageing
                                        //@ts-ignore
                                        handlePageResults={this.handlePageResults}
                                    />
                                )}
                                {PAGEINATION_ON_TOP && <ItemFrom />}
                            </PDSideBar>
                        )}
                    </div>
                }

                {!this.props.tabsProcessing && this.props.tabsError && <ErrorComponent text={'Could not load data'} />}
            </div>


        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        tabsData: state.itemForm_reducer.tabsData,
        currentTab: state.itemForm_reducer.currentTab,
        tabsProcessing: state.itemForm_reducer.tabsProcessing,
        tabsError: state.itemForm_reducer.tabsError,
        pageSizeTab: state.itemForm_reducer.pageSizeTab,
        currentTabData: state.itemForm_reducer.currentTabData,
        currentRevisions: state.itemForm_reducer.currentRevisions,
        selectedRevision: state.itemForm_reducer.selectedRevision,
        bookmarksData: state.favorites_reducer.bookmarksData,

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchRevisionsOfItem: (ItemParams: any, cb: any) => dispatch(fetchRevisionsOfItem(ItemParams, cb)),
        fetchDataForTabs: (ItemParams: any) => dispatch(fetchDataForTabs(ItemParams)),
        settingSelectedRevision: (rev: any) => dispatch(settingSelectedRevision(rev)),
        setCurrentTab: (tab: any, ItemParams: any) => dispatch(setCurrentTab(tab, ItemParams)),
        resetTheFormRedux: () => dispatch(resetTheFormRedux()),
        searchBookmark: (link: any, cb: any) => dispatch(searchBookmark(link, cb)),
        getDataForPageination: (ItemParams: any, offset: any, recordCount: any) =>
            dispatch(getDataForPageination(ItemParams, offset, recordCount)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(index);

export default withRouter(connected);
