import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Divider, Drawer } from '@material-ui/core';
import sideBarViews, { sideBarMenu } from './Views';

import { Profile, SidebarNav } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigKeyValue, getNameById } from '../../../../utils/courses';
import TrainingItemsIcon from '@material-ui/icons/NoteAdd';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { getConfigurations } from '../../../../Services/user';
import theme from '../../../../theme';

const useStyles = makeStyles((theme: any) => ({
    drawer: {
        width: 240,
        [theme.breakpoints.up('lg')]: {
            marginTop: 64,
            height: 'calc(100% - 64px)',
        },
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'row',
        height: 64,
        padding: theme.spacing(1),
        boxShadow: theme.shadows[4],
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    nav: {
        marginBottom: theme.spacing(2),
    },
}));

const Sidebar: any = (props: any) => {
    const { open, variant, onClose, className, ...rest } = props;
    // ;
    const user: any = useSelector((state: any) => ({
        ...state.user_reducer.user,
    }));

    const [pages, setPages] = useState([]);
    const [mark, marking] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user.user_name && !mark) {
            setPages(sideBarViews(user));
            marking(true);
        }
    }, [user]);

    return (
        <AppBar position="fixed" style={{ marginTop: theme.spacing(8) }}>
            <div {...rest} className={clsx(classes.root, className)}>
                {/*<Profile/>*/}
                {/* <Divider className={classes.divider}/> */}
                <div></div>
                <SidebarNav className={classes.nav} pages={pages} />
            </div>
        </AppBar>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
};

export default Sidebar;
