import React from 'react';
import PermissionContainer from '../../Containers/Permission';
import RoleWraper from '../../HOC/RoleWraper';
import { Grid } from '@material-ui/core';
import Breadcrumbs from '../../HOC/Breadcrumbs';
import ClassPermissionEdit from '../../Containers/Permission/ClassPermissionEdit';
import ClassPermissionAdd from '../../Containers/Permission/ClassPermissionAdd';
import TabPermissionAdd from '../../Containers/Permission/TabPermissionAdd';
import TabPermissionEdit from '../../Containers/Permission/TabPermissionEdit';
import RulePermissionsView from '../../Containers/Permission/RulePermissionView';
import AssignRule from '../../Containers/Permission/AssignRule';
import EditRule from '../../Containers/Permission/EditRule';
import AddRule from '../../Containers/Permission/AddRule';
import { BasePath } from '../../config';

const Permission = (props: any) => {
    return (
        // @ts-ignore
        <RoleWraper view="permission">
            <Grid>
                <Grid container>
                    <Grid xs={12}>
                        {props.location && props.location.pathname === `${BasePath}/permission` && (
                            <PermissionContainer />
                        )}
                        {props.location && props.location.pathname === `${BasePath}/permission/class-add` && (
                            <ClassPermissionAdd />
                        )}
                        {props.location && props.location.pathname === `${BasePath}/permission/class-edit` && (
                            <ClassPermissionEdit />
                        )}
                        {props.location && props.location.pathname === `${BasePath}/permission/tabs-add` && (
                            <TabPermissionAdd />
                        )}
                        {props.location && props.location.pathname === `${BasePath}/permission/tabs-edit` && (
                            <TabPermissionEdit />
                        )}
                        {props.location && props.location.pathname.includes(`${BasePath}/rule/details`) && (
                            <RulePermissionsView />
                        )}
                        {props.location && props.location.pathname.includes(`${BasePath}/rule/assign`) && (
                            <AssignRule />
                        )}
                         {props.location && props.location.pathname.includes(`${BasePath}/rule/edit`) && (
                            <EditRule />
                        )}
                        {props.location && props.location.pathname.includes(`${BasePath}/rule/add`) && (
                            <AddRule />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </RoleWraper>
    );
};

export default Permission;
