import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import BuildIcon from '@material-ui/icons/Build';
import SubjectIcon from '@material-ui/icons/Subject';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import Label from '@material-ui/icons/Label';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import InfoIcon from '@material-ui/icons/Info';
import ForumIcon from '@material-ui/icons/Forum';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ReportIcon from '@material-ui/icons/Report';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { HomeWork, PermDataSetting } from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { Grid } from '@material-ui/core';
import theme from '../../../theme';
import Loader from '../../../components/Loader';
import ClassLabelsData from './ClassLabelData';
import SearchBox from './SearchBox';
import { ClassTypes } from '../../../config';
import { replaceNames } from '../../../utils/ItemFormHelpers';

declare module 'csstype' {
    interface Properties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.secondary,
            '&:hover > $content': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:focus > $content, &$selected > $content': {
                backgroundColor: `var(--tree-view-bg-color, #d3d0df)`,
                color: 'var(--tree-view-color)',
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
                backgroundColor: 'transparent',
            },
        },
        content: {
            color: theme.palette.text.secondary,
            paddingRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightMedium,
            '$expanded > &': {
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
        group: {
            marginLeft: 0,
            '& $content': {
                paddingLeft: theme.spacing(2),
            },
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0),
        },
        labelIcon: {
            marginRight: theme.spacing(1),
        },
        labelText: {
            margin: theme.spacing(1),
            fontWeight: 400,
            flexGrow: 1,
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontSize: '20px',
        },
    }),
);

function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon color="inherit" className={classes.labelIcon} />
                    <Typography variant="body2" className={classes.labelText}>
                        {replaceNames(labelText)}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                // @ts-ignore
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

const useStyles = makeStyles(
    createStyles({
        root: {
            height: 264,
            flexGrow: 1,
        },
    }),
);

export default function TreeViewClassType() {
    const classes = useStyles();

    let {
        PartsClassType,
        DocumentClassType,
        processingPart,
        processingDocument,
        processingChange,
        ChangesClassType,
        ManufacturersClassType,
        processingManufacturer,
        SuppliersClassType,
        processingSuppliers,

        SuppliersItemsClassType,
        processingSuppliersItems,

        PSRClassType,
        processingPSR,

        QCRClassType,
        processingQCR,

        ManufacturerPartsClassType,
        processingManufacturerParts,
    } = useSelector((state: any) => ({
        ...state.browse_reducer,
    }));

    return (
        <>
            <div>
                <SearchBox
                    partClasses={PartsClassType ? PartsClassType : []}
                    documentClasses={DocumentClassType ? DocumentClassType : []}
                    changeClasses={ChangesClassType ? ChangesClassType : []}
                    manufacturersClasses={ManufacturersClassType ? ManufacturersClassType : []}
                    manufacturerPartsClasses={ManufacturerPartsClassType ? ManufacturerPartsClassType : []}
                    SuppliersClasses={SuppliersClassType ? SuppliersClassType : []}
                    SuppliersItemsClasses={SuppliersItemsClassType ? SuppliersItemsClassType : []}
                    PSRClasses={PSRClassType ? PSRClassType : []}
                    QCRClasses={QCRClassType ? QCRClassType : []}
                />
            </div>
            <TreeView
                className={classes.root}
                defaultExpanded={['000']}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
            >
                {ClassTypes.includes('Parts') && (
                    <StyledTreeItem nodeId="1" labelText="Parts" labelIcon={BuildIcon}>
                        {processingPart && <Loader />}
                        {PartsClassType &&
                            PartsClassType.length > 0 &&
                            PartsClassType.map((data: any, index: any) => {
                                // return
                                return (
                                    <>
                                        <ClassLabelsData
                                            type={'Parts'}
                                            labelVal={data['DISPLAY-NAME']}
                                            key={index}
                                            data={data}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                    </StyledTreeItem>
                )}

                {ClassTypes.includes('Documents') && (
                    <StyledTreeItem nodeId="2" labelText="Documents" labelIcon={SubjectIcon}>
                        {processingDocument && <Loader />}
                        {DocumentClassType &&
                            DocumentClassType.length > 0 &&
                            DocumentClassType.map((data: any, index: any) => {
                                // return
                                return (
                                    <>
                                        <ClassLabelsData
                                            type={'Documents'}
                                            labelVal={data['DISPLAY-NAME']}
                                            key={index}
                                            data={data}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                    </StyledTreeItem>
                )}

                {ClassTypes.includes('Changes') && (
                    <StyledTreeItem nodeId="3" labelText="Changes" labelIcon={SettingsOutlinedIcon}>
                        {processingChange && <Loader />}
                        {ChangesClassType &&
                            ChangesClassType.length > 0 &&
                            ChangesClassType.map((data: any, index: any) => {
                                // return
                                return (
                                    <>
                                        <ClassLabelsData
                                            type={'Changes'}
                                            labelVal={data['DISPLAY-NAME']}
                                            key={index}
                                            data={data}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                    </StyledTreeItem>
                )}

                {ClassTypes.includes('Manufacturers') && (
                    <StyledTreeItem nodeId="4" labelText="Manufacturers" labelIcon={HomeWork}>
                        {processingManufacturer && <Loader />}
                        {ManufacturersClassType &&
                            ManufacturersClassType.length > 0 &&
                            ManufacturersClassType.map((data: any, index: any) => {
                                // return
                                return (
                                    <>
                                        <ClassLabelsData
                                            type={'Manufacturers'}
                                            labelVal={data['DISPLAY-NAME']}
                                            key={index}
                                            data={data}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                    </StyledTreeItem>
                )}

                {ClassTypes.includes('Manufacturer_parts') && (
                    <StyledTreeItem nodeId="5" labelText="Manufacturer Parts" labelIcon={PermDataSetting}>
                        {processingManufacturerParts && <Loader />}
                        {ManufacturerPartsClassType &&
                            ManufacturerPartsClassType.length > 0 &&
                            ManufacturerPartsClassType.map((data: any, index: any) => {
                                // return
                                return (
                                    <>
                                        <ClassLabelsData
                                            type={'Manufacturer_parts'}
                                            labelVal={data['DISPLAY-NAME']}
                                            key={index}
                                            data={data}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                    </StyledTreeItem>
                )}

                {ClassTypes.includes('Suppliers') && (
                    <StyledTreeItem nodeId="6" labelText="Suppliers" labelIcon={HomeWork}>
                        {processingSuppliers && <Loader />}
                        {SuppliersClassType &&
                            SuppliersClassType.length > 0 &&
                            SuppliersClassType.map((data: any, index: any) => {
                                // return
                                return (
                                    <>
                                        <ClassLabelsData
                                            type={'Suppliers'}
                                            labelVal={data['DISPLAY-NAME']}
                                            key={index}
                                            data={data}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                    </StyledTreeItem>
                )}

                {ClassTypes.includes('Supplier_Items') && (
                    <StyledTreeItem nodeId="7" labelText="Supplier Items" labelIcon={EmojiTransportationIcon}>
                        {processingSuppliersItems && <Loader />}
                        {SuppliersItemsClassType &&
                            SuppliersItemsClassType.length > 0 &&
                            SuppliersItemsClassType.map((data: any, index: any) => {
                                // return
                                return (
                                    <>
                                        <ClassLabelsData
                                            type={'Supplier_Items'}
                                            labelVal={data['DISPLAY-NAME']}
                                            key={index}
                                            data={data}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                    </StyledTreeItem>
                )}

                {ClassTypes.includes('Product_Service_Requests') && (
                    <StyledTreeItem nodeId="8" labelText="Product Service Requests" labelIcon={ReportIcon}>
                        {processingPSR && <Loader />}
                        {PSRClassType &&
                            PSRClassType.length > 0 &&
                            PSRClassType.map((data: any, index: any) => {
                                // return
                                return (
                                    <>
                                        <ClassLabelsData
                                            type={'Product_Service_Requests'}
                                            labelVal={data['DISPLAY-NAME']}
                                            key={index}
                                            data={data}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                    </StyledTreeItem>
                )}


{ClassTypes.includes('Quality_Change_Requests') && (
                    <StyledTreeItem nodeId="9" labelText="Quality Change Requests" labelIcon={ReportIcon}>
                        {processingQCR && <Loader />}
                        {QCRClassType &&
                            QCRClassType.length > 0 &&
                            QCRClassType.map((data: any, index: any) => {
                                // return
                                return (
                                    <>
                                        <ClassLabelsData
                                            type={'Quality_Change_Requests'}
                                            labelVal={data['DISPLAY-NAME']}
                                            key={index}
                                            data={data}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                    </StyledTreeItem>
                )}
            </TreeView>
        </>
    );
}
