import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import { fetchSelectedUsers } from '../../Services/usergroup';
import { Card } from '@material-ui/core';
interface props {
    leftData: any;
    rightData: any;
    checkingClassesEmpty: any;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'grid',
            marginTop: 16,
        },
        list: {
            height: 300,
            overflowY: 'scroll',
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
    }),
);

function not(a: number[], b: number[]) {
    return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a: number[], b: number[]) {
    return a.filter(value => b.indexOf(value) !== -1);
}

export default function TransferList({ leftData, rightData, checkingClassesEmpty }: props) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState<any[]>([]);
    const [left, setLeft] = React.useState<any[]>(leftData);
    const [right, setRight] = React.useState<any[]>(rightData);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const dispatch = useDispatch();
    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
        handleEmpty(false);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        handleEmpty(false);
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));

        if (right.length <= 1) {
            handleEmpty(true);
        }
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
        handleEmpty(true);
    };

    const handleEmpty = (val: boolean) => {
        checkingClassesEmpty(val);
    };

    dispatch(fetchSelectedUsers(right));
    const customList = (items: any[]) => (
        <Card>
            <List className={classes.list} dense component="div" role="list">
                {items.map((value: any) => {
                    const labelId = `transfer-list-item-${value.id || value.class_name}-label`;
                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={value.user_name || value.class_name || value.class_type + ' - ' + value.Name}
                            />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <div className={classes.root}>
            <Grid container alignItems="center" justify="flex-start" xs={12}>
                <Grid xs={5}>{customList(left)}</Grid>
                <Grid xs={2}>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            aria-label="move all right"
                        >
                            ≫
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleAllLeft}
                            disabled={right.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </Button>
                    </Grid>
                </Grid>
                <Grid xs={5}>{customList(right)}</Grid>
            </Grid>
        </div>
    );
}
