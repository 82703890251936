import React, {useEffect, useState} from 'react';
import {assignRuleToUser, fetchAllRulesUsergroup} from "../../../Services/rules";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Loader from "../../../components/Loader";
import NoDataFound from "../../../components/Messages/NoDataFound";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import theme from "../../../theme";
import Divider from "@material-ui/core/Divider";
import {TreeListTextFilter} from "@progress/kendo-react-treelist";
import Treefile from "../../../components/KendoUICmpt/TreeList";
import {BasePath} from "../../../config";
import ConfirmationPopover from '../../Favorites/Components/ConfirmationPopover';
import SnackbarCmpt from '../../../components/Snackbar';

function Index(props:any) {

    const styles = {
        root: {
            margin: theme.spacing(2),
            height: '82vh',
        }
    }

    const [state, setState] = useState({ show: false, success: false });

    const columns  = [
        {
            field: 'Name',
            title: 'Usergroup Name',
            width: 250,
            filter: TreeListTextFilter,
            expandable: true,
        },
        {
            field: 'description',
            title: 'Description',
            width: 250,
            filter: TreeListTextFilter,
        },
        {
            width: 20,
            title: 'Remove',
            cell: (rowData: any) => (
                <td>
                    <ConfirmationPopover handleYes={(e: any) => handleAssignRule(e, rowData)} />
                </td>
            ),
        },
        ]



        const removeDuplicate = (selected:any, alreadyIn:any) => {
            let returnData:any = [];
            alreadyIn.map((already:any)=>{
                if(already.id != selected){
                    returnData.push(already)
                }
            })

            return returnData;
    };

    const handleAssignRule = (e:any, rowData:any) => {

        let userGroupData:any = rowData.dataItem;
        
            delete userGroupData['expanded'];

        let ItemParams = props.match.params;
        let { ruleID } = ItemParams;

            let userGroupAlreadyIn = removeDuplicate(userGroupData, props.rulesUsergroups.usergroups)
            let data = {
                "id":props.rulesUsergroups.id,
                "usergroups": [...userGroupAlreadyIn, {...userGroupData,
                    RuleActive: false,
                    RuleCreatedBy: `${props.user.UserName}`}]
            }


        props.assignRuleToUser(props.rulesUsergroups.id, data, (res:any)=>{
            if (res) {
                setState({ show: true, success: true });
                
            } else {
                setState({ show: true, success: false });
               
            }

            setTimeout(()=>{
                props.fetchAllRulesUsergroup(ruleID);
            }, 1000)
        })

    }

    let ItemParams = props.match.params;
    let { ruleID } = ItemParams;

    useEffect(function () {
        if(ruleID){
            props.fetchAllRulesUsergroup(ruleID)
        }

    }, []);



    const assignRule = () => {
        let ItemParams = props.match.params;
        let { ruleID } = ItemParams;
        props.history.push(`${BasePath}/rule/assign/${ruleID}`);
    };


    const editRule = () => {
        let ItemParams = props.match.params;
        let { ruleID } = ItemParams;
        props.history.push(`${BasePath}/rule/edit/${ruleID}`);
    };



    const getLabelAndValue = (label:any, value:any) => {
        return(
            <Grid container spacing={3}>
                <Grid item xs={6} md={3}>
                    <Typography variant={'body1'} style={{fontWeight:"bold"}}>{label}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Typography variant={'body1'}>{value}</Typography>
                </Grid>
            </Grid>
        )
    };

    const showRuleUsergroupData = () => {
        let data = props.rulesUsergroups;
        return(
            <div>

                <Grid container>
                    <Grid container justify="flex-end">

                    <Button
                            style={{ marginTop: '16px' }}
                            variant="contained"
                            onClick={() => {
                                editRule()
                            }}
                            color="primary"
                        >
                            Edit Rule
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                            style={{ marginTop: '16px' }}
                            variant="contained"
                            onClick={() => {
                                assignRule()
                            }}
                            color="secondary"
                        >
                            Assign Rule
                        </Button>
                    </Grid>
                </Grid>

                <div>
                    {getLabelAndValue('Rule Name', data.rule_name)}
                    <Divider style={{margin:theme.spacing(1)}}/>
                    {getLabelAndValue('Rule Expression', data.rule_expression)}
                    <Divider style={{margin:theme.spacing(1)}} />
                    {getLabelAndValue('Rule Code', data.rule_code)}
                    <Divider style={{margin:theme.spacing(1)}} />
                    {getLabelAndValue('Active Status', data.IsActive?"Active":"In Active")}
                    <Divider style={{margin:theme.spacing(1)}} />
                </div>

                <div>
                    {data.usergroups && data.usergroups.length > 0 &&
                    <div>
                        <div>
                        <Typography variant={'body1'} style={{fontWeight:"bold"}}>{"Usergroups With Rule"}</Typography>
                            
                        </div>
                        <Treefile columns={columns} tableData={data.usergroups}/>
                    </div>
                    }
                </div>


            </div>
        )
    };

    return (

            <div style={styles.root}>
                {props.processing && <Loader/>}
                {props.rulesUsergroups && props.rulesUsergroups.rule_name?
                    showRuleUsergroupData()
                    :
                    !props.processing && <div>
                        <NoDataFound title={'No Permission Rules found'}/>
                    </div>}

                    {state.show && (
                   <SnackbarCmpt
                       message={state.success ? 'deleted successfully' : 'Could not delete'}
                       severity={state.success ? 'success' : 'error'}
                   />
               )}
            </div>
    );

}
const mapStateToProps = (state: any) => {
    return {
        rulesUsergroups: state.rules_reducer.rulesUsergroups,
        processing: state.rules_reducer.processing,
        usergroup: state.usergroup_reducer.usergroup,
        user: state.user_reducer.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAllRulesUsergroup: (id:any) => dispatch(fetchAllRulesUsergroup(id)),
        assignRuleToUser:(id:any, data:any, cb:any) => dispatch(assignRuleToUser(id, data, cb))
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);