import {
    SET_SELECTED_CONFIGURATION_TYPE,
    SET_CLASS_TYPE_PROCESSING,
    SET_CLASS_TYPE_DATA,
    SET_CLASS_TYPE_ERROR,
    SET_CLASS_TYPE_TABS_PROCESSING,
    SET_CLASS_TYPE_TABS_DATA,
    SET_CLASS_TYPE_TABS_ERROR,
    SET_GENERAL_CONFIG,
    SET_GENERAL_CONFIG_PROCESSING
} from '../action-types/configurations-action-types';
export const setSelectedConfiguration = (selectedConfiguration: string) => {
    return {
        type: SET_SELECTED_CONFIGURATION_TYPE,
        payload: selectedConfiguration,
    };
};

// class type configuration actions.
export const setClassTypeProcessing = (data: any) => {
    return {
        type: SET_CLASS_TYPE_PROCESSING,
        payload: data,
    };
};
export const setClassTypeData = (data: any) => {
    return {
        type: SET_CLASS_TYPE_DATA,
        payload: data,
    };
};
export const setClassTypeError = (data: any) => {
    return {
        type: SET_CLASS_TYPE_ERROR,
        payload: data,
    };
};
// class type tabs configuration actions.
export const setClassTypeTabsProcessing = (data: any) => {
    return {
        type: SET_CLASS_TYPE_TABS_PROCESSING,
        payload: data,
    };
};
export const setClassTypeTabsData = (data: any) => {
    return {
        type: SET_CLASS_TYPE_TABS_DATA,
        payload: data,
    };
};
export const setClassTypeTabsError = (data: any) => {
    return {
        type: SET_CLASS_TYPE_TABS_ERROR,
        payload: data,
    };
};

export const setGeneralConfig = (data:any)=>{
    return{
        type: SET_GENERAL_CONFIG,
        payload: data,
    }
}

export const setGeneralConfigProcessing = (data:any)=>{
    return{
        type: SET_GENERAL_CONFIG_PROCESSING,
        payload: data,
    }
}