import React from 'react';
import SearchContainer from '../../Containers/Search';
import RoleWraper from '../../HOC/RoleWraper';
import { Grid } from '@material-ui/core';
import Breadcrumbs from '../../HOC/Breadcrumbs';
import { BasePath } from '../../config';
const Search = (props: any) => {
    return (
        // @ts-ignore
        <RoleWraper view="Search">
            <Grid>
                <Grid container>
                    <Grid xs={12}>
                        {props.location && props.location.pathname === `${BasePath}/search` && <SearchContainer />}
                    </Grid>
                </Grid>
            </Grid>
        </RoleWraper>
    );
};

export default Search;
