import {
    GET_RULES,
    RULES_PROCESSING,
    GET_RULES_USERGROUP
} from '../action-types/rules-action-types';

export const getRules = (data:any) => {
    return {
        type: GET_RULES,
        payload: data
    };
};

export const getRulesUsergroup = (data:any) => {
    return {
        type: GET_RULES_USERGROUP,
        payload: data
    };
};


export const rulesProcessing = (flag:any) => {
    return {
        type: RULES_PROCESSING,
        payload: flag
    };
};