import React, { useEffect, useState } from 'react';
import { Divider, Grid, TextField, Typography } from '@material-ui/core';

import {Checkbox, Input} from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {setPrevStateForSearch} from '../../../../Services/browse'
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import theme from '../../../../theme';
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme: any) => ({
    titleContainer: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
}));

const SearchingComponent = (props: any) => {
    const dispatch = useDispatch();
    let { simpleSearchPrevState } = useSelector((state: any) => ({
        ...state.browse_reducer,
    }));

    let [state, setState] = useState({
        searchValue: simpleSearchPrevState.searchValue ?  simpleSearchPrevState.searchValue :'',
        loaded: false,
        attachmentFlag: simpleSearchPrevState.attachmentFlag == null ? true : simpleSearchPrevState.attachmentFlag
    });

    useEffect(() => {});

    const classes = useStyles();

    const StateVal = ['NONE', 'CREATE', 'OBSOLETE', 'RELEASED', 'REVIEW'];

    const handleChange = (event: any) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        dispatch(setPrevStateForSearch({
            ...state,
            [event.target.name]: event.target.value,
        }))
    };

    const resetFilter = () => {
        setState({
            searchValue:'',
            loaded: false,
            attachmentFlag:true
        });
    };

    const handleClick = () => {
        setState({
            ...state,
            attachmentFlag:!state.attachmentFlag
        });

        dispatch(setPrevStateForSearch({
            ...state,
            attachmentFlag: !state.attachmentFlag
        }))
    };

    const handleAction = () => {
        let searchString = '';
        if (state.searchValue != '') {
            searchString += `&searchValue=${state.searchValue}&isAttachment=${state.attachmentFlag}`;
        }


        props.callWithFilters(searchString);
    };

    const validSearch = () => {
        let result = false;
        if (state.searchValue != '') {
            result = true;
        }
        return result;
    };

    const handleKeypress =( e:any )=> {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            let searchString = '';
            if (state.searchValue != '') {
                searchString += `&searchValue=${state.searchValue}&isAttachment=${state.attachmentFlag}`;
            }

            props.callWithFilters(searchString);
        }
    };

    return (
        <Grid container direction={'row'} spacing={2}>
            <Grid xs={12} sm={12} md={4} item>
                {/*<TextField*/}
                {/*    variant={"outlined"}*/}
                {/*    style={{ backgroundColor: "#ffffff" }}*/}
                {/*    fullWidth*/}
                {/*    label={"Class Name"}*/}
                {/*    placeholder={"for eg: some class name"}*/}
                {/*    name="ClassName"*/}
                {/*    onChange={handleChange}*/}
                {/*    type="text"*/}
                {/*    value={state.ClassName || ""}*/}
                {/*/>*/}{' '}
                <Input
                    style={{ backgroundColor: '#ffffff', width: '100%' }}
                    label={'Search'}
                    placeholder={'Enter Text'}
                    name="searchValue"
                    onChange={handleChange}
                    type="text"
                    value={state.searchValue || ''}
                    onKeyPress={handleKeypress}
                />
            </Grid>

            <Grid xs={12} sm={12} md={2} item style={{marginTop:theme.spacing(3)}} >
                <Checkbox

                    value={state.attachmentFlag}
                    onChange={handleClick}
                    label={"Include Attachments"}
                />
            </Grid>

            <Grid xs={12} sm={6} md={12} style={{ marginBottom: theme.spacing(2) }} item>
                <Button
                    color="primary"
                    disabled={!validSearch()}
                    size="small"
                    type="submit"
                    variant="contained"
                    onClick={handleAction}
                >
                    Search
                </Button>
            </Grid>
        </Grid>
    );
};

export default withRouter(SearchingComponent);
