export const SET_TABS_DATA = 'SET_TABS_DATA';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const TABS_ERROR = 'TABS_ERROR';
export const TABS_PROCESSING = 'TABS_PROCESSING';
export const SET_TABS_STORED_DATA = 'SET_TABS_STORED_DATA';

export const SET_CURRENT_TAB_DATA = 'SET_CURRENT_TAB_DATA';
export const SET_CURRENT_TAB_DATA_PROCESSING = 'SET_CURRENT_TAB_DATA_PROCESSING';
export const SET_CURRENT_TAB_DATA_ERROR = 'SET_CURRENT_TAB_DATA_ERROR';

export const SET_TAB_DATA_COUNT = 'SET_TAB_DATA_COUNT';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';

export const SET_CURRENT_REVISIONS = 'SET_CURRENT_REVISIONS';
export const SET_SELECTED_REVISION = 'SET_SELECTED_REVISION';

export const SET_ATTACHMENT_REVISIONS = 'SET_ATTACHMENT_REVISIONS'
export const SET_ATTACHMENT_REVISIONS_PROCESSING = 'SET_ATTACHMENT_REVISIONS_PROCESSING'