import { Button, Grid, IconButton } from '@material-ui/core';
import { TreeListDateFilter, TreeListTextFilter } from '@progress/kendo-react-treelist';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Treefile from '../../components/KendoUICmpt/TreeList';
import { fetchUsergroups, fetchEditData, fetchSelectedUsers, setNewPageSizeUsergroup } from '../../Services/usergroup';
import { containers } from '../../utils/style';
import EditIcon from '@material-ui/icons/Edit';
import { fetchUsers } from '../../Services/userManagement';
import theme from '../../theme';
import CustomPagination from '../../components/CustomPagination';
import Loader from '../../components/Loader';
import NoDataFound from '../../components/Messages/NoDataFound';
import { BasePath } from '../../config';
import moment from 'moment';
interface props {
    processing: any;
    usergroup: any;
    fetchUsergroups: any;
    history: any;
    fetchEditData: any;
    editData: any;
    fetchUsers: any;
    userData: any;
    fetchSelectedUsers: any;
    selectedUsers: any;
    total: any;
    setNewPageSizeUsergroup: any;
    pageSizeUsergroup: any;
}

class Index extends Component<props> {
    state = {
        add: false,
        page: 1,
        filter: [],
    };

    componentDidMount() {
        this.props.fetchUsergroups(this.state.page);
        this.props.fetchUsers();
        this.props.fetchSelectedUsers([]);
    }

    handleFilterChange = (event: any) => {
        this.setState({
            filter: event.filter,
        });
    };

    columns = [
        {
            field: 'Name',
            title: 'Usergroup Name',
            width: 250,
            filter: TreeListTextFilter,
            expandable: true,
        },
        {
            field: 'description',
            title: 'Description',
            width: 250,
            filter: TreeListTextFilter,
        },
        {
            field: 'create_date',
            title: 'Created Date',
            width: 200,
            filter: TreeListTextFilter,
        },
        {
            field: 'created_by',
            title: 'Created By',
            width: 200,
            filter: TreeListTextFilter,
        },
        {
            title: 'Total Member(s)',
            field: 'users',
            width: 20,
            cell: (rowData: any) => (
                <td>{rowData.dataItem.users && rowData.dataItem.users.length ? rowData.dataItem.users.length : 0}</td>
            ),
        },
        {
            width: 20,
            cell: (rowData: any) => (
                <IconButton aria-label="Edit" onClick={(e: any) => this.handleEdit(e, rowData)}>
                    <EditIcon />
                </IconButton>
            ),
        },
    ];

    handleAdd = () => {
        this.props.history.push(BasePath + '/userGroup/add');
    };

    handleEdit = (e: any, rowData: any) => {
        this.props.fetchEditData(rowData);
        console.log(rowData);
        this.props.history.push(BasePath + '/userGroup/edit');
    };

    handlePage = (value: any) => {
        this.setState({
            page: parseInt(value),
        });
        this.props.fetchUsergroups(value);
    };

    handleNewPageSize = (event: any) => {
        this.props.setNewPageSizeUsergroup(event.target.value);
        this.props.fetchUsergroups(1);
    };

    render() {
        return (
            <div>
                <Grid container>
                    <Grid container justify="flex-end">
                        <Button
                            style={{ marginTop: '16px' }}
                            variant="contained"
                            onClick={() => {
                                this.setState({
                                    add: true,
                                });
                            }}
                            color="primary"
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>

                <div style={{ marginTop: 20 }}>
                    {this.props.processing && <Loader />}

                    {this.props.usergroup != null ? (
                        <div>
                            <Treefile columns={this.columns} tableData={this.props.usergroup} />
                            <div style={{ margin: theme.spacing(3) }}>
                                <CustomPagination
                                    total={this.props.total}
                                    page={this.state.page}
                                    handlePage={this.handlePage}
                                    pageSize={this.props.pageSizeUsergroup}
                                    handleNewPageSize={this.handleNewPageSize}
                                />
                            </div>
                        </div>
                    ) : (
                        !this.props.processing && (
                            <div style={{ width: '100%' }}>
                                <NoDataFound title={'No Usergroups found'} />
                            </div>
                        )
                    )}
                </div>
                {this.state.add ? this.handleAdd() : null}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        processing: state.usergroup_reducer.processing,
        usergroup: state.usergroup_reducer.usergroup,
        editData: state.usergroup_reducer.editData,
        userData: state.userManagement_reducer.userData,
        selectedUsers: state.usergroup_reducer.selectedUsers,
        pageSizeUsergroup: state.usergroup_reducer.pageSizeUsergroup,
        total: state.usergroup_reducer.total,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchUsergroups: (page: any) => dispatch(fetchUsergroups(page)),
        fetchEditData: (data: any) => dispatch(fetchEditData(data)),
        fetchUsers: () => dispatch(fetchUsers()),
        fetchSelectedUsers: (data: any) => dispatch(fetchSelectedUsers(data)),
        setNewPageSizeUsergroup: (event: any) => dispatch(setNewPageSizeUsergroup(event)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);
