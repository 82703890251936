import { SERVER_URL } from '../config';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import theme from '../theme';
import React from 'react';
import moment from "moment-timezone";
import { TreeListTextFilter } from "@progress/kendo-react-treelist";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

export const COMPLETE_STRING = 'Completed';
export const IN_PROGRESS = 'InProgress';

export const comparer: any = (otherArray: any) => {
    return function (current: any) {
        return (
            otherArray.filter(function (other: any) {
                return other.value == current.value && other.display == current.display;
            }).length == 0
        );
    };
};

export const returnUTCFormatedDate = (dateTime: any) => {
    let dateVal = dateTime;
    if (!dateTime.includes('T') || !dateTime.includes('Z')) {
        dateVal = moment.utc(dateTime)
    }
    return dateVal
}

export const setPageSize = (total: any, pageSize: any) => {
    let result = parseInt(total / pageSize + (total % pageSize ? 1 : 0) + '');

    return result;
};

export const sort_by = (field: any, reverse: any, primer: any) => {
    const key = primer
        ? function (x: any) {
            return primer(x[field]);
        }
        : function (x: any) {
            return x[field];
        };

    reverse = !reverse ? 1 : -1;

    return function (a: any, b: any) {
        //@ts-ignore
        return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
};

export const getFileExtension = (fileName: any) => {
    let splitArray = fileName.split('.');
    let index = splitArray.length - 1;
    return splitArray[index];
};

export const getFilePath = (Path: any) => {
    return `${SERVER_URL}${Path.split('public')[1]}`;
};

export const checkEmpty = (dataArray: any, key: any) => {
    let empty = false;
    let val = dataArray.filter((data: any) => {
        return data[key] == '';
    });
    if (val.length > 0) {
        empty = true;
    }
    return empty;
};

export const showDataTitleAndValue = (title: any, values: any) => {
    let styles: any = {
        root: {
            padding: theme.spacing(1),
        },
        title: {
            fontSize: '16px',
            fontWeight: '400',
            color: '#0e0e0e',
        },
        body: {
            fontSize: '15px',
            fontWeight: '400',
        },
    };

    return (
        <Grid container style={styles.root}>
            <Grid xs={5}>
                <Typography variant="body2" style={styles.title}>
                    {title}:
                </Typography>
            </Grid>
            <Grid xs={7}>
                <Typography variant="body2" style={styles.body}>
                    {values}
                </Typography>
            </Grid>
        </Grid>
    );
};

export const showDescriptionData = (title: any, values: any) => {
    let styles: any = {
        root: {
            padding: theme.spacing(1),
        },
        title: {
            fontSize: '16px',
            fontWeight: '400',
            color: '#0e0e0e',
        },
        body: {
            fontSize: '15px',
            fontWeight: '400',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'break-spaces',
            maxHeight: '50px',
        },
    };

    return (
        <Grid container style={styles.root}>
            <Grid xs={12}>
                <Typography variant="body2" style={styles.title}>
                    {title}:
                </Typography>
            </Grid>
            <Grid xs={12}>
                <Typography variant="body2" style={styles.body}>
                    {values}
                </Typography>
            </Grid>
        </Grid>
    );
};



export const filterDataANDLayout = (dataArray: any) => {
    let returnValue: any = {
        items: [],
        documents: [],
        change: [],
        manf: [],
        manfprt: [],
        itemAttach: [],
        documentsAttach: [],
        change_attachments: [],
        affected_items: [],
        affected_attachment: []

    };


    dataArray && dataArray.length && dataArray.map((data: any) => {
        if (data.attachment && data.affected_items) {
            returnValue.affected_attachment.push(replaceClassName(data))
        } else if (data.attachment) {
            if (data.CLASS_TYPE == 'Changes') {
                returnValue.change_attachments.push(replaceClassName(data))
            } else {
                switch (data['CLASS_TYPE']) {
                    case 'Parts':
                        returnValue.itemAttach.push(replaceClassName(data))
                        break;
                    case 'Documents':
                        returnValue.documentsAttach.push(replaceClassName(data))
                        break;
                    case 'Manufacturers':
                        returnValue.manf.push(replaceClassName(data))
                        break;
                    case 'Manufacturer_parts':
                        returnValue.manfprt.push(replaceClassName(data))
                        break;
                }
            }
        } else if (data.affected_items) {
            returnValue.affected_items.push(replaceClassName(data));
        } else {
            if (data.CLASS_TYPE == 'Changes') {
                returnValue.change.push(replaceClassName(data))
            } else {
                switch (data['CLASS_TYPE']) {
                    case 'Parts':
                        returnValue.items.push(replaceClassName(data))
                        break;
                    case 'Documents':
                        returnValue.documents.push(replaceClassName(data))
                        break;
                    case 'Manufacturers':
                        returnValue.manf.push(replaceClassName(data))
                        break;
                    case 'Manufacturer_parts':
                        returnValue.manfprt.push(replaceClassName(data))
                        break;
                }
            }
        }
    })

    return returnValue;
}

function replaceClassName(data: any) {
    let returnData = { ...data }
    if (data && data['Class Name']) {
        delete returnData['Class Name'];
        switch (data['CLASS_TYPE']) {
            case 'Parts':
                returnData['Part Type'] = data['Class Name'];
                break;
            case 'Documents':
                returnData['Document Category'] = data['Class Name'];
                break;
            case 'Changes':
                returnData['Change Type'] = data['Class Name'];
                break;
            case 'Manufacturers':
                returnData['Mfr Type'] = data['Class Name'];
                break;
            case 'Manufacturer_parts':
                returnData['Mfr Part Type'] = data['Class Name'];
                break;
        }
    }

    return returnData

}


export function filterAndRearrange(data: any, toExport?: any) {
    data = replaceClassName(data)
    let newObj: any = {}
    let isAffected = data["affected_items"] ? true : false
    if (isAffected) {
        newObj["Item Number"] = data["affected_items"]["Item Number"]
        newObj["Revision"] = data["affected_items"]["Revision"]
        newObj["Description"] = data["affected_items"]["Description"]
        newObj["Class Name"] = data["affected_items"]["Class Name"]
        newObj["Class Type"] = data["affected_items"]["Class Type"]
        if (data["File Name"]) {
            newObj["File Name"] = data["File Name"]
        }
        if (data['file_path']) {
            newObj["file_path"] = data["file_path"]
        }

        // if (data['DID'] || (data['file_path'] && data['custom_id'])) {
        //     newObj["DID"] = data['custom_id']
        // }
        newObj["Lifecycle Phase"] = data["affected_items"]["Lifecycle Phase"]
        newObj["Document Owner"] = data["Document Owner"] ? data["Document Owner"] : data["affected_items"]["Document Owner"]
        newObj["Doc Owner Delegate"] = data["Doc Owner Delegate"] ? data["Doc Owner Delegate"] : data["affected_items"]["Doc Owner Delegate"]
        if (data['Number'] || data['Item Number'] || data['Name'] || data['Manufacturer Part Number']) {
            let property: any;
            if (data['Number']) property = 'Number'
            if (data['Item Number']) property = 'Item Number'
            if (data['Name']) property = 'Name'
            if (data['Manufacturer Part Number']) property = 'Manufacturer Part Number'
            newObj["Change Number"] = data[property]
        }
    }
    else {
        
        if (data['Number'] || data['Item Number'] || data['Name'] || data['Manufacturer Part Number']) {
            let property: any;
            if (data['Number']) property = 'Number'
            if (data['Item Number']) property = 'Item Number'
            if (data['Name'] && !data['Number']) property = 'Name'
            if (data['Manufacturer Part Number'] && !data['Number']) property = 'Manufacturer Part Number'

            newObj[property] = data[property]
        }

        if (data['Name'] && data['Number']) {
            newObj["Name"] = data["Name"]
        }

        if (data['Manufacturer Part Number'] && !data['Number']) {
            newObj["Manufacturer Part Number"] = data["Manufacturer Part Number"]
        }
        if (data['Revision']) {
            newObj["Revision"] = data["Revision"]
        }
        if (data['Description']) {
            newObj["Description"] = data["Description"]
        }
        if (data['Part Type']) {
            newObj['Part Type'] = data['Part Type'];
        }
        if (data['Document Category']) {
            newObj['Document Category'] = data['Document Category'];
        }
        if (data['Change Type']) {
            newObj['Change Type'] = data['Change Type'];
        }
        if (data['Mfr Type']) {
            newObj['Mfr Type'] = data['Mfr Type'];
        }
        if (data['Mfr Part Type']) {
            newObj['Mfr Part Type'] = data['Mfr Part Type'];
        }
        if (data['Class Type']) {
            newObj["Class Type"] = data["Class Type"]
        }
        if (data["Class Type"] === "Documents") {
            delete data["Unit Of Measure"]
        }
        if (data["File Name"]) {
            newObj["File Name"] = data["File Name"]
        }
        if (data['file_path']) {
            newObj["file_path"] = data["file_path"]
        }
        // if (data['DID'] || (data['file_path'] && data['custom_id'])) {
        //     newObj["DID"] = data['custom_id']
        // }
        if (data['Lifecycle Phase']) {
            newObj["Lifecycle Phase"] = data["Lifecycle Phase"]
        }
        if (data['Document Owner']) {
            newObj["Document Owner"] = data["Document Owner"]
        }
        if (data['Doc Owner Delegate']) {
            newObj["Doc Owner Delegate"] = data["Doc Owner Delegate"]
        }
    }
    if (data['Creation Date']) {
        newObj["Creation Date"] = data["Creation Date"]
    }
    if (data['Released Date']) {
        newObj["Released Date"] = data["Released Date"]
    }
    if (!toExport && data['IsLatest']) {
        newObj["IsLatest"] = data["IsLatest"]
    }
    delete data["custom_id"]

    //delete data["IsLatest"]
    delete data["attachment"]
    delete data["isAttachment"]
    delete data["isAffectedItem"]
    delete data["File Name"]
    delete data["Number"]
    delete data["Item Number"]
    delete data["Name"]
    delete data["Manufacturer Part Number"]
    delete data["Revision"]
    delete data["Class Name"]
    delete data["Class Type"]
    delete data["Lifecycle Phase"]
    delete data["file_path"]
    delete data["DID"]
    delete data["Change Type"]
    delete data["Mfr Type"]
    delete data["Mfr Part Type"]
    delete data["Description"]
    delete data["affected_items"]
    delete data["Document Owner"]
    delete data["Doc Owner Delegate"]

    newObj = { ...newObj, ...data }

    return newObj
}