import React from 'react';
import MaterialTable, { Column } from 'material-table';
import theme from '../../theme';

interface Row {
    name: string;
    surname: string;
    birthYear: number;
    birthCity: number;
}

interface TableState {
    columns: Array<Column<Row>>;
    data: Row[];
    title: '';
}

export default function CourseRecords(props: any) {
    const [state, setState] = React.useState<TableState>({
        columns: props.columns,
        data: props.tableData,
        title: props.title,
    });

    return (
        <MaterialTable
            title={props.title || ''}
            columns={state.columns}
            data={state.data}
            options={{
                actionsColumnIndex: -1,
                paging: false,
                search: false,
            }}

            // actions={[
            //     {
            //         icon: 'info',
            //         tooltip: 'View Details',
            //         onClick: (event: any, rowData: any) => props.showDetails(rowData)
            //     }
            // ]}
        />
    );
}
