import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
    Button,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import theme from '../../theme';

const NotificationsFilter = withRouter((props: any) => {
    let [state, setState] = useState({
        NotificationTypeName: '',
        Events: '',
        Roles: null,
        Status: null,
        DashboardNotification: false,
    });
    const handleChange = (event: any) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    const handleSwitch = (event: any) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSearch = () => {
        let searchString = '';
        if (state.NotificationTypeName != '') {
            searchString += `&NotificationName=${state.NotificationTypeName}`;
        }
        if (state.Events != '') {
            searchString += `&EventName=${state.Events}`;
        }
        if (state.Roles != null) {
            searchString += `&RoleID=${state.Roles}`;
        }
        if (state.Status != null) {
            searchString += `&IsActive=${state.Status}`;
        }
        if (state.DashboardNotification) {
            searchString += `&DashboardNotification=1`;
        }
        props.callWithFilters(searchString);
    };
    const handleReset = () => {
        setState({
            NotificationTypeName: '',
            Events: '',
            Roles: null,
            Status: null,
            DashboardNotification: false,
        });
        props.callWithFilters('');
    };

    let { notificationRoles } = useSelector((state: any) => ({
        ...state.notifications_reducer,
    }));
    return (
        <Grid container style={{ margin: 20 }}>
            <Grid xs={6}>
                <Grid container>
                    <Grid xs={12}>
                        <FormControl fullWidth variant="outlined" style={{ backgroundColor: '#fff' }}>
                            <InputLabel style={{ backgroundColor: '#ffffff' }} id="demo-simple-select-outlined-label">
                                Item Class
                            </InputLabel>
                            <Select
                                variant={'outlined'}
                                name="Roles"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={state.Roles || ''}
                                onChange={handleChange}
                            >
                                {notificationRoles &&
                                    notificationRoles.map((data: any) => {
                                        return <MenuItem value={data.ID}>{data.Name}</MenuItem>;
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ marginTop: 16, marginBottom: 16 }}></div>

                {/* <Grid container justify="flex-end">
          <Grid xs={10}>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ backgroundColor: "#fff" }}
            >
              <InputLabel
                style={{ backgroundColor: "#ffffff" }}
                id="demo-simple-select-outlined-label"
              >
                Item Class
              </InputLabel>
              <Select
                variant={"outlined"}
                name="Status"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.Status || ""}
                onChange={handleChange}
              >
                <MenuItem value={"0"}>Inactive</MenuItem>
                <MenuItem value={"1"}>Active</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ marginTop: 16, marginBottom: 16 }}></div> */}

                {/* <Grid
          container
          justify="center"
          style={{ paddingLeft: theme.spacing(7) }}
        >
          <Grid item xs={10}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.DashboardNotification}
                  onChange={handleSwitch}
                  name={"DashboardNotification"}
                />
              }
              label={"Show only Dashboard Notification"}
            />
          </Grid>
        </Grid> */}
            </Grid>
            <Grid xs={6}>
                <Grid container justify="flex-end">
                    <Grid item xs={10}>
                        <TextField
                            variant={'outlined'}
                            label="Item Class"
                            fullWidth
                            name="Item Class"
                            onChange={handleChange}
                            type="text"
                            value={state.NotificationTypeName || ''}
                            style={{ background: '#fff' }}
                        />
                    </Grid>
                </Grid>
                {/* <div style={{ marginTop: 16, marginBottom: 16 }}></div> */}
                {/*  <Grid container>
           <Grid item xs={10}>
            <TextField
              variant={"outlined"}
              label="Item Class"
              fullWidth
              name="Item Class"
              onChange={handleChange}
              type="text"
              value={state.Events || ""}
              style={{ background: "#fff" }}
            />
          </Grid>
        </Grid> */}

                <Grid container xs={12} direction="row" justify="flex-end">
                    <Button
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleSearch}
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                            marginRight: theme.spacing(1),
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        color="default"
                        size="large"
                        type="submit"
                        variant="outlined"
                        onClick={handleReset}
                        style={{ marginBottom: 20, marginTop: 20 }}
                    >
                        Reset Filters
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default NotificationsFilter;
