import {Operators, TextFilter, DateFilter} from "@progress/kendo-react-data-tools";
import {UIExperince} from "../../../../config";

let conditionsData  = {
    "Select All" : [
        { name: 'Number', label: 'Number', filter: TextFilter, operators: Operators.text, value:"this is a value" },
        { name: 'Description', label: 'Description', filter: TextFilter, operators: Operators.text },
        { name: 'Lifecycle Phase', label: 'Lifecycle Phase', filter: TextFilter, operators: Operators.text },
        { name: 'Rev', label: 'Rev', filter: TextFilter, operators: Operators.text },
        { name: 'Planning Type', label: 'Planning Type', filter: TextFilter, operators: Operators.text },
        { name: 'Status', label: 'Status', filter: TextFilter, operators: Operators.text },
        { name: 'Reason for Change', label: 'Reason for Change', filter: TextFilter, operators: Operators.text },
        { name: 'Description of Change', label: 'Description of Change', filter: TextFilter, operators: Operators.text },
        { name: 'Originator', label: 'Originator', filter: TextFilter, operators: Operators.text },
        { name: 'Date Originated', label: 'Date Originated', filter: DateFilter, operators: Operators.text },
        { name: 'Date Released', label: 'Date Released', filter: DateFilter, operators: Operators.text },
        { name: 'affected_items.Item Number', label: 'Item Number (Affected Objects)', filter: TextFilter, operators: Operators.text },
        { name: 'affected_items.Lifecycle Phase', label: 'Lifecycle Phase  (Affected Objects)', filter: TextFilter, operators: Operators.text },
        { name: 'affected_items.Description', label: 'Item Description (Affected Objects)', filter: TextFilter, operators: Operators.text },
        { name: 'affected_items.VPAP Level', label: 'VPAP Level  (Affected Objects)', filter: TextFilter, operators: Operators.text },
        { name: 'affected_items.Roche Material Number', label: 'Roche Material Number (Affected Objects)', filter: TextFilter, operators: Operators.text },
        { name: 'affected_items.planning_type', label: 'Planning Type (Affected Objects)', filter: TextFilter, operators: Operators.text },
        { name: 'Name', label: 'Name', filter: TextFilter, operators: Operators.text }
    ],

    "Parts": [
        { name: 'Number', label: 'Number', filter: TextFilter, operators: Operators.text, value:"this is a value" },
        { name: 'Description', label: 'Description', filter: TextFilter, operators: Operators.text },
        { name: 'Lifecycle Phase', label: 'Lifecycle Phase', filter: TextFilter, operators: Operators.text },
        { name: 'Rev', label: 'Rev', filter: TextFilter, operators: Operators.text },
        { name: 'Planning Type', label: 'Planning Type', filter: TextFilter, operators: Operators.text },
    ],
    "Documents": [
        { name: 'Number', label: 'Number', filter: TextFilter, operators: Operators.text },
        { name: 'Description', label: 'Description', filter: TextFilter, operators: Operators.text },
        { name: 'Lifecycle Phase', label: 'Lifecycle Phase', filter: TextFilter, operators: Operators.text },
        { name: 'Rev', label: 'Rev', filter: TextFilter, operators: Operators.text },
        { name: 'Planning Type', label: 'Planning Type', filter: TextFilter, operators: Operators.text },
    ],
    "Manufacturers": UIExperince.oraclePD ? [
        { name: 'Name', label: 'Name', filter: TextFilter, operators: Operators.text },
        { name: 'Description', label: 'Description', filter: TextFilter, operators: Operators.text },
        { name: 'Lifecycle Phase', label: 'Lifecycle Phase', filter: TextFilter, operators: Operators.text }
    ] : [
        { name: 'Name', label: 'Name', filter: TextFilter, operators: Operators.text },
        { name: 'Description', label: 'Description', filter: TextFilter, operators: Operators.text },
        { name: 'Lifecycle Phase', label: 'Lifecycle Phase', filter: TextFilter, operators: Operators.text }
    ],
    "Manufacturer_parts": [
        { name: 'Manufacturer Part Number', label: 'Manufacturer Part Number', filter: TextFilter, operators: Operators.text },
        { name: 'Manufacturer Name', label: 'Manufacturer Name', filter: TextFilter, operators: Operators.text },
        { name: 'Lifecycle Phase', label: 'Lifecycle Phase', filter: TextFilter, operators: Operators.text }
    ],

    "Changes" : UIExperince.oraclePD ? [
            { name: 'Number', label: 'Number', filter: TextFilter, operators: Operators.text },
            { name: 'Status', label: 'Status', filter: TextFilter, operators: Operators.text },
            { name: 'Reason for Change', label: 'Reason for Change', filter: TextFilter, operators: Operators.text },
            { name: 'Description of Change', label: 'Description of Change', filter: TextFilter, operators: Operators.text },
            { name: 'Originator', label: 'Originator', filter: TextFilter, operators: Operators.text },
            { name: 'Date Originated', label: 'Date Originated', filter: DateFilter, operators: Operators.text },
            { name: 'Date Released', label: 'Date Released', filter: DateFilter, operators: Operators.text },
            { name: 'affected_items.Item Number', label: 'Item Number (Affected Objects)', filter: TextFilter, operators: Operators.text },
            { name: 'affected_items.Lifecycle Phase', label: 'Lifecycle Phase  (Affected Objects)', filter: TextFilter, operators: Operators.text },
            { name: 'affected_items.Description', label: 'Item Description (Affected Objects)', filter: TextFilter, operators: Operators.text },
            { name: 'affected_items.VPAP Level', label: 'VPAP Level  (Affected Objects)', filter: TextFilter, operators: Operators.text },
            { name: 'affected_items.Roche Material Number', label: 'Roche Material Number (Affected Objects)', filter: TextFilter, operators: Operators.text },
            { name: 'affected_items.planning_type', label: 'Planning Type (Affected Objects)', filter: TextFilter, operators: Operators.text },
        ] :
        [
            { name: 'Number', label: 'Number', filter: TextFilter, operators: Operators.text },
            { name: 'Status', label: 'Status', filter: TextFilter, operators: Operators.text },
            { name: 'Reason for Change', label: 'Reason for Change', filter: TextFilter, operators: Operators.text },
            { name: 'Description of Change', label: 'Description of Change', filter: TextFilter, operators: Operators.text },
            { name: 'Originator', label: 'Originator', filter: TextFilter, operators: Operators.text },
            { name: 'Date Originated', label: 'Date Originated', filter: DateFilter, operators: Operators.date },
            { name: 'Date Released', label: 'Date Released', filter: DateFilter, operators: Operators.date },
            { name: 'affected_items.Item Number', label: 'Item Number (Affected Objects)', filter: TextFilter, operators: Operators.text },
            { name: 'affected_items.Item State', label: 'Item State  (Affected Objects)', filter: TextFilter, operators: Operators.text },
            { name: 'affected_items.Description', label: 'Description (Affected Objects)', filter: TextFilter, operators: Operators.text }
        ]

}



export default conditionsData;