import {
    GET_ALL_ATTACHMENTS,
    SET_ATTACHMENTS_PROCESSING,
    SET_CURRENT_USERGROUP,
    SET_USER_ATTACHMENT_PROCESSING,
    GET_USER_ATTACHMENTS
} from "../action-types/suppliers-action-types";

export const getAttachmentData = (data: any) => {
    return {
        type: GET_ALL_ATTACHMENTS,
        payload: data,
    };
};

export const setProcessingAttachment = (data: any) => {
    return {
        type: SET_ATTACHMENTS_PROCESSING,
        payload: data,
    };
};

export const setCurrentUsergroup = (data: any) => {
    return {
        type: SET_CURRENT_USERGROUP,
        payload: data,
    };
};


export const getUserAttachment = (data: any) => {
    return {
        type: GET_USER_ATTACHMENTS,
        payload: data,
    };
};

export const setProcessingUserAttachment = (data: any) => {
    return {
        type: SET_USER_ATTACHMENT_PROCESSING,
        payload: data,
    };
};