import React from 'react';
import RoleWraper from '../../HOC/RoleWraper';
import { Grid } from '@material-ui/core';
import { BasePath } from '../../config';
import SupplierContainer from '../../Containers/Suppliers';
import AddAttachment from '../../Containers/Suppliers/SupplierAttachments/AddAttachment'
const Suppliers = (props: any) => {
    return (
        // @ts-ignore
        <RoleWraper view="Suppliers">
            <Grid>
                <Grid container>
                    <Grid xs={12}>
                        {props.location && props.location.pathname === `${BasePath}/suppliers` && <SupplierContainer/>}
                        {props.location && props.location.pathname === `${BasePath}/suppliers/addAttachment` && <AddAttachment/>}
                    </Grid>
                </Grid>
            </Grid>
        </RoleWraper>
    );
};

export default Suppliers;
