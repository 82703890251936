import { combineReducers } from 'redux';
import categories_reducer from './categories_reducer';
import notifications_reducer from './notifications_reducer';
import dashboard_reducer from './dashboard_reducer';
import user_reducer from './user-reducer';
import browse_reducer from './browse_reducer';
import userManagement_reducer from './userManagement_reducer';
import usergroup_reducer from './usergroup-reducer';
import permission_reducer from './permission_reducer';
import itemForm_reducer from './itemForm_reducer';
import favorites_reducer from './favorites_reducer';
import configurations_reducer from './configurations_reducer';
import suppliers_reducer from "./suppliers_reducer";
import rules_reducer from './rules_reducer';
const reducer = combineReducers({
    categories_reducer,
    dashboard_reducer,
    notifications_reducer,
    user_reducer,
    itemForm_reducer,
    suppliers_reducer,
    rules_reducer,
    browse_reducer: browse_reducer,
    userManagement_reducer: userManagement_reducer,
    usergroup_reducer: usergroup_reducer,
    permission_reducer: permission_reducer,
    favorites_reducer: favorites_reducer,
    configurations_reducer: configurations_reducer,
});

export default reducer;
