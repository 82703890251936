import React, {useEffect, useState} from 'react';
import {fetchAllRulesUsergroup, assignRuleToUser} from "../../../Services/rules";
import {fetchUsergroups} from '../../../Services/usergroup';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Loader from "../../../components/Loader";
import NoDataFound from "../../../components/Messages/NoDataFound";
import {Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography} from "@material-ui/core";
import theme from "../../../theme";
import {BasePath} from "../../../config";
import SnackbarCmpt from '../../../components/Snackbar';

function Index(props:any) {

    const styles = {
        root: {
            margin: theme.spacing(2),
            height: '82vh',
        }
    }
    const [userGroup, setUsergroup] = useState(null);
    const [state, setState] = useState({ show: false, success: false });
    const [disable, setDisable] = useState(false);
    let ItemParams = props.match.params;
    let { ruleID } = ItemParams;

    useEffect(function () {

        if(ruleID && !props.rulesUsergroups ){
            props.fetchAllRulesUsergroup(ruleID)
        }
        props.fetchUsergroups();


    }, []);



    const handleChange = (event:any) => {
        setUsergroup(event.target.value)
    }

    const removeDuplicate = (selected:any, alreadyIn:any) => {
            let returnData:any = [];
            alreadyIn.map((already:any)=>{
                if(already.id != selected){
                    returnData.push(already)
                }
            })

            return returnData;
    };

    const handleAssignRule = () => {
        let ItemParams = props.match.params;
        let { ruleID } = ItemParams;

            let usergroupSelected = userGroup ?userGroup: props.usergroup[0];
            let userGroupAlreadyIn = removeDuplicate(usergroupSelected, props.rulesUsergroups.usergroups)
            let data = {
                "id":props.rulesUsergroups.id,
                "usergroups": [...userGroupAlreadyIn, {...usergroupSelected,
                    RuleActive: true,
                    RuleCreatedBy: `${props.user.UserName}`}]
            }

            setDisable(true)
        props.assignRuleToUser(props.rulesUsergroups.id, data, (res:any)=>{
            if (res) {
                setState({ show: true, success: true });
                
            } else {
                setState({ show: true, success: false });
               
            }

            setTimeout(()=>{
                setDisable(false)
                props.history.push(`${BasePath}/rule/details/${ruleID}`);
            }, 1000)
        })

    }

    const handleCancel = () => {
        let ItemParams = props.match.params;
        let { ruleID } = ItemParams;
        props.history.push(`${BasePath}/rule/details/${ruleID}`);
    }

    const showRuleAssignForm = () => {
           return <div>{props.usergroup && props.usergroup.length > 0 &&
           <div>

               <Grid container>
                   <Grid xs={12} md={4}>
                       <Typography style={{ margin: 10 }} variant={'h3'}>{"Assign Rule To User Group"}</Typography>
                   </Grid>
               </Grid>

               <Grid container>
                   <Grid xs={12} md={4}>
                       <TextField
                           disabled={true}
                           required
                           fullWidth
                           id={'RuleName'}
                           label="Rule Name"
                           variant={'outlined'}
                           name="Rule Name"
                           type="text"
                           value={props.rulesUsergroups.rule_name || ''}
                           style={{ margin: 10 }}
                       />
                   </Grid>
               </Grid>
               <Grid container >
                   <Grid xs={12} md={4}>
                       <FormControl variant="outlined"fullWidth  style={{ margin: 10 }}>
                           <InputLabel id="demo-simple-select-outlined-label" style={{background:"#ffffff", padding:"0px 5px"}}>{"  Role  "}</InputLabel>
                           <Select
                               required
                               name="roles"
                               labelId="demo-simple-select-outlined-label"
                               id="demo-simple-select-outlined"
                               value={userGroup ? userGroup : props.usergroup[0]}
                               onChange={handleChange}
                           >
                               {props.usergroup && props.usergroup.map((data: any, index:any) => {
                                   return <MenuItem key={index} value={data}>{data.Name}</MenuItem>
                               })}
                           </Select>
                       </FormControl>
                   </Grid>
               </Grid>

               <Grid container xs={4} justify="flex-end">
                   <Grid>
                       <Button
                           onClick={handleCancel}
                           color="inherit"
                           variant="contained"
                           style={{ marginRight: 10 }}
                       >
                           Cancel
                       </Button>
                   </Grid>
                   <Grid>
                       <Button
                           disabled={disable}
                           onClick={handleAssignRule}
                           color="primary"
                           variant="contained"
                       >
                           Assign
                       </Button>
                   </Grid>
               </Grid>
           </div>
           }
               {state.show && (
                   <SnackbarCmpt
                       message={state.success ? 'Assigned successfully' : 'Could not Assign'}
                       severity={state.success ? 'success' : 'error'}
                   />
               )}
            </div>
    }


    return (

        <div style={styles.root}>
            {props.processing && <Loader/>}
            {props.rulesUsergroups && props.rulesUsergroups.rule_name?
                showRuleAssignForm()
                :
                !props.processing && <div>
                    <NoDataFound title={'No Permission Rules found'}/>
                </div>}
        </div>
    );

}
const mapStateToProps = (state: any) => {
    return {
        rulesUsergroups: state.rules_reducer.rulesUsergroups,
        processing: state.rules_reducer.processing,
        usergroup: state.usergroup_reducer.usergroup,
        user: state.user_reducer.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAllRulesUsergroup: (id:any) => dispatch(fetchAllRulesUsergroup(id)),
        fetchUsergroups: () => dispatch(fetchUsergroups()),
        assignRuleToUser:(id:any, data:any, cb:any) => dispatch(assignRuleToUser(id, data, cb))
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);