import React, { useEffect } from 'react';
import { Divider, Tooltip, Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { returnUTCFormatedDate } from "../../../utils";
import { downloadFileFromVault } from "../../../Services/browse";
import moment from "moment-timezone";
import { provideIconForTab } from "../../../utils/IconProvider";
import "./tiles.css"
import { BasePath, ORACLE_CLOUD_BASE_URL, TIME_ZONE_LABEL, TIME_ZONE, DATA_LABELS } from '../../../config';

const useStyles = makeStyles((theme: any) => ({
    titleContainer: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
}));

const SearchTiles = (props: any) => {

    useEffect(() => { });

    const showDetails = (event: any, data: any) => {
        let classType = data['CLASS_TYPE'];
        let className = data['CLASS_NAME'];
        let id = '';

        if (data['CLASS_TYPE'] == 'Manufacturers') {
            id = data['Number'];
        } else if (data['Number']) {
            id = data['Number'];
        } else if (data['Item Name']) {
            id = data['Item Name'];
        }

        let url = `${BasePath}/item/${classType}/${className}/${id}`;
        let rev = data[DATA_LABELS.REVISION];
        let guid = data.Guid;

        if (rev) {
            url = url + `/${rev}`;
        } else {
            url = url + `/1`;
        }
        if (guid) {
            url = url + `/${guid}`;
        }
        //window.open(/class/item?${classType}#${className}#${id}, "")
        props.history.push(url);
    };

    const showTitleLink = () => {
        let id = '';
        let data = { ...props.data }
        if (data['CLASS_TYPE'] == 'Manufacturers') {
            id = data['Number'];
        } else if (data['Number']) {
            id = data['Number'];
        } else if (data['Item Name']) {
            id = data['Item Name'];
        }
        return (
            <Tooltip title="Open details" arrow placement="top-start">
                <div className={"idlink"} onClick={(e: any) => showDetails(e, props.data)}>
                    {id}
                </div>
            </Tooltip>)
    }

    const ShowHeadingValue = (property: any, value: any) => {
        return (<span className={"HeadingValue"}>
            <span className={"heading"}>
                {property}{": "}
            </span>
            {property.includes('Date') && value ?
                (<span className={"value"}>
                     {moment(returnUTCFormatedDate(value)).tz(TIME_ZONE).format('DD-MMMM-YYYY  h:mm:ss A')} {TIME_ZONE_LABEL}
                </span>)
                : (<span className={"value"}>
                    {value}
                </span>)
            }
        </span>)
    }

    const downloadFile = (event: any, data: any) => {
        if (props.data['file_path']) {
            let path = props.data['file_path'];
            if (props.data['DID']) {
                let val = `${ORACLE_CLOUD_BASE_URL}/fscmUI/content/conn/FusionAppsContentRepository/uuid/dDocID:${data.dataItem['DID']}?download`;
                window.open(`${val}`);
            } else if (path.includes('http')) {
                window.open(`${path}`);
            } else {
                downloadFileFromVault(path, (data: any) => {
                    if (data) {
                        window.open(`${data}`);
                    }
                })
            }
        }
    };

    const showAttachments = () => {
        return (
            <div>
                <Tooltip title={props.data["file_path"] && props.data["file_path"].split('/').length ? props.data["file_path"].split('/')[props.data["file_path"].split('/').length-1] : 'File Type'}>
                    <Avatar
                        onClick={(e: any) => {
                            downloadFile(e, props.data);
                        }}
                        src={provideIconForTab(props.data["file_path"].split('.')[1])}
                        style={{ borderRadius: "10%", maxWidth: "30px", maxHeight: "30px", cursor: 'pointer' }} />
                </Tooltip>
            </div>
        )
    }

    const MainDataContainer = () => {
        return (
            [<div className={"mainContainer"}>
                <div className={"details"}>
                    {showTitleLink()}
                    {Object.keys(props.data).map((key: any, i: any) => {
                        return (props.data[key] != null && (key != 'affected_items' && key != 'custom_id' && key != 'CLASS_NAME' && key != 'CLASS_TYPE' && key != 'isAttachment' && key != 'Guid' && key != 'attachment' && key != 'isAffectedItem' && key != 'file_path'))
                            ?
                            <span>
                                {
                                    ShowHeadingValue(key, props.data[key])
                                }
                            </span> : null

                    })
                    }
                    {props.data['isAttachment'] && props.data['file_path'] &&
                        <div className='attachment'>
                            {showAttachments()}
                        </div>
                    }
                </div>
            </div>,
            <Divider style={{ marginTop: 2, marginBottom: 2, color: 'red' }} />]
        )
    }

    return (
        <div style={{ height: '100%', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>
            {MainDataContainer()}
        </div>
    );
};

export default withRouter(SearchTiles);