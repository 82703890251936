import React from 'react';
import BrowseContainer from '../../Containers/Browse';
import RoleWraper from '../../HOC/RoleWraper';
import { Grid } from '@material-ui/core';
import Breadcrumbs from '../../HOC/Breadcrumbs';
import { BasePath } from '../../config';

const Browse = (props: any) => {
    return (
        // @ts-ignore
        <RoleWraper view="Browse">
            <Grid>
                <Grid container>
                    <Grid xs={12}>
                        {props.location && props.location.pathname === `${BasePath}/browse` && <BrowseContainer />}
                    </Grid>
                </Grid>
            </Grid>
        </RoleWraper>
    );
};

export default Browse;
