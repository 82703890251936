import React, {useState} from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Toolbar, Badge, Hidden, IconButton, Typography} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import {ORACLE_CLOUD_BASE_URL, SERVER_URL, UIExperince,TOP_BAR_LOGO} from '../../../../config';
import FavouritesPopper from './FavouritesPopper';
import {logoutUser} from '../../../../Services/user';
import Profile from '../Sidebar/components/Profile';
import Cookies from 'js-cookie';
import {BasePath} from '../../../../config';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        background: UIExperince.agile ? theme.palette.primary.main : "#fff"
    },
    flexGrow: {
        flexGrow: 1,
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
        color:  UIExperince.agile ? '#fff':theme.palette.primary.main
    },
}));

const Topbar = props => {
    const {className, onSidebarOpen, ...rest} = props;

    const classes = useStyles();

    const [notifications] = useState([]);

    const logout = async () => {
        localStorage.removeItem('isAuthenticated');
        Cookies.remove('SessionId');
        localStorage.removeItem('user');
        // localStorage.removeItem("views");
        logoutUser();
        window.location.replace(`${ORACLE_CLOUD_BASE_URL}/`);
    };

    return (
        <AppBar {...rest} className={clsx(classes.root, className)}>
            <Toolbar>
                {UIExperince.agile ? [<RouterLink to={`${BasePath}/search`}>
                        <img style={{width: '140px', height: '60px'}} alt="Logo" src={"/images/logos/PDP.svg"}/>
                    </RouterLink>]
                    :
                    [<RouterLink onClick={()=>{
                        window.location = `${ORACLE_CLOUD_BASE_URL}/fscmUI/faces/FuseWelcome`;
                    }}>
                        <img style={{width: '140px', height: '70px'}} alt="Logo" src={TOP_BAR_LOGO}/>
                    </RouterLink>,
                        <Typography variant={"h3"} style={{color: "#4f4f4fe0"}}>Agile Archive Portal</Typography>]

                }
                <div className={classes.flexGrow}/>
                <Hidden mdDown>
                    <Profile/>
                </Hidden>
                <Hidden mdDown>
                    <FavouritesPopper/>
                    <IconButton
                        onClick={() => {
                            logout();
                        }}
                        className={classes.signOutButton}
                        color="inherit"
                    >
                        <InputIcon/>
                    </IconButton>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onSidebarOpen}>
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
};

export default withRouter(Topbar);
