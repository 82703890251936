import React, { useEffect, useState } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import { setUserValue, getLoggedUserData } from "../../Services/user";
import Cookies from 'js-cookie';
import { SERVER_URL, BasePath } from '../../config';

interface newProps {
    Props: any;
    RouteProps: any;
    State: any;
    layout: any;
    component: any;
    Component: any;
    rest: any;
    location: any;
    Route: any;
}

const RouteWithLayout: any = (props: any) => {
    const dispatch = useDispatch();
    const connected = Cookies.get('connect.sid');
    const { layout: Layout, component: Component, ...rest } = props;

    const [formState, setFormState] = useState({
        returnCompt: [<Component {...props} />],
    });

    useEffect(() => {
        if (localStorage.getItem('isAuthenticated') === 'true') {
            if (props.location.pathname === `${BasePath}/sign-in`) {
                setFormState({ returnCompt: [<Redirect to={`${BasePath}/search`} />] });
            } else {
                setFormState({
                    returnCompt: [
                        <Route
                            {...rest}
                            render={(matchProps: any) => (
                                <Layout>
                                    <Component {...matchProps} />
                                </Layout>
                            )}
                        />,
                    ],
                });
            }
        } else {
            if(props && props.location.search && props.location.search && props.location.search.includes("SSOFLAG")){
                            let pathName = props.location.pathname;
                            props.history.push(pathName);
                            window.location.reload();
                    }
            if (props.location.pathname !== `${BasePath}/sign-in`) {
                setFormState({ returnCompt: [<Redirect to={`/`} />] });
            }
        }
    }, [props.location.pathname]);


    return formState.returnCompt;
};

export default withRouter(RouteWithLayout);
