import {
    setCurrentTabData,
    setTabError,
    setTabProcessing,
    setTabsData,
    setTabsStoredData,
    setCurrentTabFormData,
    setCurrentTabFormError,
    setCurrentTabFormProcessing,
    setTabDataCount,
    setTabPageSize,
    setCurrentRevisions,
    setSelectedRevision,
    setAttachmentRevisions,
    setAttachmentRevisionsProcessing
} from '../actions/itemForm';
import axios from '../utils/axios';
import {TRANSFORM_REVISION} from "../utils/Transformation";

export const fetchDataForTabs = (ItemParams: any) => {
    return (dispatch: any, getState: any) => {
        let { classType, className, productId, rev } = ItemParams;

        let url = `/api/tabs/${classType}/${className}`;
        const { pageSizeTab }: any = getState().itemForm_reducer;
        dispatch(setTabsState(null, null, null, true));
        dispatch(setTabsStoredData({}));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result && response.data.result.length > 0) {
                    dispatch(setTabsState(response.data.result, response.data.result[0], null, false));
                    dispatch(fetchCurrentTabsData(ItemParams, response.data.result[0], 1, pageSizeTab));
                } else {
                    dispatch(setTabsState(null, null, true, false));
                }
            })
            .catch((error: any) => {
                dispatch(setTabsState(null, null, true, false));
            });
    };
};

export const setCurrentTab = (tab: any, ItemParams: any) => {
    return (dispatch: any, getState: any) => {
        const { tabsStoredData, pageSizeTab }: any = getState().itemForm_reducer;
        dispatch(setCurrentTabData(tab));
        dispatch(setTabDataState(null, true, null));
        if (tabsStoredData[tab]) {
            dispatch(setTabDataState(tabsStoredData[tab], false, null));
        } else {
            dispatch(fetchCurrentTabsData(ItemParams, tab, 1, pageSizeTab));
        }
    };
};

export const getDataForPageination = (ItemParams: any, offset: any, pageContentSize: any) => {
    return (dispatch: any, getState: any) => {
        const { currentTab }: any = getState().itemForm_reducer;
        dispatch(fetchCurrentTabsData(ItemParams, currentTab, offset, pageContentSize));
    };
};


const returnGuidForRev = (classType:any, rev:any, selectedRev:any, currentRevisions:any) => {
        let returndata :any = null;

    currentRevisions.map((revs:any)=>{
        if(classType == 'Parts' || classType == 'Documents'){
            if(revs.rev == rev && selectedRev.split(':')[1].trim() == revs.change_number){
                returndata = revs.guid;
            }
        } else {
            returndata = revs.guid;
        }
    })

    return returndata;
}

export const fetchCurrentTabsData = (ItemParams: any, tab: any, recordsFrom?: any, recordsTo?: any) => {
    return (dispatch: any, getState:any) => {

        const {selectedRevision, currentRevisions }: any = getState().itemForm_reducer;
        let { classType, className, productId, rev, guid } = ItemParams;


        if(!rev && selectedRevision){
            rev =  selectedRevision.split(":")[0].trim()
        }

        guid = guid ? guid : returnGuidForRev(classType,rev,selectedRevision,currentRevisions);

        rev = TRANSFORM_REVISION(rev);
        let url = `/api/item/${classType}/${className}/${productId}/${rev}/${guid}?tab=${tab}`;

        if (recordsFrom && recordsTo) {
            url += `&recordsFrom=${recordsFrom}&recordsTo=${recordsTo}`;
        }
        dispatch(setTabDataState(null, true, null));

        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(setTabDataState(response.data.result, false, null));
                    dispatch(setTabDataInStoredData(tab, response.data.result));
                } else {
                    dispatch(setTabDataState(null, false, true));
                }
            })
            .catch((error: any) => {
                dispatch(setTabDataState(null, false, true));
            });
    };
};

export const fetchRevisionsOfItem = (ItemParams: any, cb: any) => {
    return (dispatch: any, getState: any) => {
        let { classType, className, productId, rev, guid } = ItemParams;
        let url = `/api/revisions/${classType}/${className}/${productId}`;
        dispatch(setCurrentRevisions(null));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(setCurrentRevisions(response.data.result));
                    cb(response.data.result);
                } else {
                    dispatch(setCurrentRevisions(null));
                    cb(false);
                }
            })
            .catch((error: any) => {
                dispatch(setCurrentRevisions(null));
                cb(false);
            });
    };
};

export const setTabDataInStoredData = (tab: any, data: any) => {
    return (dispatch: any, getState: any) => {
        const { tabsStoredData }: any = getState().itemForm_reducer;
        const NewData = { ...tabsStoredData, [tab]: data };
        dispatch(setTabsStoredData(NewData));
    };
};

export const settingSelectedRevision = (data: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setSelectedRevision(data));
    };
};

export const setTabDataState = (currentTabData: any, processing: any, error: any) => {
    return (dispatch: any) => {
        dispatch(setCurrentTabFormData(currentTabData));
        dispatch(setCurrentTabFormProcessing(processing));
        dispatch(setCurrentTabFormError(error));
        if (currentTabData && currentTabData.COUNT) {
            dispatch(setTabDataCount(parseInt(currentTabData.COUNT)));
        } else {
            dispatch(setTabDataCount(0));
        }
    };
};

const setTabsState = (tabsData: any, currentTab: any, tabError: any, tabProcessing: any) => {
    return (dispatch: any) => {
        dispatch(setTabsData(tabsData));
        dispatch(setCurrentTabData(currentTab));
        dispatch(setTabError(tabError));
        dispatch(setTabProcessing(tabProcessing));
    };
};

export const setDataSizeTab = (size: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setTabDataCount(size));
    };
};

export const setPageSizeTab = (size: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setTabPageSize(size));
    };
};

export const resetTheFormRedux = () => {
    return (dispatch: any) => {
        dispatch(setTabDataState(null, false, null));
        dispatch(setTabsState(null, null, null, false));
    };
};

export const getAttachmentRevisions = (guid:any,id:any) =>{
    return (dispatch: any, getState: any) => {
        dispatch(setAttachmentRevisionsProcessing(true))
        let url = `/api/attachmentRevisions/${guid}/${id}`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.length) {
                    dispatch(setAttachmentRevisions(response.data.result))
                    dispatch(setAttachmentRevisionsProcessing(false))
                } else {
                    dispatch(setAttachmentRevisions([]))
                    dispatch(setAttachmentRevisionsProcessing(false))
                }
            })
            .catch((error: any) => {
                dispatch(setAttachmentRevisions([]))
                dispatch(setAttachmentRevisionsProcessing(false))
            });
    };

}