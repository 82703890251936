import {
    GET_BOOKMARKS_DATA,
    SET_BOOKMARKS_DATA,
    SET_PROCESSING,
    GET_SAVED_SEARCH_DATA,
    SET_SAVED_SEARCH_DATA,
    SET_SAVED_SEARCH_PROCESSING,
    SET_FAVORITES_ERROR,
    SET_TOTAL,
    SET_FVAOURITES_MODAL_STATE,
    SET_CURRENT_TAB_SEARCH,
    SET_ADVANCE_SEARCH_STRING,
    SET_SEARCH_RECORD_PAGE_SIZE,
    SET_SEARCH_RECORD_TOTAL,
    SET_BOOKMARKS_RECORD_PAGE_SIZE,
    SET_BOOKMARKS_RECORD_TOTAL,
    SET_BOOKMARKS_PAGE,
    SET_SEARCH_PAGE,
} from '../action-types/favorites-action-types';
import { MIN_PAGE_SIZE } from '../config';
const initState = () => {
    return {
        bookmarksData: null,
        savedSearchData: null,
        total: null,
        error: null,
        processing: false,
        savedSearchProcessing: false,
        modalState: false,
        searchAdvanceTab: false,
        advanceSearchQuery: null,
        totalSearchRecords: 0,
        totalBookmarkRecords: 0,
        bookmarksPageSize: MIN_PAGE_SIZE,
        searchPageSize: MIN_PAGE_SIZE,
        currentSearchPage: 1,
        currentBookmarkPage: 1,
    };
};

const favorites_reducer = (state = initState(), action: any) => {
    let newState = { ...state };
    switch (action.type) {
        case GET_BOOKMARKS_DATA:
            setData(newState, action.payload, 'bookmarksData');
            break;
        case SET_BOOKMARKS_DATA:
            setData(newState, action.payload, 'bookmarksData');
            break;
        case SET_PROCESSING:
            setData(newState, action.payload, 'processing');
            break;
        case GET_SAVED_SEARCH_DATA:
            setData(newState, action.payload, 'savedSearchData');
            break;
        case SET_SAVED_SEARCH_DATA:
            setData(newState, action.payload, 'savedSearchData');
            break;
        case SET_SAVED_SEARCH_PROCESSING:
            setData(newState, action.payload, 'savedSearchProcessing');
            break;
        case SET_TOTAL:
            setData(newState, action.payload, 'total');
            break;
        case SET_FAVORITES_ERROR:
            setData(newState, action.payload, 'error');
            break;
        case SET_FVAOURITES_MODAL_STATE:
            setData(newState, action.payload, 'modalState');
            break;
        case SET_ADVANCE_SEARCH_STRING:
            setData(newState, action.payload, 'advanceSearchQuery');
            break;
        case SET_CURRENT_TAB_SEARCH:
            setData(newState, action.payload, 'searchAdvanceTab');
            break;

        case SET_BOOKMARKS_RECORD_TOTAL:
            setData(newState, action.payload, 'totalBookmarkRecords');
            break;
        case SET_BOOKMARKS_RECORD_PAGE_SIZE:
            setData(newState, action.payload, 'bookmarksPageSize');
            break;
        case SET_SEARCH_RECORD_PAGE_SIZE:
            setData(newState, action.payload, 'searchPageSize');
            break;
        case SET_SEARCH_RECORD_TOTAL:
            setData(newState, action.payload, 'totalSearchRecords');
            break;
        case SET_SEARCH_PAGE:
            setData(newState, action.payload, 'currentSearchPage');
            break;
        case SET_BOOKMARKS_PAGE:
            setData(newState, action.payload, 'currentBookmarkPage');
            break;
        default:
            break;
    }
    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default favorites_reducer;
