import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { fetchDataForTabs, getDataForPageination } from '../../Services/itemForm';
import Loader from '../../components/Loader';
import ErrorComponent from '../../components/Error';
import { replaceNames, sortArrayData } from '../../utils/ItemFormHelpers';
import { Grid, Typography } from '@material-ui/core';
import theme from '../../theme';
import PlainData from './Components/PlainData';
import TreeDataHandler from './Components/TreeDataHandler';
import HTMLPreview from '../../components/HTMLPreview';
import Pageing from './Components/Pageing';
import ExportDataCSV from '../../components/ExportDataCSV';

interface propsInterface {
    children?: React.ReactNode;
    index: any;
    location: any;
    history: any;
    match: any;
    currentTabData: any;
    currentTabProcessing: any;
    currentTabError: any;
    currentTab: any;
    getDataForPageination: any;
    pageSizeTab: any;
}

class ItemForm extends Component<propsInterface> {
    state = {};

    componentDidMount(): void { }

    mainLayoutHandler = () => {
        let layoutData = this.props.currentTabData.LAYOUT;
        let sortedLayout = sortArrayData(layoutData, 'DISPLAY_SEQ');
        let data = this.props.currentTabData.DATA;
        let error = null;
        let PlainDataHeadingView: any = {};
        let count = 0;
        return (
            <div style={{height: "100%" }}>
                {sortedLayout &&
                    sortedLayout.map((layout: any, index: any) => {

                        if (layout['TYPE'] == 'Text' || layout['TYPE'] == 'Date' || layout['TYPE'] == 'Checkbox') {
                            if (PlainDataHeadingView[layout.PLAIN_ATTRIBUTE_HEADING] && layout.PLAIN_ATTRIBUTE_HEADING) {
                                PlainDataHeadingView[layout.PLAIN_ATTRIBUTE_HEADING].push(<PlainData key={index} layout={layout} data={data[0][layout['ATTRIBUTE_NAME']]} />)
                            } else if (layout.PLAIN_ATTRIBUTE_HEADING) {
                                PlainDataHeadingView[layout.PLAIN_ATTRIBUTE_HEADING] = [<PlainData key={index} layout={layout} data={data[0][layout['ATTRIBUTE_NAME']]} />];
                            } else {
                                if (PlainDataHeadingView[layout.TAB]) {
                                    PlainDataHeadingView[layout.TAB].push(<PlainData key={index} layout={layout} data={data[0][layout['ATTRIBUTE_NAME']]} />)

                                } else {
                                    PlainDataHeadingView[layout.TAB] = [<PlainData key={index} layout={layout} data={data[0][layout['ATTRIBUTE_NAME']]} />]
                                }
                            }

                        } else if (layout['TYPE'] == 'Tree Data') {
                            if (!data[0]) {
                                return data[layout['LABEL_DISPLAY']] ? (
                                    <TreeDataHandler layout={layout} treeData={data[layout['LABEL_DISPLAY']]} />
                                ) : (
                                    <ErrorComponent text={`No ${replaceNames(layout['LABEL_DISPLAY'])} data`} />
                                );
                            } else {
                                return data ? (
                                    [<TreeDataHandler layout={layout} treeData={data} />]
                                ) : (
                                    <ErrorComponent text={`No ${replaceNames(layout['LABEL_DISPLAY'])} data`} />
                                );
                            }
                        } else if (layout['TYPE'] == 'HTML') {
                            if (PlainDataHeadingView[layout.PLAIN_ATTRIBUTE_HEADING] && layout.PLAIN_ATTRIBUTE_HEADING) {
                                PlainDataHeadingView[layout.PLAIN_ATTRIBUTE_HEADING].push(<HTMLPreview data={data[0][layout['ATTRIBUTE_NAME']]}  title={layout['LABEL_DISPLAY']} />)
                            } else if (layout.PLAIN_ATTRIBUTE_HEADING) {
                                PlainDataHeadingView[layout.PLAIN_ATTRIBUTE_HEADING] = [<HTMLPreview data={data[0][layout['ATTRIBUTE_NAME']]}  title={layout['LABEL_DISPLAY']} />];
                            } else {
                                if (PlainDataHeadingView[layout.TAB]) {
                                    PlainDataHeadingView[layout.TAB].push(<HTMLPreview data={data[0][layout['ATTRIBUTE_NAME']]}  title={layout['LABEL_DISPLAY']} />)

                                } else {
                                    PlainDataHeadingView[layout.TAB] = [<HTMLPreview data={data[0][layout['ATTRIBUTE_NAME']]}  title={layout['LABEL_DISPLAY']} />]
                                }
                            }
                        }
                    })
                }
                <div>{PlainDataHeadingView ? Object.keys(PlainDataHeadingView
                ).map((item: any, index: any) => {
                    return [
                        (item == sortedLayout[0].TAB) ?
                            <div>{PlainDataHeadingView[item]}</div>

                            : <div>
                                <Typography color="primary" style={{ fontWeight: "normal", color: "#0066cc", fontSize: "24px", margin: theme.spacing(1) }}>
                                    {replaceNames(item)}
                                </Typography>
                                <div>{PlainDataHeadingView[item]}</div>
                            </div>
                    ]
                }) : null}</div>
            </div>
        );
    };

    returnExportButtonUI = () => {
        let { classType, className, productId, rev, guid } = this.props.match.params;
        let currentTab = this.props.currentTab;
        return (
            <Grid container direction="row" style={{ padding: '0px' }}>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-end"
                >
                    {this.props.currentTabData.DATA && this.props.currentTabData.DATA.length > 0 ? <ExportDataCSV
                        method={'get'}
                        name={`${classType}_${className}_${currentTab}`}
                        url={`/api/item/${classType}/${className}/${productId}/${rev}/${guid ? guid : 'null'
                            }?tab=${currentTab}&recordsFrom=1&recordsTo=30000000000`}
                        body={null}
                        size={null}
                    /> : null}
                </Grid>
            </Grid>
        );
    };

    shouldRanderLayout = () => {
        return this.props.currentTabData && this.props.currentTabData.DATA && this.props.currentTabData.LAYOUT;
    };

    render() {
        let layoutData =
            this.props.currentTabData && this.props.currentTabData.LAYOUT ? this.props.currentTabData.LAYOUT : null;
        return (
            <div>
                {this.props.currentTabProcessing && <Loader />}
                {this.shouldRanderLayout() &&
                    (layoutData && layoutData[0]['TYPE'] == 'Text' ? null : this.returnExportButtonUI())}
                {this.shouldRanderLayout() && this.mainLayoutHandler()}
                {!this.props.currentTabProcessing && this.props.currentTabError && (
                    <ErrorComponent text={`Could not load ${replaceNames(this.props.currentTab)} data`} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentTabData: state.itemForm_reducer.currentTabData,
        currentTabProcessing: state.itemForm_reducer.currentTabProcessing,
        currentTabError: state.itemForm_reducer.currentTabError,
        currentTab: state.itemForm_reducer.currentTab,
        pageSizeTab: state.itemForm_reducer.pageSizeTab,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(ItemForm);

export default withRouter(connected);
