import React, { useEffect, Component } from 'react';
import { Grid, Paper, TextField, Tooltip } from '@material-ui/core';
import theme from '../../theme';

import { connect } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Switch from '@material-ui/core/Switch';

import Button from '@material-ui/core/Button';

import Loader from '../../components/Loader';
import NoDataFound from '../../components/Messages/NoDataFound';
import { fetchClassTypeTabs, fetchClassTypes, updateClassTypeTabs } from '../../Services/configurations';
import { TreeListTextFilter } from '@progress/kendo-react-treelist';
import Treefile from '../../components/KendoUICmpt/TreeList';
import { setTabsData } from '../../actions/itemForm';

class ClassTypeConfigurations extends Component<any> {
    styles = {
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '20%',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        paperSide: {
            maxHeight: '64vh',
            minHeight: '64vh',
            overflow: 'auto',
        },
        table: {
            minWidth: 650,
        },
        menuPaper: {
            maxHeight: 250
        }
    }

    state = {
        classType: null,
        tabsData: [],
        updated: false,
        updatedTabs:[]
    }


    columns = [
        {
            title: `TAB NAME`,
            field: `Name`,
            filter: TreeListTextFilter,
        },
        {
            title: `DISPLAY SEQUENCE`,
            field: `display_seq`,
            filter: TreeListTextFilter,
            cell: (rowData: any) => {
                return (
                    <td>
                        <div>
                            <Grid container spacing={3}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    item
                                    md={9} sm={6}
                                >
                                    <TextField
                                        type="number"
                                        required
                                        value={rowData.dataItem['display_seq']}
                                        onChange={(event: any) => { this.handleTextChange(parseInt(event.target.value),rowData.dataItem) }}  
                                    />
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    item
                                    md={3} sm={6}
                                >

                                </Grid>
                            </Grid>
                        </div>
                    </td>
                )
            }
        },
        {
            title: 'IS ACTIVE',
            field: 'IsActive',
            cell: (rowData: any) => {
                return (
                    <td>
                        <Switch
                            checked={rowData.dataItem['IsActive']}
                            onChange={(event:any)=>{this.handleSwitchChange(rowData.dataItem)}}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </td>
                );
            },
        },
    ];

   
    async componentDidMount() {
        this.setState({
            tabsData:[]
        })
        await this.props.fetchClassTypes();
        if(this.props.classTypeTabs.length===0 && this.props.classTypes[0]){
            await this.props.fetchClassTypeTabs(this.props.classTypes[0]);}
    }
    handleClassTypeChange = (event: any) => {
        this.props.fetchClassTypeTabs(event.target.value)
        this.setState({
            classType:event.target.value,
            tabsData: [],
            updatedTabs:[],
            updated:false
        })
    }
    handleTextChange = (newVal:any,rowData: any) => {
        
        let tempData = this.state.tabsData.length> 0 ? this.state.tabsData : this.props.classTypeTabs;
        tempData.forEach((dataRow :any)=>{
            if(dataRow.id===rowData.id){
                    if(!this.state.updated){
                        this.setState({
                            updated:true
                        })
                    }
                    dataRow['display_seq'] = newVal
                }
        })
        this.setState({
            tabsData: tempData
        })
        this.updateTabs(tempData.find((ent:any)=> rowData.id === ent.id))

    }
    handleSwitchChange = (rowData: any) => {
        if(!this.state.updated){
            this.setState({
                updated:true 
            })
        }
        let tempData = this.state.tabsData.length> 0 ? this.state.tabsData : this.props.classTypeTabs;
        tempData.forEach((dataRow :any)=>{
            if(dataRow.id===rowData.id){
                dataRow['IsActive'] = !rowData['IsActive']
            }
        })
        this.setState({
            tabsData: tempData
        })
        this.updateTabs(tempData.find((ent:any)=> rowData.id === ent.id))
    }

    handleSave = () => {
        this.props.updateClassTypeTabs(this.state.classType ? this.state.classType : this.props.classTypes[0] , this.state.updatedTabs);
        this.setState({
            tabsData: [],
            updatedTabs:[],
            updated:false
        })
    }
    updateTabs = (rowData:any) =>{
        let tempData:any = this.state.updatedTabs;
        let existing:any = false;
        tempData.forEach((row:any)=>{
            if(row.id === rowData.id){
                row = rowData;
                this.setState({
                    updatedTabs:tempData
                })
                existing=true;}
        })
        if(existing===true){
            return;
        }
        tempData.push(rowData)
        this.setState({
            updatedTabs:tempData
        })
    }
    setTabs = () =>{
        if (this.state.tabsData.length===0 && this.props.classTypeTabs.length>0){
            this.setState({ tabsData: this.props.classTypeTabs }) }
    }
    render() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper elevation={3} style={this.styles.paper} >
                            {this.props.classTypes[0] && (
                                <FormControl style={this.styles.formControl}>
                                    <InputLabel id="class-types-label">Class Types</InputLabel>
                                    <Select
                                        labelId="class-types-label"
                                        id="class-types"
                                        value={this.state.classType ? this.state.classType : this.props.classTypes[0]}
                                        onChange={(event: any) => { this.handleClassTypeChange(event) }}
                                        MenuProps={{style:this.styles.menuPaper}} 
                                    >
                                        {this.props.classTypes.map((classType: any, index: number) => {
                                            return <MenuItem value={classType}>{classType}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            )}
                            {!this.props.classTypes[0] && <Loader></Loader>}
                        </Paper>
                    </Grid>

                    
                        <Grid item xs={4}>
                            <Button variant="contained" color="secondary" onClick={() => { this.handleSave()}} disabled={!this.state.updated}>
                                Save
                            </Button>
                        </Grid>
                    

                    <Grid item xs={12}>
                        <Paper elevation={3} style={this.styles.paperSide}>
                            {this.props.classTypeTabsprocessing ? <Loader /> : null}
                            {(this.props.classTypeTabsError && !this.props.classTypeTabsprocessing) ? <NoDataFound title={this.props.classTypeTabsError} /> : null}

                            {this.props.classTypeTabs.length && this.props.classTypeTabs.length>0 ? (
                                <div key={this.state.tabsData.length} onMouseEnter={this.setTabs}>
                                    <Treefile columns={this.columns} tableData={this.state.tabsData.length > 0 ? this.state.tabsData : this.props.classTypeTabs} />
                                </div>
                            ) : null}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        classTypes: state.configurations_reducer[state.configurations_reducer.selectedConfiguration]?.classTypes || [],
        classTypesError: state.configurations_reducer[state.configurations_reducer.selectedConfiguration]?.classTypesError,
        classTypeTabsprocessing: state.configurations_reducer[state.configurations_reducer.selectedConfiguration]?.classTypeTabsprocessing,
        classTypeTabs: state.configurations_reducer[state.configurations_reducer.selectedConfiguration]?.classTypeTabs || [],
        classTypeTabsError: state.configurations_reducer[state.configurations_reducer.selectedConfiguration]?.classTypeTabsError,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchClassTypeTabs: (classType: any) => dispatch(fetchClassTypeTabs(classType)),
        fetchClassTypes: () => dispatch(fetchClassTypes()),
        updateClassTypeTabs: (classType: string, classTypeTabs: any) =>
            dispatch(updateClassTypeTabs(classType, classTypeTabs)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(ClassTypeConfigurations);

export default connected;