import { UIExperince } from "../config";

export const sortArrayData = (data: any, key: any) => {
    let DataAttributes = data;
    DataAttributes.sort(function(a: any, b: any) {
        if (a[key] && b[key]) {
            return a[key] - b[key];
        } else {
            return 1;
        }
    });
    return DataAttributes;
};


export const returnRole = (roles:any) => {
    
    let result = null;
    roles && roles.map((role:any)=>{
        if(role.RoleUserActive == "1" || role.RoleUserActive == 1){
                result = role
        }
    });

    return result;
}


export const replaceNames = (name:any) => {
   let mapping:any = {
       "BOM":"Structures",
       "Title Block":"General Information",
       "Affected Items":"Affected Objects",
       "Manufacturers":"AML",
       "Parts":"Items",
       "Manufacturer_parts":"Manufacturer Parts"
   };

   let returnName = name
   if(!UIExperince.agile){
        if(mapping[name]){
            returnName = mapping[name];
        }
   }

   return returnName;
}