import moment from 'moment';
import { couldStartTrivia } from 'typescript';

export const ADD_TRAINING_ITEMS = 'ADD_TRAINING_ITEMS';
export const DELETE_TRAINING_ITEMS = 'DELETE_TRAINING_ITEMS';
export const EDIT_TRAINING_ITEMS = 'EDIT_TRAINING_ITEMS';

export const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = error => reject(error);
    });

export const checkPermissionForTrainingItems = (views: any) => {
    let viewsUser: any = views['TrainingItems'];
    let permissions: any = [];
    if (viewsUser && viewsUser.some((perm: any) => perm.Name == 'Add TrainingItem')) {
        permissions.push(ADD_TRAINING_ITEMS);
    }
    if (viewsUser && viewsUser.some((perm: any) => perm.Name == 'Delete TrainingItem')) {
        permissions.push(DELETE_TRAINING_ITEMS);
    }
    if (viewsUser && viewsUser.some((perm: any) => perm.Name == 'Edit TrainingItem')) {
        permissions.push(EDIT_TRAINING_ITEMS);
    }
    return permissions;
};

export const filterTags = (tags: any, user: any) => {
    let result: any = [];
    tags.forEach((tag: any) => {
        let newVal: any = tag;
        delete newVal['TagModifiedBy'];
        delete newVal['TagModifiedDate'];
        delete newVal['ModifiedDate'];
        delete newVal['ModifiedBy'];
        newVal = {
            ...newVal,
            CreatedDate: moment.utc().format(),
            CreatedBy: user.UserName,
            TagActive: 1,
            TagCreatedBy: user.UserName,
            TagCreatedDate: new Date(),
            ModifiedBy: user.UserName,
            ModifiedDate: moment.utc().format(),
        };
        result.push(newVal);
    });
    return result;
};

//set approvers active

export const setActiveAttachment = (oldItemsData: any, newItemsData: any) => {
    let resultData: any = [];

    oldItemsData.forEach((oldData: any) => {
        if (newItemsData.some((user: any) => user.ID && user.ID == oldData.ID)) {
            resultData.push({
                ...oldData,
            });
        } else {
            resultData.push({
                ...oldData,
                IsActive: '0',
            });
        }
    });
    newItemsData.forEach((newData: any) => {
        if (oldItemsData.some((user: any) => newData.ID && user.ID == newData.ID)) {
        } else {
            resultData.push({
                ...newData,
                IsActive: '1',
            });
        }
    });

    return resultData;
};

export const getActiveLatestPublishedRev = (revisions: any) => {
    let newdata: any = [];
    let resultReturned = false;

    revisions &&
        revisions.filter((rev: any) => {
            if (rev.IsPublished == '1' && rev.IsLatest == '1') {
                resultReturned = true;
            }
        });

    return resultReturned;
};
export const getNonLatestPublishedRev = (trainingItem: any, revisions: any) => {
    let newdata: any = [];
    let resultReturned = true;
    let revID: any = trainingItem.ID;
    let maxRevisionID =
        revisions &&
        Math.max.apply(
            Math,
            revisions.map(function(rev: any) {
                return rev.ID;
            }),
        );

    if (revID < maxRevisionID) {
        resultReturned = false;
    }

    return resultReturned;
};
