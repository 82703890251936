let icons :any = {
    'Title Block':"/images/cloudIcons/general.png",
    'Changes':"/images/cloudIcons/changes.png",
    'BOM':"/images/cloudIcons/bom.png",
    'Related Documents':"/images/cloudIcons/documents.png",
    'Where Used':"/images/cloudIcons/whereUsed.png",
    'Manufacturers':"/images/cloudIcons/manufacturer.png",
    'Quality':"/images/cloudIcons/quality.png",
    'Relationships':"/images/cloudIcons/relationship.png",
    'Attachments':"/images/cloudIcons/attachments.png",
    'History':"/images/cloudIcons/history.png",
    'Page Two':"/images/cloudIcons/page.png",
    "default":"/images/cloudIcons/general.png",
    "Workflow":"/images/cloudIcons/workflow.png",
    "Affected Items": "/images/cloudIcons/AffectedItems.png",

    "doc":"/images/fileicons/doc.png",
    "pdf":"/images/fileicons/pdf.png",
    "txt": "/images/fileicons/txt.png",
    "docx":"/images/fileicons/doc.png",
    "DOCX":"/images/fileicons/doc.png",
    "DOC":"/images/fileicons/doc.png",
    "PDF":"/images/fileicons/pdf.png",
    "TXT": "/images/fileicons/txt.png"
}




export function provideIconForTab (tabName:any) {
     ;
    let result:any = icons[tabName] ? icons[tabName]:icons["default"];
     return(result)
}

