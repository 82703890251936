import React, { Component } from 'react';
import DynamicClassAttrTable from './Components/DynamicClassAttrTable';
import { fetchDocumentClassTypeData, fetchPartClassTypeData, fetchClassData, setPageSize } from '../../Services/browse';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import theme from '../../theme';
import CustomPagination from '../../components/CustomPagination';
import { sortArrayObjectViewSeq } from '../../utils/browse';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { TreeListTextFilter } from '@progress/kendo-react-treelist';
import Treefile from '../../components/KendoUICmpt/TreeList';
import { BasePath } from '../../config';
import ExportDataCSV from '../../components/ExportDataCSV';

interface props {
    layout: any;
    data: any;
    fetchClassData: any;
    selectedClass: any;
    selectedClassType: any;
    setPageSize: any;
    history: any;
}

class ClassContent extends Component<props> {
    state = {
        show: false,
        dataColumns: [],
    };

    componentDidMount() {
        let { layout, data } = this.props;
        let val: any = [];
        let sortedLayout = sortArrayObjectViewSeq(layout);
        sortedLayout.forEach((data: any, index: any) => {
            if (data.GRID_VISIBLE && data.TYPE != 'Hidden') {
                if (data.ATTRIBUTE_NAME == 'ID_NUMBER') {
                    val.push({
                        title: `${data.LABEL_DISPLAY}`,
                        field: `${data.ATTRIBUTE_NAME}`,
                        filter: TreeListTextFilter,
                        cell: (rowData: any) => (
                            <Tooltip title="Open details">
                                <td style={{ minWidth: '180px', cursor: 'pointer' }}>
                                    <span style={{ color: 'blue' }} onClick={(e: any) => this.showDetails(e, rowData)}>
                                        {rowData.dataItem[rowData.field]}
                                    </span>
                                </td>
                            </Tooltip>
                        ),
                    });
                } else {
                    val.push({
                        title: `${data.LABEL_DISPLAY}`,
                        field: `${data.ATTRIBUTE_NAME}`,
                        filter: TreeListTextFilter,
                        cell: (rowData: any) => (
                            <td style={{ minWidth: '180px' }}>
                                <div style={{minHeight:"50px", maxHeight:"130px", overflow:"auto"}}>
                                      {rowData.dataItem[rowData.field]}
                                </div>
                            </td>
                        ),
                    });
                }
            }
        });

        this.setState({
            show: true,
            dataColumns: val,
        });
    }

    showDetails = (event: any, data: any) => {
        let classType = this.props.selectedClassType;
        let className = this.props.selectedClass['CLASS-NAME'];
        let id = data.dataItem.ID_NUMBER;

        let url = `${BasePath}/item/${classType}/${className}/${id}`;
        let rev = data.dataItem.REV;
        let guid = data.dataItem.GUID;
        if (rev) {
            url = url + `/${rev}`;
        } else {
            url = url + `/1`;
        }

        if (guid) {
            url = url + `/${guid}`;
        }

        //window.open(/class/item?${classType}#${className}#${id}, "")
        this.props.history.push(url);
    };

    showClassValues = () => {
        let classType = this.props.selectedClassType;
        let className = this.props.selectedClass['CLASS-NAME'];
        return (
            <div style={{ padding: theme.spacing(2), width: '100%' }}>
                <Grid container direction="row">
                    <Grid  item
                           lg={10}
                           sm={6}
                           xl={10}
                           xs={12}>
                        <Typography variant={'h4'}>{this.props.selectedClass['DISPLAY-NAME']}</Typography>
                    </Grid>
                {this.state.show && (
                        <Grid
                            item
                            lg={2}
                            sm={6}
                            xl={2}
                            xs={12}
                            container
                            direction="column"
                            justify="center"
                            alignItems="flex-end"
                        >
                            <ExportDataCSV
                                method={'get'}
                                name={`${classType}_${className}`}
                                url={`/api/browse/${classType}/${className}`}
                                body={null}
                                size={null}
                            />
                        </Grid>

                )}

                <Grid style={{ paddingTop: theme.spacing(2) }}
                      lg={8}
                      sm={12}
                      xl={8}
                      xs={12}>
                    <Typography variant={'body2'}>{this.props.selectedClass.DESCRIPTION}</Typography>
                </Grid>
                </Grid>
            </div>
        );
    };

    render() {


        return (
            <div style={{ height: '100%', overflowX: 'auto' }}>
                <div>{this.showClassValues()}</div>

                <div>
                    {this.state.show && <Treefile columns={this.state.dataColumns} tableData={this.props.data} />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedClass: state.browse_reducer.selectedClass,
        selectedClassType: state.browse_reducer.selectedClassType,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(ClassContent);

export default withRouter(connected);
