import {
    getAttachmentData, setCurrentUsergroup,
    setProcessingAttachment,
    setProcessingUserAttachment,
    getUserAttachment
} from '../actions/suppliers';
import axios from "../utils/axios";


export const fetchAllSuppliersAttachments = (usergroup:any) => {
    return (dispatch: any) => {
        let url = `/api/groupAttachments/${usergroup.id}`;
        dispatch(setCurrentUsergroup(usergroup))
        dispatch(getAttachmentData(null));
        dispatch(setProcessingAttachment(true));
        axios
            .get(url)
            .then(response => {
                if (response.data.success && response.data.result) {
                    dispatch(getAttachmentData(response.data.result));
                    dispatch(setProcessingAttachment(false));
                } else {
                    dispatch(getAttachmentData(null));
                    dispatch(setProcessingAttachment(false));
                }
            })
            .catch(err => {
                dispatch(getAttachmentData(null));
                dispatch(setProcessingAttachment(false));
            });
    };
};


export const deleteAttachment = (id:any, formData:any, cb:any) => {
    return (dispatch: any) => {
        let url = `/api/groupAttachments/${id}/attachments`;
        axios
            .patch(url, formData)
            .then(response => {
                if (response.data.success && response.data.result) {
                   cb(true)
                } else {
                  cb(false)
                }
            })
            .catch(err => {
               cb(false)
            });
    };
};


export const addAttachment = (id:any, formData:any, cb:any) => {
    return (dispatch: any) => {
        let url = `/api/groupAttachments/${id}`;
         ;
        axios
            .post(url, formData)
            .then(response => {
                if (response.data.success && response.data.result) {
                    cb(true)
                } else {
                    cb(false)
                }
            })
            .catch(err => {
                cb(false)
            });
    };
};


export const fetchUserAttachments = () => {
    return (dispatch: any, getState:any) => {
        const { user }: any = getState().user_reducer;
        let url = `/api/userAttachments/${user.id}`;
        dispatch(getUserAttachment(null));
        dispatch(setProcessingUserAttachment(true));
        axios
            .get(url)
            .then(response => {
                if (response.data.success && response.data.result) {
                    dispatch(getUserAttachment(response.data.result));
                    dispatch(setProcessingUserAttachment(false));
                } else {
                    dispatch(getUserAttachment(null));
                    dispatch(setProcessingUserAttachment(false));
                }
            })
            .catch(err => {
                dispatch(getUserAttachment(null));
                dispatch(setProcessingUserAttachment(false));
            });
    };
};




export const setCurrentUserGroup = (usergroup:any) => {
    return (dispatch: any, getState:any) => {
        dispatch(setCurrentUsergroup(usergroup))
    };
};