import {
    GET_USER_DATA,
    SET_USER_ERROR,
    SET_USER_DATA,
    ADD_USER,
    SET_EDIT_DATA,
    SET_PAGE_SIZE_USER,
    SET_TOTAL,
    SET_USER_PROCESSING,
    GET_ROLES
} from '../action-types/user-management-action-types';

export const setUserProcessing = (flag: any) => {
    return {
        type: SET_USER_PROCESSING,
        payload: flag,
    };
};

export const getUserData = (data: any) => {
    return {
        type: GET_USER_DATA,
        payload: data,
    };
};

export const setUserData = (data: any) => {
    return {
        type: SET_USER_DATA,
        payload: data,
    };
};

export const setEditData = (data: any) => {
    return {
        type: SET_EDIT_DATA,
        payload: data,
    };
};

export const addUser = (data: any) => {
    return {
        type: ADD_USER,
        payload: data,
    };
};

export const setPageSizeUser = (data: any) => {
    return {
        type: SET_PAGE_SIZE_USER,
        payload: data,
    };
};

export const setTotal = (data: any) => {
    return {
        type: SET_TOTAL,
        payload: data,
    };
};

export const getError = (error: any) => {
    return {
        type: SET_USER_ERROR,
        payload: error,
    };
};


export const getRoles = (data: any) => {
    return {
        type: GET_ROLES,
        payload: data,
    };
};