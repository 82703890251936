import React, { useEffect, useState } from 'react';
import { fetchAllRulesUsergroup, assignRuleToUser } from "../../../Services/rules";
import { fetchUsergroups } from '../../../Services/usergroup';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/Loader";
import NoDataFound from "../../../components/Messages/NoDataFound";
import { Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper,Switch, Select, TextField, Typography } from "@material-ui/core";
import theme from "../../../theme";
import { BasePath } from "../../../config";
import SnackbarCmpt from '../../../components/Snackbar';

function Index(props: any) {

    const styles = {
        root: {
            margin: theme.spacing(2),
            height: '82vh',
        }
    }

    const [state, setState] = useState({ show: false, success: false });
    const [disable, setDisable] = useState(false);
    let ItemParams = props.match.params;
    let { ruleID } = ItemParams;

    const [formData, setFormData]: any = useState({
        IsActive:true
    });

    useEffect(function () {

        if (ruleID && !props.rulesUsergroups) {
            props.fetchAllRulesUsergroup(ruleID)
        }
        props.fetchUsergroups();


    }, []);



    const handleChange = (event: any) => {
        if(event.target.name == "IsActive"){
            setFormData({ ...formData, [event.target.name]: event.target.checked })
        }else {
            setFormData({ ...formData, [event.target.name]: event.target.value })
        }
    }



    const handleEditRule = () => {
        let ItemParams = props.match.params;
        let { ruleID } = ItemParams;
        let dataSubmitted = formData;

        let data = {
            ...props.rulesUsergroups,
            ...dataSubmitted
        }
        
        setDisable(true)
        props.assignRuleToUser(props.rulesUsergroups.id, data, (res: any) => {
            if (res) {
                setState({ show: true, success: true });

            } else {
                setState({ show: true, success: false });

            }

            setTimeout(() => {
                setDisable(false)
                props.history.push(`${BasePath}/rule/details/${ruleID}`);
            }, 1000)
        })

    }

    const handleCancel = () => {
        let ItemParams = props.match.params;
        let { ruleID } = ItemParams;
        props.history.push(`${BasePath}/rule/details/${ruleID}`);
    }

    const showRuleAssignForm = () => {
        return <div>{props.usergroup && props.usergroup.length > 0 &&
            <div>

                <Grid container>
                    <Grid xs={12} md={4}>
                        <Typography style={{ margin: 10 }} variant={'h3'}>{"Edit Rule"}</Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid xs={12} md={4}>
                        <TextField
                            required
                            fullWidth
                            onChange={handleChange}
                            id={'rule_name'}
                            label="Rule Name"
                            variant={'outlined'}
                            name="rule_name"
                            type="text"
                            value={formData  && formData.rule_name ? formData.rule_name : (props.rulesUsergroups.rule_name || '')}
                            style={{ margin: 10 }}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid xs={12} md={4}>
                        <TextField
                            disabled={true}
                            required
                            fullWidth
                            id={'rule_expression'}
                            label="Rule Expression"
                            variant={'outlined'}
                            name="rule_expression"
                            type="text"
                            value={props.rulesUsergroups.rule_expression || ""}
                            style={{ margin: 10 }}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid xs={12} md={4}>
                        <TextField
                            required
                            fullWidth
                            onChange={handleChange}
                            id={'rule_code'}
                            label="Rule Expression"
                            variant={'outlined'}
                            name="rule_code"
                            type="text"
                            value={formData  &&formData.rule_code ? formData.rule_code : (props.rulesUsergroups.rule_code || '')}
                            style={{ margin: 10 }}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                                <Grid xs={5}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                            //@ts-ignore
                                                checked={formData.IsActive}
                                                onChange={handleChange}
                                                name={'IsActive'}
                                            />
                                        }
                                        style={{ margin: 10 }}
                                        label={'Is Active'}
                                    />
                                </Grid>
                            </Grid>


                <Grid container xs={4} justify="flex-end">
                    <Grid>
                        <Button
                            onClick={handleCancel}
                            color="inherit"
                            variant="contained"
                            style={{ marginRight: 10 }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid>
                        <Button
                            disabled={disable}
                            onClick={handleEditRule}
                            color="primary"
                            variant="contained"
                        >
                            Edit
                        </Button>
                    </Grid>
                </Grid>
            </div>
        }
            {state.show && (
                <SnackbarCmpt
                    message={state.success ? 'Edited successfully' : 'Could not Edit'}
                    severity={state.success ? 'success' : 'error'}
                />
            )}
        </div>
    }


    return (

        <div style={styles.root}>
            {props.processing && <Loader />}
            {props.rulesUsergroups && props.rulesUsergroups.rule_name ?
                showRuleAssignForm()
                :
                !props.processing && <div>
                    <NoDataFound title={'No Permission Rules found'} />
                </div>}
        </div>
    );

}
const mapStateToProps = (state: any) => {
    return {
        rulesUsergroups: state.rules_reducer.rulesUsergroups,
        processing: state.rules_reducer.processing,
        usergroup: state.usergroup_reducer.usergroup,
        user: state.user_reducer.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAllRulesUsergroup: (id: any) => dispatch(fetchAllRulesUsergroup(id)),
        fetchUsergroups: () => dispatch(fetchUsergroups()),
        assignRuleToUser: (id: any, data: any, cb: any) => dispatch(assignRuleToUser(id, data, cb))
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(connected);