import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    CardHeader,
    CardContent,
    Button,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    TableSortLabel,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme: any) => ({
    root: {},
    content: {
        padding: theme.spacing(1),
    },
    inner: {
        minWidth: 200,
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    status: {
        marginRight: theme.spacing(1),
    },
    actions: {
        justifyContent: 'flex-start',
    },
}));

const statusColors = {
    delivered: 'success',
    pending: 'info',
    refunded: 'danger',
};

const CardHeaderCmpt = (props: any) => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            {props.actions || props.title ? (
                <CardHeader action={props.action ? props.action : null} title={props.title} />
            ) : null}
            <Divider />
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>{props.children}</div>
                </PerfectScrollbar>
            </CardContent>
            <Divider />
            {props.footer ? <CardActions className={classes.actions}>{props.footer}</CardActions> : null}
        </Card>
    );
};

export default CardHeaderCmpt;
