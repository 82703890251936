import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: any) => ({
    root: {
        padding: theme.spacing(4),
    },
    iframe: {
        width: '100%',
        minHeight: 640,
        border: 0,
    },
}));

const Icons = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <iframe
                className={classes.iframe}
                src="https://material.io/tools/icons/?icon=accessibility&style=outline"
                title="Material Design icons"
            />
        </div>
    );
};

export default Icons;
