import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';
import { setPageSize } from '../../utils';
import { paginationList, PAGEINATION_ON_TOP} from '../../config';
import { useDispatch, useSelector } from 'react-redux';

function CustomPagination(props: any) {
    let { pageSize } = props;
    const dispatch = useDispatch();
    const handleChange = (event: any) => {
        props.handleNewPageSize(event);
    };

    return (
        <div>
            <Grid container >
                <Grid xs={12} sm={3} md={PAGEINATION_ON_TOP?2:3}>
                    <FormControl style={{ width: '98%' }} size={'small'} variant="outlined">
                        <InputLabel
                            style={{ backgroundColor: 'rgba(251, 251, 251, 0.95)' }}
                            id="demo-simple-select-outlined-label"
                        >
                            Records Displayed Per Page
                        </InputLabel>
                        <Select
                            name="pagesize"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={pageSize}
                            onChange={handleChange}
                            style={{ marginTop: '-3px'}}
                        >
                            {paginationList.map((data: any, index: any) => {
                                return props.total + 50 >= data ? (
                                    <MenuItem key={index} value={data}>
                                        {data}
                                    </MenuItem>
                                ) : null;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={3} md={2}>
                    <TextField
                        variant={'outlined'}
                        disabled={true}
                        style={{ width: '90%', marginTop: '-3px' }}
                        size={'small'}
                        label={'Total Records'}
                        name="traineeText"
                        type="text"
                        value={props.total}
                    />
                </Grid>
                <Grid xs={12} sm={7} md={6}>
                    <Pagination
                        count={setPageSize(props.total, pageSize)}
                        page={props.page}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        onChange={(event: any) => {
                            let value: any = event.target.innerText;
                            props.handlePage(value);
                        }}
                        hidePrevButton
                        hideNextButton
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default CustomPagination;
