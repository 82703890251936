export const SET_SELECTED_CONFIGURATION_TYPE = 'SET_SELECTED_CONFIGURATION_TYPE';

// class types
export const SET_CLASS_TYPE_PROCESSING = 'SET_CLASS_TYPE_PROCESSING';
export const SET_CLASS_TYPE_DATA = 'SET_CLASS_TYPE_DATA';
export const SET_CLASS_TYPE_ERROR = 'SET_CLASS_TYPE_ERROR';

// class type tabs.
export const SET_CLASS_TYPE_TABS_PROCESSING = 'SET_CLASS_TYPE_TABS_PROCESSING';
export const SET_CLASS_TYPE_TABS_DATA = 'SET_CLASS_TYPE_TABS_DATA';
export const SET_CLASS_TYPE_TABS_ERROR = 'SET_CLASS_TYPE_TABS_ERROR';

export const SET_GENERAL_CONFIG = "SET_GENERAL_CONFIG";
export const SET_GENERAL_CONFIG_PROCESSING = 'SET_GENERAL_CONFIG_PROCESSING' 