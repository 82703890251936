import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Chip,
    Switch,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Checkbox,
} from '@material-ui/core';
import {
    fetchNotificationEvents,
    fetchRecipientRoles,
    fetchNotificationTags,
    addNotification,
    editNotification,
} from '../../Services/notifications';
import { containers } from '../../utils/style';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { checkPermissionForNotifications, MANAGE_NOTIFICATIONS } from '../../utils/notifications';
import HelperInfo from '../../components/HelperInfo';
import validate from 'validate.js';
interface props {
    fetchNotificationEvents: any;
    notificationEvents: any;
    fetchRecipientRoles: any;
    recipientRoles: any;
    fetchNotificationTags: any;
    notificationTags: any;
    user: any;
    addNotification: any;
    editData: any;
    editNotification: any;
    history: any;
    views: any;
}

const schema = {
    notificationName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    notificationDescription: {
        length: {
            maximum: 500,
        },
    },
    notificationSubject: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 100,
        },
    },
    body: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 500,
        },
    },
    dashBoardBody: {
        length: {
            maximum: 500,
        },
    },
    daysBeforeDueDate: {
        format: {
            pattern: /[0-9]+/,
            message: 'should be a number greater than zero',
        },
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
            message: 'should be a number greater than zero',
        },
    },
    daysAfterDueDate: {
        format: {
            pattern: /[0-9]+/,
            message: 'should be a number greater than zero',
        },
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
            message: 'should be a number greater than zero',
        },
    },
};

class EditNotification extends Component<props> {
    state = {
        disabled: false,
        isValid: false,
        eventTrigger: null,
        recipientRole: null,
        notificationName: '',
        body: '',
        dashBoardBody: '',
        reminderDays: null,
        daysBeforeDueDate: '',
        daysAfterDueDate: '',
        activateNotification: false,
        DueDate: null,
        currentTag: {},
        notificationDescription: '',
        ID: null,
        cursorPos: null,
        notificationSubject: '',
        subjectFlag: false,
        dashboardNotification: false,
        sameAsBody: false,
        emailBodyFlag: false,
        dashboardBodyFlag: false,
        touched: {
            notificationName: null,
            notificationDescription: null,
            notificationSubject: null,
            body: null,
            dashBoardBody: null,
        },
        errors: {
            notificationName: [],
            notificationDescription: [],
            notificationSubject: [],
            body: [],
            dashBoardBody: [],
            reminderDays: [],
            daysBeforeDueDate: [],
            daysAfterDueDate: [],
        },
    };

    componentDidMount(): void {
        if (!checkPermissionForNotifications(this.props.views).includes(MANAGE_NOTIFICATIONS)) {
            this.props.history.push('/dashboard');
        } else {
            this.props.fetchNotificationEvents();
            if (this.props.editData != null) {
                this.props.fetchRecipientRoles(this.props.editData.EventID);
                this.props.fetchNotificationTags(this.props.editData.EventRoleID);
                const errors = validate(
                    {
                        notificationName: this.props.editData.Name,
                        notificationDescription: this.props.editData.Description,
                        notificationSubject: this.props.editData.Subject,
                        body: this.props.editData.Body,
                        dashBoardBody: this.props.editData.DashboardBody,
                    },
                    schema,
                );
                this.setInitialState(errors);
            }
        }
    }

    setInitialState(errors: any[]) {
        // //
        this.setState({
            ID: this.props.editData.ID,
            body: this.props.editData.Body,
            dashBoardBody: this.props.editData.DashboardBody,
            notificationSubject: this.props.editData.Subject,
            notificationName: this.props.editData.Name,
            dashboardNotification: this.props.editData.DashboardNotification == 1 ? true : false,
            notificationDescription: this.props.editData.Description,
            activateNotification: this.props.editData.IsActive == 1 ? true : false,
            eventTrigger: this.props.editData.EventID,
            recipientRole: this.props.editData.EventRoleID,
            sameAsBody: this.props.editData.Body == this.props.editData.DashboardBody ? true : false,
            touched: {
                notificationName: true,
                notificationDescription: true,
                notificationSubject: true,
                body: true,
                dashBoardBody: true,
            },
            DueDate:
                this.props.editData.ReminderConfigurations &&
                this.props.editData.ReminderConfigurations.DaysBeforeDueDate
                    ? 'DaysBeforeDueDate'
                    : 'DaysAfterDueDate',
            isValid: errors ? false : true,
            errors: errors || {},
        });
        if (this.props.editData.ReminderConfigurations) {
            this.setState({
                reminderDays: this.props.editData.ReminderConfigurations.DaysBeforeDueDate
                    ? this.props.editData.ReminderConfigurations.DaysBeforeDueDate
                    : this.props.editData.ReminderConfigurations.DaysAfterDueDate,
                daysBeforeDueDate: this.props.editData.ReminderConfigurations.DaysBeforeDueDate
                    ? this.props.editData.ReminderConfigurations.DaysBeforeDueDate
                    : null,
                daysAfterDueDate: this.props.editData.ReminderConfigurations.DaysAfterDueDate
                    ? this.props.editData.ReminderConfigurations.DaysAfterDueDate
                    : null,
            });
        }
    }

    handleChange = (event: any) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
                touched: { [event.target.name]: true },
            },
            () => {
                const errors = validate(this.state, schema);
                this.setState({
                    isValid: errors ? false : true,
                    errors: errors || {},
                });
            },
        );
    };

    handleBlur = (event: any) => {
        const errors = validate(this.state, schema);
        this.setState({
            touched: { [event.target.name]: true },
            isValid: errors ? false : true,
            errors: errors || {},
        });
    };

    handleBodyChange = (event: any) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
                cursorPos: event.target.selectionStart,
                touched: { [event.target.name]: true },
            },
            () => {
                const errors = validate(this.state, schema);
                this.setState({
                    isValid: errors ? false : true,
                    errors: errors || {},
                });
            },
        );
    };

    addExtraIndex = (event: any) => {
        this.setState({
            cursorPos: event.target.selectionStart - 1,
        });
    };

    onBlurEvent = () => {
        this.props.fetchRecipientRoles(this.state.eventTrigger);
    };

    onBlurRoles = () => {
        this.props.fetchNotificationTags(this.state.recipientRole);
    };

    handleActivateNotification = (event: any) => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleDashboardNotification = (event: any) => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleSameAsBody = (event: any) => {
        this.setState({
            [event.target.name]: event.target.checked,
            dashboardNotification: false,
        });
    };

    handleCancel = () => {
        this.props.history.push(`/notifications`);
    };

    setSubjectFlag = () => {
        this.setState({
            subjectFlag: true,
            emailBodyFlag: false,
            dashboardBodyFlag: false,
        });
    };

    setEmailBodyFlag = () => {
        this.setState({
            subjectFlag: false,
            emailBodyFlag: true,
            dashboardBodyFlag: false,
        });
    };

    setDashboardBodyFlag = () => {
        this.setState({
            subjectFlag: false,
            emailBodyFlag: false,
            dashboardBodyFlag: true,
        });
    };

    handleEditNotification = () => {
        if (this.state.disabled) {
            return;
        }
        this.setState({
            disabled: true,
        });
        let searchString = '';
        if (this.state.DueDate == 'DaysBeforeDueDate') {
            searchString = `{"DaysBeforeDueDate": ${this.state.daysBeforeDueDate}}`;
        } else if (this.state.DueDate == 'DaysAfterDueDate') {
            searchString = `{"DaysAfterDueDate": ${this.state.daysAfterDueDate}}`;
        }

        let formData = {
            NotificationType: {
                IsActive: this.state.activateNotification ? 1 : 0,
                Name: this.state.notificationName,
                Body: this.state.body,
                DashboardBody: this.state.sameAsBody
                    ? this.state.body
                    : this.state.dashboardNotification
                    ? this.state.dashBoardBody
                    : null,
                DashboardNotification: this.state.dashboardNotification || this.state.sameAsBody ? 1 : 0,
                Subject: this.state.notificationSubject,
                ModifiedDate: moment.utc().format(),
                ModifiedBy: this.props.user.UserName,
                ReminderConfigurations: this.state.eventTrigger == '8' ? `${searchString}` : null,
                Description: this.state.notificationDescription,
                ID: this.state.ID,
            },
        };
        this.props.editNotification(formData, (res: any) => {
            if (res) {
                this.props.history.push(`/notifications`);
            } else {
                this.setState({
                    showErr: true,
                    disabled: false,
                });
            }
        });
    };

    handleTagClick = (tag: any) => {
        if (this.state.subjectFlag) {
            let cursorPosition: any = this.state.cursorPos;
            let textBeforeCursorPosition = this.state.notificationSubject.substring(0, cursorPosition);
            let textAfterCursorPosition = this.state.notificationSubject.substring(
                cursorPosition,
                this.state.notificationSubject.length,
            );
            this.setState({
                currentTag: tag,
                notificationSubject: `${textBeforeCursorPosition} {${tag.Name}} ${textAfterCursorPosition}`,
            });
        } else if (this.state.emailBodyFlag) {
            let cursorPosition: any = this.state.cursorPos;
            let textBeforeCursorPosition = this.state.body.substring(0, cursorPosition);
            let textAfterCursorPosition = this.state.body.substring(cursorPosition, this.state.body.length);
            this.setState({
                currentTag: tag,
                body: `${textBeforeCursorPosition} {${tag.Name}} ${textAfterCursorPosition}`,
            });
        } else if (this.state.dashboardBodyFlag) {
            let cursorPosition: any = this.state.cursorPos;
            let textBeforeCursorPosition = this.state.dashBoardBody.substring(0, cursorPosition);
            let textAfterCursorPosition = this.state.dashBoardBody.substring(
                cursorPosition,
                this.state.dashBoardBody.length,
            );
            this.setState({
                currentTag: tag,
                dashBoardBody: `${textBeforeCursorPosition} {${tag.Name}} ${textAfterCursorPosition}`,
            });
        }
    };

    hasError = (field: any) =>
        //@ts-ignore
        this.state.touched[field] && this.state.errors[field] ? true : false;

    render() {
        const titleContainer = {
            marginTop: 10,
            marginBottom: 'auto',
            marginLeft: 10,
        };
        return (
            <Grid container>
                <Grid container style={containers}>
                    <Grid container style={{ margin: 10 }}>
                        <Grid
                            xs={3}
                            style={{
                                marginTop: '15',
                                marginBottom: '15',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="h2" style={{ marginLeft: 12 }}>
                                Edit Notification
                            </Typography>
                        </Grid>
                        <Grid container style={titleContainer} xs={12}>
                            <Grid item xs={5}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel
                                        style={{ backgroundColor: '#ffffff' }}
                                        id="demo-simple-select-outlined-label"
                                    >
                                        Event Trigger
                                    </InputLabel>
                                    <Select
                                        name="eventTrigger"
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.eventTrigger || ''}
                                        onChange={this.handleChange}
                                        onBlur={this.onBlurEvent}
                                    >
                                        {this.props.notificationEvents &&
                                            this.props.notificationEvents.map((data: any) => {
                                                return <MenuItem value={data.ID}>{data.Name}</MenuItem>;
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container style={titleContainer}>
                            <Grid item xs={5}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel
                                        style={{ backgroundColor: '#ffffff' }}
                                        id="demo-simple-select-outlined-label"
                                    >
                                        Recipient Roles
                                    </InputLabel>
                                    <Select
                                        name="recipientRole"
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.recipientRole || ''}
                                        onChange={this.handleChange}
                                        onBlur={this.onBlurRoles}
                                    >
                                        {this.props.recipientRoles &&
                                            this.props.recipientRoles.map((data: any) => {
                                                return <MenuItem value={data.EventRoleID}>{data.Name}</MenuItem>;
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container style={titleContainer}>
                            <Grid xs={5}>
                                <TextField
                                    required
                                    id={'notificationNameText'}
                                    label="Name"
                                    variant={'outlined'}
                                    fullWidth
                                    name="notificationName"
                                    onChange={this.handleChange}
                                    type="text"
                                    value={this.state.notificationName || ''}
                                    error={this.hasError('notificationName')}
                                    helperText={
                                        this.hasError('notificationName') ? this.state.errors.notificationName[0] : null
                                    }
                                    onBlur={this.handleBlur}
                                />
                            </Grid>
                        </Grid>

                        <Grid container style={titleContainer}>
                            <Grid xs={5}>
                                <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    label="Description"
                                    variant={'outlined'}
                                    name="notificationDescription"
                                    onChange={this.handleChange}
                                    value={this.state.notificationDescription || ''}
                                    error={this.hasError('notificationDescription')}
                                    helperText={
                                        this.hasError('notificationDescription')
                                            ? this.state.errors.notificationDescription[0]
                                            : null
                                    }
                                    onBlur={this.handleBlur}
                                />
                            </Grid>
                        </Grid>

                        <Grid container style={titleContainer}>
                            <Grid xs={5}>
                                <TextField
                                    required
                                    multiline
                                    rows={2}
                                    fullWidth
                                    variant={'outlined'}
                                    label="Subject"
                                    name="notificationSubject"
                                    onChange={this.handleBodyChange}
                                    onMouseUp={this.handleBodyChange}
                                    onKeyDown={this.addExtraIndex}
                                    onFocus={this.setSubjectFlag}
                                    value={this.state.notificationSubject || ''}
                                    error={this.hasError('notificationSubject')}
                                    helperText={
                                        this.hasError('notificationSubject')
                                            ? this.state.errors.notificationSubject[0]
                                            : null
                                    }
                                    onBlur={this.handleBlur}
                                />
                            </Grid>
                        </Grid>

                        <Grid container style={titleContainer}>
                            <Grid xs={5}>
                                <TextField
                                    required
                                    multiline
                                    rows={8}
                                    fullWidth
                                    variant={'outlined'}
                                    label="Email Body"
                                    name="body"
                                    onChange={this.handleBodyChange}
                                    onMouseUp={this.handleBodyChange}
                                    onKeyDown={this.addExtraIndex}
                                    onFocus={this.setEmailBodyFlag}
                                    value={this.state.body || ''}
                                    error={this.hasError('body')}
                                    helperText={this.hasError('body') ? this.state.errors.body[0] : null}
                                    onBlur={this.handleBlur}
                                />
                            </Grid>
                        </Grid>

                        <Grid container style={titleContainer}>
                            <Grid xs={5}>
                                {this.props.notificationTags &&
                                    this.props.notificationTags.map((data: any) => {
                                        return (
                                            <Chip
                                                style={{ margin: 5 }}
                                                label={data.Name}
                                                color={'primary'}
                                                onClick={() => {
                                                    this.handleTagClick(data);
                                                }}
                                            />
                                        );
                                    })}
                            </Grid>
                        </Grid>

                        <Grid container style={titleContainer}>
                            <Grid>
                                {this.state.sameAsBody ? (
                                    <FormControlLabel
                                        disabled
                                        control={
                                            <Switch
                                                checked={true}
                                                onChange={this.handleDashboardNotification}
                                                name={'dashboardNotificationDisabled'}
                                            />
                                        }
                                        label={'Dashboard Notification'}
                                    />
                                ) : (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.dashboardNotification}
                                                onChange={this.handleDashboardNotification}
                                                name={'dashboardNotification'}
                                            />
                                        }
                                        label={'Dashboard Notification'}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <Grid container style={titleContainer}>
                            <Grid>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.sameAsBody}
                                            onChange={this.handleSameAsBody}
                                            name="sameAsBody"
                                        />
                                    }
                                    label="Set Dashboard Body same as Email Body"
                                />
                            </Grid>
                            <Grid style={{ margin: 5, marginTop: 8 }}>
                                <HelperInfo title="Mark checkbox to set dashboard body same as email body" />
                            </Grid>
                        </Grid>

                        {this.state.dashboardNotification && !this.state.sameAsBody ? (
                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        multiline
                                        rows={8}
                                        fullWidth
                                        variant={'outlined'}
                                        label="Dashboard Body"
                                        name="dashBoardBody"
                                        onChange={this.handleBodyChange}
                                        onMouseUp={this.handleBodyChange}
                                        onKeyDown={this.addExtraIndex}
                                        onFocus={this.setDashboardBodyFlag}
                                        value={this.state.dashBoardBody || ''}
                                        error={this.hasError('dashBoardBody')}
                                        helperText={
                                            this.hasError('dashBoardBody') ? this.state.errors.dashBoardBody[0] : null
                                        }
                                        onBlur={this.handleBlur}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container style={titleContainer}>
                                <Grid xs={5}>
                                    <TextField
                                        multiline
                                        disabled
                                        rows={8}
                                        fullWidth
                                        variant={'outlined'}
                                        label="Dashboard Body"
                                        name="dashBoardBody"
                                        onChange={this.handleBodyChange}
                                        onMouseUp={this.handleBodyChange}
                                        onKeyDown={this.addExtraIndex}
                                        value={''}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {this.state.eventTrigger == '8' ? (
                            <Grid container style={titleContainer}>
                                <Grid xs={4}>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="dueDate"
                                            name="DueDate"
                                            value={this.state.DueDate || null}
                                            onChange={this.handleChange}
                                        >
                                            <Grid item>
                                                <FormControlLabel
                                                    value="DaysBeforeDueDate"
                                                    control={<Radio />}
                                                    label="Before DueDate"
                                                />
                                                {this.state.DueDate == 'DaysBeforeDueDate' ? (
                                                    <TextField
                                                        style={{ marginLeft: 5 }}
                                                        id="standard-number"
                                                        name="daysBeforeDueDate"
                                                        onChange={this.handleChange}
                                                        label="Days"
                                                        type="number"
                                                        value={this.state.daysBeforeDueDate || ''}
                                                        error={this.hasError('daysBeforeDueDate')}
                                                        helperText={
                                                            this.hasError('daysBeforeDueDate')
                                                                ? this.state.errors.daysBeforeDueDate[0]
                                                                : null
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                ) : (
                                                    <TextField
                                                        style={{ marginLeft: 5 }}
                                                        disabled
                                                        id="standard-number"
                                                        label="Days"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item style={{ marginTop: 10 }}>
                                                <FormControlLabel
                                                    value="DaysAfterDueDate"
                                                    control={<Radio />}
                                                    label="After DueDate"
                                                />
                                                {this.state.DueDate == 'DaysAfterDueDate' ? (
                                                    <TextField
                                                        style={{ marginLeft: 15 }}
                                                        id="standard-number"
                                                        label="Days"
                                                        type="number"
                                                        name="daysAfterDueDate"
                                                        value={this.state.daysAfterDueDate || ''}
                                                        onChange={this.handleChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={this.hasError('daysAfterDueDate')}
                                                        helperText={
                                                            this.hasError('daysAfterDueDate')
                                                                ? this.state.errors.daysAfterDueDate[0]
                                                                : null
                                                        }
                                                    />
                                                ) : (
                                                    <TextField
                                                        style={{ marginLeft: 15 }}
                                                        disabled
                                                        id="standard-number"
                                                        label="Days"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ) : null}

                        <Grid container style={{ ...titleContainer, marginTop: 40 }}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.activateNotification}
                                            onChange={this.handleActivateNotification}
                                            name="activateNotification"
                                        />
                                    }
                                    label={'Activate Notification'}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={4} style={titleContainer}>
                            <Button
                                color="inherit"
                                size="large"
                                type="submit"
                                variant="outlined"
                                onClick={this.handleCancel}
                                style={{ marginBottom: 10, marginTop: 10, marginRight: 5 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={!this.state.isValid || this.state.disabled}
                                color="primary"
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={this.handleEditNotification}
                                style={{ marginBottom: 10, marginTop: 10 }}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        notificationEvents: state.notifications_reducer.notificationEvents,
        recipientRoles: state.notifications_reducer.recipientRoles,
        notificationTags: state.notifications_reducer.notificationTags,
        editData: state.notifications_reducer.editData,
        user: state.user_reducer.user,
        views: state.user_reducer.views,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchNotificationEvents: () => dispatch(fetchNotificationEvents()),
        fetchRecipientRoles: (ID: any) => dispatch(fetchRecipientRoles(ID)),
        fetchNotificationTags: (ID: any) => dispatch(fetchNotificationTags(ID)),
        addNotification: (data: any, cb: any) => dispatch(addNotification(data, cb)),
        editNotification: (data: any, cb: any) => dispatch(editNotification(data, cb)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(EditNotification);

export default withRouter(connected);
