import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClassData } from '../../../Services/browse';

const CardStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(4),
            height: 40,
            alignItems: 'center',
            fontWeight: 300,
            cursor: 'pointer',
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontSize: '16px',
        },
    }),
);

const ClassLabelsData = (props: any) => {
    const classes = CardStyle();
    let { labelVal, data, type } = props;

    // function changeBackground(e:any) {
    //     e.target.style.background = '#f6f6f6';
    // }
    //
    // function changeBackgroundPrev(e:any) {
    //     e.target.style.background = '#ffffff';
    // }

    // onMouseOver={changeBackground}
    // onMouseOut={changeBackgroundPrev}

    let { selectedClass, pageSizeClassContent } = useSelector((state: any) => ({
        ...state.browse_reducer,
    }));

    const dispatch = useDispatch();

    function handleClick() {
        dispatch(fetchClassData(type, data, 0, pageSizeClassContent));
    }

    return (
        <div
            style={selectedClass['CLASS-NAME'] == data['CLASS-NAME'] ? { background: '#d3d0df' } : {}}
            onClick={handleClick}
        >
            <Grid container className={classes.root}>
                {labelVal}
            </Grid>
        </div>
    );
};

export default ClassLabelsData;
