import {
    SET_ITEM_DATA,
    SET_USERS_PROCESSING,
    SET_USERS_ERROR,
    SET_SEARCHED_USERS,
    GET_ALL_USERS,
    SET_EDIT_USER,
    SET_DEPARTMENTS,
    SET_USER_ROLES,
    SET_VIEWS,
    SET_TOTAL_USERS,
    GET_COURSES_TO_APPROVE,
    GET_TRAININGS_TO_APPROVE,
    SET_CONFIGURATION_DATA,
    SET_CONFIGURATION_TABS_STATE,
    SET_AUDIT_TRAIL_DATA,
    SET_AUDIT_TRAIL_DATA_PROCESSING,
    SET_EVENT,
    SET_AUDIT_TOTAL,
    SET_MODULES,
    SET_PAGE_SIZE_USER,
    SET_SS_ERROR_FLAG,
    SET_SS_ERROR_MSG,
    SET_USER,
    LOADING_DATA_FORM,
    SET_REDLINE_PROCESSING,
    SET_REDLINES_CONFIG,
    SET_REDLINES_DATA,
    SET_REEDLINES_CONFIGURATION_TABS_STATE,
    SET_REDLINED_ITEM
} from '../action-types/user-action-types';

export const setDataFormLoading = (flag: boolean) => {
    return {
        type: LOADING_DATA_FORM,
        payload: flag,
    };
};

export const setCurrentUserData = (data: any) => {
    return {
        type: SET_USER,
        payload: data,
    };
};
export const setItemData = (data: Object) => {
    return {
        type: SET_ITEM_DATA,
        payload: data,
    };
};

export const setViews = (views: any) => {
    return {
        type: SET_VIEWS,
        payload: views,
    };
};

export const setUserSearch = (users: any) => {
    return {
        type: SET_SEARCHED_USERS,
        payload: users,
    };
};

export const setUserProcessing = (flag: any) => {
    return {
        type: SET_USERS_PROCESSING,
        payload: flag,
    };
};

export const setUserError = (flag: any) => {
    return {
        type: SET_USERS_ERROR,
        payload: flag,
    };
};

export const setAllTheUsers = (users: any) => {
    return {
        type: GET_ALL_USERS,
        payload: users,
    };
};

export const setUserEdit = (user: any) => {
    return {
        type: SET_EDIT_USER,
        payload: user,
    };
};

export const setDepartments = (dept: any) => {
    return {
        type: SET_DEPARTMENTS,
        payload: dept,
    };
};

export const setUserRoles = (roles: any) => {
    return {
        type: SET_USER_ROLES,
        payload: roles,
    };
};

export const setTotalUsers = (total: any) => {
    return {
        type: SET_TOTAL_USERS,
        payload: total,
    };
};

export const setConfigurations = (layout: any) => {
    return {
        type: SET_CONFIGURATION_DATA,
        payload: layout,
    };
};

export const setConfigurationTab = (value: any) => {
    return {
        type: SET_CONFIGURATION_TABS_STATE,
        payload: value,
    };
};

// course approve actions

export const setApprovableCourses = (data: any) => {
    return {
        type: GET_COURSES_TO_APPROVE,
        payload: data,
    };
};

// trainings approve actions

export const setApprovableTrainings = (data: any) => {
    return {
        type: GET_TRAININGS_TO_APPROVE,
        payload: data,
    };
};

// audit trails

export const setAuditTrails = (audit: any) => {
    return {
        type: SET_AUDIT_TRAIL_DATA,
        payload: audit,
    };
};

export const setAuditProcessing = (flag: any) => {
    return {
        type: SET_AUDIT_TRAIL_DATA_PROCESSING,
        payload: flag,
    };
};

export const setEvent = (flag: any) => {
    return {
        type: SET_EVENT,
        payload: flag,
    };
};
export const setModules = (flag: any) => {
    return {
        type: SET_MODULES,
        payload: flag,
    };
};

export const setTotalAudit = (flag: any) => {
    return {
        type: SET_AUDIT_TOTAL,
        payload: flag,
    };
};

export const setPageSizeUser = (size: Object) => {
    return {
        type: SET_PAGE_SIZE_USER,
        payload: size,
    };
};

export const setServerErrorFlag = (flag: any) => {
    return {
        type: SET_SS_ERROR_FLAG,
        payload: flag,
    };
};

export const setServerErrorMessage = (msg: Object) => {
    return {
        type: SET_SS_ERROR_MSG,
        payload: msg,
    };
};

export const setRedlinesData = (data: any) => {
    return {
        type: SET_REDLINES_DATA,
        payload: data,
    };
};

export const setRedlinesConfigurations = (config: any) => {
    return {
        type: SET_REDLINES_CONFIG,
        payload: config,
    };
};

export const redlineDataLoading = (flag: any) => {
    return {
        type: SET_REDLINE_PROCESSING,
        payload: flag,
    };
};

export const setRedlineConfigurationTab = (value: any) => {
    return {
        type: SET_REEDLINES_CONFIGURATION_TABS_STATE,
        payload: value,
    };
};

export const setRedlinesItem = (data: any) => {
    return {
        type: SET_REDLINED_ITEM,
        payload: data,
    };
};
