import React, { Component } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { toBase64 } from "../../utils/TrainingItems";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.css";

interface state {
  files: any;
}

interface props {
  handleAttachment: any;
    user:any
}

class MaterialDropAttachments extends Component<props, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      files: [],
    };
  }

  handleChange(files: any) {
    let filesData = files;
    let returnData: any = [];
    filesData.forEach(async (file: any) => {
      let content: any = await toBase64(file);
      returnData.push({
        Title: file.name,
        IsActive: "1",
        CreatedBy: this.props.user.user_name,
        CreatedDate: new Date(),
        FileContent: content.split(",")[1],
        AttachmentTypeID: "2",
      });
      if (returnData.length === files.length) {
        this.props.handleAttachment(returnData);
      }
    });
    this.setState({
      files: files,
    });
  }

  render() {
    return (
      <DropzoneArea
        dropzoneText={"Drag and drop a file or click"}
        filesLimit={1}
        maxFileSize={100000000}
        showPreviewsInDropzone={false}
        onChange={this.handleChange.bind(this)}
        acceptedFiles={[
          "image/*",
          "video/*",
          "application/msword",
          "application/pdf",
          "audio/*",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "application/rtf",
        ]}
      />
    );
  }
}
export default MaterialDropAttachments;