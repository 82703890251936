import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
    root: {
        padding: theme.spacing(1),
        background: '#fff',
        marginTop: theme.spacing(8),
    },
}));

const Footer = (props: any) => {
    const { className, ...rest } = props;

    const classes = useStyles();
    const year = new Date().getFullYear()
    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Typography variant="body1">
                &copy;{' '}
                <Link component="a" href="https://www.gosaas.io/" target="_blank">
                    GoSaaS
                </Link>
                . {year}
            </Typography>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;
