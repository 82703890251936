import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, TextField, Typography, FormHelperText } from '@material-ui/core';

import { testCategoryName } from '../../Services/categories';
import SnackbarCmpt from '../Snackbar';
import Switch from '@material-ui/core/Switch';
import HelperInfo from '../../components/HelperInfo';

const useStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: '#fffff',
        height: '100%',
        minWidth: '660px',
    },
    grid: {
        height: '100%',
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white,
    },
    bio: {
        color: theme.palette.white,
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    logoImage: {
        marginLeft: theme.spacing(4),
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    form: {
        paddingLeft: '5%',
        paddingRight: '5%',
        minWidth: '650px',
        paddingBottom: 125,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    title: {
        marginTop: theme.spacing(3),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    policy: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    },
    policyCheckbox: {
        marginLeft: '-14px',
    },
    signUpButton: {
        margin: theme.spacing(4, 0),
        marginRight: theme.spacing(1),
    },
    person: {},
    policyText: {},
}));

const schema = {
    CategoryName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 50,
        },
    },
    Description: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 500,
        },
    },
};

const CategoryForm = (props: any) => {
    const { history } = props;

    const classes = useStyles();
    const [formState, setFormState] = useState({
        disabled: false,
        isValid: false,
        loaded: false,
        duplicate: false,
        values: {
            CategoryName: '',
            Description: '',
            IsActive: '1',
        },
        touched: { CategoryName: null, Description: null },
        errors: { CategoryName: [], Description: [] },
    });

    useEffect(() => {
        const errors = validate(formState.values, schema);
        if (props.edit && !formState.loaded) {
            setFormState({ ...formState, loaded: true, values: props.editData });
        }
        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);

    const handleChange = (event: any) => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
            duplicate: false,
        }));
    };

    const handleStatus = (event: any) => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.checked ? '1' : '0',
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
            duplicate: false,
        }));
    };

    const handleAction = (event: any) => {
        if (formState.disabled) {
            return;
        }
        setFormState({
            ...formState,
            disabled: true,
        });
        testCategoryName(formState.values, (res: any) => {
            if (!res) {
                event.preventDefault();
                props.submitAction(formState.values);
            } else {
                setFormState({ ...formState, duplicate: true, disabled: false });
            }
        });
    };

    const hasCourses = () => {
        return props.editData && props.editData.courses.length > 0;
    };

    const hasError = (field: any) =>
        //@ts-ignore
        formState.touched[field] && formState.errors[field] ? true : false;

    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.content} item lg={12} xs={12}>
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            <div className={classes.form}>
                                <Typography className={classes.title} variant="h2">
                                    {props.title}
                                </Typography>

                                <TextField
                                    className={classes.textField}
                                    error={hasError('CategoryName')}
                                    fullWidth
                                    required={true}
                                    helperText={hasError('CategoryName') ? formState.errors.CategoryName[0] : null}
                                    label="Category Name"
                                    name="CategoryName"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.values.CategoryName || ''}
                                    variant="outlined"
                                />

                                <TextField
                                    className={classes.textField}
                                    error={hasError('Description')}
                                    required={true}
                                    fullWidth
                                    helperText={hasError('Description') ? formState.errors.Description[0] : null}
                                    label="Description"
                                    name="Description"
                                    onChange={handleChange}
                                    type="text"
                                    multiline
                                    rowsMax="20"
                                    rows={4}
                                    value={formState.values.Description || ''}
                                    variant="outlined"
                                />

                                <Grid container direction="row" justify="flex-start" alignItems="center">
                                    <Grid>
                                        <FormControlLabel
                                            className={classes.textField}
                                            control={
                                                <Switch
                                                    disabled={hasCourses()}
                                                    checked={formState.values.IsActive == '1' ? true : false}
                                                    onChange={handleStatus}
                                                    name="IsActive"
                                                />
                                            }
                                            label={formState.values.IsActive == '1' ? 'Active' : 'Inactive'}
                                        />
                                    </Grid>
                                    <Grid style={{ paddingTop: 14 }}>
                                        {hasCourses() ? (
                                            <HelperInfo
                                                title={'Delete all courses in this category to delete this category.'}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </Grid>
                                </Grid>

                                {formState.duplicate ? (
                                    <SnackbarCmpt
                                        message={`Cannot use category name ${formState.values.CategoryName} its already in use`}
                                        severity={'error'}
                                    />
                                ) : null}

                                <Button
                                    className={classes.signUpButton}
                                    color="primary"
                                    disabled={!formState.isValid || formState.disabled}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={handleAction}
                                >
                                    Save
                                </Button>

                                <Button
                                    className={classes.signUpButton}
                                    color="default"
                                    size="large"
                                    type="submit"
                                    variant="outlined"
                                    onClick={() => {
                                        props.history.push(`/categories/details?${props.editData.ID}`);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(CategoryForm);
