import {
    GET_USER_DATA,
    SET_USER_ERROR,
    SET_USER_DATA,
    ADD_USER,
    SET_EDIT_DATA,
    SET_PAGE_SIZE_USER,
    SET_TOTAL,
    SET_USER_PROCESSING,
    GET_ROLES
} from '../action-types/user-management-action-types';
import { MIN_PAGE_SIZE } from '../config';
const initState = () => {
    return {
        userData: null,
        setUserData: null,
        addUser: null,
        editData: null,
        error: null,
        pageSizeUser: MIN_PAGE_SIZE,
        total: null,
        processing: false,
        roles:null
    };
};

const userManagement_reducer = (state = initState(), action: any) => {
    let newState = { ...state };
    switch (action.type) {
        case GET_USER_DATA:
            setData(newState, action.payload, 'userData');
            break;
        case SET_USER_PROCESSING:
            setData(newState, action.payload, 'processing');
            break;
        case SET_EDIT_DATA:
            setData(newState, action.payload, 'editData');
            break;
        case SET_USER_DATA:
            setData(newState, action.payload, 'setUserData');
            break;
        case ADD_USER:
            setData(newState, action.payload, 'addUser');
            break;
        case SET_USER_ERROR:
            setData(newState, action.payload, 'error');
            break;
        case SET_PAGE_SIZE_USER:
            setData(newState, action.payload, 'pageSizeUser');
            break;
        case SET_TOTAL:
            setData(newState, action.payload, 'total');
            break;
        case GET_ROLES:
            setData(newState, action.payload, 'roles');
            break;
        default:
            break;
    }
    return newState;
};

const setData = (state: any, payload: any, key: any) => {
    state[key] = payload;
};

export default userManagement_reducer;
