import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addFavourites, getFavouritesData, setStateOfFavModal } from '../../../../Services/favorites';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import Loader from '../../../../components/Loader';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import SnackbarCmpt from '../../../../components/Snackbar';
import { withRouter } from 'react-router-dom';
import * as React from 'react';
import {MIN_PAGE_SIZE} from '../../../../config'

function SearchSaveForm(props: any) {
    const [formData, setFromData] = useState({
        Name: '',
        SearchQuery: props.SearchQuery ? props.SearchQuery : '',
        loading: false,
        show: false,
        success: false,
    });
    const dispatch = useDispatch();
    const handleChange = (event: any) => {
        setFromData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const textFildsStyle = {
        margin: '8px',
        width: '100%',
    };

    const handleSave = () => {
        let data = {
            SearchQuery: formData.SearchQuery,
            IsActive: 1,
            Name: formData.Name,
        };

        modifyState({ loading: true });
        dispatch(
            addFavourites(data, 'search', (res: any) => {
                if (res) {
                    modifyState({ loading: false, show: true, success: true });
                } else {
                    modifyState({ loading: false, show: true, success: false });
                }
                setTimeout(() => {
                    dispatch(getFavouritesData('search', 0, MIN_PAGE_SIZE));
                    handleCancel();
                }, 2000);
            }),
        );
    };

    const handleCancel = () => {
        dispatch(setStateOfFavModal(false));
    };

    const modifyState = (obj: any) => {
        setFromData({
            ...formData,
            ...obj,
        });
    };

    return (
        <div>
            <Grid container direction={'row'}>
                <Grid lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ textAlign: 'center' }} variant="h4" color={'primary'}>
                        Save Search
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction={'row'}>
                <Grid lg={12} md={12} xl={12} xs={12}>
                    <TextField
                        id="outlined-basic"
                        style={textFildsStyle}
                        name={'Name'}
                        value={formData.Name}
                        onChange={handleChange}
                        label="Name"
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            <Grid container direction={'row'}>
                <Grid lg={12} md={12} xl={12} xs={12}>
                    <TextField
                        disabled={true}
                        id="outlined-basic"
                        style={textFildsStyle}
                        name={'SearchQuery'}
                        value={formData.SearchQuery}
                        label="Search Query"
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            {formData.loading ? (
                <Loader />
            ) : (
                <Grid container direction={'row'} style={{ marginTop: '4px' }}>
                    <Grid lg={3} md={4} xl={4} xs={12}>
                        <Button
                            disabled={formData.Name ? false : true}
                            style={{ margin: '8px', textAlign: 'center' }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleSave}
                            startIcon={<SaveIcon />}
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid lg={3} md={4} xl={4} xs={12}>
                        <Button
                            style={{ margin: '8px', textAlign: 'center' }}
                            variant="contained"
                            color="default"
                            size="small"
                            onClick={handleCancel}
                            startIcon={<CancelIcon />}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            )}

            {formData.show && (
                <SnackbarCmpt
                    message={formData.success ? 'Search saved successfully' : 'Could not save search'}
                    severity={formData.success ? 'success' : 'error'}
                />
            )}
        </div>
    );
}

export default withRouter(SearchSaveForm);
