export const ADD_CATEGORIES = 'ADD_CATEGORIES';
export const DELETE_CATEGORIES = 'DELETE_CATEGORIES';
export const EDIT_CATEGORIES = 'EDIT_CATEGORIES';

export const checkPermissionForCategories = (views: any) => {
    let viewsUser: any = views['Categories'];
    let permissions: any = [];
    if (viewsUser && viewsUser.some((perm: any) => perm.Name == 'Create Course Category')) {
        permissions.push(ADD_CATEGORIES);
    }
    if (viewsUser && viewsUser.some((perm: any) => perm.Name == 'Delete Course Category')) {
        permissions.push(DELETE_CATEGORIES);
    }
    if (viewsUser && viewsUser.some((perm: any) => perm.Name == 'Edit Course Category')) {
        permissions.push(EDIT_CATEGORIES);
    }
    return permissions;
};
