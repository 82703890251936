import {
    GET_ALL_CATEGORIES,
    SET_CATEGORIES_ERROR,
    SET_CATEGORIES_PROCESSING,
    SET_TOTAL,
    SET_EDITION_CATEGORY,
    SET_DETAILS_CATEGORY,
    SET_ADD_CATEGORY_COURSE,
    SET_CAT_COURSES_TOTAL,
    SET_PAGE_SIZE_CATEGORIES,
} from '../action-types/categories-action-types';

export const getCategories = (trainingItems: any) => {
    return {
        type: GET_ALL_CATEGORIES,
        payload: trainingItems,
    };
};

export const setProcessing = (processing: any) => {
    return {
        type: SET_CATEGORIES_PROCESSING,
        payload: processing,
    };
};

export const getError = (data: any) => {
    return {
        type: SET_CATEGORIES_ERROR,
        payload: data,
    };
};

export const setTotal = (data: any) => {
    return {
        type: SET_TOTAL,
        payload: data,
    };
};

export const setTotalCourseCategory = (data: any) => {
    return {
        type: SET_CAT_COURSES_TOTAL,
        payload: data,
    };
};

export const setEditionCategory = (data: any) => {
    return {
        type: SET_EDITION_CATEGORY,
        payload: data,
    };
};

export const setDetailCategory = (data: any) => {
    return {
        type: SET_DETAILS_CATEGORY,
        payload: data,
    };
};

export const setCategoryForCourse = (data: any) => {
    return {
        type: SET_ADD_CATEGORY_COURSE,
        payload: data,
    };
};

export const setPageSizeCategories = (size: Object) => {
    return {
        type: SET_PAGE_SIZE_CATEGORIES,
        payload: size,
    };
};
