import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const LightTooltip = withStyles((theme: any) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        padding: theme.spacing(1),
    },
}))(Tooltip);

function HelperInfo(props: any) {
    let { title } = props;
    return (
        <Grid container>
            <Grid md={12} container direction="column" justify="center" alignItems="flex-start">
                <LightTooltip title={title} placement="right" aria-label="add">
                    <InfoIcon style={{ color: '#8b8b8b' }} />
                </LightTooltip>
            </Grid>
        </Grid>
    );
}

export default HelperInfo;
