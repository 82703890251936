export const SET_COURSE_STATS = 'SET_COURSE_STATS';
export const COURSE_STATS_PROCESSING = 'COURSE_STATS_PROCESSING';

export const SET_REVISED_COURSES = 'SET_REVISED_COURSES';
export const REVISED_COURSES_PROCESSING = 'REVISED_COURSES_PROCESSING';

export const REVISIONS_AVAILABLE = 'REVISIONS_AVAILABLE';
export const REV_AVL_PROCESSING = 'REV_AVL_PROCESSING';

export const OVERDUE_TRAININGS = 'OVERDUE_TRAININGS';
export const OVERDUE_PROCESSING = 'OVERDUE_PROCESSING';

export const TRAINING_DEADLINE = 'TRAINING_DEADLINE';
export const DEADLINE_PROCESSING = 'DEADLINE_PROCESSING';

export const DASHBOARD_NOTIFICATIONS = 'DASHBOARD_NOTIFICATIONS';
export const NOTIFICATION_PROCESSING = 'NOTIFICATION_PROCESSING';

export const SET_COURSE_PIE_STATS = 'SET_COURSE_PIE_STATS';
export const PIE_COURSE_STATS_PROCESSING = 'PIE_COURSE_STATS_PROCESSING';
