import moment from 'moment';

export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const EDIT_USER = 'EDIT_USER';

export const USER_SORTLIST = [
    { title: 'First Name', value: 'FirstName' },
    { title: 'Last Name', value: 'LastName' },
    { title: 'UserName', value: 'UserName' },
    { title: 'Status', value: 'IsActive' },
    { title: 'Source Name', value: 'UserSourceID' },
    { title: 'Department', value: 'DepartmentID' },
    { title: 'Created Date', value: 'CreatedDate' },
];

export const AUDIT_SORTLIST = [
    { title: 'Created By', value: 'CreatedBy' },
    { title: 'Description', value: 'Description' },
    { title: 'Created Date', value: 'CreatedDate' },
];

export const transformUserDeptRole = (userArray: any) => {
    let data: any = [];
    userArray.forEach((user: any) => {
        let userData = user;
        let roles = '';
        let DepartmentName = '';

        if (user.roles) {
            user.roles.forEach((role: any) => {
                roles += role.Name + ' ';
            });
        }

        if (user.departments) {
            DepartmentName = user.departments.DepartmentName;
        }

        data.push({ ...userData, roles: roles, DepartmentName: DepartmentName });
    });

    return data;
};

export const userFilter = (status: any) => {
    let newExpand = '';
    switch (status) {
        case 'Active':
            newExpand = '&IsActive=1';
            break;
        case 'InActive':
            newExpand = '&IsActive=0';
            break;
        default:
            break;
    }

    return newExpand;
};

export const setActiveMemeberTrue = (users: any, pers: any) => {
    let usersData: any = [];
    users.forEach((user: any) => {
        usersData.push({
            ...user,
            IsActive: 1,
            MemberActive: 1,
            MemberCreatedBy: 'Tom',
            MemberCreatedDate: moment.utc().format(),
            MemberModifiedBy: 'Tom',
            MemberModifiedDate: moment.utc().format(),
            ModifiedDate: moment.utc().format(),
            ModifiedBy: 'Tom',
        });
    });

    return usersData;
};

export const setStausForActiveRoles = (oldRolesData: any, newRolesData: any, user: any) => {
    let resultData: any = [];
    oldRolesData.forEach((oldData: any) => {
        if (newRolesData.some((user: any) => user.ID === oldData.ID)) {
            resultData.push({
                ...oldData,
                IsActive: 1,
                RoleUserActive: 1,
                RoleUserModifiedBy: `${user.UserName}`,
                RoleUserModifiedDate: moment.utc().format(),
            });
        } else {
            resultData.push({
                ...oldData,
                IsActive: 1,
                RoleUserActive: 0,
                RoleUserModifiedBy: `${user.UserName}`,
                RoleUserModifiedDate: moment.utc().format(),
            });
        }
    });
    newRolesData.forEach((newData: any) => {
        if (oldRolesData.some((user: any) => user.ID === newData.ID)) {
        } else {
            resultData.push({
                ...newData,
                IsActive: 1,
                RoleUserActive: 1,
                RoleUserCreatedBy: `${user.UserName}`,
                RoleUserCreatedDate: moment.utc().format(),
            });
        }
    });

    return resultData;
};

export const checkPermissionForUser = (views: any) => {
    let viewsUser: any = views['Users'];
    let permissions: any = [];
    if (viewsUser && viewsUser.some((perm: any) => perm.Name == 'Manage Users')) {
        permissions = [ADD_USER, EDIT_USER, DELETE_USER];
    }
    return permissions;
};

// export const SortDataGroupsByTab = (dataArray: any) => {
//   let resultant: any = {};
//   dataArray.map((dataValue: any) => {
//     // if (dataValue.Tab != "") {
//       if (resultant[`${dataValue.tab}`]) {
//         resultant[`${dataValue.tab}`].push({
//           Data:{ ...dataValue},
//           SequenceNumber: dataValue["Display Order"],
//           Tab: dataValue.tab,
//           Type:dataValue.Type
//           });
//         } else {
//           resultant[`${dataValue.tab}`] = [ {
//           //resultant[`${dataValue.Tab}`]:{ ...resultant[`${dataValue.Tab}`]},
//           Data: { ...dataValue },
//           SequenceNumber: dataValue["Display Order"],
//           Tab: dataValue.tab,
//           Type:dataValue.Type

//           }];
//         }

//   });

//   if(resultant["Page Three"]){
//     // get subgroups
//     resultant["Page Three"]=SortDataGroupsBySubgroups(resultant["Page Three"])

//   }
//   return resultant;
// };

export const SortDataGroupsBySubgroups = (dataArray: any) => {
    let resultant: any = {};

    dataArray.map((dataValue: any) => {
        // if (dataValue.Tab != "") {

        if (resultant[`${dataValue.Data.subclass}`]) {
            resultant[`${dataValue.Data.subclass}`].push({
                Data: { ...dataValue.Data },
                SequenceNumber: dataValue.Data['Display Order'],
                SubClass: dataValue.Data.subclass,
                Type: dataValue.Type,
            });
        } else {
            resultant[`${dataValue.Data.subclass}`] = [
                {
                    //resultant[`${dataValue.Tab}`]:{ ...resultant[`${dataValue.Tab}`]},
                    Data: { ...dataValue.Data },
                    SequenceNumber: dataValue.Data['Display Order'],
                    SubClass: dataValue.Data.subclass,
                    Type: dataValue.Type,
                },
            ];
        }
    });

    const ordered = Object.keys(resultant).sort(function(a, b) {
        return resultant[a]['SequenceNumber'] - resultant[b]['SequenceNumber'];
    });

    let SequenceData: any = [];

    ordered.map((val: any) => {
        SequenceData.push(resultant[val]);
    });

    return SequenceData;
};
export const formatDataGroupsForConfigurations = (dataArray: any) => {
    let resultant: any = {};

    dataArray.map((dataValue: any) => {
        if (dataValue.Tab != '') {
            if (resultant[`${dataValue.Tab}`]) {
                resultant[`${dataValue.Tab}`].push({
                    ...dataValue,
                });
            } else {
                resultant[`${dataValue.Tab}`] = {
                    ...resultant[`${dataValue.Tab}`],
                    [`${dataValue.Tab}`]: [{ ...dataValue }],
                };
            }
        } else {
            resultant[`${dataValue.Tab}`] = {
                [`${dataValue.Tab}`]: [{ ...dataValue }],
                SequenceNumber: dataValue['Display Order'],
                Tab: dataValue.Tab,
            };
        }
    });

    const ordered = Object.keys(resultant).sort(function(a, b) {
        return resultant[a]['Display Order'] - resultant[b]['Display Order'];
    });

    let SequenceData: any = [];

    ordered.map((val: any) => {
        SequenceData.push(resultant[val]);
    });

    return SequenceData;
    // let resultant: any = {};

    // dataArray.map((dataValue: any) => {
    //   if (dataValue.Group != "") {
    //     if (resultant[`${dataValue.Group}`]) {
    //       if (resultant[`${dataValue.Group}`][`${dataValue.SubGroup}`]) {
    //         resultant[`${dataValue.Group}`][`${dataValue.SubGroup}`].push({
    //           ...dataValue,
    //         });
    //       } else {
    //         resultant[`${dataValue.Group}`] = {
    //           ...resultant[`${dataValue.Group}`],
    //           [`${dataValue.SubGroup}`]: [{ ...dataValue }],
    //         };
    //       }
    //     } else {
    //       resultant[`${dataValue.Group}`] = {
    //         [`${dataValue.SubGroup}`]: [{ ...dataValue }],
    //         SequenceNumber: dataValue["GroupSequenceNumber"],
    //         GroupName: dataValue.Group,
    //       };
    //     }
    //   }
    // });

    // const ordered = Object.keys(resultant).sort(function(a, b) {
    //   return resultant[a]["SequenceNumber"] - resultant[b]["SequenceNumber"];
    // });

    // let SequenceData: any = [];

    // ordered.map((val: any) => {
    //   SequenceData.push(resultant[val]);
    // });

    // return SequenceData;
};
export const SortDataGroupsByTab = (dataArray: any) => {


    for (let [key, value] of Object.entries(dataArray)) {
        let array: any = value;
        let resultant: any = { subClass: true };
        array.map((dataValue: any) => {
            if (dataValue.subclass && dataValue.subclass != '') {
                if (resultant[`${dataValue.subclass}`]) {
                    resultant[`${dataValue.subclass}`].push({
                        Data: { ...dataValue },
                        SubClass: dataValue.subclass,
                    });
                } else {
                    resultant[`${dataValue.subclass}`] = [
                        {
                            //resultant[`${dataValue.Tab}`]:{ ...resultant[`${dataValue.Tab}`]},
                            Data: { ...dataValue },
                            SubClass: dataValue.subclass,
                        },
                    ];
                }
                dataArray[key] = resultant;
            }
        });
    }

    return dataArray;
};

export const SortTabs = (layout: any, ordered: any) => {
    let orderedLayout: any = {};

    if (ordered.length > 0) {
        for (let each in ordered) {
            if (layout[ordered[each]]) {
                orderedLayout[`${ordered[each]}`] = layout[ordered[each]];
            }
        }

        return orderedLayout;
    } else return layout;
};
