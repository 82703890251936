import {
    setDocumentTypeClassData,
    setDocumentTypeClassError,
    setDocumentTypeClassProcessing,
    setPartTypeClassData,
    setPartTypeClassError,
    setPartTypeClassProcessing,
    setClassData,
    setClassProcessing,
    setSelectedClass,
    setClassContentPageSize,
    setClassContentTotal,
    setSelectedClassType,
    setSearchedData,
    setSearchedDataProcessing,
    setChangeTypeClassData,
    setChangeTypeClassError,
    setChangeTypeClassProcessing,
    setManufacturerPartsTypeClassData,
    setManufacturerPartsTypeClassError,
    setManufacturerPartsTypeClassProcessing,
    setManufacturerTypeClassData,
    setManufacturerTypeClassError,
    setManufacturerTypeClassProcessing,
    setSuppliersTypeClassData,
    setSuppliersTypeClassError,
    setSuppliersTypeClassProcessing,
    setSuppliersItemsTypeClassData,
    setSuppliersItemsTypeClassError,
    setSuppliersItemsTypeClassProcessing,
    setSearchedAdvanceData,
    setSearchedAdvanceDataProcessing,
    setClassContentTotalBrowse,
    setTextSearchData,
    setTextSearchDataProcessing,
    setPSRClassProcessing,
    setPSRTypeClassData,
    setPSRTypeClassError, 
    setSimpleSearchPrevState, 
    setAdvanceSearchPrevState, 
    setSelectedClassName, 
    setClassNameList,
    setStatusList,
    setQCRClassProcessing,
    setQCRTypeClassData,
    setQCRTypeClassError
} from '../actions/browse';
import axios from '../utils/axios';
import { MIN_PAGE_SIZE } from '../config';
import PartClassData from './Data/PartData';
import DocumentData from './Data/DocumentData';
import ContentData from './Data/SpecificData';
import {setCurrentUserData} from "../actions/user";

export const fetchPartClassTypeData = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setPartTypeClassProcessing(true));
        dispatch(setPartTypeClassData(null));
        let {selectedClassName,
            selectedClassType} = getState().browse_reducer
        let url = `/api/list/Parts`;

        axios
            .get(url)
            .then((response: any) => {
                // ;
                if (response.data.result['CLASS-LIST']) {
                    let data: any = response.data.result['CLASS-LIST'];
                    dispatch(setPartTypeClassProcessing(false));
                    dispatch(setPartTypeClassData(data));
                    dispatch(setClassNameList(response.data.result['CLASS-LIST']))

                    if(!selectedClassType){
                        dispatch(setSelectedClassType('Select All'));
                        dispatch(setSelectedClassName('Select All'));
                    }

                    // dispatch(fetchClassData('Parts', data[0], 0, 8))
                } else {
                    dispatch(setPartTypeClassProcessing(false));
                    dispatch(setPartTypeClassError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setPartTypeClassProcessing(false));
                dispatch(setPartTypeClassError('No data found'));
            });
    };
};

export const fetchDocumentClassTypeData = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setDocumentTypeClassProcessing(true));
        dispatch(setDocumentTypeClassData(null));
        let url = `/api/list/Documents`;

        axios
            .get(url)
            .then((response: any) => {
                //   ;
                if (response.data.result['CLASS-LIST']) {
                    dispatch(setDocumentTypeClassProcessing(false));
                    dispatch(setDocumentTypeClassData(response.data.result['CLASS-LIST']));
                } else {
                    dispatch(setDocumentTypeClassProcessing(false));
                    dispatch(setDocumentTypeClassError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setDocumentTypeClassProcessing(false));
                dispatch(setDocumentTypeClassError('No data found'));
            });
    };
};

export const fetchChangeClassTypeData = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setChangeTypeClassProcessing(true));
        dispatch(setChangeTypeClassData(null));
        let url = `/api/list/Changes`;

        axios
            .get(url)
            .then((response: any) => {
                //   ;
                if (response.data.result['CLASS-LIST']) {
                    dispatch(setChangeTypeClassProcessing(false));
                    dispatch(setChangeTypeClassData(response.data.result['CLASS-LIST']));
                } else {
                    dispatch(setChangeTypeClassProcessing(false));
                    dispatch(setChangeTypeClassError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setChangeTypeClassProcessing(false));
                dispatch(setChangeTypeClassError('No data found'));
            });
    };
};

//manufacturer
export const fetchManufacturerClassTypeData = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setManufacturerTypeClassProcessing(true));
        dispatch(setManufacturerTypeClassData(null));
        let url = `/api/list/Manufacturers`;

        axios
            .get(url)
            .then((response: any) => {
                //   ;
                if (response.data.result['CLASS-LIST']) {
                    dispatch(setManufacturerTypeClassProcessing(false));
                    dispatch(setManufacturerTypeClassData(response.data.result['CLASS-LIST']));
                } else {
                    dispatch(setManufacturerTypeClassProcessing(false));
                    dispatch(setManufacturerTypeClassError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setManufacturerTypeClassProcessing(false));
                dispatch(setManufacturerTypeClassError('No data found'));
            });
    };
};

//psr
export const fetchPSRClassTypeData = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setPSRClassProcessing(true));
        dispatch(setPSRTypeClassData(null));
        let url = `/api/list/Product_Service_Requests`;

        axios
            .get(url)
            .then((response: any) => {
                //   ;
                if (response.data.result['CLASS-LIST']) {
                    dispatch(setPSRClassProcessing(false));
                    dispatch(setPSRTypeClassData(response.data.result['CLASS-LIST']));
                } else {
                    dispatch(setPSRClassProcessing(false));
                    dispatch(setPSRTypeClassError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setPSRClassProcessing(false));
                dispatch(setPSRTypeClassError('No data found'));
            });
    };
};

//QCR
export const fetchQCRClassTypeData = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setQCRClassProcessing(true));
        dispatch(setQCRTypeClassData(null));
        let url = `/api/list/Quality_Change_Requests`;

        axios
            .get(url)
            .then((response: any) => {
                //   ;
                if (response.data.result['CLASS-LIST']) {
                    dispatch(setQCRClassProcessing(false));
                    dispatch(setQCRTypeClassData(response.data.result['CLASS-LIST']));
                } else {
                    dispatch(setQCRClassProcessing(false));
                    dispatch(setQCRTypeClassError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setQCRClassProcessing(false));
                dispatch(setQCRTypeClassError('No data found'));
            });
    };
};

//suppliers
export const fetchSuppliersClassTypeData = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setSuppliersTypeClassProcessing(true));
        dispatch(setSuppliersTypeClassData(null));
        let url = `/api/list/Suppliers`;

        axios
            .get(url)
            .then((response: any) => {
                //   ;
                if (response.data.result['CLASS-LIST']) {
                    dispatch(setSuppliersTypeClassProcessing(false));
                    dispatch(setSuppliersTypeClassData(response.data.result['CLASS-LIST']));
                } else {
                    dispatch(setSuppliersTypeClassProcessing(false));
                    dispatch(setSuppliersTypeClassError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setSuppliersTypeClassProcessing(false));
                dispatch(setSuppliersTypeClassError('No data found'));
            });
    };
};

//suppliers items
export const fetchSuppliersItemsClassTypeData = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setSuppliersItemsTypeClassProcessing(true));
        dispatch(setSuppliersItemsTypeClassData(null));
        let url = `/api/list/Supplier_Items`;

        axios
            .get(url)
            .then((response: any) => {
                //   ;
                if (response.data.result['CLASS-LIST']) {
                    dispatch(setSuppliersItemsTypeClassProcessing(false));
                    dispatch(setSuppliersItemsTypeClassData(response.data.result['CLASS-LIST']));
                } else {
                    dispatch(setSuppliersItemsTypeClassProcessing(false));
                    dispatch(setSuppliersItemsTypeClassError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setSuppliersItemsTypeClassProcessing(false));
                dispatch(setSuppliersItemsTypeClassError('No data found'));
            });
    };
};
//manufacturer parts
export const fetchManufacturerPartsClassTypeData = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setManufacturerPartsTypeClassProcessing(true));
        dispatch(setManufacturerPartsTypeClassData(null));
        let url = `/api/list/Manufacturer_parts`;

        axios
            .get(url)
            .then((response: any) => {
                //   ;
                if (response.data.result['CLASS-LIST']) {
                    dispatch(setManufacturerPartsTypeClassProcessing(false));
                    dispatch(setManufacturerPartsTypeClassData(response.data.result['CLASS-LIST']));
                } else {
                    dispatch(setManufacturerPartsTypeClassProcessing(false));
                    dispatch(setManufacturerPartsTypeClassError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setManufacturerPartsTypeClassProcessing(false));
                dispatch(setManufacturerPartsTypeClassError('No data found'));
            });
    };
};

export const fetchClassData = (classtype: any, classname: any, offset: any, records: any) => {
    return (dispatch: any, getState: any) => {
        //  ;
        dispatch(setClassProcessing(true));
        dispatch(setClassData(null));
        dispatch(setSelectedClass(classname));
        dispatch(setSelectedClassType(classtype));

        const { pageSizeClassContent }: any = getState().browse_reducer;

        let url = `/api/browse/${classtype}/${classname['CLASS-NAME']}?page=${offset * records}&pageSize=${records}`;

        axios
            .get(url)
            .then((response: any) => {
                if (response.data) {
                    dispatch(setClassProcessing(false));
                    dispatch(setClassData(response.data.result));
                    dispatch(setClassContentTotalBrowse(response.data.result.COUNT));
                    if (response.data.result.COUNT && parseInt(response.data.result.COUNT) + (MIN_PAGE_SIZE-1) < pageSizeClassContent) {
                        let countData = parseInt(response.data.result.COUNT);
                        dispatch(setPageSize(pageSizesCalculation(countData)));
                    }
                } else {
                    dispatch(setClassProcessing(true));
                    dispatch(setClassData(null));
                    dispatch(setClassContentTotalBrowse(response.data.result.COUNT));
                    dispatch(setPageSize(MIN_PAGE_SIZE));
                }
            })
            .catch(err => {
                dispatch(setClassProcessing(false));
                dispatch(setClassContentTotalBrowse(0));
                dispatch(setPageSize(MIN_PAGE_SIZE));
            });
    };
};

export const searchClassData = (filter: any, offset: any, records: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setSearchedDataProcessing(true));
        dispatch(setSearchedData(null));
        const { pageSizeClassContent }: any = getState().browse_reducer;

        let url = `/api/search/simple?pageSize=${records}&page=${offset}${filter}`;

        axios
            .get(url)
            .then((response: any) => {
                // ;

                if (response.data) {
                    dispatch(setSearchedDataProcessing(false));
                    dispatch(setSearchedData(response.data.result));
                    dispatch(setClassContentTotal(response.data.result.COUNT));
                    if (response.data.result.COUNT && parseInt(response.data.result.COUNT) + (MIN_PAGE_SIZE-1) < pageSizeClassContent) {
                        let countData = parseInt(response.data.result.COUNT);
                        dispatch(setPageSize(pageSizesCalculation(countData)));
                    }
                } else {
                    dispatch(setSearchedDataProcessing(false));
                    dispatch(setSearchedData(null));
                    dispatch(setClassContentTotal(0));
                    dispatch(setPageSize(MIN_PAGE_SIZE));
                }
            })
            .catch(err => {
                dispatch(setSearchedDataProcessing(false));
                dispatch(setSearchedData(null));
                dispatch(setClassContentTotal(0));
                dispatch(setPageSize(MIN_PAGE_SIZE));
            });
    };
};

export function returnClassName(selectedClassname:any, selectedList:any) {
    let returnData = selectedClassname;
    selectedList && selectedList.length > 0 && selectedList.map((listVal:any)=>{
        if(listVal['DISPLAY-NAME'] == selectedClassname){
            returnData = listVal['CLASS-NAME'];
        }
    });
    return returnData
}



export const searchAdvanceClassData = (filter: any, offset: any, records: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setSearchedAdvanceDataProcessing(true));
        dispatch(setSearchedAdvanceData(null));
        const { pageSizeClassContent, selectedClass, selectedClassType, SelectedClassList }: any = getState().browse_reducer;

        let addFilter:any = null;

        if(selectedClassType && selectedClassType!=="Select All" && filter.filters.length){
            addFilter = {
                "logic": "and",
                "filters": [
                    {
                        "field": "CLASS_TYPE",
                        "operator": "contains",
                        "value": selectedClassType
                    },
                    {
                        ...filter
                    }
                ]
            }
        } else if(selectedClassType && selectedClassType!=="Select All") {
        addFilter = {
                        "logic": "and",
                        "filters": [
                            {
                                "field": "CLASS_TYPE",
                                "operator": "contains",
                                "value": selectedClassType
                            }
                        ]
                    }
        }
        if(addFilter && selectedClass && selectedClass != "Select All"){
            addFilter.filters.push(
                {
                    "field": "CLASS_NAME",
                    "operator": "contains",
                    "value": returnClassName(selectedClass, SelectedClassList)
                },
            )
        }



        let url = `/api/search`;

        axios
            .post(url, {
                pageSize: records,
                page: offset,
                filter: addFilter ? addFilter : filter
            })
            .then((response: any) => {
                // ;
                if (response.data) {
                    dispatch(setSearchedAdvanceDataProcessing(false));
                    dispatch(setSearchedAdvanceData(response.data.result));
                    dispatch(setClassContentTotal(response.data.result.COUNT));
                    if (response.data.result.COUNT && parseInt(response.data.result.COUNT) + (MIN_PAGE_SIZE-1) < pageSizeClassContent) {
                        let countData = parseInt(response.data.result.COUNT);
                        dispatch(setPageSize(pageSizesCalculation(countData)));
                    }
                } else {
                    dispatch(setSearchedAdvanceDataProcessing(false));
                    dispatch(setSearchedAdvanceData(null));
                    dispatch(setClassContentTotal(0));
                    dispatch(setPageSize(MIN_PAGE_SIZE));
                }
            })
            .catch(err => {
                dispatch(setSearchedAdvanceDataProcessing(false));
                dispatch(setSearchedAdvanceData(null));
                dispatch(setClassContentTotal(0));
                dispatch(setPageSize(MIN_PAGE_SIZE));
            });
    };
};

export const searchTextDataInAttachments = (filter: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setTextSearchDataProcessing(true));
        dispatch(setTextSearchData(null));
        let url = `/api/textSearch${filter}`;

        axios
            .get(url)
            .then((response: any) => {
                // ;
                if (
                    response.data &&
                    response.data.result &&
                    response.data.result.DATA &&
                    response.data.result.DATA.length > 0
                ) {
                    dispatch(setTextSearchDataProcessing(false));
                    dispatch(setTextSearchData(response.data.result.DATA));
                } else {
                    dispatch(setTextSearchDataProcessing(false));
                    dispatch(setTextSearchData(null));
                }
            })
            .catch(err => {
                dispatch(setTextSearchDataProcessing(false));
                dispatch(setTextSearchData(null));
            });
    };
};

const pageSizesCalculation = (count: any) => {
    //@ts-ignore
    let division: any = parseInt(count / MIN_PAGE_SIZE);
    //@ts-ignore
    if (parseFloat(count / MIN_PAGE_SIZE) > division) {
        return (division + 1) * MIN_PAGE_SIZE;
    } else {
        return count;
    }
};

export const setDataSize = (size: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setClassContentTotal(size));
    };
};

export const setPageSize = (size: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setClassContentPageSize(size));
    };
};


export const setPrevStateForSearch = (data: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setSimpleSearchPrevState(data));
    };
};


export const setPrevStateForAdvanceSearch = (data: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setAdvanceSearchPrevState(data));
    };
};


export const downloadFileFromVault = (filePath: any, cb: any) => {
        let url = `/api/download`;
        axios.post(url, {	"file_path":filePath}).then(response => {
            if (response.data.result) {
                cb(response.data.result);
            } else {
                cb(false);
            }
        }).catch((err:any)=>{
            cb(false);
        });
};



export const setClassTypeFromDrop = (data: any, searchFlag?:any) => {
    return (dispatch: any, getState: any) => {

        dispatch(setSelectedClassType(null));
        dispatch(setSelectedClassType(data));

        let {PartsClassType,
            DocumentClassType,
            ChangesClassType,
            ManufacturersClassType,
            ManufacturerPartsClassType} = getState().browse_reducer

        if(data == 'Parts' && PartsClassType && PartsClassType.length){
            dispatch(setClassNameList(PartsClassType));
        }else if(data == 'Documents' && DocumentClassType && DocumentClassType.length){
            dispatch(setClassNameList(DocumentClassType));
        }else if(data == 'Changes' && ChangesClassType && ChangesClassType.length) {
            dispatch(setClassNameList(ChangesClassType));
        } else if(data == 'Manufacturers' && ManufacturersClassType && ManufacturersClassType.length) {
            dispatch(setClassNameList(ManufacturersClassType));
        } else if(data == 'Manufacturer_parts' && ManufacturerPartsClassType && ManufacturerPartsClassType.length){
            dispatch(setClassNameList(ManufacturerPartsClassType));
        }
    };
};

export const setClassNameFromDrop = (data: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setSelectedClassName(null));
        dispatch(setSelectedClassName(data));
    };
};


export const fetchStatusList = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setStatusList(null));
    
        let url = `/api/status_list`;

        axios
            .get(url)
            .then((response: any) => {
                if (response.data && response.data.result) {
                    dispatch(setStatusList(response.data.result));
                }
            })
            .catch(err => {
                console.log("Error while fetching statuses", err)
            });
    };
}; 