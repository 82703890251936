import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { TreeListTextFilter } from '@progress/kendo-react-treelist';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomPagination from '../../components/CustomPagination';
import Treefile from '../../components/KendoUICmpt/TreeList';
import Loader from '../../components/Loader';
import NoDataFound from '../../components/Messages/NoDataFound';
import theme from '../../theme';
import {
    getFavouritesData,
    deleteBookmark,
    updateFavourites,
    setPageSizeFavt,
    setCurrentPageFavt,
} from '../../Services/favorites';
import { MIN_PAGE_SIZE } from '../../config';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import SnackbarCmpt from '../../components/Snackbar';
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmationNumber } from '@material-ui/icons';
import ConfirmationPopover from './Components/ConfirmationPopover';

interface props {
    processing: any;
    removeBookmark: any;
    deleteBookmark: any;
    getFavouritesData: any;
    bookmarksData: any;
    updateFavourites: any;
    history: any;
    handleCloseDrawer: any;
    setPageSizeFavt: any;
    totalBookmarkRecords: any;
    bookmarksPageSize: any;
    currentBookmarkPage: any;
    setCurrentPageFavt: any;
}

class Bookmarks extends Component<props> {
    state = {
        show: false,
        success: false,
        page: 1,
    };

    columns = [
        {
            title: 'Name',
            width: 200,
            cell: (rowData: any) => (
                <Tooltip title="Open Search">
                    <td style={{ cursor: 'pointer' }} onClick={(e: any) => this.handleOpen(e, rowData)}>
                        <span style={{ color: 'blue' }}>{rowData.dataItem['Name']}</span>
                    </td>
                </Tooltip>
            ),
        },
        // {
        //     title: 'Browsing Link',
        //     filter: TreeListTextFilter,
        //     cell: (rowData: any) => (
        //         <Tooltip title={rowData.dataItem['BrowsingLink']}>
        //             <td style={{ cursor: 'pointer', maxWidth: '80px' }}>
        //                 <span>{rowData.dataItem['BrowsingLink']}</span>
        //             </td>
        //         </Tooltip>
        //     ),
        // },
        {
            width: 20,
            title: 'Remove',
            cell: (rowData: any) => (
                <td>
                    <ConfirmationPopover handleYes={(e: any) => this.handleRemove(e, rowData)} />
                </td>
            ),
        },
    ];

    componentDidMount() {
        if (!this.props.bookmarksData || this.props.bookmarksData.length < 1) {
            this.props.getFavouritesData('bookmark', 0, MIN_PAGE_SIZE);
        }
    }

    handleRemove = (e: any, rowData: any) => {
        let data = rowData.dataItem;
        if (data) {
            let id = data.id;
            delete data['id'];
            delete data['expanded'];

            let formData = { ...data, IsActive: 0 };
            let type = 'bookmark';

            this.props.updateFavourites(id, formData, type, (res: any) => {
                if (res) {
                    this.setState({ show: true, success: true, page:1 });
                } else {
                    this.setState({ show: true, success: false, page:1 });
                }
                this.props.setCurrentPageFavt('bookmark',1);

                this.props.getFavouritesData(
                    'bookmark',
                    0,
                    this.props.bookmarksPageSize,
                );
            });
        }
    };

    handleOpen = (e: any, rowData: any) => {
        let data = rowData.dataItem;
        if (data && data.BrowsingLink) {
            this.props.history.push(data.BrowsingLink);
            this.props.handleCloseDrawer();
        }
    };

    handlePage = (value: any) => {
        this.setState({
            page: parseInt(value),
        });

        this.props.setCurrentPageFavt('browse', parseInt(value));

        let pageNumbr = parseInt(value) - 1;
        this.props.getFavouritesData('bookmark', pageNumbr, this.props.bookmarksPageSize);
    };

    handleNewPageSize = (event: any) => {
        this.props.setPageSizeFavt('bookmark', event.target.value);
        this.props.getFavouritesData('bookmark', this.props.currentBookmarkPage - 1,  event.target.value);
    };

    render() {
        return (
            <div>
                <div style={{ marginTop: 20 }}>
                    {this.props.processing && <Loader />}

                    {this.props.bookmarksData && this.props.bookmarksData.length > 0 ? (
                        <div>
                            <Treefile columns={this.columns} tableData={this.props.bookmarksData} />
                        </div>
                    ) : (
                        !this.props.processing && (
                            <div style={{ width: '100%' }}>
                                <NoDataFound title={'No Bookmarks found'} />
                            </div>
                        )
                    )}
                </div>
                {this.state.show && (
                    <SnackbarCmpt
                        message={this.state.success ? 'Deleted successfully' : 'Could not delete'}
                        severity={this.state.success ? 'success' : 'error'}
                    />
                )}
                <div>
                    <div style={{ margin: theme.spacing(2) }}>
                        <CustomPagination
                            total={this.props.totalBookmarkRecords}
                            page={this.props.currentBookmarkPage}
                            handlePage={this.handlePage}
                            pageSize={this.props.bookmarksPageSize}
                            handleNewPageSize={this.handleNewPageSize}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        processing: state.favorites_reducer.processing,
        bookmarksData: state.favorites_reducer.bookmarksData,
        removeBookmark: state.favorites_reducer.removeBookmark,
        totalBookmarkRecords: state.favorites_reducer.totalBookmarkRecords,
        bookmarksPageSize: state.favorites_reducer.bookmarksPageSize,
        currentBookmarkPage: state.favorites_reducer.currentBookmarkPage,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getFavouritesData: (type: any, page: any, pageSize: any) => dispatch(getFavouritesData(type, page, pageSize)),
        deleteBookmark: (originalData: any, removeData: any) => dispatch(deleteBookmark(originalData, removeData)),
        updateFavourites: (id: any, data: any, type: any, cb: any) => dispatch(updateFavourites(id, data, type, cb)),
        setPageSizeFavt: (type: any, data: any) => dispatch(setPageSizeFavt(type, data)),
        setCurrentPageFavt: (type: any, data: any) => dispatch(setCurrentPageFavt(type, data)),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(Bookmarks);

export default withRouter(connected);
