import React from 'react';
import BrowseContainer from '../../Containers/Browse';
import ConfigurationsContainer from '../../Containers/Configurations';
import RoleWraper from '../../HOC/RoleWraper';
import { Grid } from '@material-ui/core';
import Breadcrumbs from '../../HOC/Breadcrumbs';
import { BasePath } from '../../config';

const Configurations = (props: any) => {
    console.log(props.location && props.location.pathname === `${BasePath}/configuration`);
    return (
        // @ts-ignore
        <RoleWraper view="Configurations">
            <Grid>
                <Grid container>
                    <Grid xs={12}>
                        {props.location && props.location.pathname === `${BasePath}/configuration` && (
                            <ConfigurationsContainer />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </RoleWraper>
    );
};

export default Configurations;
