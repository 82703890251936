import {
    getUsergroups,
    getError,
    setSelectedUsers,
    setEditData,
    setSelectFromUsers,
    setPageSizeUsergroup,
    setTotal,
    setUsergroupProcessing,
} from '../actions/usergroup';
import axios from '../utils/axios';

export const fetchUsergroups = (page?: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(getUsergroups(null));
        const { pageSizeUsergroup }: any = getState().usergroup_reducer;
        let url = `/api/usergroups?Expand=[users]`;
        if (page) {
            url = `/api/usergroups?Expand=[users]&Page=${page - 1}&PageSize=${pageSizeUsergroup}`;
        }
        dispatch(setUsergroupProcessing(true));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.results) {
                    dispatch(getUsergroups(response.data.result.results));
                    dispatch(setTotal(response.data.result.total));
                    dispatch(setUsergroupProcessing(false));
                } else {
                    dispatch(getUsergroups(response.data.result));
                    dispatch(setUsergroupProcessing(false));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const addUsergroup = (data: any, cb: any) => {
    return (dispatch: any) => {
        let url = `/api/usergroups`;
        dispatch(setUsergroupProcessing(true));
        axios
            .post(url, data)
            .then(response => {
                if (response.data.success) {
                    dispatch(setUsergroupProcessing(false));
                    cb(response.data.res);
                } else {
                    dispatch(setUsergroupProcessing(false));
                    cb(false);
                }
            })
            .catch(err => {
                dispatch(setUsergroupProcessing(false));
                cb(false);
            });
    };
};

export const editUsergroup = (data: any, cb: any) => {
    return (dispatch: any) => {
        let url = `/api/usergroups/${data.id}`;
        dispatch(setUsergroupProcessing(true));
        axios
            .patch(url, data)
            .then(response => {
                if (response.data.success) {
                    dispatch(setUsergroupProcessing(false));
                    cb(response.data.res);
                } else {
                    dispatch(setUsergroupProcessing(false));
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const fetchSelectedUsers = (data: any) => {
    return (dispatch: any) => {
        dispatch(setSelectedUsers(data));
    };
};

export const fetchSelectFromUsers = (data: any) => {
    return (dispatch: any) => {
        dispatch(setSelectFromUsers(data));
    };
};

export const fetchEditData = (data: any) => {
    return (dispatch: any) => {
        dispatch(setEditData(data));
    };
};

export const testUserGroupName = (name: any, cb: any) => {
    return () => {
        let url = `/api/usergroups?Name=${name.Name}`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result && response.data.result.length) {
                    if (name.id) {
                        if (response.data.result.some((cat: any) => cat.id === name.id)) {
                            cb(false);
                        } else {
                            cb(true);
                        }
                    } else {
                        cb(true);
                    }
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const setNewPageSizeUsergroup = (value: any) => {
    return (dispatch: any) => {
        dispatch(setPageSizeUsergroup(value));
    };
};
