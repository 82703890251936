import axios from 'axios';
import { store } from '../index';
import { SERVER_URL ,ORACLE_CLOUD_BASE_URL} from '../config';
import Cookies from 'js-cookie';
import moment from 'moment';
import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { constructErrorMesssage } from './validations';
import { setServerErrorFlag, setServerErrorMessage } from '../actions/user';

const getJwt = () => {
    const { user }: any = store.getState().user_reducer;

    if (user && user.token) {
        return 'Bearer ' + user.token;
    }
    return '';
};

const connected = Cookies.get('connect.sid');

const getOptions = (isFile: any) => {
    let options: any = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    if (isFile) {
        options.responseType = 'blob';
    }

    return options;
};

const prepareUrl = (api: string) => `${SERVER_URL}${api}`;

axios.interceptors.response.use(
    (response: any) => {
        if (response && response.data && response.data.message == 'AuthenticationFailure') {
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('user');
            window.location.replace(`${ORACLE_CLOUD_BASE_URL}/`);
        } else {
            return response;
        }
    },
    error => {
        if (error.response && error.response.data && error.response.data.message == 'AuthenticationFailure') {
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('user');
            window.location.replace(`${ORACLE_CLOUD_BASE_URL}/`);
        } else {
            if (error.response && error.response.data) {
                let string = constructErrorMesssage(error.response.data);
                store.dispatch(setServerErrorFlag(true));
                store.dispatch(setServerErrorMessage(string));
                setTimeout(() => {
                    store.dispatch(setServerErrorFlag(false));
                    store.dispatch(setServerErrorMessage(''));
                }, 3000);
            }
            return Promise.reject(error);
        }
    },
);

const wrapper = {
    get: (api: string, isFile = false) => axios.get(prepareUrl(api), getOptions(isFile)),
    post: (api: string, formData = {}, isFile = false) => axios.post(prepareUrl(api), formData, getOptions(isFile)),
    put: (api: string, formData = {}) => axios.put(prepareUrl(api), formData, getOptions(null)),
    patch: (api: string, formData = {}, isFile = false) => axios.patch(prepareUrl(api), formData, getOptions(isFile)),
    delete: (api: string) => axios.delete(prepareUrl(api), getOptions(null)),
};

export default wrapper;
