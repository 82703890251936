import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { editConfigurations, fetchRedlines, resetRedlinesData } from '../../Services/user';
import { formatDataGroupsForConfigurations } from '../../utils/users';
import { makeStyles, Theme } from '@material-ui/core/styles';
import theme from '../../theme';
import { Divider, Grid, Typography } from '@material-ui/core';
import { containers } from '../../utils/style';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ConfigurationViews from '../../components/Configurations/ConfigurationViews';
import moment from 'moment';
import SnackbarCmpt from '../../components/Snackbar';
import { fetchNotificationDashboard } from '../../Services/dashboard';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PermCameraMicIcon from '@material-ui/icons/PermCameraMic';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Box from '@material-ui/core/Box';
import AttributeData from './AttributeData';
import Page2 from '../Page2';
import Page3 from '../Page3';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import ErrorComponent from '../../components/Error';
import { useHistory } from 'react-router';

interface propsInterface {
    fetchRedlines: any;
    redlinesConfigurationData: any;
    redlinesData: any;
    redlineProcessing: any;
    redisConfigurationTabState: any;
    children?: React.ReactNode;
    index: any;
    value: any;
    location: any;
    history: any;
    resetRedlinesData: any;
    redlinedItem:any;
}
function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={index}
            //   aria-labelledby={simple-tab-${index}}
            //   {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

// function TabPanel(props:any) {
//     const {children, value, index, ...other} = props;

//     return (
//         <div> Hello </div>
//         // <Typography
//         //     component="div"
//         //     role="tabpanel"
//         //     hidden={value !== index}
//         //     id={`scrollable-prevent-tabpanel-${index}`}
//         //     aria-labelledby={`scrollable-prevent-tab-${index}`}
//         //     {...other}
//         // >
//         //     {value === index && <Box p={3}>{children}</Box>}
//         // </Typography>
//     );
// }
const classes = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        //backgroundColor: theme.palette.background.paper,
    },
}));
class index extends Component<propsInterface> {
    state = {
        value: this.props.redisConfigurationTabState,
        show: false,
        showErr: false,
        classType: '',
        className: '',
        itemId: '',
        rev: '',
    };

    componentWillUnmount() {
        this.props.resetRedlinesData();
    }

    //   const [value, setValue] = React.useState(configurationTabState);

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            value: newValue,
        });
    };

    a11yProps(index: any) {
        return {
            id: `scrollable-prevent-tab-${index}`,
            'aria-controls': `scrollable-prevent-tabpanel-${index}`,
        };
    }

    showHeader = () => {
        return (
            <Grid container style={containers}>
                <Grid
                    sm={12}
                    md={3}
                    style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h3">Title Block</Typography>
                </Grid>
            </Grid>
        );
    };

    showData = (key: any, index: any) => {
        // console.log("IN SHOW DATA");
        return <Tab icon={<AssignmentIndIcon />} label={key} aria-label="phone" {...this.a11yProps(index)} />;
    };

    render() {
        return (
            <div>
                {/* className={classes.root} */}

                <Grid container style={{ margin: theme.spacing(3) }}>
                    <Grid
                        xs={4}
                        style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            justifyContent: 'center',
                        }}
                    ></Grid>
                </Grid>

                <div>
                    {this.props.redlinesConfigurationData && (
                        <AppBar position="static" style={{ backgroundColor: 'white' }}>
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                variant="scrollable"
                                scrollButtons="on"
                                // aria-label="scrollable prevent tabs example"
                                indicatorColor="secondary"
                                textColor="primary"
                            >
                                {}
                                {Object.keys(this.props.redlinesConfigurationData).map((key, index) => {
                                    return (
                                        <Tab
                                            // icon={<AssignmentIndIcon/>}
                                            label={key}
                                            aria-label="phone"
                                            {...this.a11yProps(index)}
                                        />
                                    );
                                    //this.showData(key,index);
                                })}
                            </Tabs>
                        </AppBar>
                    )}
                    <div style={{ backgroundColor: '#f4f6f8' }}>
                        <Typography style={{padding:theme.spacing(2), color:"#444444"}} variant={'h4'}>Redlines For {this.props.redlinedItem}</Typography>

                        {this.props.redlineProcessing && <Loader />}
                        {!this.props.redlinesConfigurationData && !this.props.redlineProcessing}
                        {this.props.redlinesConfigurationData &&
                            Object.keys(this.props.redlinesConfigurationData).map((key, index) => {
                                return (
                                    <TabPanel value={this.state.value} index={index}>
                                        <AttributeData
                                            tab={key}
                                            layout={this.props.redlinesConfigurationData[key]}
                                            data={this.props.redlinesData}
                                        />
                                    </TabPanel>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        redlinesConfigurationData: state.user_reducer.redlinesConfigurationData,
        redlinesData: state.user_reducer.redlinesData,
        redlineProcessing: state.user_reducer.redlineProcessing,
        redisConfigurationTabState: state.user_reducer.redisConfigurationTabState,
        redlinedItem: state.user_reducer.redlinedItem,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchRedlines: (classtype: any, classname: any, rev: any, id: any) =>
            dispatch(fetchRedlines(classtype, classname, rev, id)),
        resetRedlinesData: () => dispatch(resetRedlinesData()),
    };
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(index);

export default withRouter(connected);
