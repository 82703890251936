import {
    getError,
    getUserData,
    setEditData,
    setPageSizeUser,
    setTotal,
    setUserProcessing,
    getRoles
} from '../actions/userManagement';
import axios from '../utils/axios';

export const fetchUsers = (page?: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(getUserData(null));
        const { pageSizeUser }: any = getState().userManagement_reducer;
        let url = `/api/users`;
        if (page) {
            url = `/api/users?Page=${page - 1}&PageSize=${pageSizeUser}&OrderByDirection=desc&OrderBy=ModifiedDate`;
        }
        dispatch(setUserProcessing(true));
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.results) {
                    dispatch(getUserData(response.data.result.results));
                    dispatch(setTotal(response.data.result.total));
                    dispatch(setUserProcessing(false));
                } else {
                    dispatch(getUserData(response.data.result));
                    dispatch(setUserProcessing(false));
                }
            })
            .catch(err => {
                dispatch(getError(err));
            });
    };
};

export const editUser = (data: any, cb: any) => {
    return (dispatch: any) => {
        let url = `/api/users`;
        dispatch(setUserProcessing(true));
        axios
            .post(url, data)
            .then(response => {
                if (response.data.success) {
                    dispatch(setUserProcessing(false));

                    cb(response.data.result);
                } else {
                    dispatch(setUserProcessing(false));

                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const addUser = (data: any, cb: any) => {
    return (dispatch: any) => {
        let url = `/api/users`;
        dispatch(setUserProcessing(true));
        axios
            .post(url, data)
            .then(response => {
                if (response.data.success) {
                    dispatch(setUserProcessing(false));
                    cb(response.data.result);
                } else {
                    dispatch(setUserProcessing(false));
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const setUserEditData = (data: any) => {
    return (dispatch: any) => {
        dispatch(setEditData(data));
    };
};

export const testUserName = (name: any, cb: any) => {
    return () => {
        let url = `/api/users?user_name=${name.user_name}`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result && response.data.result.length) {
                    if (name.id) {
                        if (response.data.result.some((cat: any) => cat.id === name.id)) {
                            cb(false);
                        } else {
                            cb(true);
                        }
                    } else {
                        cb(true);
                    }
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};

export const setNewPageSizeUser = (value: any) => {
    return (dispatch: any) => {
        dispatch(setPageSizeUser(value));
    };
};

export const fetchRoles = (cb?: any) => {
    return (dispatch: any, getState: any) => {
        let url = `/api/roles?IsActive=1`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result) {
                    dispatch(getRoles(response.data.result));
                    cb(true)
                } else {
                    cb(false)
                    dispatch(getRoles(null));
                }
            })
            .catch(err => {
                cb(false)
                dispatch(getRoles(null));
            });
    };
};

