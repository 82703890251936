import moment from 'moment';
import React from 'react';

export const ADD_USERGROUP = 'ADD_USERGROUP';
export const DELETE_USERGROUP = 'DELETE_USERGROUP';
export const EDIT_USERGROUP = 'EDIT_USERGROUP';

export const USERGROUP_SORTLIST = [
    { title: 'Name', value: 'GroupName' },
    { title: 'Created By', value: 'CreatedBy' },
    { title: 'Created Date', value: 'CreatedDate' },
];

export const setStausForActiveUser = (oldUsersData: any, newUsersData: any, user: any) => {
    let resultData: any = [];
    oldUsersData.forEach((oldData: any) => {
        if (newUsersData.some((user: any) => user.id === oldData.id)) {
            resultData.push({
                ...oldData,
                MemberActive: 1,
            });
        } else {
            resultData.push({
                ...oldData,
                MemberActive: 0,
            });
        }
    });
    newUsersData.forEach((newData: any) => {
        if (oldUsersData.some((user: any) => user.id === newData.id)) {
        } else {
            resultData.push({
                ...newData,
                MemberActive: 1,
            });
        }
    });

    return resultData;
};

export const removeDuplicated = (searchedUsers: any, activeUsers: any) => {
    let listofAllUser: any = [];

    searchedUsers.map((serchUser: any) => {
        if (!activeUsers.some((user: any) => user.ID === serchUser.ID)) {
            listofAllUser.push(serchUser);
        }
    });
    return listofAllUser;
};

export const checkPermissionForUserGroup = (views: any) => {
    let viewsUserGroups: any = views['UserGroups'];
    let permissions: any = [];
    if (viewsUserGroups) {
        if (viewsUserGroups.some((perm: any) => perm.Name == 'Add Usergroup')) {
            permissions.push(ADD_USERGROUP);
        }
        if (viewsUserGroups.some((perm: any) => perm.Name == 'Usergroups')) {
            permissions.push(EDIT_USERGROUP);
        }
        if (viewsUserGroups.some((perm: any) => perm.Name == 'Save User Group')) {
            permissions.push(DELETE_USERGROUP);
        }
    }
    return permissions;
};
