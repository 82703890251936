export const LOADING_DATA_FORM = 'LOADING_DATA_FORM';
export const SET_ITEM_DATA = 'SET_ITEM_DATA';
export const SET_USER = 'SET_USER';

export const SET_USERS_PROCESSING = 'SET_USER_PROCESSING';
export const SET_USERS_ERROR = 'SET_USER_ERROR';
export const SET_SEARCHED_USERS = 'SET_SEARCHED_USERS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const SET_EDIT_USER = 'SET_EDIT_USER';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_VIEWS = 'SET_VIEWS';
export const SET_TOTAL_USERS = 'SET_TOTAL_USERS';
export const GET_COURSES_TO_APPROVE = 'GET_COURSES_TO_APPROVE';
export const GET_TRAININGS_TO_APPROVE = 'GET_TRAININGS_TO_APPROVE';
export const SET_CONFIGURATION_DATA = 'SET_CONFIGURATION_DATA';
export const SET_CONFIGURATION_TABS_STATE = 'SET_ASSIGNMENTS_TABS_STATE';
export const SET_AUDIT_TRAIL_DATA = 'SET_AUDIT_TRAIL_DATA';
export const SET_AUDIT_TRAIL_DATA_PROCESSING = 'SET_AUDIT_TRAIL_DATA_PROCESSING';
export const SET_EVENT = 'SET_EVENT';
export const SET_AUDIT_TOTAL = 'SET_AUDIT_TOTAL';

export const SET_MODULES = 'SET_MODULES';

export const SET_PAGE_SIZE_USER = 'SET_PAGE_SIZE_USER';

export const SET_SS_ERROR_FLAG = 'SET_SS_ERROR_FLAG';
export const SET_SS_ERROR_MSG = 'SET_SS_ERROR_MSG';

export const SET_REDLINED_ITEM = 'SET_REDLINED_ITEM';
export const SET_REDLINES_DATA = 'SET_REDLINES_DATA';
export const SET_REDLINES_CONFIG = 'SET_REDLINES_CONFIG';
export const SET_REDLINE_PROCESSING = 'SET_REDLINE_PROCESSING';
export const SET_REEDLINES_CONFIGURATION_TABS_STATE = 'SET_REEDLINES_CONFIGURATION_TABS_STATE';
