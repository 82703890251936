import {
    setClassTypeProcessing,
    setClassTypeData,
    setClassTypeError,
    setClassTypeTabsProcessing,
    setClassTypeTabsData,
    setClassTypeTabsError,
    setGeneralConfig,
    setGeneralConfigProcessing,
} from '../actions/configurations';
import axios from '../utils/axios';
export const updateClassTypeTabs = (classType: string, classTypeTabs: any) => {
    return (dispatch: any, getState: any) => {
        let url = `/api/tabs/${classType}`;
        dispatch(setClassTypeTabsProcessing(true));
        dispatch(setClassTypeTabsData(null));
        axios
            .patch(url, classTypeTabs)
            .then((response: any) => {
                dispatch(fetchClassTypeTabs(classType));
            })
            .catch(err => {
                dispatch(fetchClassTypeTabs(classType));
            });
    };
};
export const fetchClassTypes = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setClassTypeProcessing(true))
        dispatch(setClassTypeData(null));
        dispatch(setClassTypeTabsData(null));

        let url = `/api/classTypes`;

        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.length) {
                    let data: any = response.data.result;
                    dispatch(setClassTypeProcessing(false));
                    dispatch(setClassTypeData(data));
                    //TODO: fetch tabs data for given class type.
                    dispatch(fetchClassTypeTabs(data[0]));
                } else {
                    dispatch(setClassTypeProcessing(false));
                    dispatch(setClassTypeError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setClassTypeProcessing(false));
                dispatch(setClassTypeError('Something bad happened!'));
            });
    };
};
export const fetchClassTypeTabs = (classType: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setClassTypeTabsProcessing(true));
        dispatch(setClassTypeTabsData(null));
        dispatch(setClassTypeTabsError(null));
        let url = `/api/tabs/${classType}`;
        axios
            .get(url)
            .then((response: any) => {
                if (response.data.result.length) {
                    let data: any = response.data.result;
                    dispatch(setClassTypeTabsProcessing(false));
                    dispatch(setClassTypeTabsData(data));
                    dispatch(setClassTypeTabsError(null));
                } else {
                    dispatch(setClassTypeTabsProcessing(false));
                    dispatch(setClassTypeTabsError('No data found'));
                }
            })
            .catch(err => {
                dispatch(setClassTypeTabsProcessing(false));
                dispatch(setClassTypeTabsError('Something bad happened!'));
            });
    };
};

export const fetchGeneralConfigurations = () =>{
    return(dispatch:any)=>{
        let url='/api/configurations'
        dispatch(setGeneralConfigProcessing(true))
        axios
        .get(url)
        .then((response:any)=>{
            if(response.data.result){
            dispatch(setGeneralConfig(response.data.result))
            dispatch(setGeneralConfigProcessing(false))}
            else{
                dispatch(setGeneralConfig([]))
                dispatch(setGeneralConfigProcessing(false))
            }
        })
        .catch(err => {
            dispatch(setGeneralConfigProcessing(false));
        });
    }
}
export const updateGeneralConfig = (config : any,updated:any) =>{
    return (dispatch:any)=>{
        let url='/api/configurations'
        dispatch(setGeneralConfigProcessing(true))
        axios
        .post(url,updated)
        .then((response:any)=>{
            if(response.data.success){
                dispatch(setGeneralConfig(config))
                dispatch(setGeneralConfigProcessing(false))
            }
            else{
                dispatch(setGeneralConfigProcessing(false))
            }
        })
        .catch(err => {
            dispatch(setGeneralConfigProcessing(false));
        });
    }
}