import {
    getRules,
    rulesProcessing,
    getRulesUsergroup
} from '../actions/rules';
import axios from '../utils/axios';


export const fetchAllRules = () => {
    return (dispatch:any) => {
        let url = `/api/rules`;
        dispatch(rulesProcessing(true));
        dispatch(getRules(null));
        axios
            .get(url)
            .then((response: any) => {
                if(response && response.data && response.data.result){
                    dispatch(rulesProcessing(false));
                    dispatch(getRules(response.data.result));
                }else{
                    dispatch(rulesProcessing(false));
                }
            })
            .catch((err:any)=>{
                dispatch(rulesProcessing(false));
            })
    }
}


export const fetchAllRulesUsergroup = (id:any) => {
    return (dispatch:any) => {
        let url = `/api/rules/${id}/usergroups`;
        dispatch(rulesProcessing(true));
        dispatch(getRulesUsergroup(null));
        axios
            .get(url)
            .then((response: any) => {
                if(response && response.data && response.data.result){
                    dispatch(rulesProcessing(false));
                    dispatch(getRulesUsergroup(response.data.result));
                }else{
                    dispatch(rulesProcessing(false));
                }
            })
            .catch((err:any)=>{
                dispatch(rulesProcessing(false));
            })
    }
}


export const assignRuleToUser = (id: any, data: any, cb: any) => {
    return (dispatch: any, getState: any) => {
        const { user }: any = getState().user_reducer;
        let url = `/api/rules/${id}`;
        let fromData = { ...data, ModifiedBy: user.user_name, ModifiedDate: new Date() };
        axios
            .patch(url, fromData)
            .then(response => {
                if (response.data.success) {
                    cb(true);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};


export const addRule = (data: any, cb: any) => {
    return (dispatch: any, getState: any) => {
        const { user }: any = getState().user_reducer;
        let url = `/api/rules`;
        let fromData = { ...data, 
            CreatedBy:user.user_name,
            CreatedDate:  new Date(),
            ModifiedBy: null, 
            ModifiedDate: null};
        axios
            .post(url, fromData)
            .then(response => {
                if (response.data.success) {
                    dispatch(fetchAllRules())
                    cb(response.data.res);
                } else {
                    cb(false);
                }
            })
            .catch(err => {
                cb(false);
            });
    };
};