import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useDispatch, useSelector } from 'react-redux';
import { setStateOfFavModal } from '../../Services/favorites';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 500,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #fff',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }),
);

export default function FavouritesModals(props: any) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    let { modalState } = useSelector((state: any) => ({
        ...state.favorites_reducer,
    }));
    const dispatch = useDispatch();

    const handleOpen = () => {
        dispatch(setStateOfFavModal(true));
    };

    const handleClose = () => {
        dispatch(setStateOfFavModal(false));
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div style={{ width: '100%' }}>{props.children}</div>
        </div>
    );

    return (
        <div>
            <Button
                variant="contained"
                color="default"
                size="small"
                onClick={handleOpen}
                startIcon={props.type == 'search' ? <SaveIcon /> : <BookmarkIcon />}
            >
                {props.title}
            </Button>
            <Modal
                open={modalState}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}
